// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { CartItem as CartItemType } from '@/types/models';
import type { CartStoreState } from '@/types/stores';

import { CartStoreActions } from '@/reflux';
import { prettyPrice } from '@/utils/misc';
import { useStore } from '@/hooks';
import { APIContext } from '@/globals/contexts';

import { Button, Icon, InputFileUpload } from '@/components/new';
import { RichTextContent } from '@/components/old';
import SubscriptionFormulaCartItem from './SubscriptionFormula';
import DefaultItem from './Default';

const JUSTIFICATIVE_NEEDS_CONNECTION =
  "Merci de vous connecter afin d'uploader votre justificatif.";

type Props = {|
  item: CartItemType<*>,
  id: string,
  disabled: boolean,
  errors: Array<string>,
|};

function CartItem({ item, id, errors, disabled }: Props): React.Node {
  const [localError, setLocalError] = React.useState(null);
  const { userContributionUploadUrl } = React.useContext(APIContext);
  const { entity, quantity, justificative } = item;
  const cartState = useStore<CartStoreState>('CartStore');
  const { discountCode, discountedItems } = cartState;

  const discountSet = !!discountCode;
  const discountedPrice = discountedItems[id]?.discountPrice;

  let ItemComponent = null;

  switch (entity._cls) {
    case 'SubscriptionFormula':
      ItemComponent = SubscriptionFormulaCartItem;
      break;
    default:
      ItemComponent = DefaultItem;
  }

  return (
    <div
      className={classnames('cart-item', {
        'no-discount': discountSet && !discountedPrice,
        'has-discount': discountSet && !!discountedPrice,
      })}
    >
      {discountedPrice && <span className="tag promotion">Promo</span>}

      <div className="item-wrapper">
        <ItemComponent id={id} item={item} />
      </div>

      {entity.allowChangeQuantity && (
        <div className="quantity">
          {disabled ? (
            <span>x{quantity}</span>
          ) : (
            <>
              <Button
                onClick={() =>
                  CartStoreActions.updateQuantity(entity, quantity + 1)
                }
                additionalClassName="increment-quantity"
              >
                <Icon name="add" />
              </Button>
              <span>{quantity}</span>
              <Button
                onClick={() =>
                  CartStoreActions.updateQuantity(entity, quantity - 1)
                }
                additionalClassName="decrement-quantity"
              >
                <Icon name="trash" />
              </Button>
            </>
          )}
        </div>
      )}

      {entity.requireJustif && (
        <div className="justificative">
          {entity.justifText && (
            <RichTextContent
              className="RegularForm-label"
              html={entity.justifText}
            />
          )}

          <div className="justificative-upload-control">
            <InputFileUpload
              value={justificative}
              error={localError}
              disabled={disabled}
              path={userContributionUploadUrl}
              onChange={({ file: newJustificative, url }) => {
                return CartStoreActions.addJustificativeToItem(id, {
                  ...newJustificative,
                  url,
                });
              }}
              onError={(error) =>
                setLocalError(
                  error.toString() === 'Error: Unauthorized'
                    ? JUSTIFICATIVE_NEEDS_CONNECTION
                    : error.toString()
                )
              }
            />
          </div>
        </div>
      )}

      <div className="cart-item-footer">
        {errors.length > 0 ? (
          <div className="cart-item-error">
            {errors.length > 1 ? (
              <ul>
                {errors.map((error) => (
                  <li>{error}</li>
                ))}
              </ul>
            ) : (
              errors[0]
            )}
          </div>
        ) : (
          <div />
        )}

        <div className="cart-item-actions">
          <div className="cart-item-price">
            {discountedPrice && (
              <span className="discounted-price">
                {prettyPrice(discountedPrice, { forceInteger: true })}
              </span>
            )}

            <span className="original-price">{prettyPrice(entity.price)}</span>
          </div>

          {!disabled && (
            <Button
              additionalClassName="remove-from-cart"
              color="light"
              size="small"
              onClick={() => CartStoreActions.removeItem(id)}
            >
              <Icon name="trash" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CartItem;
