import * as React from 'react';
import classnames from 'classnames';

import { Flex, Image } from '@/components/new';
import EntityLink from '../../navigation/EntityLink';
import FutureGames from '../FullParticipant/FutureGames';
import PastGames from '../FullParticipant/PastGames';
import Lineup from './Lineup';

export default function FullTournamentParticipant({ entity }) {
  const {
    _id: participantId,
    label,
    image,
    history,
    lineup,
    linkedContents,
    teams,
  } = entity;

  const hasLinkedContents = linkedContents.length > 0;

  return (
    <Flex direction="column" additionalClassName="tournament_participant--full">
      <Flex
        direction="column"
        additionalClassName={classnames('title-flag_wrapper', {
          'has-linked-contents': hasLinkedContents,
        })}
      >
        <h1>{label}</h1>

        <Image
          src={image}
          width={150}
          height={150}
          alt={`Drapeau ${label} `}
          defaultNextImage
          additionalClassName="participant-image"
        />

        {hasLinkedContents && (
          <EntityLink entity={linkedContents[0]}>
            <a className="prez_wrapper" style={{ fontSize: 0 }}>
              <Image
                src={linkedContents[0].image}
                width={509}
                height={287}
                alt={`Drapeau ${label}`}
                defaultNextImage
                additionalClassName="thumbnail"
                objectFit="cover"
              />

              <h2 className="title">{linkedContents[0].label}</h2>
            </a>
          </EntityLink>
        )}
      </Flex>

      <Flex direction="column" additionalClassName="games-history">
        <h2>Matchs</h2>

        <div>
          {Object.values(history).map((tournamentHistory) => {
            const { futureGames, pastGames, label } = tournamentHistory;

            return (
              <div key={label} className="tournament-history">
                <EntityLink entity={tournamentHistory}>
                  <a>
                    <h3>{label}</h3>
                  </a>
                </EntityLink>
                <div className="matchs_wrapper">
                  <PastGames
                    games={pastGames}
                    teams={teams}
                    participantId={participantId}
                  />

                  <FutureGames
                    games={futureGames}
                    participantId={participantId}
                    teams={teams}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Flex>

      {!!lineup && lineup.length > 0 && <Lineup lineup={lineup} />}
    </Flex>
  );
}
