import moment from 'moment';
import React from 'react';

import { SiteContext } from '@/globals/contexts';

import { UserStore, UserActions, InstanceStore } from '@/reflux';
import withContext from '@/utils/HOC/withContext';

import { InputSelectBase, Button } from '@/components/old';
import { InformationBubble } from '@/components/new';
import FrontComponentBase from '../../FrontComponentBase';

class Profile extends FrontComponentBase {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      pass: '',
      firstName: '',
      lastName: '',
      nickname: '',
      viewPass: false,
    };

    this.stores = [InstanceStore, UserStore];
  }

  didMount() {
    if (this.state.user) this.initFromUser(this.state.user);
    UserActions.set.listen((user) => this.initFromUser(user));
  }

  initFromUser = (user) => {
    this.setState(user);

    if (!!user)
      this.setState({
        birthDate:
          user.birthDateTs &&
          moment.unix(user.birthDateTs).format('YYYY-MM-DD'),
      });
  };

  onValidate = () => {
    // TODO other checks
    let { firstName, lastName } = this.state;

    let isValid = true;
    this.setState({ error: null, success: false });

    if (!firstName || !lastName) {
      this.setState({ error: 'Merci de saisir vos nom et prénom' });
      isValid = false;
    }

    this.props.onValidate && this.props.onValidate(isValid);
    return isValid;
  };

  onSubmit = () => {
    if (this.onValidate()) {
      // ! see also FIELDS_FOR_PROFILE_UPDATE in server
      let {
        firstName,
        lastName,
        user,
        address,
        tel,
        title,
        nickname,
        birthDate,
      } = this.state;

      this.setState({ loading: true, success: false });

      UserActions.update(
        {
          _id: user._id,
          firstName,
          lastName,
          nickname,
          address,
          tel,
          title,
          name: nickname || firstName + ' ' + lastName,
          birthDateTs: moment(birthDate).isValid()
            ? moment(birthDate).unix()
            : null,
        },
        (err, user) => {
          this.setState({
            error: err && err.message,
            loading: false,
            success: !err,
          });
          this.props.onSuccess && this.props.onSuccess(user, 'profile_update');
        }
      );
    }
  };

  onChangeField = (fieldInfo, value) => {
    this.setState({ [fieldInfo.field]: value });
  };

  render() {
    const componentBaseClass = 'RegularForm';

    const {
      firstName,
      lastName,
      nickname,
      birthDate,
      title,
      tel,
      loading,
      error,
      success,
    } = this.state;

    return (
      <form
        className={
          'profile-form ' +
          componentBaseClass +
          ' ' +
          componentBaseClass +
          '--register'
        }
      >
        <div className={componentBaseClass + '-section'}>
          {this.props.label && <h2>{this.props.label}</h2>}
          <div className={componentBaseClass + '-field'}>
            <label className="RegularForm-field-label">
              <span className="RegularForm-field-label-inner">Civilité :</span>
            </label>

            <InputSelectBase
              useValue={true}
              options={[
                { label: 'M.', value: 'M.' },
                { label: 'Mme', value: 'Mme' },
              ]}
              name={'title'}
              autoComplete={'title'}
              placeholder="civilité"
              required={false}
              type="text"
              value={title}
              onChange={(value) =>
                this.setState({ title: value }, this.onValidate)
              }
            />
          </div>
          <div className={componentBaseClass + '-field'}>
            <label className="RegularForm-field-label">
              <span className="RegularForm-field-label-inner">Nom :</span>
            </label>

            <input
              name="lastName"
              autoComplete="family-name"
              placeholder="Nom*"
              className={componentBaseClass + '-text-input'}
              type="text"
              value={lastName}
              onChange={(e) =>
                this.setState({ lastName: e.target.value }, this.onValidate)
              }
            />
          </div>
          <div className={componentBaseClass + '-field'}>
            <label className="RegularForm-field-label">
              <span className="RegularForm-field-label-inner">Prénom :</span>
            </label>

            <input
              name="firstName"
              autoComplete="given-name"
              placeholder="Prénom*"
              className={componentBaseClass + '-text-input'}
              type="text"
              value={firstName}
              onChange={(e) =>
                this.setState({ firstName: e.target.value }, this.onValidate)
              }
            />
          </div>

          <InformationBubble size="small" style={{ marginTop: 30 }}>
            Si vous souhaitez que votre nom n'apparaîsse pas sur le site, merci
            de choisir un pseudonyme.
          </InformationBubble>
          <div className={componentBaseClass + '-field'}>
            <label className="RegularForm-field-label">
              <span className="RegularForm-field-label-inner">
                Pseudonyme :
              </span>
            </label>

            <input
              name="nickname"
              autoComplete="nickname"
              placeholder="Pseudonyme"
              className={componentBaseClass + '-text-input'}
              type="text"
              value={nickname}
              onChange={(e) =>
                this.setState({ nickname: e.target.value }, this.onValidate)
              }
            />
          </div>

          <div className={componentBaseClass + '-field'}>
            <label className="RegularForm-field-label label-needed">
              <span className="RegularForm-field-label-inner">
                Date de naissance :
              </span>
            </label>

            <input
              name="birthDate"
              autoComplete="birthDate"
              placeholder="Date de naissance" // does not work on date input!
              className={componentBaseClass + '-text-input'}
              type="date"
              value={birthDate}
              onChange={(e) =>
                this.setState({ birthDate: e.target.value }, this.onValidate)
              }
            />
          </div>

          <div className={componentBaseClass + '-field'}>
            <label className="RegularForm-field-label">
              <span className="RegularForm-field-label-inner">Téléphone :</span>
            </label>

            <input
              name="telephone"
              autoComplete="tel"
              placeholder="Téléphone"
              className={componentBaseClass + '-text-input'}
              type="tel"
              value={tel}
              onChange={(e) =>
                this.setState({ tel: e.target.value }, this.onValidate)
              }
            />
          </div>
        </div>

        <div className={componentBaseClass + '-submit-wrapper'}>
          <Button
            isSubmit={true}
            disabled={loading || error}
            onClick={this.onSubmit}
          >
            {success ? '✔ Ok' : 'Enregistrer'}
          </Button>
        </div>

        {error && <div className={componentBaseClass + '-error'}>{error}</div>}
      </form>
    );
  }
}

export default withContext(SiteContext, 'siteContext', Profile);
