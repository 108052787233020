import _ from 'lodash';
import React from 'react';

import { SiteContext } from '@/globals/contexts';
import { UserStore } from '@/reflux';
import { withLinks, withContext } from '@/utils/HOC';

import FrontComponentBase from '../../FrontComponentBase';
import PageLink from '../navigation/PageLinkV1';

class PauseScreenPremium extends FrontComponentBase {
  store = UserStore;

  render() {
    const {
      links: {
        login: { register: registerLink, login: loginLink },
      },
    } = this.props;
    let content = this.getSingleEntity();
    let user = this.state.user;

    return (
      <div className={this.getMainCSSClass()}>
        {(content && content.premiumText && (
          <div>{content.premiumText}</div>
        )) || (
          <div>
            Pour voir l'intégralité de toutes les vidéos du site, merci de vous
            abonner ou de vous connecter si vous êtes déjà abonné :
          </div>
        )}
        <SiteContext.Consumer>
          {(
            siteContext // TODO use UserButtonsCustom
          ) => {
            return (
              <div>
                <PageLink link={registerLink}>
                  <a className={'ContentVideo-register-button standard-button'}>
                    {_.get(siteContext, 'siteConfig.options.registerLabel') ||
                      "S'abonner"}
                  </a>
                </PageLink>

                {!user && (
                  <PageLink link={loginLink}>
                    <a className={'ContentVideo-login-button standard-button'}>
                      {_.get(siteContext, 'siteConfig.options.loginLabel') ||
                        'Se connecter'}
                    </a>
                  </PageLink>
                )}
              </div>
            );
          }}
        </SiteContext.Consumer>
      </div>
    );
  }
}

export default withContext(
  SiteContext,
  'siteContext',
  withLinks(PauseScreenPremium)
);
