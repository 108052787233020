// @flow
import * as React from 'react';

import type { Address } from '@/types/models';

import Register from '@/PUFComponents/base/login/Register';

type Props = {|
  allowSimpleRegister: boolean,
  prefill?: ?Address, // temporary: wrong way of doing things but need to for Furia
|};

export default function Step1Register({
  allowSimpleRegister,
  prefill,
}: Props): React.Node {
  return (
    <div className="step-1-register">
      <Register
        prefill={prefill}
        options={{
          stayOnPage: true,
          simpleMode: allowSimpleRegister,
        }}
      />
    </div>
  );
}
