import React from 'react';

import { Button } from '@/components/new';
import PageLinkV2 from './PageLinkV2';

const baseClassName = 'PUF-link';

export default function Link({ options = {}, style }) {
  const { label, to, isButton, size, color } = options;

  return (
    <PageLinkV2 link={to}>
      {(href) => (
        <Button
          style={style}
          isText={!isButton}
          href={href}
          size={size || 'large'}
          element="a"
          color={color?.type}
          target={to?.openInANewTab ? '_blank' : undefined}
        >
          {label}
        </Button>
      )}
    </PageLinkV2>
  );
}

Link.baseClassName = baseClassName;
