// @flow
import * as React from 'react';

import type { Style } from '@/components/new/types';

type Props = {|
  style?: Style,
|};

export default function SoftMaintenance({ style }: Props): React.Node {
  return (
    <div className="soft-maintenance-alert" style={style}>
      <h3>Site en maintenance</h3>
      <p>
        Le rétablissement de certaines fonctionnalités est prévu d'ici quelques
        heures. Merci de votre compréhension !
      </p>
    </div>
  );
}
