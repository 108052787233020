import React from 'react';
import _ from 'lodash';

import FrontComponentBase from '../../FrontComponentBase';

class PageTitle extends FrontComponentBase {
  static baseClassName = 'Page-title';

  render() {
    let options = this.getOptions();
    let label = _.get(this.props, 'data.page.label');

    return (
      (options.useH1 && (
        <h1 className={this.getMainCSSClass()}>{label}</h1>
      )) || <div className={this.getMainCSSClass()}>{label}</div>
    );
  }
}

export default PageTitle;
