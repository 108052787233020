// @flow
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import type { User } from '@/types/models';
import type { PaymentIntent } from '@/types/stripe';

import RawPaymentInput from './RawPaymentInput';

type Props = {|
  onPaymentInformationEntered: (PaymentIntent) => Promise<any>,
  actionLabel: string,
  intent: PaymentIntent,
  user: User,
  disabled: boolean,
  title?: string,
|};

export default function PaymentInput({
  title,
  onPaymentInformationEntered,
  actionLabel,
  intent,
  user,
  disabled,
}: Props): React.Node {
  const [submitting, setSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const elements = useElements();
  const stripe = useStripe();

  const onSubmit = async (e) => {
    if (disabled) return;
    if (e) {
      e.preventDefault();
    }

    if (!stripe || !elements) {
      return;
    }

    try {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const { client_secret: clientSecret } = intent;
      setSubmitting(true);

      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: `${user.firstName} ${user.lastName}`,
            email: user.login,
          },
        },
      });

      if (result.error) {
        Sentry.captureMessage(
          `Credit card charge declined ${JSON.stringify(result.error)}`
        );
        console.error(result.error);
        setError(result.error.message);
      } else {
        onPaymentInformationEntered(result.paymentIntent);
      }
    } catch (e) {
      console.error(e);
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <RawPaymentInput
      title={title}
      actionLabel={actionLabel}
      onSubmit={onSubmit}
      error={error}
      submitting={submitting}
      disabled={disabled}
    />
  );
}
