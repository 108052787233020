// @flow
import * as React from 'react';

import type { FullCartState } from '@/types/stores';

import { CartStoreActions } from '@/reflux';

import { InputCheckboxBase } from '@/components/old';
import AddressForm from '@/PUFComponents/base/user/LegacyAddressForm';
import GiftInfo from './GiftInfo';
import Errors from './Errors';

type Props = {|
  cartState: FullCartState,
  disabled: boolean,
|};

export default function Gift({ cartState, disabled }: Props): React.Node {
  const {
    isGift,
    giftInfo,
    someItemsAreGiftable,
    shippingAddress,
    requiresShipping,
    uiErrors: { gift: errors },
  } = cartState;

  if (!someItemsAreGiftable) {
    return (
      <>
        <InputCheckboxBase
          label={
            <label className="gift-checkbox-label">
              Cochez cette case si la commande est un cadeau
            </label>
          }
          value={false}
          disabled={true}
          disabledReason="Les produits sélectionnés ne peuvent pas être offerts en cadeau"
        />
      </>
    );
  }

  return (
    <div className="gift">
      <InputCheckboxBase
        label={
          <label className="gift-checkbox-label">
            Cochez cette case si la commande est un cadeau
          </label>
        }
        clickableLabel
        value={isGift}
        onChange={(newIsGift) =>
          !disabled ? CartStoreActions.setIsGift(newIsGift) : undefined
        }
        disabled={disabled}
      />

      {isGift && !!giftInfo && (
        <>
          <GiftInfo giftInfo={giftInfo} disabled={disabled}>
            {requiresShipping && (
              <>
                <hr className="separator" />

                <h4>Adresse de livraison du bénéficiaire</h4>
                <p>
                  Entrez l'adresse de livraison de votre ami si vous la
                  connaissez, à défaut rentrez la vôtre. Il sera de toute façon
                  demandé à votre ami de la confirmer lors de la réception du
                  cadeau.
                </p>
                <AddressForm
                  name="shipping"
                  type="de livraison"
                  value={shippingAddress}
                  disabled={disabled}
                  onChange={(address) =>
                    CartStoreActions.setAddress('shippingAddress', address)
                  }
                />
              </>
            )}
          </GiftInfo>

          {errors.length > 0 && (
            <div style={{ marginTop: 10 }}>
              <Errors errors={errors} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
