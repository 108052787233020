// @flow
import * as React from 'react';

import type { CartItem, SubscriptionFormula } from '@/types/models';

import { CartStoreActions } from '@/reflux';
import { useGlobal } from '@/hooks';

import { RichTextContent } from '@/components/old';
import { Text } from '@/components/new';

type Props = {|
  item: CartItem<SubscriptionFormula>,
  id: string,
|};

export default function SubscriptionFormulaCartItem({
  item,
  id,
}: Props): React.Node {
  const { entity: formula } = item;

  return (
    <>
      <div className="entity-name">{formula.label}</div>

      {formula.cartDecription && (
        <RichTextContent
          className="entity-description"
          html={formula.cartDecription}
        />
      )}

      <MagazineIssueSelection item={item} itemId={id} />
    </>
  );
}

type MagazineIssueSelectionProps = {|
  item: CartItem<SubscriptionFormula>,
  itemId: string,
|};

function MagazineIssueSelection({ item, itemId }: MagazineIssueSelectionProps) {
  const { entity: formula, options } = item;
  const {
    site: { servableMagazineIssues },
  } = useGlobal();

  React.useEffect(() => {
    if (
      formula.needsMagazineIssueSelection &&
      servableMagazineIssues.length > 0 &&
      !options?.firstIssue
    ) {
      CartStoreActions.addItemOptions(itemId, {
        firstIssue: servableMagazineIssues[0].number,
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!formula.needsMagazineIssueSelection) return null;
  if (!options?.firstIssue) return null;

  return (
    <div className="magazine-issue-selection">
      {servableMagazineIssues.length > 1 && (
        <div className="formula-issues">
          <div className="headline">
            Choisissez le premier numéro de votre abonnement
          </div>

          {servableMagazineIssues.map((issue) => {
            const issueId = issue.number;
            const issueLabel = [issue.period, issue.label || issue.number].join(
              ' - '
            );
            const onChange = () =>
              CartStoreActions.addItemOptions(itemId, {
                firstIssue: issueId,
              });

            return (
              <div key={issueId} className="formula-issue">
                <input
                  type="radio"
                  id={issueId}
                  checked={!!options && options.firstIssue === issueId}
                  value={issueId}
                  onChange={onChange}
                />
                <label for={issue._id} onClick={onChange}>
                  {issueLabel}
                </label>
              </div>
            );
          })}
        </div>
      )}

      <Text
        color="black"
        weight="bold"
        size={7}
        element="p"
        transformation="italic"
      >
        Votre abonnement commencera à la revue n°
        {options.firstIssue}
      </Text>
    </div>
  );
}
