/**
 
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import moment from 'moment';
import React from 'react';

import { getFirst } from '@/utils/misc';

import EntityIsFreeLabel from '../../../base/entity/EntityIsFreeLabel';
import EntityLink from '../../../base/navigation/EntityLink';
import FrontComponentBase from '../../../FrontComponentBase';
import VideoPoster from '../../medias/VideoPosterV1';
import { RichTextContent } from '@/components/old';

// Must match CSS!
const ZOOM_FACTOR = 1.8;
const ANIM_DURATION = 500;

class ContentThumb extends FrontComponentBase {
  static baseClassName = 'ContentThumb';

  rootRef = React.createRef();
  zoomableRef = React.createRef();

  onMouseEnter = (e) => {
    if (!this.zoomableRef.current || !this.rootRef.current) {
      return console.warn(
        'Mouse enter but no ref?! Please check the refs zoomableRef/rootRef'
      );
    }

    let rect = this.rootRef.current.getBoundingClientRect();
    let leftAfterZoom = rect.left - rect.width / ZOOM_FACTOR;
    let rightAfterZoom = rect.right + rect.width / ZOOM_FACTOR;

    let el = this.zoomableRef.current;
    el.style.zIndex = 3; // in front

    // Prevent zoomed content to go off-screen : adjust x position by changing transform-origin
    if (leftAfterZoom < 0) {
      el.style.transformOrigin = -leftAfterZoom / ZOOM_FACTOR + 'px center';
    } else if (rightAfterZoom > window.innerWidth) {
      el.style.transformOrigin =
        rect.width -
        (rightAfterZoom - window.innerWidth) / ZOOM_FACTOR +
        'px center';
    } else {
      el.style.transformOrigin = ''; // = center center
    }
  };

  onMouseLeave = () => {
    let el = this.zoomableRef.current;

    if (el) {
      // disabled - perf impac too gret on Chrome
      //el.style.zIndex = 2;  // still in front, but behind another thumb zooming

      this.setTimer(() => {
        el.style.zIndex = 1; // restore original value
        el.style.transformOrigin = ''; // restore original value (probably not needed)
      }, ANIM_DURATION + 1);
    }
  };

  render() {
    let content = this.props.content || this.getSingleEntity();

    if (this.state.draftMode && !content) {
      // "no display" condition
      return (
        <div className="draft-placeholder">{this.props.component.label}</div>
      );
    }

    let { contentType, desc, publishTs, eventTs, label, authors } = content;

    moment.locale('fr');
    let publicDate = eventTs || publishTs;
    let displayedDate =
      publicDate && moment.unix(publicDate).format('DD/MM/YYYY');

    let options = this.getOptions();
    let mode = this.props.mode || options.mode || 'simple'; // mode simple never used here - replaced by ContentThumbCiceron !

    /*** CSS classes ***/

    let componentBaseClass = this.constructor.baseClassName;

    let mainContentType = getFirst(contentType);

    // TODO handle color scheme

    return (
      <div
        ref={this.rootRef}
        className={this.getMainCSSClass(
          mainContentType && componentBaseClass + '--' + mainContentType
        )}
      >
        <EntityLink entity={content}>
          {(mode === 'large' && (
            <a
              className={componentBaseClass + '-link'}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              ref={this.zoomableRef}
            >
              <div className={componentBaseClass + '-poster '}>
                <VideoPoster
                  content={content}
                  wrappedByLink={true}
                  size={this.props.size}
                />
              </div>

              <div className={componentBaseClass + '-infos'}>
                <div className={componentBaseClass + '-infos-inner'}>
                  {label && (
                    <div className={componentBaseClass + '-title'}>
                      {label} <EntityIsFreeLabel entity={content} />
                    </div>
                  )}

                  {desc && (
                    <RichTextContent
                      className={componentBaseClass + '-desc'}
                      html={desc}
                    />
                  )}

                  <div className={componentBaseClass + '-footer'}>
                    {authors && authors.length > 0 && (
                      <div className={componentBaseClass + '-authors'}>
                        <span>Par </span>
                        {authors.map(
                          (author) =>
                            author && (
                              <span
                                key={author._id}
                                className={componentBaseClass + '-authors-item'}
                              >
                                {author.label || 'Anonyme'}
                              </span>
                            )
                        )}
                      </div>
                    )}

                    {displayedDate && (
                      <div className={componentBaseClass + '-date'}>
                        {displayedDate}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </a>
          )) ||
            (mode === 'detailed' && (
              <a
                className={componentBaseClass + '-link'}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                ref={this.zoomableRef}
              >
                <div className={componentBaseClass + '-poster '}>
                  <VideoPoster
                    content={content}
                    wrappedByLink={true}
                    size={this.props.size}
                  />
                </div>

                <div className={componentBaseClass + '-infos'}>
                  <div className={componentBaseClass + '-infos-inner'}>
                    {label && (
                      <div className={componentBaseClass + '-title'}>
                        {label} <EntityIsFreeLabel entity={content} />
                      </div>
                    )}

                    {desc && (
                      <RichTextContent
                        className={componentBaseClass + '-desc'}
                        html={desc}
                      />
                    )}

                    <div className={componentBaseClass + '-footer'}>
                      {authors && authors.length > 0 && (
                        <div className={componentBaseClass + '-authors'}>
                          <span>Par </span>
                          {authors.map(
                            (author) =>
                              author && (
                                <span
                                  key={author._id}
                                  className={
                                    componentBaseClass + '-authors-item'
                                  }
                                >
                                  {author.label || 'Anonyme'}
                                </span>
                              )
                          )}
                        </div>
                      )}

                      {displayedDate && (
                        <div className={componentBaseClass + '-date'}>
                          {displayedDate}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </a>
            )) ||
            (mode === 'simple' && (
              <a
                className={componentBaseClass + '-link'}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                ref={this.zoomableRef}
              >
                <div className={componentBaseClass + '-poster '}>
                  <VideoPoster
                    content={content}
                    wrappedByLink={true}
                    size={this.props.size}
                  />
                </div>

                <div className={componentBaseClass + '-infos'}>
                  <div className={componentBaseClass + '-infos-inner'}>
                    {label && (
                      <div className={componentBaseClass + '-title'}>
                        {label} <EntityIsFreeLabel entity={content} />
                      </div>
                    )}

                    <div className={componentBaseClass + '-footer'}>
                      {authors && authors.length > 0 && (
                        <div className={componentBaseClass + '-authors'}>
                          <span>Par </span>
                          {authors.map(
                            (author) =>
                              author && (
                                <span
                                  key={author._id}
                                  className={
                                    componentBaseClass + '-authors-item'
                                  }
                                >
                                  {author.label || 'Anonyme'}
                                </span>
                              )
                          )}
                        </div>
                      )}

                      {displayedDate && (
                        <div className={componentBaseClass + '-date'}>
                          {displayedDate}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </a>
            )) ||
            (mode === 'image_only' &&
              ((content && content.image && (
                <a
                  className={componentBaseClass + '-link'}
                  onMouseEnter={this.onMouseEnter}
                  onMouseLeave={this.onMouseLeave}
                  ref={this.zoomableRef}
                >
                  <div className={componentBaseClass + '-poster '}>
                    <VideoPoster
                      content={content}
                      wrappedByLink={true}
                      size={this.props.size}
                    />
                  </div>
                </a>
              )) || <span />)) || ( // no image: // default
              <a
                className={componentBaseClass + '-link'}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
                ref={this.zoomableRef}
              >
                <div className={componentBaseClass + '-poster '}>
                  <VideoPoster
                    content={content}
                    wrappedByLink={true}
                    size={this.props.size}
                  />
                </div>

                <div className={componentBaseClass + '-infos'}>
                  <div className={componentBaseClass + '-infos-inner'}>
                    {label && (
                      <div className={componentBaseClass + '-title'}>
                        {label} <EntityIsFreeLabel entity={content} />
                      </div>
                    )}

                    {desc && (
                      <RichTextContent
                        className={componentBaseClass + '-desc'}
                        html={desc}
                      />
                    )}

                    <div className={componentBaseClass + '-footer'}>
                      {authors && authors.length > 0 && (
                        <div className={componentBaseClass + '-authors'}>
                          <span>Par </span>
                          {authors.map(
                            (author) =>
                              author && (
                                <span
                                  key={author._id}
                                  className={
                                    componentBaseClass + '-authors-item'
                                  }
                                >
                                  {author.label || 'Anonyme'}
                                </span>
                              )
                          )}
                        </div>
                      )}

                      {displayedDate && (
                        <div className={componentBaseClass + '-date'}>
                          {displayedDate}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </a>
            )}
        </EntityLink>
      </div>
    );
  }
}

export default ContentThumb;
