// @flow
import * as React from 'react';
import { pickBy } from 'lodash';

import type { PUFComponentProps } from '@/types/component';

import {
  TwitterIcon,
  FacebookIcon,
  YoutubeIcon,
  InstagramIcon,
} from '@/components/new/icons';

type Options = {||};
type RequiredProps = {||};
type Mode = 'default';
type Props = PUFComponentProps<RequiredProps, Options, Mode, void>;

const SOCIAL_NETWORK_PROPERTY_KEYS = [
  'twitterUrl',
  'facebookUrl',
  'instagramUrl',
  'youtubeUrl',
];

export default function EntitySocialNetworks({
  entity,
  mainClassName,
}: Props): React.Node {
  if (!entity) return null;
  const socialNetworkProperties = pickBy(
    entity,
    (propertyValue, propertyKey) =>
      SOCIAL_NETWORK_PROPERTY_KEYS.includes(propertyKey) && !!propertyValue
  );

  return (
    <div className={mainClassName}>
      <Link network="twitter" url={socialNetworkProperties.twitterUrl}>
        <TwitterIcon />
      </Link>
      <Link network="facebook" url={socialNetworkProperties.facebookUrl}>
        <FacebookIcon />
      </Link>
      <Link network="youtube" url={socialNetworkProperties.youtubeUrl}>
        <YoutubeIcon />
      </Link>
      <Link network="instagram" url={socialNetworkProperties.instagramUrl}>
        <InstagramIcon />
      </Link>
    </div>
  );
}

EntitySocialNetworks.baseClassName = 'EntitySocialNetworks';

type LinkProps = {|
  network: 'twitter' | 'facebook' | 'youtube' | 'instagram',
  children: React.Node,
  url: ?string,
|};

function Link({ network, children, url }: LinkProps) {
  if (!url) return null;

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={`Page ${network}`}
      className={`${network}-link social-link`}
    >
      {children}
    </a>
  );
}
