// Simple span button component

import React from 'react';
import { isInteger } from 'lodash';

import { pluralize } from '@/helpers/string';

import { Icon } from '@/components/new';

const baseClassName = 'CommentsCount';

export default function CommentsCount({ options, entity, mainClassName }) {
  const { iconInsteadOfText } = options;

  if (!entity) return null;

  const { commentsCount: count } = entity;

  if (!isInteger(count)) return null;

  return (
    <div className={baseClassName}>
      {iconInsteadOfText && <Icon name="comment-alt" />}
      <span className={`${baseClassName}-count`}>{count}</span>{' '}
      {!iconInsteadOfText && (
        <span className={`${baseClassName}-label`}>
          {pluralize('commentaire', 'commentaires', count)}
        </span>
      )}
    </div>
  );
}

CommentsCount.baseClassName = baseClassName;
