import React from 'react';
import FutureGame from './FutureGame';
import { Flex } from '@/components/new';

function FutureGames({ games, participantId, teams }) {
  return (
    <Flex direction="column" additionalClassName="future-games">
      <h4>Prochains matchs</h4>

      {games.length > 0 ? (
        <ul className="games">
          {games.slice(0, 3).map((game) => {
            return (
              <FutureGame
                game={game}
                participantId={participantId}
                teams={teams}
                key={game._id}
              />
            );
          })}
        </ul>
      ) : (
        <p className="empty-match-placeholder">Pas de match à venir</p>
      )}
    </Flex>
  );
}

export default FutureGames;
