import { pick, range, isObject } from 'lodash';
import Color from 'color';

import { getApiUrl } from '@/utils/config';

const FONT_KEYS = ['mainFont', 'secondaryFont'];

export const FONT_KEY_TO_CSS_VARIABLE = {
  mainFont: '--main-font-face',
  secondaryFont: '--secondary-font-face',
};

const COLOR_KEYS = [
  'textColor',
  'textColorLight',
  'primaryColor',
  'secondaryColor',
  'tertiaryColor',
  'successColor',
  'dangerColor',
  'warningColor',
  'color1light',
  'color2light',
  'color1dark',
  'color2dark',
  'backgroundColor',
  'siteBgColor',
  'color1B',
  'color1F',
  'color1HlB',
  'color1HlF',
  'color2B',
  'color2F',
  'color2HlB',
  'color2HlF',
];

const COLOR_KEY_TO_CSS_VARIABLE = {
  textColor: '--text-color',
  textColorLight: '--text-color-light',
  primaryColor: '--primary-color',
  secondaryColor: '--secondary-color',
  tertiaryColor: '--tertiary-color',
  successColor: '--success-color',
  dangerColor: '--danger-color',
  warningColor: '--warning-color',
  color1light: '--color1-light', // OBSOLETE
  color2light: '--color2-light', // OBSOLETE
  color1dark: '--color1-dark', // OBSOLETE
  color2dark: '--color2-dark', // OBSOLETE
  backgroundColor: '--background-color', // NOT USED
  siteBgColor: '--site-bg-color', // not yet in config
  color1B: '--color-1-b',
  color1F: '--color-1-f',
  color1HlB: '--color-1-hl-b',
  color1HlF: '--color-1-hl-f',
  color2B: '--color-2-b',
  color2F: '--color-2-f',
  color2HlB: '--color-2-hl-b',
  color2HlF: '--color-2-hl-f',
};

const LIGHTENED_RANGE = range(10, 100, 10);
const DARKENED_RANGE = range(10, 100, 10);
const GRADIENTED_COLOR_KEYS = [
  'primaryColor',
  'secondaryColor',
  'tertiaryColor',
  'successColor',
  'dangerColor',
  'warningColor',
];

const makeColorString = (key, value, { lightenessGradient }) => {
  let CSSDefinitions = [];
  const CSSVariableName = COLOR_KEY_TO_CSS_VARIABLE[key];
  const color = Color(value).hsl();

  CSSDefinitions.push(`${CSSVariableName}: ${color.string()}`);

  // Create the HSL separate variables so that CSS transformations are possible
  CSSDefinitions.push(`${CSSVariableName}-h: ${Math.round(color.hue())}`);
  CSSDefinitions.push(
    `${CSSVariableName}-s: ${Math.round(color.saturationl())}%`
  );
  CSSDefinitions.push(
    `${CSSVariableName}-l: ${Math.round(color.lightness())}%`
  );

  // Useful for gradients
  const alpha0 = Color(value).alpha(0).string();
  CSSDefinitions.push(`${CSSVariableName}-alpha0: ${alpha0}`);

  if (lightenessGradient) {
    const lightenedDefinitions = LIGHTENED_RANGE.map((ligthenPercentage) => {
      return `${CSSVariableName}-lightened-${ligthenPercentage}: ${Color(value)
        .lighten(ligthenPercentage / 100)
        .hex()}`;
    });

    const darkenedDefinitions = DARKENED_RANGE.map((darkenPercentage) => {
      return `${CSSVariableName}-darkened-${darkenPercentage}: ${Color(value)
        .darken(darkenPercentage / 100)
        .hex()}`;
    });

    CSSDefinitions = [
      ...CSSDefinitions,
      ...lightenedDefinitions,
      ...darkenedDefinitions,
    ];
  }

  return CSSDefinitions.join('; \n');
};

const makeFontString = (key, value) => {
  const CSSVariableName = FONT_KEY_TO_CSS_VARIABLE[key];
  return `${CSSVariableName}: ${value.fontFamily};\n`;
};

export const prepareCSSVariables = (styles) => {
  if (!isObject(styles)) {
    throw new Error('Instance styles is not an object');
  }

  const colors = pick(styles, COLOR_KEYS);
  const fonts = pick(styles, FONT_KEYS);

  const CSSVariableString = `
      :root {
        ${Object.keys(colors)
          .filter((colorKey) => !!styles[colorKey])
          .map((colorKey) =>
            makeColorString(colorKey, styles[colorKey], {
              lightenessGradient: GRADIENTED_COLOR_KEYS.includes(colorKey),
            })
          )
          .join(';\n')};\n

        ${Object.keys(fonts)
          .filter((fontKey) => !!styles[fontKey])
          .map((fontKey) => makeFontString(fontKey, styles[fontKey]))
          .join(';\n')};\n
      }
    `;

  return CSSVariableString;
};

export const getRootStyles = (page, siteOptions) => {
  if (page && page.bgImage) {
    return {
      backgroundImage: `url("${getApiUrl() + page.bgImage}")`,
      backgroundPosition: page.bgImagePos,
      backgroundSize: page.bgImageSize,
      backgroundRepeat: page.bgImageRepeat || 'no-repeat',
    };
  }

  if (siteOptions && siteOptions.bgImage) {
    return {
      backgroundImage: `url("${getApiUrl() + siteOptions.bgImage}")`,
    };
  }

  return {};
};
