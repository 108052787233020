import * as React from 'react';

import type { Purchase, SubscriptionFormula, Payment } from '@/types/models';
import type { PUFComponentProps } from '@/types/component';

import { getIntervalLabel } from '@/globals/TempTraductions';

import { Date, RichTextContent } from '@/components/old';
import { Field, Label, Switch } from '@/components/new';
import AddressForm from '@/PUFComponents/base/user/LegacyAddressForm';

type Options = {||};

type Mode = 'history';
type ComponentProps = {|
  payment: Payment,
  onUpdatePayment: (id: string, $Shape<Payment>) => any,
  onUpdatePurchase: (id: string, $Shape<Purchase>) => any,
|};

type Props = PUFComponentProps<
  ComponentProps,
  Options,
  Mode,
  Purchase<SubscriptionFormula>
>;

const BASE_CLASS_NAME = 'SubscriptionItemPurchaseHistory';

export default function SubscriptionItemPurchaseHistory({
  entity: purchase,
  mainClassName,
  payment,
  onUpdatePayment,
  onUpdatePurchase,
}: Props): React.Node {
  const [updatedPayment, setUpdatedPayment] = React.useState(payment);

  React.useEffect(() => {
    setUpdatedPayment(payment);
  }, [payment]);

  if (!purchase?.product) return null;

  const { product } = purchase;
  const { requiresShipping } = product;

  if (product?._cls !== 'SubscriptionFormula') {
    console.error(
      'SubscriptionItemPurchaseHistory requires a Purchase of SubscriptionFormula',
      purchase
    );
    return null;
  }

  const baseClassName = SubscriptionItemPurchaseHistory.baseClassName;

  return (
    <div className={mainClassName}>
      <div className={baseClassName + '-label'}>{product.label}</div>
      <div className={baseClassName + '-info'}>
        <RichTextContent html={product.infoText} />
      </div>
      {product.noRecurrence ? (
        <div
          className={baseClassName + 'SubscriptionFormulaPurchaseHistory-dates'}
        >
          <span>Valide {getIntervalLabel(product)}</span>
          <span>
            {' '}
            à partir du <Date ts={purchase.createTs} format="LL" />
          </span>
        </div>
      ) : (
        <>
          <div className="property createTs">
            <span className="property-label">Date d'abonnement :</span>
            <span className="property-value">
              <Date ts={purchase.createTs} format="LL" />
            </span>
          </div>

          <div className="property endTs">
            <span className="property-label">Date de fin d'abonnement :</span>
            <span className="property-value">
              <Date ts={purchase.endTs} format="LL" />
            </span>
          </div>

          <div className="property duration">
            <span className="property-label">Durée :</span>
            <span className="property-value">
              {product.intervalCount || 1 + ' ' + getIntervalLabel(product)}
            </span>
          </div>

          <div className="property resubTs">
            <span className="property-label">Renouvellement :</span>
            <span className="property-value">
              Chaque{' '}
              {getIntervalLabel(product, { year: 'année' }, { year: 'années' })}
            </span>
          </div>
        </>
      )}

      <div className="subscription-edition-form">
        {!!requiresShipping && (
          <Field additionalClassName="address-form-field">
            <Label>Adresse de livraison</Label>
            <AddressForm
              name="adress"
              type="de livraison"
              value={updatedPayment?.shippingAddress}
              onChange={(newShippingAddress) => {
                setUpdatedPayment({
                  ...updatedPayment,
                  shippingAddress: newShippingAddress,
                });
              }}
              onSubmit={() =>
                onUpdatePayment(payment._id, {
                  shippingAddress: updatedPayment.shippingAddress,
                })
              }
            />
          </Field>
        )}

        <Field>
          <Switch
            label={
              <span
                className={
                  purchase.dontRenew
                    ? 'has-text-homemade-warning'
                    : 'has-text-homemade-success'
                }
              >
                {purchase.dontRenew ? (
                  'Votre abonnement ne sera pas renouvelé'
                ) : (
                  <>
                    <span>Votre abonnement sera renouvelé le </span>
                    <Date ts={purchase.endTs} format="LL" />
                  </>
                )}
              </span>
            }
            value={!purchase.dontRenew}
            onChange={() =>
              onUpdatePurchase(purchase._id, { dontRenew: !purchase.dontRenew })
            }
          />
        </Field>
      </div>
    </div>
  );
}

SubscriptionItemPurchaseHistory.baseClassName = BASE_CLASS_NAME;
