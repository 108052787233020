// @flow
import * as React from 'react';

import { CartStoreActions } from '@/reflux';

import { InputTextBase, Field, Label } from '@/components/old';

type Props = {|
  donation: number,
  maxDonation?: number,
  donationLabel?: string,
  disabled: boolean,
|};

export default function Donation({
  donation,
  maxDonation,
  donationLabel: donationDescription,
  disabled,
}: Props): React.Node {
  return (
    <div className="donation">
      <label className="donation-title">Faire un don</label>

      <div className="donation-field-wrapper">
        <div className="donation-description">
          {donationDescription ||
            'Soutenez-nous pour nous aider à offrir la revue à prix réduit aux étudiants, chômeurs, RSA...'}
        </div>

        <Field>
          <Label>Montant du don</Label>
          <InputTextBase
            disabled={disabled}
            placeholder="0€"
            min="0"
            max={!!maxDonation ? maxDonation.toString() : undefined}
            value={donation}
            onChange={(newDonation) =>
              CartStoreActions.setDonation(parseInt(newDonation, 10))
            }
            type="number"
          />
          <span>€</span>
        </Field>
      </div>
    </div>
  );
}
