// Component used to display the content description

import React from 'react';

import { useContent } from '@/hooks';

import EntityLink from '@/PUFComponents/base/navigation/EntityLink';

const baseClassName = 'PUF-entity-authors';

export default function EntityAuthorsV2({ options, style }) {
  const content = useContent();

  if (!content?.authors) return null;

  const { authors } = content;
  const { Element = 'div', linkToEntity } = options;
  const filteredAuthors = authors.filter((author) => !!author);

  return (
    <Element className={baseClassName} style={style}>
      {filteredAuthors.map((author, i) => {
        const authorName = author.label || author.name;

        if (linkToEntity) {
          return (
            <React.Fragment key={author._id}>
              <EntityLink entity={author}>
                <a className="author-link">
                  <span title={authorName}>{authorName}</span>
                </a>
              </EntityLink>
              {i < authors.length - 1 && <span>,&nbsp;</span>}
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={author._id}>
            <span className="author" title={authorName}>
              {authorName}
            </span>
            {i < authors.length - 1 && <span>,&nbsp;</span>}
          </React.Fragment>
        );
      })}
    </Element>
  );
}

EntityAuthorsV2.baseClassName = baseClassName;
