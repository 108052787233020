import React from 'react';
import classnames from 'classnames';

import { useContent } from '@/hooks';

import EntityLink from '../../base/navigation/EntityLink';
import { Image, Icon } from '@/components/new';

const baseClassName = 'PUF-content-poster';

export default function EntityPosterV2({
  content: contentProp,
  options = {},
  style,
}) {
  const contextContent = useContent();
  const content = contentProp || contextContent;

  if (!content) return null;

  const { label, image } = content;
  const { ratioWH = 'ratio-16-9', linkToEntity } = options;

  const component = (
    <div
      className={classnames(baseClassName, ratioWH, {
        'has-video': content.hasVideo,
        'has-text': content.hasText,
      })}
      style={style}
    >
      {content.hasVideo && (
        <div className="navigation">
          <Icon name="play" />
        </div>
      )}

      <Image
        additionalClassName="thumb"
        src={image}
        alt={label}
        ratioClassName={ratioWH}
      />
    </div>
  );

  if (!linkToEntity) return component;

  return (
    <EntityLink entity={content}>
      <a>{component}</a>
    </EntityLink>
  );
}

EntityPosterV2.baseClassName = baseClassName;
