// Template for all user related buttons (login/logout, register...)

import _ from 'lodash';
import React from 'react';
import cn from 'classnames';
import { withRouter } from 'next/router';

import { SiteContext } from '@/globals/contexts';
import { withContext, withLinks } from '@/utils/HOC';
import { UserStore, UserActions } from '@/reflux';

import FrontComponentBase from '../../../FrontComponentBase';
import { Search } from '../../components';
import PageLink from '../PageLinkV1';

class UserButtonsCustomV1 extends FrontComponentBase {
  static baseClassName = 'UserButtonsCustom';
  stores = [UserStore];

  logout = () => {
    UserActions.logout();
  };

  render() {
    const { links, router } = this.props;
    const { login: loginLinks, myAccount: myAccountLinks } = links;
    let options = this.getOptions();
    let user = this.state.user;
    const activePageId = router.query.pageId;
    const activeRoute = router.route;

    let componentBaseClass = this.constructor.baseClassName;

    return (
      <div className={this.getMainCSSClass()}>
        {_.map(options.buttons, (button) => {
          if (!button.buttonType) {
            // probably edit in progress
            return this.state.draftMode ? (
              <div className="draft-placeholder">bouton</div>
            ) : null;
          }

          // special cases (no route)
          if (button.buttonType === 'logout' && user) {
            return (
              <span
                onClick={this.logout}
                className={cn(
                  componentBaseClass + '-button',
                  'type--' + button.buttonType,
                  button.buttonStyle
                )}
                key={button.buttonType}
              >
                {button.label || 'Se déconnecter'}
              </span>
            );
          }

          if (button.buttonType === 'search') {
            return (
              <Search
                placeholder={button.label || 'Rechercher'}
                key={button.buttonType}
              />
            );
          }

          let link = undefined,
            defaultLabel;

          if (button.buttonType === 'login' && !user) {
            link = loginLinks.login;
            defaultLabel = <span>Se&nbsp;connecter</span>;
          } else if (button.buttonType === 'redeemCode' && !user?.isPremium) {
            link = links.redeemCode;
            defaultLabel = <span>J'ai un code cadeau</span>;
          } else if (
            button.buttonType === 'register' &&
            (!user || !user.isPremium)
          ) {
            link = loginLinks.register;
            defaultLabel = "S'abonner"; // TODO use siteConfig.options.registerLabel
          }

          // DOTO case register + resubrule
          else if (
            button.buttonType === 'account' &&
            user /* && user.isPremium*/
          ) {
            link = myAccountLinks.profile;
            defaultLabel = 'Mon compte';
          } else if (button.buttonType.split('/')[0] === 'account' && user) {
            let tab = button.buttonType.split('/')[1];
            link = myAccountLinks[tab];
            defaultLabel = {
              profile: 'Mon profil',
              subscriptions: 'Mon abonnement',
              paymentMeans: 'Mes moyens de paiement',
              purchases: 'Mes commandes',
            }[tab];
          } else if (button.buttonType === 'gift') {
            link = myAccountLinks.gift;
            defaultLabel = 'Offrir';
          }

          if (link) {
            const isActive =
              (link.isPageId && activePageId === link.url) || // PageId
              (!link.isPageId && link.internal && activeRoute === link.url); // Internal link but not pageId;

            return (
              <PageLink link={link} key={button.label || defaultLabel}>
                <a
                  className={cn(
                    componentBaseClass + '-button',
                    'type--' + button.buttonType,
                    button.buttonStyle,
                    {
                      active: isActive,
                    }
                  )}
                >
                  {button.label || defaultLabel}
                </a>
              </PageLink>
            );
          }

          return null;
        })}
      </div>
    );
  }
}

export default withRouter(
  withContext(SiteContext, 'siteContext', withLinks(UserButtonsCustomV1))
);
