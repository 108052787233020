// Component used to display the content description
import React from 'react';

import { getFirst } from '@/utils/misc';

import TagList from '../../base/entity/TagList';
import FrontComponentBase from '../../FrontComponentBase';

class EntityTypeAndFirstTag extends FrontComponentBase {
  static baseClassName = 'EntityTypeAndFirstTag';

  didMount() {
    let bindContentType = this.getRebindToObjectFunc('loadedContentType');

    this.observeSingleEntity((entity) => {
      bindContentType('ContentType', getFirst(entity.contentType));
    });
  }

  render() {
    // you can use props "content" for direct usage
    let entity =
      this.props.content || // retrocompat TODO remove
      this.props.entity ||
      this.getSingleEntity();

    if (!entity) {
      // TODO draft mode
      if (this.props.component && this.props.component._isDraft) {
        console.debug('render draft empty component EntityTags');
        // TODO
        return (
          <div className="Entity-draft">Création d'un bloc Tags en cours</div>
        );
      } else {
        return null;
      }
    }

    let { contentType, mainTags } = entity;

    let baseClassName = this.constructor.baseClassName;
    // add content type to the component main class
    let mainContentType = getFirst(contentType);
    let componentMainClass =
      baseClassName +
      (mainContentType ? ' ' + baseClassName + '--' + mainContentType : '');

    //componentMainClass += " EntityTags"; // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    // TODO correct link on type

    return (
      <TagList
        tags={[
          this.state.loadedContentType || {
            _id: mainContentType,
            label: mainContentType,
            text: mainContentType, // TODO virer cette retrocompat
          },
          mainTags && mainTags[0],
        ]}
        className={componentMainClass}
        options={this.getOptions()}
      />
    );
  }
}

export default EntityTypeAndFirstTag;
