// @flow
// Simple Facebook SVG icon

import * as React from 'react';

type Props = {| color?: string |};

export default function FacebookIcon({ color: colorProp }: Props): React.Node {
  const color = colorProp || 'currentColor';

  return (
    <svg
      x="0px"
      y="0px"
      width="8.917px"
      height="17.171px"
      viewBox="0 0 8.917 17.171"
    >
      <path
        fill={color}
        d="M5.788,17.172V9.339h2.629l0.394-3.053H5.788V4.337c0-0.884,0.245-1.486,1.513-1.486l1.616,0V0.12
            	C8.638,0.083,7.678,0,6.562,0C4.23,0,2.636,1.422,2.636,4.035v2.251H0v3.053h2.636v7.833H5.788z"
      />
    </svg>
  );
}
