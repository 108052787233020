// Component used to display the content description

import React from 'react';
import _ from 'lodash';

import { getFirst } from '@/utils/misc';

import EntityLink from '../../base/navigation/EntityLink';
import FrontComponentBase from '../../FrontComponentBase';

class EntityAuthorsV1 extends FrontComponentBase {
  static baseClassName = 'EntityAuthors';

  render() {
    // you can use props "content" for direct usage
    let {
      label,
      labelIfMultiple,
      linkToEntity,
      displayOccupation,
      separatorSymbol,
    } = this.getOptions();
    let entity =
      this.props.content || // retrocompat TODO remove
      this.props.entity ||
      this.getSingleEntity();

    if (!entity) {
      // TODO draft mode
      if (this.props.component && this.props.component._isDraft) {
        console.debug('render draft empty component EntityAuthors');
        // TODO
        return (
          <div className="Entity-draft">
            Création d'un bloc Auteurs en cours
          </div>
        );
      } else {
        return null;
      }
    }

    let { contentType, author, authors } = entity;
    if (author && !authors) authors = [author];
    authors = authors?.filter((author) => !!author);

    let baseClassName = this.constructor.baseClassName;
    // add content type to the component main class
    let mainContentType = getFirst(contentType);
    let componentMainClass =
      baseClassName +
      (mainContentType ? ' ' + baseClassName + '--' + mainContentType._id : '');

    // New: author can be Author or User

    return (
      !_.isEmpty(authors) && (
        <div className={componentMainClass}>
          {(authors.length === 1 && label && (
            <h3 className={baseClassName + '-title'}>{label}</h3>
          )) ||
            (authors.length > 1 && (labelIfMultiple || label) && (
              <h3 className={baseClassName + '-title'}>
                {labelIfMultiple || label}
              </h3>
            ))}
          {/* Note : labelsList is only used in RNR */}
          <div className={baseClassName + '-list labelsList'}>
            {authors.map((author, i) => {
              if (linkToEntity) {
                return (
                  <React.Fragment key={author._id}>
                    <EntityLink entity={author} key={author._id}>
                      <a
                        className={
                          baseClassName + '-list-item link labelsList-item'
                        }
                      >
                        {author.label || author.name}
                        {displayOccupation && author.occupation && (
                          <span className={baseClassName + '-item-occupation'}>
                            {author.occupation}
                          </span>
                        )}
                      </a>
                    </EntityLink>
                    {i < authors.length - 1 && (
                      <span className="separator">
                        {separatorSymbol || ' - '}
                      </span>
                    )}
                  </React.Fragment>
                );
              }

              return (
                <React.Fragment key={author._id}>
                  <span
                    className={baseClassName + '-list-item labelsList-item'}
                  >
                    {author.label || author.name}

                    {displayOccupation && author.occupation && (
                      <span className={baseClassName + '-item-occupation'}>
                        {author.occupation}
                      </span>
                    )}
                  </span>
                  {i < authors.length - 1 && (
                    <span className="separator">
                      {separatorSymbol || ' - '}
                    </span>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )
    );
  }
}

export default EntityAuthorsV1;
