// @flow
import * as React from 'react';

import type { FullCartState } from '@/types/stores';
import type { FeatureFlags } from '@/types/models';
import type { Options } from '../components/CommonWrapper';

import CartStoreActions from '@/reflux/CartStore/actions';
import { CHECKOUT_STEP } from '@/reflux/CartStore/constants';
import { prettyPrice } from '@/helpers/format';
import { featureEnabled } from '@/helpers/models/instance';

import { Button, Icon } from '@/components/new';
import RawComponent from '@/PUFComponents/RawComponent';
import CartItem from '../components/CartItem';

type Props = {|
  cartState: FullCartState,
  goBackToProducts?: () => void,
  options: Options,
  featureFlags: FeatureFlags,
|};

export default function DefaultCartCheckoutSummary({
  cartState,
  goBackToProducts,
  options,
  featureFlags,
}: Props): React.Node {
  const {
    cartItems,
    donation,
    currentStep,
    uiErrors: errors,
    total,
    shippingCost,
  } = cartState;
  const disabled = currentStep !== CHECKOUT_STEP.CART_REVIEW;
  const hasItems = Object.keys(cartItems).length > 0;
  const discountCodeEnabled = featureEnabled('DiscountCode', featureFlags);

  return (
    <>
      {discountCodeEnabled && (
        <RawComponent
          id="DiscountOrGiftInput"
          options={{
            label: options.discountLabel,
            placeholderLabel: options.discountPlaceholderLabel,
          }}
        />
      )}
      <div className="summary">
        <h2 className="summary-title">Récapitulatif</h2>

        <h5 className="details">Détails de ma commande</h5>

        <hr className="separator" />

        <div className="cart-items">
          {hasItems ? (
            <>
              {Object.keys(cartItems).map((itemId) => (
                <CartItem
                  key={itemId}
                  item={cartItems[itemId]}
                  id={itemId}
                  errors={errors.cartItems[itemId]}
                  disabled={disabled}
                />
              ))}
              {goBackToProducts && (
                <Button isText id="other-products" onClick={goBackToProducts}>
                  Voir les autres produits
                </Button>
              )}
            </>
          ) : (
            <div className="empty-cart">
              <h4 className="empty-cart-title">
                Vous n'avez pas d'article dans votre panier
              </h4>
              <Button onClick={goBackToProducts}>Voir les offres</Button>
            </div>
          )}
        </div>

        {options.allowDonation && !!donation && (
          <>
            <hr className="separator" />
            <Donation donation={donation} disabled={disabled} />
          </>
        )}

        <hr className="separator" />

        <Total total={total} shippingCost={shippingCost} />
      </div>
    </>
  );
}

type DonationProps = {|
  donation: number,
  disabled: boolean,
|};

function Donation({ donation, disabled }: DonationProps) {
  return (
    <div className="donation">
      <span className="donation-label">Don</span>
      <div>
        <span className="donation-amount">{prettyPrice(donation)}</span>
        {!disabled && (
          <Button
            color="light"
            additionalClassName="remove-donation"
            onClick={() => CartStoreActions.setDonation(0)}
          >
            <Icon name="trash" />
          </Button>
        )}
      </div>
    </div>
  );
}

type TotalProps = {|
  total: number,
  shippingCost: number,
|};

function Total({ total, shippingCost }: TotalProps) {
  return (
    <div className="total">
      <h4>Total de votre commande</h4>
      <h1>{prettyPrice(total)}</h1>
      {shippingCost > 0 && (
        <p className="shipping-cost">
          Dont {prettyPrice(shippingCost)} de frais de livraison
        </p>
      )}
    </div>
  );
}
