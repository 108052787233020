import * as React from 'react';

import { writeToLocalStorage } from '@/helpers/storage';

import { RichTextContent, Button } from '@/components/old';

export default function TournamentIntro({ tournament, refresh }) {
  const {
    label,
    desc,
    rules,
    _id: tournamentId,
    seoLabel,
    seoDescription,
  } = tournament;

  return (
    <div className="tournament-intro">
      <h1 className="title">{seoLabel || label}</h1>

      {seoDescription && <h2 className="description">{seoDescription}</h2>}

      {!seoDescription && desc?.html && (
        <h2 className="description">
          <RichTextContent html={desc.html} />
        </h2>
      )}

      <div className="actions">
        <Button
          color="primary"
          onClick={() => {
            writeToLocalStorage(`${tournamentId}_hasSeenIntro`, true);
            refresh();
          }}
        >
          C'est parti !
        </Button>
      </div>

      <hr />

      {rules && (
        <div className="rules">
          <h3>Réglement</h3>
          <RichTextContent html={rules} className="rules" />
        </div>
      )}

      <div className="actions">
        <Button
          color="primary"
          onClick={() => {
            writeToLocalStorage(`${tournamentId}_hasSeenIntro`, true);
            refresh();
          }}
        >
          Compris !
        </Button>
      </div>
    </div>
  );
}
