import * as React from 'react';
import classnames from 'classnames';
import { kebabCase } from 'lodash';

import { Flex } from '@/components/new';

export default function Lineup({ lineup }) {
  return (
    <Flex direction="column" additionalClassName="roster">
      <h2>La compo</h2>
      <Flex direction="column" horizontalAlign verticalAlign>
        <ul className="compo">
          {lineup.map((player) => (
            <li className={classnames('list-item', kebabCase(player.role))}>
              {player.role && <span className="role">{player.role[0]}</span>}
              <span className="name">{player.name}</span>
            </li>
          ))}
        </ul>
      </Flex>
    </Flex>
  );
}
