// @flow
import * as React from 'react';

import type { GiftInfo as GiftInfoType } from '@/types/stores';

import { CartStoreActions } from '@/reflux';
import { useSite } from '@/hooks';
import { getFeatureFlags, featureEnabled } from '@/helpers/models/instance';

import { Tabs, Icon } from '@/components/new';
import GiftCard from './GiftCard';
import MailToBeneficiary from './MailToBeneficiary';

type Props = {|
  giftInfo: GiftInfoType,
  disabled: boolean,
  children: React.Node,
|};

const TAB = {
  MAIL_TO_BENEFICIARY: 'MAIL_TO_BENEFICIARY',
  GIFT_CARD: 'GIFT_CARD',
};

export default function GiftInfo({
  giftInfo,
  disabled,
  children,
}: Props): React.Node {
  const site = useSite();
  const featureFlags = getFeatureFlags(site);
  const giftCardEnabled = featureEnabled('giftCard', featureFlags);
  const updateGiftInfo = React.useCallback(
    (giftInfoShape: $Shape<GiftInfoType>) => {
      CartStoreActions.forceSetState({
        giftInfo: {
          ...giftInfo,
          ...giftInfoShape,
        },
      });
    },
    [giftInfo]
  );

  const changeGiftCardMode = React.useCallback(
    (isGiftCard: boolean) => updateGiftInfo({ giftCard: isGiftCard }),
    [updateGiftInfo]
  );

  if (!giftCardEnabled) {
    return (
      <div className="giftInfo">
        <MailToBeneficiary
          giftInfo={giftInfo}
          disabled={disabled}
          onChange={updateGiftInfo}
        />

        {children}
      </div>
    );
  }

  const { giftCard } = giftInfo;

  const tabsItems = [
    {
      id: TAB.GIFT_CARD,
      //label: 'Carte cadeau',
      label: (
        <span>
          <Icon name="fas fa-gift" />
          <span className="is-hidden-touch">Carte cadeau</span>
        </span>
      ),
      onClick: () => !disabled && changeGiftCardMode(true),
      active: giftCard,
    },
    {
      id: TAB.MAIL_TO_BENEFICIARY,
      label: (
        <span>
          <Icon name="fas fa-envelope" />
          <span className="is-hidden-touch">Mail au bénéficiaire</span>
        </span>
      ),
      onClick: () => !disabled && changeGiftCardMode(false),
      active: !giftCard,
    },
  ];

  return (
    <div className="giftInfo">
      <Tabs items={tabsItems} align="centered" size="medium" />

      {giftCard ? (
        <GiftCard
          disabled={disabled}
          giftInfo={giftInfo}
          onChange={updateGiftInfo}
        />
      ) : (
        <MailToBeneficiary
          disabled={disabled}
          giftInfo={giftInfo}
          onChange={updateGiftInfo}
        />
      )}

      {children}
    </div>
  );
}
