export const computeStyleFromStyleProperties = (
  styleProperties: Array<StyleProperty>
) => {
  if (!styleProperties || styleProperties.lenth === 0) return {};

  const style = {};

  for (const { key, value } of styleProperties) {
    style[key] = value;
  }

  return style;
};
