import { withRouter } from 'next/router';
import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { InstanceStore } from '@/reflux';

import RawComponent from '../../RawComponent';
import { DynImg } from '@/components/old';
import PageLink from './PageLinkV1';
import FrontComponentBase from '../../FrontComponentBase';

const HEADER_DEFAULT_USER_BUTTONS_CONFIG = [
  { buttonType: 'login' },
  { buttonType: 'logout' },
  { buttonType: 'register' },
  { buttonType: 'myAccount' },
];

class Header extends FrontComponentBase {
  constructor(props) {
    super(props);
    this.store = InstanceStore;

    this.state = {
      mobileMenuToggled: false,
    };
  }

  toggleMobileMenu = () => {
    this.setState({ mobileMenuToggled: !this.state.mobileMenuToggled });
  };

  closeMobileMenu = () => {
    this.setState({ mobileMenuToggled: false });
  };

  render() {
    const options = this.getOptions();
    const { headerRetracted } = this.props;
    const { mobileMenuToggled } = this.state;

    const layout = options.layoutType || 'style1';
    const headerClass = classNames('Header', `Header--${layout}`, {
      [`Header--${options.colors}`]: !!options.colors,
      'Header--retracted': headerRetracted,
      'Header--toggled': mobileMenuToggled,
    });

    const menuOptions = _.pick(options, ['menuItems', 'submenuHover']);

    const title = (
      <PageLink pageId="home" route="/">
        <a className="Header-site-title-link">
          {!!options.siteLogo ? ( // TODO use site options siteTitle from context
            <div className="Header-site-title-link-image">
              {(options.siteMobileLogo && (
                <>
                  <DynImg
                    className="mobile"
                    w={200}
                    src={options.siteMobileLogo}
                    title={options.siteLabel}
                  />
                  <DynImg
                    className="normal"
                    w={200}
                    src={options.siteLogo}
                    title={options.siteLabel}
                  />
                </>
              )) || (
                <DynImg
                  w={200}
                  src={options.siteLogo}
                  title={options.siteLabel}
                />
              )}
            </div>
          ) : (
            <div className="Header-site-title-link-text">
              {options.siteLabel}
            </div>
          )}

          {options.siteBaseline && !headerRetracted && (
            <div className="Header-site-title-baseline">
              {options.siteBaseline}
            </div>
          )}
        </a>
      </PageLink>
    );

    const headerPerStyle = {
      style1: (
        <div className="Row Header-wrapper">
          <div className="Col Col--2 Header-site-title">{title}</div>

          <div className="Col Col--10 Header-navigation flexWrapper flexWrapper--column">
            <div className="Header-navigation-inner flexWrapper">
              <div className="Header-account flexWrapper">
                {(options.userButtons && options.userButtons.length && (
                  <RawComponent
                    id="UserButtonsCustom"
                    options={{ buttons: options.userButtons }}
                  />
                )) || ( // réto-compat (à virer)
                  <RawComponent id="UserButtons" options={options} />
                )}
              </div>

              <div className="Header-social flexWrapper">
                <RawComponent id="SocialButtons" options={options} />
              </div>
            </div>

            <RawComponent
              id="Menu"
              options={menuOptions}
              componentProps={{
                closeMobileMenu: this.closeMobileMenu,
              }}
            />
          </div>
        </div>
      ),
      style2: (
        <div className="Header-wrapper">
          <div className="Row Header-wrapper-inner">
            <div className="Col Col--2 Header-site-title">{title}</div>

            <div className="Col Col--10 Header-navigation flexWrapper">
              <div className="Header-navigation-inner flexWrapper">
                <div className="Header-account flexWrapper">
                  {(options.userButtons && options.userButtons.length && (
                    <RawComponent
                      id="UserButtonsCustom"
                      options={{ buttons: options.userButtons }}
                    />
                  )) || ( // rétro-compat (à virer)
                    <RawComponent id="UserButtons" options={options} />
                  )}
                </div>

                <div className="Header-social flexWrapper">
                  <RawComponent id="SocialButtons" options={options} />
                </div>
              </div>
            </div>

            <div className="Col Col--12 Header-main-menu">
              <RawComponent
                id="Menu"
                options={menuOptions}
                componentProps={{
                  closeMobileMenu: this.closeMobileMenu,
                }}
              />
            </div>
          </div>
        </div>
      ),
      style3: (
        <div className="Header-wrapper">
          <div className="Row Header-wrapper-inner">
            <div className="Col Col--4 Header-account flexWrapper">
              <RawComponent
                id="UserButtonsCustom"
                options={{
                  buttons: !_.isEmpty(options.userButtons)
                    ? options.userButtons
                    : HEADER_DEFAULT_USER_BUTTONS_CONFIG,
                }}
              />
            </div>

            <div className="Col Col--4 Header-site-title">{title}</div>

            <div className="Col Col--4 Header-social flexWrapper">
              <RawComponent id="SocialButtons" options={options} />
            </div>

            <div className="Col Col--12 Header-main-menu">
              <RawComponent
                id="Menu"
                options={menuOptions}
                componentProps={{
                  closeMobileMenu: this.closeMobileMenu,
                }}
              />
            </div>
          </div>
        </div>
      ),
    };

    return (
      <header
        className={headerClass}
        onClick={mobileMenuToggled ? this.closeMobileMenu : undefined}
      >
        <span className="Header-toggle-menu" onClick={this.toggleMobileMenu}>
          <span>Menu</span>
        </span>

        <div className="siteWrapper">
          {headerRetracted ? (
            <div className="Row Header-wrapper">
              <div className="Col Col--2 Header-site-title">{title}</div>

              <div className="Col Col--10 Header-navigation flexWrapper flexWrapper--column">
                <RawComponent
                  id="Menu"
                  options={menuOptions}
                  componentProps={{
                    closeMobileMenu: this.closeMobileMenu,
                  }}
                />
              </div>
            </div>
          ) : (
            headerPerStyle[layout]
          )}
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
