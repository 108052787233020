// Component used to display the content description

import _ from 'lodash';
import React from 'react';
import withRouter from 'next/dist/client/with-router';

import { UserStore, InstanceStore, UserActions } from '@/reflux';
import API from '@/utils/API';
import { requiresUser } from '@/utils/HOC/userMixins';

import FrontComponentBase from '../../FrontComponentBase';

class MyMozzoReader extends FrontComponentBase {
  static baseClassName = 'MyMozzoReader';
  stores = [InstanceStore, UserStore];

  state = {
    embedUrl: null,
  };

  didMount() {
    this.loadEmbedUrl();

    this.unsubListenToAction = UserActions.set.listen(() =>
      this.loadEmbedUrl()
    );
  }

  willUnmount() {
    this.unsubListenToAction && this.unsubListenToAction();
  }

  loadEmbedUrl() {
    API.get(this.state.instanceName + '/mymozzo/embedUrl').then((embedUrl) =>
      this.setState({ embedUrl })
    );
  }

  render() {
    const { embedUrl } = this.state;
    const { hideHeader, hideFooter, height } = this.getOptions();

    let dispCode = _.get(this.props, 'router.query.code');

    /* // TODO when we use MagazineIssue:
        if (!dispCode) {
            const entity = this.getSingleEntity();
            if (entity && entity.mymozzoDocumentCode) {
                dispCode =entity.mymozzoDocumentCode;
            }
        }*/

    // use "&" because we assume there are already get params
    const emberUrlWithParams =
      embedUrl +
      '&header=' +
      (hideHeader ? '0' : '1') +
      '&footer=' +
      (hideFooter ? '0' : '1') +
      (dispCode ? '&dispCode=' + encodeURIComponent(dispCode) : '');

    return (
      embedUrl && (
        <div className={this.getMainCSSClass()}>
          <iframe
            src={emberUrlWithParams}
            frameBorder="0"
            width="100%"
            height={height || '1300'}
            marginWidth="0"
            marginHeight="0"
            allowFullScreen={true}
            title="mymozzoreader"
          />
        </div>
      )
    );
  }
}

export default requiresUser(withRouter(MyMozzoReader));
