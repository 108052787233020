/**
 
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import React from 'react';

import { InstanceStore } from '@/reflux';

import FrontComponentBase from '../../FrontComponentBase';

import VideoPlayer from '../video/VideoPlayer';

class SimpleVideo extends FrontComponentBase {
  static baseClassName = 'ContentVideo';

  constructor(props) {
    super(props);
    this.store = InstanceStore;

    this.state = {
      shouldDisplayOnTop: true,
      displayedOnTop: false,
    };

    this.mainDivRef = React.createRef();
  }

  // c/c ContentVideo TODO Merge
  didMount() {
    this.listenToEvent('scroll', this.onScrollOrResize);
    this.listenToEvent('resize', this.onScrollOrResize);

    this.bindPropToObject(
      'Video',
      'component.options.video._id',
      { videoData: false },
      'completeVideo'
    );
  }

  // c/c ContentVideo TODO Merge
  onScrollOrResize = (e) => {
    let el = this.mainDivRef && this.mainDivRef.current;
    if (el) {
      let options = this.getOptions();

      let domDoc = document.documentElement;
      let largeEnoughForPip =
        Math.min(domDoc.clientWidth, domDoc.clientHeight) > 480;

      if (options.pip && largeEnoughForPip) {
        // TODO handle header height : done ?

        // TODO patch DOM directly (speed)
        let videoPlayerPos = el.getBoundingClientRect().bottom;
        // wrong! // TODO use a nuique id/class to identify the read header height element?
        let headerBottom = 0;

        if (videoPlayerPos < headerBottom && this.state.shouldDisplayOnTop) {
          this.setState({ displayedOnTop: true });
        } else {
          this.setState({ displayedOnTop: false });
        }
      } else if (this.state.displayedOnTop) {
        // in case of orientation change
        this.setState({ displayedOnTop: false });
      }
    }
  };

  // c/c ContentVideo TODO Merge
  closeHighlightedPlayer() {
    this.setState({
      displayedOnTop: false,
      shouldDisplayOnTop: false,
    });
  }

  render() {
    let options = this.getOptions();

    let video;

    // c/c ContentVideo TODO Merge
    if (options.srcType === 'internal') {
      video = this.state.completeVideo; //options.video;
    } else if (options.srcType === 'youtube') {
      video = {
        type: 'youtube',
        youtubeUrl: options.youtubeUrl,
      };
    }

    let opacity = 1;
    if (this.state.displayedOnTop) {
      opacity = (100 - options.pipTransparency) / 100;
    }

    let el = this.mainDivRef && this.mainDivRef.current;
    el && el.style.setProperty('--player-opacity', opacity);

    return (
      <div className={this.getMainCSSClass()} ref={this.mainDivRef}>
        {options.title && <div className="EntityTitle">{options.title}</div>}

        <VideoPlayer
          video={video}
          closeHighlightedPlayer={() => this.closeHighlightedPlayer()}
          onEnd={() => this.closeHighlightedPlayer()}
        />
      </div>
    );
  }
}

export default SimpleVideo;
