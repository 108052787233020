import React from 'react';

import Content from './Content';
import Group from './Group';

export default function ColumnContentsManager({ column }) {
  const { contents = [], groups = [], children } = column;

  const isContentInGroup = React.useCallback((group, contentIndex) => {
    const { selectionRange } = group;
    return (
      selectionRange[0] <= contentIndex && contentIndex <= selectionRange[1]
    );
  }, []);

  const isContentInAGroup = React.useCallback(
    (contentIndex) => {
      return groups.some((group) => isContentInGroup(group, contentIndex));
    },
    [groups, isContentInGroup]
  );

  if (children) return children;

  return contents
    .map((content, contentIndex) => {
      const contentIsInGroup = isContentInAGroup(contentIndex);

      if (!contentIsInGroup)
        return <Content content={content} key={contentIndex} />;

      const group = groups.find((group) =>
        isContentInGroup(group, contentIndex)
      );

      const isFirstOfGroup = group.selectionRange[0] === contentIndex;

      if (!isFirstOfGroup) return null;

      const groupedContents = contents.filter((content, contentIndex) =>
        isContentInGroup(group, contentIndex)
      );

      return (
        <Group group={group} key={group.uuid}>
          {groupedContents.map((groupedContent, groupedContentIndex) => (
            <Content content={groupedContent} key={groupedContentIndex} />
          ))}
        </Group>
      );
    })
    .filter((component) => !!component);
}
