/**
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import { InstanceStore, UserStore } from '@/reflux';
import ApiClient from '@/utils/ApiClient';
import { gtmVideoEvent } from '@/utils/googleTagManager';
import withContext from '@/utils/HOC/withContext';
import { SiteContext, PaywallContext, APIContext } from '@/globals/contexts';

import VideoPlayer from '../video/VideoPlayer';
import FrontComponentBase from '../../FrontComponentBase';
import { goToEntityPage } from '../navigation/EntityLink';
import PageLink from '../navigation/PageLinkV1';
import RawComponent from '../../RawComponent';
import PlayStatsMgr from './PlayStatsMgr';
import VimeoEventEmbed from '../embeds/VimeoEventEmbed';
import PaywallContentInternalComponent from '@/pageComponents/PaywallManager/ContentInternalPaywall';

class ContentVideo extends FrontComponentBase {
  render() {
    const content = this.props.content || this.getSingleEntity();

    if (!content) return null;

    return content.videoEmbedUrl ? (
      <VimeoEventEmbed {...this.props} />
    ) : (
      <ContentVideoPUF {...this.props} />
    );
  }
}

class ContentVideoPUF extends FrontComponentBase {
  static baseClassName = 'ContentVideo';

  constructor(props) {
    super(props);
    this.stores = [InstanceStore, UserStore];

    this.state = {
      shouldDisplayOnTop: true,
      displayedOnTop: false,
      playStatus: 'stopped',
      displayAnnoyingButton: false,
      displaySkipOpening: false,
    };

    this.mainDivRef = React.createRef();
    this.playerRef = React.createRef();

    this.playStats = new PlayStatsMgr();
    this.stores = [UserStore, InstanceStore];
  }

  didMount() {
    this.listenToEvent('scroll', this.onScrollOrResize);
    this.listenToEvent('resize', this.onScrollOrResize);

    this._annoyingButtonStartTs = moment().unix();
    this.setTimer(this.onAnnoyingButtonTime, 1000, true);

    const rebindUserWatched = this.getRebindToObjectFunc((userWatched) => {
      if (
        userWatched &&
        userWatched.time > 30 &&
        (!userWatched.duration || userWatched.duration - userWatched.time > 10)
      ) {
        this.setState({ startTime: userWatched.time - 5 });
      }
    });

    this.observeSingleEntity((content) => {
      this._hasPlayed = false;
      this.setState({
        displayAnnoyingButton: false,
        displaySkipOpening: false,
        startTime: null,
      });
      rebindUserWatched('users/watched', content && content._id);
    });
  }

  // almost c/c EntityBody, TODO refactor
  recordLocalView() {
    if (!this._hasPlayed) {
      const { entity } = this.getSingleEntity();

      const { paywallContext } = this.props;
      paywallContext.notifiyView(entity);

      this._hasPlayed = true;
    }
  }

  onAnnoyingButtonTime = () => {
    const content = this.props.content || this.getSingleEntity();

    if (
      (this.state.user && this.state.user.isPremium) ||
      !(content && content.video && content.video.isFree)
    ) {
      if (this.state.displayAnnoyingButton) {
        this.setState({ displayAnnoyingButton: false });
      }
      return;
    }

    const options = this.getOptions();
    if (options.registerButtonInterval && options.registerButtonDuration) {
      let t = moment().unix();
      // skip first by adding registerButtonDuration
      let dt = t + options.registerButtonDuration - this._annoyingButtonStartTs;
      let timeInInterval = dt % options.registerButtonInterval;

      if (
        timeInInterval <= options.registerButtonDuration &&
        !this.state.displayAnnoyingButton
      ) {
        console.debug('Anoying button ON');
        this.setState({ displayAnnoyingButton: true });
      } else if (
        timeInInterval > options.registerButtonDuration &&
        this.state.displayAnnoyingButton
      ) {
        console.debug('Anoying button OFF');
        this.setState({ displayAnnoyingButton: false });
      }
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  // Playstats
  onPlayerTime = (currentTime, duration, playingTime, mode) => {
    const content = this.props.content || this.getSingleEntity();
    const playlistRank = this.props.playlistRank; // not used

    if (content && content.video && content.video.openingDuration) {
      const displaySkipOpening =
        currentTime > 0 && currentTime < content.video.openingDuration;
      if (displaySkipOpening !== this.state.displaySkipOpening) {
        this.setState({ displaySkipOpening });
      }
    }

    if (content && content._id && content.video) {
      this.playStats.onPlayTime(
        currentTime,
        playingTime,
        content,
        this.state.user,
        playlistRank,
        mode
      );
    }

    // "played" = 30s or 50%
    const playbackLimit =
      _.get(this.props, 'siteContext.site.anonymousPlaybackLimit') || 30;
    if (
      !this._hasPlayed &&
      this.playStats.get('totalTime') > Math.min(playbackLimit, duration / 2)
    ) {
      this.recordLocalView();
    }

    if (content) {
      [0, 25, 50, 75, 90].forEach((percent) => {
        if (_.isNil(this._lastPercent) || this._lastPercent < percent) {
          if (this.playStats.get('totalTime') > (duration * percent) / 100) {
            this._lastPercent = percent;
            gtmVideoEvent(
              content.label + ' - ' + content._id,
              content.video.full
                ? _.get(content, 'video.isFree')
                  ? 'gratuite'
                  : 'payante'
                : 'bande-annonce',
              percent,
              _.get(content, 'authors[0]._id', ''),
              _.get(content, 'rightOwner._id')
            );
          }
        }
      });
    }

    const { user } = this.state;
    const video = content && content.video;
    if (
      video &&
      video.endingDuration &&
      currentTime > duration - video.endingDuration
    ) {
      if (video.isFree || (user && user.isPremium)) {
        this.autoplayNextIfNeeded();
      }
    }
  };

  autoplayNextIfNeeded(onNoNextEpisode = null) {
    const content = this.props.content || this.getSingleEntity();
    const { autoplayNextEpisode } = this.getOptions();

    if (
      autoplayNextEpisode &&
      content &&
      content.series &&
      content.episodeIndex
    ) {
      // TODO cancel this request if component unmounted
      ApiClient.getCollection(
        'VideoContent',
        {
          filter: {
            'series._id': content.series._id,
            episodeIndex: content.episodeIndex + 1,
          },
          limit: 1,
        },
        (next) => {
          const nextEpidodeContent = _.first(next);
          if (nextEpidodeContent && nextEpidodeContent.video) {
            console.debug('Autoplay next episode:', nextEpidodeContent.label);
            this.endStats();
            goToEntityPage(nextEpidodeContent, { autoplay: true });
          } else if (onNoNextEpisode) onNoNextEpisode();
        },
        (err) => {
          console.warn(err);
          if (onNoNextEpisode) onNoNextEpisode();
        }
      );
    } else if (onNoNextEpisode) onNoNextEpisode();
  }

  endStats() {
    const content = this.props.content || this.getSingleEntity();
    if (content._id && content.video) {
      this.playStats.onEnd(content);
    }
  }

  willUnmount() {
    this._cancelAutoplayNextEpisode && this._cancelAutoplayNextEpisode();
    this.playStats.onClose();
  }

  //////////////////////////////////////////////////////////////////////////////////////////////

  onScrollOrResize = () => {
    const el = this.mainDivRef && this.mainDivRef.current;
    if (el) {
      let options = this.getOptions();

      let domDoc = document.documentElement;
      let largeEnoughForPip =
        Math.min(domDoc.clientWidth, domDoc.clientHeight) > 480;

      if (options.pip && largeEnoughForPip) {
        // TODO handle header height : done ?

        // TODO patch DOM directly (speed)
        let videoPlayerPos = el.getBoundingClientRect().bottom;
        // wrong! // TODO use a nuique id/class to identify the read header height element?
        let headerBottom = 0;

        if (videoPlayerPos < headerBottom && this.state.shouldDisplayOnTop) {
          this.setState({ displayedOnTop: true });
        } else {
          this.setState({ displayedOnTop: false });
        }
      } else if (this.state.displayedOnTop) {
        // in case of orientation change
        this.setState({ displayedOnTop: false });
      }
    }
  };

  onEndPlay = () => {
    this.endStats();

    const content = this.props.content || this.getSingleEntity();
    const video = content && content.video;
    const { user } = this.state;

    if (video.isFree || (user && user.isPremium)) {
      this.autoplayNextIfNeeded(() => this.closeHighlightedPlayer());
    }
  };

  onPlayStatus = (playStatus) => {
    this.setState({ playStatus });
  };

  closeHighlightedPlayer() {
    this.setState({
      displayedOnTop: false,
      shouldDisplayOnTop: false,
    });
  }

  onSkipOpening = () => {
    let content = this.props.content || this.getSingleEntity();
    if (
      this.playerRef.current &&
      content &&
      content.video &&
      content.video.openingDuration
    ) {
      this.playerRef.current.seekTo(content.video.openingDuration);
    }
  };

  render() {
    const {
      apiContext: { getImageUrl },
    } = this.props;
    const content = this.props.content || this.getSingleEntity();
    const options = this.getOptions();

    if (!content) return null;
    if (content && !content.video && options.displayImageIfNoVideo === false)
      return null;

    const video = content.video;
    const unauthorized = !video || ('authorized' in video && !video.authorized);

    const { user, playStatus, displayAnnoyingButton, displaySkipOpening } =
      this.state;

    const baseClassName = this.constructor.baseClassName;

    let opacity = 1;
    if (this.state.displayedOnTop) {
      opacity = (100 - options.pipTransparency) / 100;
    }

    const el = this.mainDivRef && this.mainDivRef.current;
    el && el.style.setProperty('--player-opacity', opacity);

    const pageId = _.get(
      this.props.siteContext,
      'siteConfig.options.subscribePageId'
    );

    return (
      <div
        className={`${this.getMainCSSClass()} paywalled`}
        ref={this.mainDivRef}
      >
        {playStatus === 'ended' && unauthorized && (
          <PaywallContentInternalComponent content={video} />
        )}

        {(video && (
          <VideoPlayer
            ref={this.playerRef}
            video={video}
            autoplay={options.autoplay === false ? false : 'withSound'}
            closeHighlightedPlayer={() => this.closeHighlightedPlayer()}
            onEnd={this.onEndPlay}
            onPlayStatus={this.onPlayStatus}
            onPlayerTime={this.onPlayerTime}
            poster={(content.image && getImageUrl(content.image)) || null}
            startTime={this.state.startTime}
          />
        )) ||
          (content &&
            content.image &&
            options.displayImageIfNoVideo !== false && ( // default true
              <RawComponent
                id="EntityImage"
                options={{ ratioWH: 16 / 9 }}
                componentProps={{
                  entity: content,
                  allowFullScreen: true,
                }}
              />
            ))}

        {displaySkipOpening && (
          <div
            className={baseClassName + '-skip-opening-button standard-button'}
            onClick={this.onSkipOpening}
          >
            {options.skipOpeningLabel || 'Passer le générique'}
          </div>
        )}

        {playStatus === 'playing' &&
          _.get(content, 'video.isFree') &&
          (!user || !user.isPremium) && (
            <CSSTransition
              in={!!displayAnnoyingButton}
              timeout={300}
              classNames="fade"
              unmountOnExit
            >
              <div className={baseClassName + '-register-links'}>
                <PageLink route={!pageId && '/register'} pageId={pageId}>
                  <a>{options.registerButtonLabel}</a>
                </PageLink>
              </div>
            </CSSTransition>
          )}
      </div>
    );
  }
}

export default withContext(
  SiteContext,
  'siteContext',
  withContext(
    PaywallContext,
    'paywallContext',
    withContext(APIContext, 'apiContext', ContentVideo)
  )
);
