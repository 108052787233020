export default function PUFSeparator({ options = {} }) {
  return <hr className="PUFSeparator" style={getStyle(options)} />;
}

const getStyle = ({ margin, width }) => {
  const style = {};

  if (width) {
    style.marginRight = 'auto';
    style.marginLeft = 'auto';
    style.width = `${width}%`;
  }

  if (margin) {
    style.marginTop = margin;
    style.marginBottom = margin;
  }

  return style;
};
