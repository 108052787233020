import { detect as detectBrowser } from 'detect-browser';
import sha256 from 'crypto-js/sha256';

import API2, { backendApi } from '@/utils/API2';
import { TEST_USER_LOGIN, TEST_USER_PASSWORD } from './constants';

// Publications here -> https://phenix2.immanens.com/api/v1/app/381/store/default/publications

export const getReaderToken = async (
  user,
  { baseUrl, appId, appSecret, storeId, issueId, temporaryHackyVersion }
) => {
  // Création de l'instance d'API permettant les intéractions avec le serveur Immanens
  const immanensApi = new API2({
    baseUrl,
    headers: {
      Accept: 'application/json',
    },
    withCredentials: false,
    withEnvironmentInfo: false,
  });

  // Récupération du token anonyme
  // Ce token sert notamment à Immanens pour suivre combien de devices lisent en parallèle le même fichier pour un utilisateur
  const browser = detectBrowser();
  const browserCryptValue = sha256(
    `${user._id}:${browser.os}-${browser.name}-${browser.version}`
  )
    .toString()
    .slice(0, 18);
  const { x_anonymous_token: immanensAnonymousToken } = await immanensApi.post(
    '/anonymous/get-online-token',
    {
      app_id: appId,
      app_secret: appSecret,
      device_auth: {
        os: 'browser',
        description: `${browser.name} ${browser.version} ${browser.os}`,
        token: {
          crypt_mode: null,
          crypt_value: browserCryptValue,
        },
      },
    }
  );
  immanensApi.setHeader('x-anonymous-token', immanensAnonymousToken);

  // temporaryHackyVersion = Besoin de développement côté Immanens et chez nous pour
  // mettre en place le login utilisateur correctemnt
  // La version hacky utilise l'utilisateur déjà existant chez eux.
  if (temporaryHackyVersion) {
    // Récupération du token de l'utilisateur Immanens
    const { x_user_token: immanensUserToken } = await immanensApi.post(
      '/user/online-login',
      {
        login: TEST_USER_LOGIN,
        password: TEST_USER_PASSWORD,
      }
    );
    immanensApi.setHeader('x-user-token', immanensUserToken);

    // Récupération du token de lecture permettant l'ouverture de la liseuse
    const { access_token: readingToken } = await immanensApi.get(
      `/store/${storeId}/get-streaming-token/issue/${issueId}`
    );

    return readingToken;
  } else {
    // Récupération de notre token utilisateur
    // Ce token sera envoyé à Immanens qui vérifiera auprès de notre serveur que l'utilisateur existe bien.
    const { token: pufUserToken } = await backendApi.get('/users/token', {
      userId: user.id,
    });

    // Login Immanens à l'aide du token utilisateur généré plus haut.
    const { x_user_token: immanensUserToken } = await immanensApi.post(
      '/user/online-login',
      {
        ext_token: pufUserToken,
      }
    );
    immanensApi.setHeader('x-user-token', immanensUserToken);

    // Récupération du token de lecture permettant l'ouverture de la liseuse
    const { access_token: readingToken } = await immanensApi.get(
      `/store/${storeId}/get-streaming-token/issue/${issueId}`
    );

    return readingToken;
  }
};

export const getPreviewToken = async ({ baseUrl, issueId }) => {
  // Création de l'instance d'API permettant les intéractions avec le serveur Immanens
  const immanensApi = new API2({
    baseUrl,
    headers: {
      Accept: 'application/json',
    },
    withCredentials: false,
    withEnvironmentInfo: false,
  });

  // Récupération du token de lecture permettant l'ouverture de la liseuse
  const { access_token: readingToken } = await immanensApi.get(
    `/get-sampler-token/issue/${issueId}`
  );
  return readingToken;
};

export const getAllIssues = async ({ baseUrl, storeId }) => {
  const immanensApi = new API2({
    baseUrl,
    headers: {
      Accept: 'application/json',
    },
    withCredentials: false,
    withEnvironmentInfo: false,
  });

  const { models } = await immanensApi.get(`/store/${storeId}/issues`);
  return models;
};
