import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import API from '@/utils/API';
import { ContentContext } from '@/globals/contexts';
import { makeRef } from '@/helpers/models/schema';
import useSite from '@/hooks/useSiteContext';

import SingleEntity from '../../SingleEntity';
import Row from '@/Layout/Row';
import { Button } from '@/components/new';
import PUFSeparator from '@/PUFComponents/base/visual/Separator';

const baseClassName = 'PUF-contents-grid';

export default function ContentsGridV2({
  entity: parentEntity,
  options,
  data: initialEntities,
  mainClassName,
  component = {},
  page = {},
  style,
}) {
  const {
    label: labelIfSingle,
    labelIfMultiple,
    mode,
    alternateMode,
    colCount = 1,
    enableLoadMore = false,
    seperatorBetweenRows = false,
    itemCount,
  } = options;
  const { path } = component;
  const { _id: pageId } = page;

  const { siteId } = useSite();
  const [entities, setEntities] = React.useState(initialEntities);
  const [isLoadingMoreContents, setIsLoadingMoreContents] =
    React.useState(false);
  const [hasMoreContents, setHasMoreContents] = React.useState(
    !itemCount || initialEntities.length >= itemCount
  );

  React.useEffect(() => {
    if (
      !_.isEqual(entities, initialEntities) &&
      initialEntities.length > entities.length
    ) {
      setEntities(initialEntities);
    }
  }, [initialEntities, entities]);

  const fetchMore = React.useCallback(
    async function () {
      setIsLoadingMoreContents(true);

      const getExcludedIds = () => {
        const excludedIds = {};
        const entitiesGroupedByCls = _.groupBy(entities, '_cls');
        Object.keys(entitiesGroupedByCls).forEach((cls) => {
          excludedIds[cls] = excludedIds[cls] || {};
          _.map(entitiesGroupedByCls[cls], '_id').forEach(
            (id) => (excludedIds[cls][id] = true)
          );
        });
        return excludedIds;
      };

      try {
        if (!path || !pageId) return;

        const newEntities = await API.get(
          `${siteId}/rendering/pages/${pageId}/loadMorePageComponentContents`,
          {
            componentPath: path,
            excludedIds: getExcludedIds(),
            entity: makeRef(parentEntity),
          }
        );

        setEntities([...entities, ...newEntities]);

        if (newEntities.length === 0) setHasMoreContents(false);
      } finally {
        setIsLoadingMoreContents(false);
      }
    },
    [entities, parentEntity, siteId, path, pageId]
  );

  if (!entities || entities.length === 0) return null;

  const label =
    entities.length > 0 ? labelIfMultiple || labelIfSingle : labelIfSingle;

  const rowCount =
    entities.length > 0 ? Math.ceil(entities.length / colCount) : 0;

  if (rowCount === 0) return null;

  return (
    <div className={mainClassName} style={style}>
      {label && <h3 className={`${baseClassName}-title`}>{label}</h3>}

      {_.times(rowCount).map((rowIndex) => {
        const rowEntities = entities.slice(
          rowIndex * colCount,
          (rowIndex + 1) * colCount
        );

        const columns = _.times(colCount).map((colIndex) => ({
          size: colCount ? 12 / colCount : 12,
          children: (
            <ContentContext.Provider value={rowEntities[colIndex]}>
              <SingleEntity
                mode={alternateMode && colIndex % 2 ? alternateMode : mode}
              />
            </ContentContext.Provider>
          ),
        }));

        return (
          <React.Fragment key={rowIndex}>
            <Row
              row={{ cols: columns }}
              key={rowIndex}
              className={classnames({
                'has-next': rowIndex < rowCount - 1,
              })}
            />
            {seperatorBetweenRows && rowIndex < rowCount - 1 && (
              <PUFSeparator />
            )}
          </React.Fragment>
        );
      })}

      {enableLoadMore && hasMoreContents && (
        <div className={'load-more-button-wrapper'}>
          <Button isText onClick={fetchMore} disabled={isLoadingMoreContents}>
            {isLoadingMoreContents ? 'Chargement...' : 'Voir plus de contenus'}
          </Button>
        </div>
      )}
    </div>
  );
}

ContentsGridV2.baseClassName = baseClassName;
