// Component used to display the content tags

import React from 'react';
import _ from 'lodash';

import { getFirst } from '@/utils/misc';

import FrontComponentBase from '../../FrontComponentBase';
import TagList from './TagList';

class EntityTags extends FrontComponentBase {
  static baseClassName = 'EntityTags';

  render() {
    // you can use props "content" for direct usage
    let entity =
      this.props.content || // retrocompat TODO remove
      this.props.entity ||
      this.getSingleEntity();

    if (!entity) {
      if (this.props.component && this.props.component._isDraft) {
        return (
          <div className="Entity-draft">Création d'un bloc Tags en cours</div>
        );
      } else {
        return null;
      }
    }

    let { contentType, mainTags } = entity;
    let { maxTags } = this.getOptions();

    let baseClassName = this.constructor.baseClassName;
    // add content type to the component main class
    let componentMainClass = baseClassName;
    let mainContentType = getFirst(contentType);
    if (mainContentType) {
      componentMainClass += ' ' + baseClassName + '--' + mainContentType?._id;
    }

    return (
      <TagList
        tags={maxTags ? _.slice(mainTags, 0, maxTags) : mainTags}
        className={componentMainClass}
        options={this.getOptions()}
      />
    );
  }
}

export default EntityTags;
