import React from 'react';
import classnames from 'classnames';
import { useUserAgent } from 'next-useragent';

import { ImageContext, UserAgentContext } from '@/globals/contexts';

import Column from '../Column';

//  const BREAKPOINTS = [480, 640, 720, 840, 1080, 1240, 1480];

const DEVICE_TYPE_TO_BREAKPOINTS = {
  desktop: [1080, 1240, 1480],
  tablet: [720, 840, 1080, 1240, 1480],
  mobile: [480, 640, 720, 840, 1080, 1240, 1480],
};

const getClassName = ({ cols, gutters, options, colCount, noResponsive }) => {
  const { className: optionClassName } = options || {};
  const actualColsCount = cols ? cols.length : colCount;

  return classnames('flexWrapper', optionClassName, {
    Row: gutters !== false,
    noResponsive: noResponsive,
    [`countCols${actualColsCount}`]: actualColsCount > 0,
  });
};

const getBreakpointClassNames = (ua: any) => {
  let breakpoints;

  if (ua.isMobile) {
    breakpoints = DEVICE_TYPE_TO_BREAKPOINTS.mobile;
  } else if (ua.isTablet) {
    breakpoints = DEVICE_TYPE_TO_BREAKPOINTS.tablet;
  } else {
    breakpoints = DEVICE_TYPE_TO_BREAKPOINTS.desktop;
  }

  return breakpoints.map((bp) => `break${bp}`).join(' ');
};

export default function RowV1({
  row = {},
  className: classNameProp,
  children,
}) {
  const useragent = React.useContext(UserAgentContext);
  const ua = useUserAgent(useragent);
  const { cols: columns, gutters, fullWidth, options = {} } = row;
  const { loadImagesSync } = options;

  const { loadImagesSync: parentLoadImagesSync } =
    React.useContext(ImageContext);
  const shouldLoadImagesSync =
    loadImagesSync !== undefined ? loadImagesSync : parentLoadImagesSync;

  const wrapperClassName = fullWidth ? 'fullwidthWrapper' : 'siteWrapper';
  const breakpointClassName = getBreakpointClassNames(ua);
  const className = classnames(
    getClassName(row),
    classNameProp || '',
    breakpointClassName
  );

  return (
    <ImageContext.Provider value={{ loadImagesSync: shouldLoadImagesSync }}>
      <div className={wrapperClassName}>
        <div className={className}>
          {!!columns
            ? columns.map((column, i) => (
                <Column column={column} gutters={gutters} key={i} />
              ))
            : children}
        </div>
      </div>
    </ImageContext.Provider>
  );
}
