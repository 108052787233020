import { INSTANCES } from '@/constants/instances';

import AnswersThumb from './answers/thumb/AnswersThumb';
import AnswerQuestionAuthor from '../base/answers/AnswerQuestionAuthor';
import AddToCartButton from '../base/purchase/AddToCartButton';
import AskAQuestion from '../base/questions/AskAQuestion';
import AuthorsThumb from './authors/thumb/AuthorsThumb';
import {
  BackToTop,
  VoteButtons,
  PageTitle,
  RichTextBlock,
} from '../base/components';
import Breadcrumb from '../base/navigation/BreadCrumb';
import CartButton from '../base/purchase/CartButton';
import SimpleCartCheckout from '../base/purchaseV2/CartCheckout/Simple';
import DefaultCartCheckout from '../base/purchaseV2/CartCheckout/Default';
import CartGlobalQuantity from '../base/purchase/CartGlobalQuantity';
import Comments from '../base/comments/List';
import CommentListV2 from '../base/commentsV2';
import ContentDuration from '../base/contents/ContentDuration';
import ContentPlaybackProgress from '../base/medias/ContentPlaybackProgress';
import ContentPlaylist from '../base/medias/ContentPlaylist';
import ContentThumbV1 from './contents/thumb/ContentThumbV1';
import ContentVideo from '../base/medias/ContentVideo';
import ContentsGridV1 from './lists/ContentsGridV1';
import ContentsGridV2 from './lists/ContentsGridV2';
import ContentsSliderV1 from '../base/lists/ContentsSliderV1';
import ContentsSliderV2 from '../base/lists/ContentsSliderV2';
import Contributors from '../base/purchase/Contributors';
import Countdown from '../base/Countdown';
import CrowdFundingCampaign from '../base/CrowdFundingCampaign';
import DiscountOrGiftInput from '../base/purchaseV2/CodeInput';
import EntityAttachment from '../base/entity/EntityAttachment';
import EntityAuthorsV1 from './entity/EntityAuthorsV1';
import EntityAuthorsV2 from './entity/EntityAuthorsV2';
import EntityBody from '../base/entity/EntityBody';
import EntityCommentsCountV2 from '../base/entity/EntityCommentsCountV2';
import EntityPosterV2 from './entity/EntityPosterV2';
import EntityIsFreeLabel from '../base/entity/EntityIsFreeLabel';
import EntityPrice from '../base/entity/EntityPrice';
import EntityPropertyV1 from '../base/entity/EntityPropertyV1';
import EntityPropertyV2 from '../base/entity/EntityPropertyV2';
import EntityDateV1 from '../base/entity/EntityDateV1';
import EntityDateV2 from '../base/entity/EntityDateV2';
import EntityDescV1 from '../base/entity/EntityDescV1';
import EntityDescV2 from '../base/entity/EntityDescV2';
import EntityTags from '../base/entity/EntityTags';
import EntityTitleV1 from '../base/entity/EntityTitleV1';
import EntityTitleV2 from '../base/entity/EntityTitleV2';
import EntityImage from '../base/entity/EntityImage';
import EntitySelect from '../base/lists/EntitySelect';
import EntitySocialNetworks from '../base/entity/EntitySocialNetworks';
import EntityTypeAndFirstTag from './entity/EntityTypeAndFirstTag';
import EntityContentTypeV1 from './entity/EntityContentTypeV1';
import EntityContentTypeV2 from './entity/EntityContentTypeV2';
import EntityMainTagV2 from './entity/EntityMainTagV2';
import EntityPaywallTagV2 from './entity/EntityPaywallTagV2';
import EntityEpisodeNumber from '../base/entity/EntityEpisodeNumber';
import EntityEdiisCartLink from '../clients/ediis/EntityEdiisCartLink';

// Ne pas confondre avec l'autre composant EntityLink qui est un outil
// Pour les autres composants
import PUFEntityLink from '../base/entity/EntityLink';

import Footer from '../base/navigation/Footer';
import ForgotPass from '../base/login/ForgotPass';
import Header from '../base/navigation/Header';
import Iframe from '../base/Iframe';
import ImmanensArticleLink from '../base/navigation/ImmanensArticleLink';
import ImmanensReader from '../base/reader/ImmanensReader';
import Link from '../base/navigation/Link';
import Login from '../base/login/Login';
import MainMenuV1 from '../base/navigation/menus/MainMenuV1';
import MainMenuV2 from '../base/navigation/menus/MainMenuV2';
import SubMenu from '../base/navigation/menus/SubMenu';
import MyMozzoReader from '../base/reader/MyMozzoReader';
import NewsThumb from '../base/news/thumb/NewsThumb';
import ProductContributors from '../base/purchase/ProductContributors';
import Profile from '../base/user/Profile';
import QuestionsThumb from './questions/thumb/QuestionsThumb';
import Register from '../base/login/Register';
// import Register from '../base/login/NewRegister';
import ResetPass from '../base/login/ResetPass';
import ResumeSeriesPlayback from '../base/navigation/ResumeSeriesPlayback';
import TournamentThumbnail from '../base/tournament/Thumbnail';
import SearchButton from '../base/navigation/SearchButton';
import SendAFile from '../base/user/SendAFile';
import SharingButtons from '../base/socials/SharingButtons';
import ShopsThumb from '../base/shops/thumb/ShopsThumb';
import SimpleImageV1 from '../base/medias/SimpleImageV1';
import SimpleImageV2 from '../base/medias/SimpleImageV2';
import SingleEntity from '../SingleEntity';
import SocialButtons from '../base/socials/SocialButtons';
import SubscribeToNewsletter from '../base/login/SubscribeToNewsletter';
import SubscriptionGroupRegister from '../base/subscription/SubscriptionGroupRegister';
import SubscriptionItem from './subscription/SubscriptionItem';
import ContentSwitchDisplay from '../base/contents/ContentSwitchDisplay';
import TagList from '../base/entity/TagList';
import UserButtons from '../base/navigation/UserButtons';
import {
  UserButtonsCustomV1,
  UserButtonsCustomV2,
} from '../base/navigation/UserButtonsCustom';
import SimpleVideo from '../base/medias/SimpleVideo';
import VideoPoster from '../base/medias/VideoPosterV1';
import CommentsCount from '../base/comments/Count';
import VimeoChatEmbed from '../base/embeds/VimeoChatEmbed';
import Title from '../base/visual/Title';
import Separator from '../base/visual/Separator';

const getRawComponentMap = (featureFlags: FeatureFlags) => ({
  AddToCartButton: {
    v1: {
      default: AddToCartButton,
    },
  },
  AnswerQuestionAuthor: {
    v1: {
      default: AnswerQuestionAuthor,
    },
  },
  AnswersThumb: {
    v1: {
      default: AnswersThumb,
    },
  },
  AskAQuestion: {
    v1: {
      default: AskAQuestion,
    },
  },
  AuthorsThumb: {
    v1: {
      default: AuthorsThumb,
    },
  },
  BackToTop: {
    v1: {
      default: BackToTop,
    },
  },
  Breadcrumb: {
    v1: {
      default: Breadcrumb,
    },
  },
  CartButton: {
    v1: {
      default: CartButton,
    },
  },
  CartCheckout: {
    v1: {
      default: DefaultCartCheckout,
      simple: SimpleCartCheckout,
    },
  },
  CartGlobalQuantity: {
    v1: {
      default: CartGlobalQuantity,
    },
  },
  Comments: {
    v1: {
      count: CommentsCount,
      default: (props) => <Comments {...props} maxCommentLength={700} />,
      list: (props) => <Comments {...props} maxCommentLength={700} />,
    },
    v2: {
      count: CommentsCount,
      default: (props) => <CommentListV2 {...props} maxCommentLength={700} />,
      list: (props) => <CommentListV2 {...props} maxCommentLength={700} />,
    },
  },
  CommentsCount: { v1: { default: CommentsCount } },
  ContentDuration: { v1: { default: ContentDuration } },
  ContentPlaybackProgress: {
    v1: {
      default: ContentPlaybackProgress,
    },
  },
  ContentPlaylist: {
    v1: {
      default: ContentPlaylist,
    },
  },
  ContentThumb: {
    v1: {
      default: ContentThumbV1,
    },
  },
  ContentVideo: {
    v1: {
      default: ContentVideo,
    },
  },
  ContentsGrid: {
    v1: {
      default: ContentsGridV1,
    },
    v2: {
      default: ContentsGridV2,
    },
  },
  ContentsSlider: {
    v1: {
      default: ContentsSliderV1,
    },
    v2: {
      default: ContentsSliderV2,
    },
  },
  ContentsThumb: {
    v1: {
      default: ContentThumbV1,
    },
  },
  Contributors: {
    v1: {
      default: Contributors,
    },
  },
  CrowdFundingCampaign: { v1: { default: CrowdFundingCampaign } },
  Countdown: {
    v1: {
      default: Countdown,
    },
  },
  DiscountOrGiftInput: {
    v1: {
      default: DiscountOrGiftInput,
    },
  },
  EntityAttachment: {
    v1: {
      default: EntityAttachment,
    },
  },
  EntityAuthors: {
    v1: {
      default: EntityAuthorsV1,
    },
    v2: {
      default: EntityAuthorsV2,
    },
  },
  EntityBody: {
    v1: {
      default: EntityBody,
    },
  },
  EntityCommentsCount: {
    v1: {
      default: EntityCommentsCountV2,
    },
    v2: {
      default: EntityCommentsCountV2,
    },
  },
  EntityPoster: {
    v1: {
      default: EntityPosterV2,
    },
    v2: {
      default: EntityPosterV2,
    },
  },
  EntityDate: {
    v1: {
      default: EntityDateV1,
    },
    v2: {
      default: EntityDateV2,
    },
  },
  EntityDesc: {
    v1: {
      default: EntityDescV1,
    },
    v2: {
      default: EntityDescV2,
    },
  },
  EntityImage: {
    v1: {
      default: EntityImage,
    },
  },
  EntityIsFreeLabel: {
    v1: {
      default: EntityIsFreeLabel,
    },
  },
  EntityPrice: {
    v1: {
      default: EntityPrice,
    },
  },
  EntityProperty: {
    v1: {
      default: EntityPropertyV1,
    },
    v2: {
      default: EntityPropertyV2,
    },
  },
  EntitySelect: {
    v1: {
      default: EntitySelect,
    },
  },
  EntitySocialNetworks: {
    v1: {
      default: EntitySocialNetworks,
    },
  },
  EntityTags: {
    v1: {
      default: EntityTags,
    },
  },
  EntityThumb: {
    v1: {
      default: EntityImage,
    },
  },
  EntityTitle: {
    v1: {
      default: EntityTitleV1,
    },
    v2: {
      default: EntityTitleV2,
    },
  },
  EntityContentType: {
    v1: { default: EntityContentTypeV1 },
    v2: { default: EntityContentTypeV2 },
  },
  EntityMainContentType: {
    v1: { default: EntityContentTypeV2 },
    v2: { default: EntityContentTypeV2 },
  },
  EntityMainTag: {
    v1: { default: EntityMainTagV2 },
    v2: { default: EntityMainTagV2 },
  },
  EntityPaywallTag: {
    v1: { default: EntityPaywallTagV2 },
    v2: { default: EntityPaywallTagV2 },
  },
  EntityTypeAndFirstTag: {
    v1: {
      default: EntityTypeAndFirstTag,
    },
  },
  EpisodeNumber: {
    v1: {
      default: EntityEpisodeNumber,
    },
  },
  EntityEdiisCartLink: {
    v1: {
      default: EntityEdiisCartLink,
    },
  },
  EntityLink: {
    v1: {
      default: PUFEntityLink,
    },
  },
  Footer: {
    v1: {
      default: Footer,
    },
  },
  ForgotPass: {
    v1: {
      default: ForgotPass,
    },
  },
  Header: {
    v1: {
      default: Header,
    },
  },
  Iframe: {
    v1: {
      default: Iframe,
    },
  },
  ImmanensArticleLink: {
    v1: {
      default: ImmanensArticleLink,
    },
  },
  ImmanensReader: {
    v1: {
      default: ImmanensReader,
    },
  },
  Link: {
    v1: {
      default: Link,
    },
  },
  Login: {
    v1: {
      default: Login,
    },
  },
  Menu: {
    v1: {
      default: MainMenuV1,
      tabs: SubMenu,
    },
    v2: {
      default: MainMenuV2,
    },
  },
  MyMozzoReader: {
    v1: {
      default: MyMozzoReader,
    },
  },
  NewsThumb: {
    v1: {
      default: NewsThumb,
    },
  },
  PageTitle: {
    v1: {
      default: PageTitle,
    },
  },
  ProductContributors: {
    v1: {
      default: ProductContributors,
    },
  },
  Profile: {
    v1: {
      default: Profile,
    },
  },
  QuestionsThumb: {
    v1: {
      default: QuestionsThumb,
    },
  },
  Register: {
    v1: {
      default: Register,
    },
  },
  ResetPass: {
    v1: {
      default: ResetPass,
    },
  },
  ResumeSeriesPlayback: {
    v1: {
      default: ResumeSeriesPlayback,
    },
  },
  RichTextBlock: {
    v1: {
      default: RichTextBlock,
    },
  },
  SearchButton: {
    v1: {
      default: SearchButton,
    },
  },

  SendAFile: {
    v1: {
      default: SendAFile,
    },
  },
  Separator: {
    v1: {
      default: Separator,
    },
  },
  SharingButtons: {
    v1: {
      default: SharingButtons,
    },
  },
  ShopsThumb: {
    v1: {
      default: ShopsThumb,
    },
  },
  SimpleImage: {
    v1: {
      default: SimpleImageV1,
    },
    v2: {
      default: SimpleImageV2,
    },
  },
  SingleEntity: {
    v1: {
      default: SingleEntity,
    },
  },
  SocialButtons: {
    v1: {
      default: SocialButtons,
    },
  },
  SubscribeToNewsletter: {
    v1: {
      default: SubscribeToNewsletter,
    },
  },
  SubscriptionGroupRegister: {
    v1: {
      default: SubscriptionGroupRegister,
    },
  },
  SubscriptionItem: {
    v1: {
      default: SubscriptionItem,
    },
  },
  SwitchDisplayButton: {
    v1: {
      default: ContentSwitchDisplay,
    },
  },
  TagList: {
    v1: {
      default: TagList,
    },
  },
  Title: {
    v1: {
      default: Title,
    },
  },
  TournamentThumbnail: {
    v1: {
      default: TournamentThumbnail,
    },
  },
  UserButtons: {
    v1: {
      default: UserButtons,
    },
  },
  UserButtonsCustom: {
    v1: {
      default: UserButtonsCustomV1,
    },
    v2: {
      default: UserButtonsCustomV2,
    },
  },
  Video: {
    v1: {
      default: SimpleVideo,
    },
  },
  VideoPoster: {
    v1: {
      default: VideoPoster,
    },
  },
  VoteButtons: {
    v1: {
      default: VoteButtons,
    },
  },
  contributors: {
    v1: {
      default: EntityAuthorsV1,
    },
  },
  VimeoChatEmbed: {
    v1: {
      default: VimeoChatEmbed,
    },
  },
  id: INSTANCES.TEMPLATE1,
});

export default getRawComponentMap;
