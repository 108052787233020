// @flow
import * as React from 'react';

import { InformationBubble } from '@/components/new';

type Props = {| error: { code: number, message: string } |};

export default function PaymentErrorBanner({ error }: Props): React.Node {
  return (
    <InformationBubble
      type="danger"
      style={{ marginBottom: 20 }}
      additionalClassName="error-banner"
    >
      {error.message}
    </InformationBubble>
  );
}
