import moment from 'moment';
import React from 'react';

import FrontComponentBase from '../FrontComponentBase';

class Countdown extends FrontComponentBase {
  static baseClassName = 'Countdown';
  static defaultProps = {};

  didMount() {
    this.setTimer(
      () =>
        this.setState({
          time: moment().unix(),
        }),
      1000,
      true
    );
  }

  render() {
    let { endTs, beginTs, prefix, suffix } = this.getOptions();
    let { time } = this.state;
    let duration = moment.duration(moment.unix(endTs).diff(moment()));

    let width;
    if (beginTs) {
      let progress = beginTs && (time - beginTs) / (endTs - beginTs);
      width = Math.max(0, Math.min(100, progress * 100)).toFixed(5) + '%';
    }

    // classNames code style not respected (backyard again) // TODO refactor

    return (
      (endTs && (
        <div className={this.getMainCSSClass('blocBar')}>
          <div className="number-bold border-special">
            {prefix}
            {Math.max(0, Math.floor(duration.asDays()))}
            {suffix || ' jours restants'}
          </div>
          {beginTs && (
            <div className={this.getMainCSSClass()}>
              <div className="bar">
                <div className="inside" style={{ width }}></div>
              </div>
            </div>
          )}
        </div>
      )) ||
      null
    );
  }
}

export default Countdown;
