// @flow
import * as React from 'react';
import classnames from 'classnames';

import type { Layout as LayoutType } from '@/types/layout';

import {
  LayoutComponentsContext,
  LayoutDataContext,
  LayoutComponentsDataContext,
  LayoutCustomModesContext,
} from './contexts';

import Row from './Row';
import PUFSeparator from '@/PUFComponents/base/visual/Separator';

type Props = {|
  layout: LayoutType,
  isPageWrapper?: boolean,
|};

export default function Layout({ layout, isPageWrapper }: Props): React.Node {
  const {
    components,
    data,
    componentsData,
    structure = [],
    customModes,
  } = layout;

  const componentContext = React.useContext(LayoutComponentsContext);
  const dataContext = React.useContext(LayoutDataContext);
  const componentsDataContext = React.useContext(LayoutComponentsDataContext);
  const customModesContext = React.useContext(LayoutCustomModesContext);

  const pageWrap = React.useCallback(
    (content, { additionalClassName = '', style = {} }) =>
      isPageWrapper ? (
        <div
          className={classnames('content-container', additionalClassName)}
          style={style}
        >
          {content}
        </div>
      ) : (
        content
      ),
    [isPageWrapper]
  );

  return (
    <LayoutComponentsContext.Provider value={components || componentContext}>
      <LayoutDataContext.Provider value={data || dataContext}>
        <LayoutComponentsDataContext.Provider
          value={componentsData || componentsDataContext}
        >
          <LayoutCustomModesContext.Provider
            value={customModes || customModesContext}
          >
            {structure.map((row, i) => {
              const { options = {} } = row;
              const {
                withSeparator,
                noPaddingTop,
                noPaddingBottom,
                wrapperClassName,
              } = options;

              return (
                <React.Fragment key={row.uuid || i}>
                  {pageWrap(<Row row={row} isPageWrapper={isPageWrapper} />, {
                    style: {
                      paddingTop: noPaddingTop ? 0 : undefined,
                      paddingBottom: noPaddingBottom ? 0 : undefined,
                    },
                    additionalClassName: wrapperClassName,
                  })}
                  {withSeparator &&
                    pageWrap(<PUFSeparator />, {
                      additionalClassName: 'for-separator',
                    })}
                </React.Fragment>
              );
            })}
          </LayoutCustomModesContext.Provider>
        </LayoutComponentsDataContext.Provider>
      </LayoutDataContext.Provider>
    </LayoutComponentsContext.Provider>
  );
}
