import _ from 'lodash';
import ApiClient from './ApiClient';

// TODO handle binds !

class PaginatedData {
  constructor(
    resource,
    params,
    options,
    callback,
    loadNow = true,
    initialData
  ) {
    this.options = {
      perPage: 10,
      startOffset: 0,
      ...options,
    };

    this.resource = resource;
    this.params = params;
    this.callback = callback;
    this.count = null;

    this.data = [];
    this.dataLength = 0;
    this.channel = _.uniqueId('pd_');
    this.finished = false;
    this.finished = false;

    if (initialData) {
      this.data = initialData;
      this.dataLength = initialData.length;
    }

    if (loadNow) this.loadMore(true);
  }

  updateParams(newParams, replace = false) {
    this.params = replace ? { ...newParams } : { ...this.params, ...newParams };
    this.data = [];
    this.dataLength = 0;

    if (this.isLoading) {
      this.stopLoading();
    }

    this.loadMore(true);
  }

  loadMore(reset = false) {
    if (this.isLoading) {
      console.warn('still loading paginated data for ' + this.resource);
    } else if (
      !reset &&
      this.count === this.dataLength + (this.params.offset || 0)
    ) {
      console.debug('nothing more to load for ' + this.resource);
    } else if (!reset && this.finished) {
      console.debug('nothing more to load (2) for ' + this.resource);
    } else {
      // load
      this.isLoading = true;
      if (reset) {
        this.finished = false;
        this.dataLength = 0;
      }

      let paginationParams = reset
        ? {
            offset: this.options.startOffset,
            limit:
              this.dataLength +
              (this.options.initialCount || this.options.perPage),
            withCount: true,
          }
        : {
            offset: this.options.startOffset + this.dataLength,
            limit: this.options.perPage,
            withCount: this.count === null,
          };

      console.debug(
        'request ' +
          this.resource +
          ' offset ' +
          paginationParams.offset +
          ', limit ' +
          paginationParams.limit +
          ', total ' +
          this.count +
          ((reset && ' RESET') || '')
      );

      ApiClient.getCollection(
        this.resource,
        _.assign({}, this.params, paginationParams),
        (res) => {
          // if withCount works, we get {data, count} otherwise data directly
          let moreData = (res && res.data) || res || [];

          console.debug(
            'received ' +
              moreData.length +
              (this.count ? ', total ' + this.count : '')
          );
          console.debug(moreData.map((x) => x._id)); /////

          this.dataLength = reset
            ? moreData.length
            : this.dataLength + moreData.length;
          this.data = reset ? moreData : this.data.concat(moreData);

          if (_.isInteger(res.count)) {
            this.count = res.count;
          } else if (moreData.length < this.options.perPage) {
            console.debug('last load ' + this.resource);
            this.finished = true;
          }

          this.isLoading = false;
          this.callback(_.clone(this.data), this.count);
        },
        (err) => {
          this.isLoading = false;
          if (err && err.code === 'ABORTED') {
            // Most likely stopLoading() was called, or request is being re-made, so don't fire the callback!
            // It may arrive AFTER the subsequent request containing the new data (and thus override this new data).
            // If you see a weird bug related to data loading, you can try restoring this code temporarily:
            //this.callback(_.clone(this.data), this.count);
          } else {
            // TODO handle error better to display message or else...
            console.warn('Error loading ' + this.resource + ': ' + err.message);
            this.callback(null, null);
          }
        },
        this.channel
      );
    }
  }

  stopLoading() {
    ApiClient.abort(this.channel);
    this.isLoading = false;
  }

  // TODO check those 2 functions:
  getLength() {
    if (this.data) {
      if (this.data.items) {
        return this.data.items.length;
      }
      return this.data.length;
    }
    return 0;
  }

  hasMore() {
    return this.getLength() < this.count;
  }
}

export default PaginatedData;
