// @flow
import NextHead from 'next/head';
import * as React from 'react';
import { filter, map, get } from 'lodash';
import { DefaultSeo, SocialProfileJsonLd } from 'next-seo';

import type { SiteConfigOptions, Styles, Site } from '@/types/site';

import {
  prepareCSSVariables,
  FONT_KEY_TO_CSS_VARIABLE,
} from '@/helpers/DOM/css';
import config from '@/utils/config';
import { APIContext } from '@/globals/contexts';

type Props = {|
  site: Site,
  siteOptions: SiteConfigOptions,
  styles: Styles,
  baseUrl: string,
|};

export const STRIPE_SCRIPT_ID = 'stripe-js';

export default function Head({
  site,
  siteOptions,
  styles,
  baseUrl,
}: Props): React.Node {
  const { getImageUrl } = React.useContext(APIContext);
  const favicon = siteOptions.favicon
    ? getImageUrl(siteOptions.favicon)
    : '/static/images/favicon.png';

  // TODO meta should also be set by pages
  const fontToImportUrls = filter(
    // $FlowIgnore
    map(FONT_KEY_TO_CSS_VARIABLE, (cssVar, f) => get(styles, [f, 'cssUrl'])) //
  );

  const canonicalUrl = site && (site.url || 'https://' + site.domain);

  const sharingImageUrl = getImageUrl(
    siteOptions.shareImage || siteOptions.emailHeaderImage
  ); // must be absolute

  // twittter: 2:1 ratio
  const twitterSharingImageUrl = getImageUrl(
    siteOptions.shareImage || siteOptions.emailHeaderImage
  );

  const twitterUserWithoutAt =
    siteOptions.twitterUser && siteOptions.twitterUser.replace(/^@?/, '');
  const twitterUser = twitterUserWithoutAt && '@' + twitterUserWithoutAt; // ensure the "@" at beginning
  const twitterUrl =
    siteOptions.twitterUrl || // obsolete ?
    (twitterUserWithoutAt && 'https://twitter.com/' + twitterUserWithoutAt);

  return (
    <>
      <NextHead>
        {siteOptions.googleSiteVerification && (
          <meta
            name="google-site-verification"
            key="gsv"
            content={siteOptions.googleSiteVerification}
          />
        )}

        <link rel="icon" href={favicon} />

        {fontToImportUrls.map((url) => (
          <link key={url} rel="stylesheet" href={url} />
        ))}

        <style
          dangerouslySetInnerHTML={{
            // key needed?
            // CSS variable SSR:
            __html: prepareCSSVariables(styles),
          }}
        />

        {typeof window !== 'undefined' &&
          typeof window.immanens === 'undefined' && (
            <script
              async
              src="https://pressview5.immanens.com/api/app/reader-press/js"
            />
          )}

        {/* beware of the "double execution" bug with async/defer: https://github.com/zeit/next.js/issues/9070 */}

        <script
          defer
          id={STRIPE_SCRIPT_ID}
          src="https://js.stripe.com/v3/"
        ></script>

        {/* to allow Chromecast API */}
        <script
          defer
          src="//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"
        ></script>
      </NextHead>

      <DefaultSeo
        // https://github.com/garmeeh/next-seo
        title={siteOptions.siteTitle}
        description={siteOptions.siteDescription}
        canonical={canonicalUrl}
        openGraph={{
          type: 'website',
          locale: 'fr_FR',
          images: [
            {
              url: sharingImageUrl,
              //width: 800,
              //height: 600,
              //alt: 'Og Image Alt',
            },
          ],
        }}
        twitter={{
          //handle: '@handle', // twitter:creator
          site: twitterUser,
          cardType: 'summary_large_image',
          image: twitterSharingImageUrl,
          // Note: Twitter will read the og:title, og:image and og:description tags for their card, this is why next-seo omits twitter:title, twitter:image and twitter:description so not to duplicate.
        }}
        dangerouslySetAllPagesToNoIndex={
          !['prod', 'preprod'].includes(config.PUF_ENV)
        }
        dangerouslySetAllPagesToNoFollow={
          !['prod', 'preprod'].includes(config.PUF_ENV)
        }
      />

      <SocialProfileJsonLd
        type="Organization"
        name={siteOptions.siteTitle}
        url={canonicalUrl}
        sameAs={filter([
          // filter undefined
          twitterUrl,
          siteOptions.facebookUrl,
          siteOptions.youtubeUrl,
        ])}
      />
    </>
  );
}
