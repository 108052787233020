import React from 'react';

import { useContent } from '@/hooks';

import { Button } from '@/components/new';
import EntityLink from '@/PUFComponents/base/navigation/EntityLink';

const baseClassName = 'PUF-entity-link';

export default function PUFEntityLink({ options, mainClassName, style }) {
  const content = useContent();

  if (!content) return null;

  const { label, isButton, size, color } = options;

  return (
    <EntityLink
      entity={content}
      render={(href) => (
        <Button
          style={style}
          isText={!isButton}
          href={href}
          size={size || 'large'}
          element="a"
          color={color?.type}
        >
          {label}
        </Button>
      )}
    />
  );
}

PUFEntityLink.baseClassName = baseClassName;
