// @flow
import * as React from 'react';

import type { FullCartState } from '@/types/stores';

import { prettyPrice } from '@/helpers/format';

type Props = {|
  cartState: FullCartState,
|};

export default function Total({ cartState }: Props): React.Node {
  const { total, totalDiscount, cartItems, shippingCost, donation } = cartState;

  return (
    <div className="cart-total">
      <div className="summary-title">Récapitulatif</div>

      {Object.keys(cartItems).map((itemId) => {
        const item = cartItems[itemId];
        const { userPrice, entity, quantity } = item;
        const price = userPrice || entity.price;

        return (
          <div className="cart-item" key={itemId}>
            <div>
              <span className="entity-name">&bull; {entity.label}</span>
              <span className="quantity"> x {quantity || 1}</span>
            </div>

            <span className="amount">+ {prettyPrice(price * quantity)}</span>
          </div>
        );
      })}

      {donation > 0 && (
        <div className="donation">
          <span>&bull; Don</span>
          <span className="amount">+ {prettyPrice(donation)}</span>
        </div>
      )}

      {shippingCost > 0 && (
        <div className="shipping-cost">
          <span>&bull; Frais de livraison</span>
          <span className="amount">+ {prettyPrice(shippingCost)}</span>
        </div>
      )}

      {!!totalDiscount && totalDiscount > 0 && (
        <div className="total-discount">
          <span>&bull; Discount</span>
          <span className="amount">- {prettyPrice(totalDiscount)}</span>
        </div>
      )}

      <hr className="seperator" />

      <div className="total-amount">
        <span>Total</span>
        <span className="amount">= {prettyPrice(total)}</span>
      </div>
    </div>
  );
}
