// Component used to display a user name

import React from 'react';
import moment from 'moment';

import { InstanceStore, UserStore, UserActions } from '@/reflux';
import API from '@/utils/API';
import {
  setGtmUserStatus,
  gtmEventNewsletterSubscribe,
} from '@/utils/googleTagManager';
import { isValidEmail } from '@/utils/misc';

import { InputTextBase, RichTextContent } from '@/components/old';
import FrontComponentBase from '../../FrontComponentBase';

class SubscribeToNewsletter extends FrontComponentBase {
  static baseClassName = 'SubscribeToNewsletter';
  stores = [UserStore, InstanceStore];
  state = {
    email: '',
    sent: false,
  };

  onSubscribe = () => {
    let { user } = this.state;
    if (user) {
      console.debug('Subscribe user to newsletter');

      UserActions.update({ receiveNewsletter: true }, (err, user) => {
        // TODO display error
        if (!err) {
          this.setState({ sent: true });
          gtmEventNewsletterSubscribe();
          setGtmUserStatus(user);
        }
      });
    } else {
      if (this.isValid()) {
        console.debug('Subscribe to newsletter with e-mail', this.state.email);

        API.post(this.state.instanceName + '/data/NewsletterSubscriber', {
          email: this.state.email,
          createTs: moment().unix(),
        }).then(() => {
          this.setState({ sent: true });
          gtmEventNewsletterSubscribe();
        });
      }
    }
  };

  onChangeEmail = (email) => {
    this.setState({ email });
  };

  isValid() {
    return isValidEmail(this.state.email);
  }

  render() {
    let options = this.getOptions();
    let { user, sent } = this.state;
    let isValid = this.isValid();

    if (user && user.receiveNewsletter && !options.alreadySubscribedLabel)
      return null; // already subscribed

    const baseClass = this.constructor.baseClassName;

    // TODO use InputSubmitBase component

    return (
      <div className={this.getMainCSSClass()}>
        {user && user.receiveNewsletter && options.alreadySubscribedLabel ? (
          <RichTextContent html={options.alreadySubscribedLabel} />
        ) : sent ? (
          <span>Merci !</span>
        ) : user ? ( // thus, not subscribed
          <React.Fragment>
            {options.text && <RichTextContent html={options.text} />}
            <span className={baseClass + '-button InputSubmitBase'}>
              <button
                onClick={this.onSubscribe}
                className="InputSubmitBase-button"
              >
                {options.buttonLabel || 'Recevoir la newsletter'}
              </button>
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {options.text && <RichTextContent html={options.text} />}
            <span className={baseClass + '-input-email'}>
              <InputTextBase
                value={this.state.email}
                onChange={this.onChangeEmail}
                type="email"
                autoComplete="email"
                placeholder="Votre e-mail"
              />
            </span>
            <span
              className={
                baseClass +
                '-button InputSubmitBase' +
                (isValid ? '' : ' InputSubmitBase--disabled')
              }
            >
              <button
                onClick={this.onSubscribe}
                className="InputSubmitBase-button"
              >
                {options.buttonLabel || 'Envoyer'}
              </button>
            </span>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default SubscribeToNewsletter;
