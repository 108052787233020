import * as React from 'react';

import Button from '@/components/new/Button';
import Icon from '@/components/new/Icon';
import Input from '@/components/old/form/InputTextBase';

const formatScore = (value) => {
  if (value > 10) return 10;
  if (value < 0) return 0;
  return value;
};

export default function ScoreBet({ bet, makeBet }) {
  const { winnerId, score: betScore = [], gameId } = bet;
  const betHasScore = betScore.length === 2;
  const [scoreValidated, setScoreValidated] = React.useState(betHasScore);
  const [score, setScore] = React.useState(betHasScore ? betScore : [0, 0]);
  const [error, setError] = React.useState(null);

  if (scoreValidated)
    return (
      <div className="score-bet">
        <div className="scores-wrapper">
          <div className="scores">
            <div className="score">{bet.score[0]}</div>
            <span>-</span>
            <div className="score">{bet.score[1]}</div>

            <Button onClick={() => setScoreValidated(false)} size="tiny">
              <Icon name="edit" size="small" />
            </Button>
          </div>
        </div>
      </div>
    );

  return (
    <div className="score-bet">
      <div className="scores-wrapper">
        <div className="scores">
          <Input
            value={score[0]}
            onChange={(value) =>
              setScore([formatScore(parseInt(value, 10)), score[1]])
            }
            type="number"
          />

          <span>-</span>
          <Input
            value={score[1]}
            onChange={(value) =>
              setScore([score[0], formatScore(parseInt(value, 10))])
            }
            type="number"
          />
          <Button
            onClick={async () => {
              try {
                await makeBet(gameId, { score, winnerId });
                setScoreValidated(true);
              } catch (e) {
                setError(e);
              }
            }}
            size="tiny"
          >
            <Icon name="check" size="small" />
          </Button>
        </div>
      </div>
      {error && <p className="has-text-error">{JSON.stringify(error)}</p>}
    </div>
  );
}
