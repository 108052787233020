import React from 'react';

import { UserStore } from '@/reflux';
import { getFirst } from '@/utils/misc';

import VideoPlayer from '../video/VideoPlayer';
import { goToEntityPage } from '../navigation/EntityLink';
import FrontComponentBase from '../../FrontComponentBase';
import PlayStatsMgr from './PlayStatsMgr';

class ContentPlaylist extends FrontComponentBase {
  static baseClassName = 'ContentPlaylist';

  state = {
    index: 0,
    currentContent: null,
  };
  playStats = new PlayStatsMgr();
  stores = [UserStore];

  playFirst() {
    let entities = this.getEntityList();

    console.debug('Playlist  > play first');

    this.setState({
      index: 0,
      currentContent: entities[0],
    });
  }

  loadCurrentContent(entity) {
    console.debug('Playlist  > load details for', entity);
    //this.setState({currentContent: null}); // disabled for smoother transitions

    if (entity) {
      this.bindObject(
        entity._cls,
        entity._id,
        { videoData: false },
        'currentContent'
      );
    }
  }

  playNext(forceIndex) {
    let entities = this.getEntityList();
    let { index } = this.state;
    let nextIndex =
      forceIndex !== undefined ? forceIndex : (index + 1) % entities.length;

    console.debug('Playlist  > play next', forceIndex, '=>', nextIndex);

    this.setState({
      index: nextIndex,
    });

    this.loadCurrentContent(entities[nextIndex]);
  }

  didMount() {
    console.debug('Playlist  > did mount');
    this.observeProps(['component', 'data'], () => {
      console.debug('Playlist  > change content');
      this.playFirst();
    });
  }

  onVideoClick = () => {
    goToEntityPage(this.state.currentContent);
  };

  onNext = () => {
    this.playNext();
  };

  onEnd = () => {
    this.onEndStats();
    this.playNext();
  };

  //////////////////////////////////////////////////////////////////////////////////////////////
  // Playstats
  onPlayerTime = (currentTime, duration, playingTime, mode) => {
    let content = this.state.currentContent;
    let playlistRank = this.state.index + 1;

    if (content && content._id && content.video) {
      this.playStats.onPlayTime(
        currentTime,
        playingTime,
        content,
        this.state.user,
        playlistRank,
        mode
      );
    }
  };

  onEndStats = () => {
    let content = this.state.currentContent;
    if (content._id && content.video) {
      this.playStats.onEnd(content);
    }
  };

  willUnmount() {
    this.playStats.onClose();
  }

  render() {
    let options = this.getOptions();
    let { currentContent } = this.state;

    let entities = this.getEntityList();

    if (!entities || entities.length === 0) {
      if (this.state.draftMode) {
        // "no display" condition
        return (
          <div className="draft-placeholder">{this.props.component.label}</div>
        );
      } else return null;
    }

    let { contentType, label } = currentContent || {};

    let baseClassName = this.constructor.baseClassName;
    // add currentContent type to the component main class
    let mainContentType = getFirst(contentType);
    let componentMainClass =
      baseClassName +
      (mainContentType ? ' ' + baseClassName + '--' + mainContentType : '');

    return (
      <div className={componentMainClass}>
        <div className={baseClassName + '-video '}>
          <VideoPlayer
            video={currentContent && currentContent.video}
            onEnd={this.onEnd}
            autoplay={'muted'}
            onVideoClick={this.onVideoClick}
            onNext={this.onNext}
            onPlayerTime={this.onPlayerTime}
            inlineOnIphone={true}
          />
        </div>

        <div className={baseClassName + '-header'}>
          <div className={baseClassName + '-header-inner'}>
            {options.prefix}
            {label}
          </div>
        </div>
      </div>
    );
  }
}

export default ContentPlaylist;
