// Component used to display the content description

import React from 'react';

import { ContentContext } from '@/globals/contexts';

import FrontComponentBase from '../../FrontComponentBase';
import SingleEntity from '../../SingleEntity';
import EntityLink from '../navigation/EntityLink';

class ResumeSeriesPlayback extends FrontComponentBase {
  static baseClassName = 'ResumeSeriesPlayback';

  getEpisode(seriedId, episodeIndex, callback) {
    this.stopRequests && this.stopRequests();
    this.stopRequests = this.bindCollection(
      'VideoContent',
      {
        filter: {
          'series._id': seriedId,
          episodeIndex,
        },
        limit: 1,
      },
      (contents) => {
        this.stopRequests();
        callback(contents && contents[0]);
      }
    );
  }

  getFirstEpisode(series) {
    this.getEpisode(series._id, 1, (firstEpisode) => {
      if (firstEpisode) {
        console.debug('Watch episode 1');
        this.setState({ content: firstEpisode });
      }
      console.warn('No episode 1 for series', series.label);
    });
  }

  handleUserWatched(userWatched) {
    const series = this.getSingleEntity();
    if (series) {
      // always true
      if (userWatched) {
        if (
          !userWatched.duration ||
          userWatched.duration - userWatched.time > 10
        ) {
          console.debug(
            'User is watching episode',
            userWatched.entity.episodeIndex,
            'of series',
            series.label
          );
          this.setState({
            content: userWatched.entity,
            status: 'watching_episode',
          });
        } else {
          console.debug(
            'User finished episode',
            userWatched.entity.episodeIndex,
            'of series',
            series.label
          );
          this.getEpisode(
            series._id,
            userWatched.entity.episodeIndex + 1,
            (nextEpisode) => {
              if (nextEpisode) {
                console.debug('Watch next episode');
                this.setState({ content: nextEpisode, status: 'next_episode' });
              } else {
                this.getFirstEpisode(series);
              }
            }
          );
        }
      } else {
        console.debug("User didn't watch any episode of series", series.label);
        this.setState({ status: 'never_played' });
        this.getFirstEpisode(series);
      }
    } else console.error('no series entity anymore');
  }

  didMount() {
    const rebindUserWatchedData = this.getRebindToCollectionFunc(
      (userWatchedList) =>
        this.handleUserWatched(userWatchedList && userWatchedList[0])
    );

    this.observeSingleEntity((entity) => {
      this.setState({ content: null, status: null });
      if (entity && entity._cls === 'Series') {
        rebindUserWatchedData('UserWatched', {
          seriesId: entity._id,
          sortBy: { watchTs: -1 },
          depDepth: 2,
          limit: 1,
        });
      } else if (entity) {
        console.warn(
          "ResumeSeriesPlayback needs a 'Series' object, not ",
          entity._cls
        );
      }
    });
  }

  render() {
    const { content, status } = this.state;
    if (!content) return null;

    const {
      mode,
      seriesMode,
      startLabel,
      resumeLabel,
      nextLabel,
      displayIfStart,
    } = this.getOptions();

    if (mode === 'button') {
      if (displayIfStart === false && status === 'never_played') return null;

      let label =
        {
          watching_episode: resumeLabel || "Reprendre l'épisode [[n]]",
          next_episode: nextLabel || "Lire l'épisode [[n]]",
        }[status] || // status = "never_played":
        startLabel ||
        'Regarder la série';

      return (
        <div className={this.getMainCSSClass()}>
          <EntityLink entity={content}>
            <a
              className={
                this.constructor.baseClassName + '-button-link standard-button'
              }
            >
              {label.replace('[[n]]', content.episodeIndex || '')}
            </a>
          </EntityLink>
        </div>
      );
    } else if (mode === 'series') {
      const series = this.getSingleEntity();

      return (
        <EntityLink entity={content}>
          <a className={this.constructor.baseClassName + '-link'}>
            <ContentContext.Provider value={series}>
              <SingleEntity mode={seriesMode} />
            </ContentContext.Provider>
          </a>
        </EntityLink>
      );
    }
  }
}

export default ResumeSeriesPlayback;
