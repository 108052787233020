import { capitalize, pickBy, mapKeys } from 'lodash';
import React from 'react';
import classnames from 'classnames';

import { useContent } from '@/hooks';
import { LayoutComponentsDataContext } from '@/Layout/contexts';
import { ContentContext } from '@/globals/contexts';

import Layout from '@/Layout'; // Warning: circular dependency Row.js -> Col.js -> Item.js -> ComplexComponent.js
import EntityLink from '../base/navigation/EntityLink';
import PageLink from '../base/navigation/PageLinkV1';

export default function ComplexComponentV1({ component, className }) {
  const { layout, linkToEntity, link, objectType } = component;
  const content = useContent();
  const componentsDataContext = React.useContext(LayoutComponentsDataContext);

  const classNames = classnames(
    'cc',
    component._id || '',
    component.className || '',
    component?.options?.cssClass || '',
    content?.cssClass || '',
    (component.objectType || []).map((objectType) => `type--${objectType}`),
    (component.objectType || []).map(
      (objectType) => `${objectType}${capitalize(component.mode)}`
    ),
    {
      [`mode--${component.mode}`]: !!component.mode,
      'ComplexComponent-has-overlay': component.useOverlay,
      [component._id]: !component.objectType && component._id,
    }
  );

  const children = <Layout layout={layout} />;

  const dataSignature = content
    ? `${component._id}.${content._cls}_${content._id}.`
    : `${component._id}.`;
  const newComponentsDataContext = mapKeys(
    pickBy(componentsDataContext, (value, key) =>
      key.startsWith(dataSignature)
    ),
    (value, key) => key.replace(dataSignature, '')
  );

  let renderedContent;
  if (content && linkToEntity) {
    renderedContent = (
      <EntityLink entity={content}>
        <a className={classNames}>{children}</a>
      </EntityLink>
    );
  } else if (link) {
    renderedContent = (
      <PageLink
        pageId={link.pageId}
        route={!link.pageId && link.url}
        queryString={link.queryString}
      >
        <a className={classNames}>{children}</a>
      </PageLink>
    );
  } else {
    renderedContent = <div className={classNames}>{children}</div>;
  }

  return (
    <ContentContext.Provider value={objectType ? content : null}>
      <LayoutComponentsDataContext.Provider value={newComponentsDataContext}>
        {renderedContent}
      </LayoutComponentsDataContext.Provider>
    </ContentContext.Provider>
  );
}
