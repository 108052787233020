// Component used to display the content description

import React from 'react';

import { getFirst } from '@/utils/misc';

import FrontComponentBase from '../../FrontComponentBase';

class EntityContentType extends FrontComponentBase {
  static baseClassName = 'EntityContentType';

  render() {
    let entity =
      this.props.content || // retrocompat TODO remove
      this.props.entity ||
      this.getSingleEntity();

    if (!entity) {
      // TODO draft mode
      if (this.props.component && this.props.component._isDraft) {
        console.debug('render draft empty component EntityContentType');
        // TODO
        return (
          <div className="Entity-draft">
            Création d'un bloc Catégorie et tag en cours
          </div>
        );
      } else {
        return null;
      }
    }

    const { displayFirstTag } = this.getOptions();

    const { contentType, mainTags } = entity;

    const mainContentType = getFirst(contentType);
    const mainTag = displayFirstTag && getFirst(mainTags);
    const baseClassName = this.constructor.baseClassName;

    return (
      ((mainContentType || mainTag) && (
        <div
          className={this.getMainCSSClass(
            baseClassName + '--' + mainContentType
          )}
        >
          <div className={baseClassName + '-header'}>
            {mainContentType && (
              <div className={baseClassName + '-content-type'}>
                {mainContentType.label}
              </div>
            )}
            {mainTag && (
              <div className={baseClassName + '-tag'}>
                {mainTag.label || mainTag.text}
              </div>
            )}
          </div>
        </div>
      )) ||
      null
    );
  }
}

export default EntityContentType;
