import React from 'react';

import { goToPage } from '../navigation/PageLinkV1';

import FrontComponentBase from '../../FrontComponentBase';
import { RichTextContent } from '@/components/old';

class RichTextBlock extends FrontComponentBase {
  static baseClassName = 'RichTextBlock';

  render() {
    let options = this.getOptions();
    let componentBaseClass = this.constructor.baseClassName;
    let componentMainClass = this.getMainCSSClass();

    let body = options.body;

    if (options.body) {
      return (
        <div
          className={
            componentMainClass +
            (options.linkPageId ? ' ' + componentBaseClass + '--with-link' : '')
          }
          onClick={options.linkPageId && (() => goToPage(options.linkPageId))}
        >
          {options.aName && (
            <div className="a-name">
              {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
              <a name={options.aName} />{' '}
            </div>
          )}

          <RichTextContent
            html={body}
            className={componentBaseClass + '-inner'}
          />
        </div>
      );
    }

    return null;
  }
}

export default RichTextBlock;
