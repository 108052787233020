// Component used to display a content grid with a sort/filter menu

import _ from 'lodash';
import React from 'react';

import PaginatedData from '@/utils/PaginatedData';
import { ContentContext } from '@/globals/contexts';

import PageLink from '../navigation/PageLinkV1';
import Row from '@/Layout/Row';
import FrontComponentBase from '../../FrontComponentBase';
import SortFilterMenu from '../menus/SortFilterMenu';
import SingleEntity from '../../SingleEntity';

export default class ContentsGrid extends FrontComponentBase {
  static baseClassName = 'ContentsGrid';

  getName_() {
    return _.get(
      this.props,
      'component.options.label',
      _.get(this.props, 'component.innerPath')
    );
  }

  didMount() {
    this.observeProp('component.scrollLoad', (scrollLoad) => {
      if (scrollLoad) {
        console.debug(this.getName_() + '> we have scroll load');

        if (!this._scollEventIsSetup) {
          this.listenToEvent('scroll', this.checkScroll);
          this._scollEventIsSetup = true;
        }
      }
    });

    this.observeEntities((entities) => {
      // This does the binding (?) no - we receive no entities now
      console.debug(
        this.getName_() + '> we have',
        (entities && entities.length) || 0,
        'new entities',
        _.map(entities, '_id').join(', ')
      );
      this.setState({ cachedFilters: null, filteredEntities: null });

      let { scrollLoad } = this.props.component || {};
      if (scrollLoad) {
        this.load(this.state.cachedFilters || {}, entities);
      }
    });

    this.observeProps(
      [
        'component.data',
        'data.mainObject',
        'data.componentObject',
        'mode',
        'component.options.mode',
      ],
      () => {
        let { component } = this.props;

        // Has list, but no data ? load it
        if (
          component &&
          !component.data &&
          component.options &&
          component.options.list
        ) {
          console.debug('Grid has list, but no data: load it');
          this.load(this.state.cachedFilters || {});
        }
      }
    );
  }

  load(filterParams = {}, initialData) {
    console.debug(this.getName_() + '> load filtered entities');
    const pageId = _.get(this.props, 'page._id');

    let { component, data } = this.props;

    this.paginatedData && this.paginatedData.stopLoading();

    if (component && component.innerPath) {
      let params = {
        ...filterParams,
        compPath: component.innerPath,
        exclusions: component.exclusions,
        depDepth: 2, // Necessary to resolve refs of ContentSchedule // TODO set auto on server
      };

      this.paginatedData = new PaginatedData(
        'Page/' + encodeURIComponent(pageId),
        params,
        {
          perPage: _.get(
            component,
            'options.itemCount',
            _.get(component, 'options.colCount', 4) * 2
          ),
          startOffset: 0, //scrollLoadComponent.data.length, // current contents in list are included in exclusions, so no start offset is needed
          // but there will be a problem if we rese the list (ex sort/filters)
          // so we changed how it's done: do not load in initial page request, load everything here
        },
        (moreData) => {
          // Patch current data with more contents // TODO refactoring: use imutable ?
          console.debug(
            this.getName_() + '> scroll-loaded',
            (moreData && moreData.length) || 0,
            'entities',
            _.map(moreData, '_id').join(', ')
          );
          this.setState({ filteredEntities: moreData });
        },
        !initialData, // load now if no initial data
        initialData
      );
    } else if (
      component &&
      !component.data &&
      component.options &&
      component.options.list
    ) {
      console.debug('Grid has list, but no data: load it');
      let mainObject = data.componentObject || data.mainObject;

      this.paginatedData = new PaginatedData(
        'list',
        {
          ...filterParams,
          list: component.options.list,
          mainObjectType: mainObject && mainObject._cls,
          mainObjectId: mainObject && mainObject._id,
          limit: component.options.itemCount,
        },
        {
          perPage: _.get(
            component,
            'options.itemCount',
            _.get(component, 'options.colCount', 4) * 2
          ),
          startOffset: 0, //scrollLoadComponent.data.length, // current contents in list are included in exclusions, so no start offset is needed
          // but there will be a problem if we rese the list (ex sort/filters)
          // so we changed how it's done: do not load in initial page request, load everything here
        },
        (moreData) => {
          // Patch current data with more contents // TODO refactoring: use imutable ?
          console.debug(
            this.getName_() + '> scroll-loaded',
            (moreData && moreData.length) || 0,
            'entities',
            _.map(moreData, '_id').join(', ')
          );
          this.setState({ filteredEntities: moreData });
        },
        !initialData, // load now if no initial data
        initialData
      );
    }
  }

  loadMore() {
    if (this.paginatedData) {
      console.debug(
        this.getName_() + '> scroll-load more contents in last list'
      );
      this.paginatedData.loadMore();
    }
  }

  checkScroll = () => {
    let windowScroll = window.scrollY || window.pageYOffset;
    let documentHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

    if (window.innerHeight + windowScroll >= documentHeight - 10) {
      this.loadMore();
    }
  };

  onUpdateFilters = (filters) => {
    if (_.isEqual(filters, this.state.cachedFilters)) return;

    console.debug('filters', this.state.cachedFilters, '->', filters);
    this.setState({ cachedFilters: filters });

    if (_.isEmpty(filters)) {
      this.setState({ filteredEntities: null });
    } else {
      const pageId = _.get(this.props, 'data.page._id');
      const { component } = this.props;

      if (pageId && component) {
        let filterParams = {};
        _.forEach(filters, (f) => {
          if (f._cls === 'RequestFilterButton') {
            if (f.intervalComp) {
              _.set(filterParams, ['compIntervals', f.prop], f);
            } else if (f.filterOp === 'value') {
              if (_.isArray(f.value)) {
                _.set(
                  filterParams,
                  ['compArrayFilter', f.prop], // isPlainObject == should be a ref// TODO better test
                  f.value.map((v) =>
                    _.isPlainObject(v) && v._id ? _.pick(v, '_id') : v
                  ) // object_ref/object_id);
                );
              } else {
                if (_.isPlainObject(f.value) && f.value._id) {
                  _.set(
                    filterParams,
                    ['compFilter', f.prop + '._id'],
                    f.value._id
                  );
                } else {
                  _.set(filterParams, ['compFilter', f.prop], f.value);
                }
              }
            } else if (f.filterOp === 'has' || f.filterOp === 'hasNot') {
              _.set(filterParams, ['compHas', f.prop], f.filterOp === 'has');
            } else if (['gt', 'gte', 'lt', 'lte'].includes(f.filterOp)) {
              _.set(
                filterParams,
                ['compCompare', f.prop],
                [f.filterOp, f.value]
              );
            }
            //... TODO
          } else if (f._cls === 'RequestSortButton') {
            _.set(filterParams, ['compSort', f.prop], f.value);
          } else if (f._cls === 'Tag') {
            _.set(filterParams, 'compTag', f._id);
          } else if (f._cls === 'QuestionCategory') {
            _.set(filterParams, ['compFilter', 'questionCategory._id'], f._id);
          } else if (f._cls === 'RequestFilterDropdown') {
            // c/c above
            if (_.isPlainObject(f.value) && f.value._id) {
              _.set(
                filterParams,
                ['compFilter', f.propRef.key + '._id'],
                f.value._id
              );
            } else {
              _.set(filterParams, ['compFilter', f.propRef], f.value);
            }
          } else if (f.search) {
            _.set(filterParams, 'compSearch', f.search);
          }
        });

        this.load(filterParams);
      } else {
        console.error('No page ID/No component', this.props);
      }
    }
  };

  // !! nearly c/c-ied in template1 ComponentGrid
  render() {
    let entities = this.state.filteredEntities || this.getEntityList();
    let options = this.getOptions();

    let {
      displayMenu,
      label: labelIfSingle,
      pageId,
      mode,
      alternateMode,
      labelIfMultiple,
    } = options;

    let colCount = options.colCount;
    let colSize = colCount ? Math.floor(12 / colCount) : 0;

    if (this.state.draftMode && _.isEmpty(entities) && !displayMenu) {
      // "no display" condition - TODO à revoir
      return (
        <div className="draft-placeholder">
          {this.props.component && this.props.component.label}
        </div>
      );
    }

    if (_.isEmpty(entities)) return null; // empty = "no display" condition

    let componentBaseClass = this.constructor.baseClassName;
    const label =
      !!entities && !!labelIfMultiple && entities.length > 0
        ? labelIfMultiple
        : labelIfSingle;

    return (
      <div className={this.getMainCSSClass()}>
        {label && (
          <h2 className={componentBaseClass + '-title'}>
            {(pageId && (
              <PageLink pageId={pageId}>
                <a>{label}</a>
              </PageLink>
            )) ||
              label}
          </h2>
        )}

        {displayMenu && (
          <SortFilterMenu
            component={this.props.component}
            onUpdateFilters={this.onUpdateFilters}
          />
        )}

        {(!_.isEmpty(entities) && (
          <Row gutters={true} colCount={colCount}>
            {entities.map &&
              entities.map(
                (entity, i) =>
                  (entity && (
                    <div
                      className={'ContentsGrid-item Col Col--' + colSize}
                      key={entity._id}
                    >
                      <ContentContext.Provider value={entity}>
                        <SingleEntity
                          mode={alternateMode && i % 2 ? alternateMode : mode}
                        />
                      </ContentContext.Provider>
                    </div>
                  )) ||
                  null
              )}
          </Row>
        )) || ( // here getEntityList() not empty -> it's the filtered list that is empty
          <div>
            <i>Aucun contenu correspondant à ces critères.</i>
          </div>
        )}
      </div>
    );
  }
}
