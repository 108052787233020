import * as React from 'react';

import Component from './Component';
import Row from '../Row';

export default function Content({ content }) {
  if (content.cols) {
    return <Row row={content} />;
  } else if (content.component) {
    return <Component component={content.component} />;
  }

  return null;
}
