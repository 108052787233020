import videojs from 'video.js';

/**
 * TODO: crashes repeatedly at different point in time (tried to secure this with ifs and try-catch but still crashes)
 * This module is really shitty
 *
 * Try to replace with  @silvermine/videojs-quality-selector ?? or https://github.com/chrisboustead/videojs-hls-quality-selector ?
 *
 */

class QualitySelector {
  constructor(player) {
    this.player = player;
    this.sources = [];
    this.callback = undefined;
    this.containerDropdownElement = undefined;
    this.defaults = {};
  }

  /**
   * event on selected the quality
   */
  onQualitySelect(quality, noAutoplay) {
    try {
      if (this.callback) {
        this.callback(quality);
      }

      if (this.sources) {
        // tries to find the source with this quality
        let source = this.sources.find((ss) => ss.key === quality);

        if (source) {
          this.player.src({ src: source.src, type: source.type });

          let currentTime = this.player.currentTime() || 0;
          this.player.on('loadedmetadata', () => {
            console.debug(
              'Video QS> selected source, meta loaded -> ',
              noAutoplay ? "don't play" : 'play',
              source
            );
            noAutoplay || this.player.play();
            this.player.currentTime(currentTime);

            Array.from(
              this.containerDropdownElement.firstChild.childNodes
            ).forEach((ele) => {
              if (ele.dataset.key === quality) {
                ele.setAttribute('class', 'current');
              } else {
                ele.removeAttribute('class');
              }
            });
          });

          const player = document.getElementById(this.player.id_);
          const qualitySelector = player.getElementsByClassName(
            'vjs-brand-quality-link'
          );

          if (qualitySelector && qualitySelector.length > 0) {
            qualitySelector[0].innerText = source.name;
          }
        }
        this.onHideDropdown();
      }
    } catch (e) {
      console.warn(
        'Oh no, quality selector plugin crashed again! How surprising',
        e
      );
    }
  }

  /**
   * show or hide the dropdown
   */
  onToggleDropdown() {
    if (this.containerDropdownElement.className.indexOf('show') === -1) {
      this.containerDropdownElement.className += ' show';
    } else {
      this.onHideDropdown();
    }
  }

  /**
   * hide the dropdown
   */
  onHideDropdown() {
    const className = this.containerDropdownElement.className.replace(
      ' show',
      ''
    );
    this.containerDropdownElement.className = className;
  }

  /**
   * Function to invoke when the player is ready.
   *
   * This is a great place for your plugin to initialize itself. When this
   * function is called, the player will have its DOM and child components
   * in place.
   *
   * @function onPlayerReady
   * @param    {Player} player
   * @param    {Object} [options={}]
   */
  onPlayerReady(options) {
    try {
      this.containerDropdownElement = document.createElement('div');
      this.containerDropdownElement.className = 'vjs-quality-dropdown';

      let containerElement = document.createElement('div');

      containerElement.className = 'vjs-quality-container';

      let buttonElement = document.createElement('button');

      buttonElement.className = 'vjs-brand-quality-link';
      buttonElement.onclick = (event) => this.onToggleDropdown(event);
      buttonElement.innerText = options.text || 'Quality';

      let ulElement = document.createElement('ul');

      if (options.onFormatSelected) {
        this.callback = options.onFormatSelected;
      }

      if (options.sources) {
        this.sources = options.sources;
      }

      options.sources.forEach((source) => {
        let liElement = document.createElement('li');

        liElement.dataset.key = source.key;

        let linkElement = document.createElement('a');

        linkElement.innerText = source.name;
        linkElement.setAttribute('href', '#');
        linkElement.addEventListener('click', (event) => {
          event.preventDefault();
          this.onQualitySelect(source.key);
        });

        liElement.appendChild(linkElement);
        ulElement.appendChild(liElement);
      });

      this.containerDropdownElement.appendChild(ulElement);
      containerElement.appendChild(this.containerDropdownElement);
      containerElement.appendChild(buttonElement);

      if (this.player.controlBar.qualitySelectorToggleEl) {
        this.player.controlBar
          .el()
          .removeChild(this.player.controlBar.qualitySelectorToggleEl);
      }

      const fullScreenToggle = this.player.controlBar.fullscreenToggle.el();

      if (this.player.controlBar.el()) {
        this.player.controlBar.qualitySelectorToggleEl = this.player.controlBar
          .el()
          .insertBefore(containerElement, fullScreenToggle);

        this.player.addClass('vjs-qualityselector');
      } else {
        console.warn(
          'VideoJS quality selector: cannot mount button, DOM not found'
        );
        setTimeout(() => {
          try {
            this.player.controlBar.qualitySelectorToggleEl =
              this.player.controlBar
                .el()
                .insertBefore(containerElement, fullScreenToggle);

            this.player.addClass('vjs-qualityselector');
          } catch (e) {}
        }, 500);
      }
    } catch (e) {
      console.warn('Error in video quality selector onReady', e);
    }
  }
}

/**
 * A video.js plugin.
 *
 * In the plugin function, the value of `this` is a video.js `Player`
 * instance. You cannot rely on the player being in a 'ready' state here,
 * depending on how the plugin is invoked. This may or may not be important
 * to you; if not, remove the wait for 'ready'!
 *
 * @function qualityselector
 * @param    {Object} [options={}]
 *           An object of options left to the plugin author to define.
 */
const qualityselector = function (options) {
  this.ready(() => {
    let qualityControl = new QualitySelector(this);

    qualityControl.onPlayerReady(
      videojs.mergeOptions(qualityControl.defaults, options)
    );

    if (options.defaultSource) {
      qualityControl.onQualitySelect(options.defaultSource, !options.autoplay);
    }
  });
};

// Register the plugin with video.js.
const registerPlugin = videojs.registerPlugin || videojs.plugin;

registerPlugin('qualityselector', qualityselector);

// Include the version number.
qualityselector.VERSION = '__VERSION__';

export default qualityselector;
