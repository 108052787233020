import * as React from 'react';
import _ from 'lodash';

import { withLinks } from '@/utils/HOC';

import { Modal } from '@/components/old';
import { Button } from '@/components/new';
import PageLink from '../../navigation/PageLinkV1';

function PreviewEndModal({ user, onClose, siteContext, links }) {
  const { login: loginLink, register: registerLink } = links.login;

  return (
    <Modal
      title={
        !!user
          ? 'Abonnez-vous pour lire la suite'
          : 'Connectez-vous ou abonnez-vous pour lire la suite'
      }
      titleCentered
      size="one-third"
      onClose={onClose}
      additionalClassName="immanens-paywall-modal"
    >
      <div className="buttons">
        {!user && (
          <PageLink link={loginLink}>
            <Button>
              {_.get(siteContext, 'siteConfig.options.loginLabel') ||
                'Se connecter'}
            </Button>
          </PageLink>
        )}

        <PageLink link={registerLink}>
          <Button>S'abonner</Button>
        </PageLink>
      </div>
    </Modal>
  );
}

export default withLinks(PreviewEndModal);
