/**
 
 * Component used to display question thumbs
 * a question can have an id, a title, an eventTs, an author, a vote count
 *
 */

import moment from 'moment';
import React from 'react';

import API from '@/utils/API';
import { InstanceStore, UserStore, UserActions } from '@/reflux';
import { titleCase } from '@/utils/misc';
import { SiteContext } from '@/globals/contexts';
import { withContext } from '@/utils/HOC';

import RawComponent from '../../../RawComponent';
import FrontComponentBase from '../../../FrontComponentBase';
import { Modal } from '@/components/old';
import { ReportButton } from '@/PUFComponents/components';

class QuestionsThumbDetailed extends FrontComponentBase {
  static baseClassName = 'QuestionsThumbDetailed';

  constructor(props) {
    super(props);
    this.state = {
      isLiked: false,
      cantLikeModal: false,
    };
  }

  // set user state
  stores = [UserStore, InstanceStore];

  didMount() {
    let bindLikes = this.getRebindToObjectFunc('likes');
    this.observeSingleEntity((question) => {
      bindLikes('likes_' + question._cls, question._id);
    });
  }

  logout = () => {
    UserActions.logout();
  };

  handleLike = () => {
    if (this.state.user) {
      let question = this.getSingleEntity();
      let { instanceName } = this.state;

      if (this.state.likes && this.state.likes.userLikes) {
        API.post(
          instanceName +
            '/like/' +
            question._cls +
            '/' +
            encodeURIComponent(question._id),
          { value: 0 }
        ).then((likes) => this.setState({ likes }));
      } else {
        API.post(
          instanceName +
            '/like/' +
            question._cls +
            '/' +
            encodeURIComponent(question._id),
          { value: 1 }
        ).then((likes) => this.setState({ likes }));
      }
    } else {
      this.setState({ cantLikeModal: true });
    }
  };

  closeLikeModal = () => {
    this.setState({ cantLikeModal: false });
  };

  render() {
    //let content = this.props.data && this.props.data.mainObject || this.props.content;
    let question = this.getSingleEntity();
    let { likes } = this.state;

    if (!question) return null;

    let { eventTs, publishTs, label, questionCategory, author } = question;
    let baseClassName = this.constructor.baseClassName;
    let componentMainClass = baseClassName;
    if (likes && likes.userLikes) {
      componentMainClass += ' ' + baseClassName + '--voted';
    }

    let nbLikes = (likes && likes.likeScore) || 0;
    let likeCount = (
      <div className={baseClassName + '-vote-count'}>
        <span className={baseClassName + '-vote-count-number'}>{nbLikes}</span>
        <span> j'aime</span>
      </div>
    ); // better than likeCount if we introduce vote-down

    moment.locale('fr');
    let displayedDate =
      publishTs && moment.unix(eventTs || publishTs).format('LL');

    const { reportableContents } = this.props.siteContext.site;
    const isReportable = reportableContents?.includes('Question');

    return (
      <div className={componentMainClass}>
        <div className={baseClassName + '-content'}>
          <div className={baseClassName + '-content-body'}>
            <div className={baseClassName + '-content-body-date'}>
              {displayedDate && <span>{displayedDate}</span>}
            </div>

            <div className={baseClassName + '-content-body-date-and-author'}>
              {author && author.name && (
                <span className={baseClassName + '-author'}>
                  {titleCase(author.name)},{' '}
                </span>
              )}{' '}
              le {displayedDate && <span>{displayedDate}</span>}
            </div>

            {author && author.name && (
              <div className={baseClassName + '-content-body-author'}>
                {titleCase(author.name)}
              </div>
            )}

            <div className={baseClassName + '-content-body-questionCategory'}>
              {questionCategory && <span>{questionCategory.label}</span>}
            </div>

            {displayedDate && (
              <div
                className={baseClassName + '-content-body-date after-category'}
              >
                {displayedDate}
              </div>
            )}

            <div className={baseClassName + '-content-body-desc'}>{label}</div>
          </div>
        </div>

        <div className={baseClassName + '-vote'}>
          {likes && (
            <div>
              {likeCount}
              <button
                className={baseClassName + '-vote-button'}
                onClick={() => this.handleLike()}
              >
                {(likes.userLikes && <span>Votée &#10004;</span>) || (
                  <span>Voter</span>
                )}
              </button>
            </div>
          )}
          {isReportable && (
            <ReportButton
              contentId={question._id}
              contentType={question._cls}
              contentText={question.label}
            />
          )}
        </div>

        {this.state.cantLikeModal && (
          <Modal
            title="Se connecter"
            size="half"
            onClose={this.closeLikeModal}
            contentClassName={baseClassName + '-modal'}
          >
            <p>Vous devez vous connecter pour pouvoir poser une question :</p>
            <RawComponent
              id="Login"
              componentProps={{ onSuccess: this.closeLikeModal }}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default withContext(SiteContext, 'siteContext', QuestionsThumbDetailed);
