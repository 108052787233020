// @flow
// Simple Pinterest SVG icon

import * as React from 'react';

type Props = {| color?: string |};

export default function PinterestIcon({ color: colorProp }: Props): React.Node {
  const color = colorProp || 'currentColor';

  return (
    <svg x="0px" y="0px" width="15.6px" height="20px" viewBox="0 0 15.6 20">
      <path
        fill={color}
        d="M6.4,13.2C6.3,13.9,6.2,14.4,6,15c-0.3,1.2-0.6,2.3-1.2,3.3C4.4,19,4,19.5,3.5,19.9c0,0-0.1,0-0.1,0.1
	c0-0.6-0.1-1.1-0.1-1.6c0-1.6,0.2-3.1,0.6-4.6c0.3-1.4,0.7-2.8,1-4.2c0-0.1,0-0.3,0-0.4c-0.4-1-0.4-2-0.1-3c0.2-0.5,0.4-0.9,0.8-1.3
	c0.7-0.8,2.1-0.7,2.7,0.1c0.3,0.4,0.3,0.8,0.2,1.2C8.2,7.2,7.9,8.1,7.6,9c-0.2,0.6-0.3,1.2-0.4,1.8c-0.1,1,0.5,1.6,1.5,1.7
	c1.1,0.2,2-0.3,2.6-1.1c0.8-1,1.2-2.2,1.4-3.5c0.1-1,0.1-2.1-0.2-3.1c-0.3-0.9-0.8-1.6-1.6-2.1C9.2,1.6,7.5,1.6,5.6,2.4
	C4.5,2.9,3.6,3.7,3,4.8C2.3,6,2.1,7.2,2.4,8.6c0.1,0.4,0.3,0.7,0.5,1c0.4,0.6,0.3,1.3-0.1,1.9c0,0-0.2,0.1-0.2,0.1
	c-1.4-0.4-2.2-1.2-2.5-2.6c-0.3-1.8-0.2-3.5,0.7-5c0.9-1.6,2.2-2.6,3.9-3.3C6.3,0,7.9-0.1,9.6,0.1C10.8,0.3,12,0.8,13,1.5
	c1.3,0.9,2.1,2.1,2.4,3.7c0.3,1.5,0.2,3-0.2,4.5c-0.4,1.2-0.9,2.3-1.8,3.2c-1.2,1.3-2.7,1.8-4.5,1.6c-0.7-0.1-1.2-0.4-1.8-0.8
	C6.9,13.5,6.6,13.4,6.4,13.2z"
      />
    </svg>
  );
}
