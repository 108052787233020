import Link from 'next/link';
import React from 'react';

import { useUrl, useLinks } from '@/hooks';
import { formatQueryParams } from '@/helpers/url';

const buildFullHref = (link, { dynamicLinks, fullUrl }) => {
  if (!link) return null;

  const { queryString, url, pageId, hash } = link;

  if (url) return url;
  if (!pageId || !dynamicLinks[pageId]?.path) return null;

  const href = dynamicLinks[pageId].path;

  const qs = formatQueryParams(queryString, { fullUrl });

  const fullHref = `${href}${!!qs ? '?' + qs : ''}${hash ? `#${hash}` : ''}`;

  return fullHref;
};

export default function PageLinkV2({ link, children }) {
  const { fullUrl } = useUrl();
  const { dynamicPages: dynamicLinks } = useLinks();

  if (!link) return children(null);

  const fullHref = buildFullHref(link, { dynamicLinks, fullUrl });

  if (!fullHref) return children(null);

  return <Link href={fullHref}>{children(fullHref, link)}</Link>;
}
