import React from 'react';

import { InstanceStore, UserStore, UserActions } from '@/reflux';
import API from '@/utils/API';
import ApiClient from '@/utils/ApiClient';

import ComponentBase from '../../ComponentBase';
import { Button, EyeIconOn, EyeIconOff } from '@/components/old';

export default class ResetPass extends ComponentBase {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      confirmPass: '',
      viewPass: false,
    };

    this.stores = [InstanceStore, UserStore];
  }

  onStartLoading = () => {
    this.setState({ loading: true, success: false, error: null });
  };

  onSuccess = (user) => {
    UserActions.set(user);
    this.setState({ loading: false, success: true, error: null });
    this.props.onSuccess && this.props.onSuccess();
    ApiClient.updateAll();
  };

  onError = (err) => {
    this.setState({ loading: false, error: err.message }); // TEMP
  };

  // TODO confirm password

  resetPass = () => {
    let { password, instanceName } = this.state;
    let { token } = this.props;
    this.onStartLoading();
    API.post(instanceName + '/users/resetPass', {
      token: token,
      password: password,
      pass: password, // retro-compat // TODO remove
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  toggleViewPassword = () => {
    this.setState({ viewPass: !this.state.viewPass }, this.onValidate);
  };

  render() {
    let { success, password, error, loading, viewPass, confirmPass } =
      this.state;

    // TODO check autocomplete https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#inappropriate-for-the-control

    return (
      <div className="ResetPass">
        <form className="RegularForm RegularForm--login">
          <p style={{ marginTop: 0 }}>Choisissez un mot de passe :</p>

          <div className="RegularForm-field RegularForm-field--row">
            <input
              name="password"
              autoComplete="password"
              placeholder="Mot de passe"
              className="RegularForm-text-input pass"
              value={password || ''}
              type={viewPass ? 'text' : 'password'}
              onChange={(e) => this.setState({ password: e.target.value })}
            />

            <span
              onClick={this.toggleViewPassword}
              className={
                viewPass ? 'display-password active' : 'display-password'
              }
              title="Afficher le mot de passe"
            >
              {viewPass ? <EyeIconOn /> : <EyeIconOff />}
            </span>
          </div>

          {!viewPass && (
            <div className={'RegularForm-field'}>
              <input
                name="password"
                placeholder="Confirmer le mot de passe"
                className="RegularForm-text-input pass"
                type="password"
                value={confirmPass || ''}
                onChange={(e) => this.setState({ confirmPass: e.target.value })}
              />
            </div>
          )}

          <div className="RegularForm-field">
            <Button
              disabled={!password || (!viewPass && password !== confirmPass)}
              isSubmit={true}
              onClick={this.resetPass}
            >
              Valider le nouveau mot de passe
            </Button>
          </div>

          {loading && <span>Connexion en cours... </span>}

          {success && (
            <span>Mot de passe mis à jour ! Vous êtes connecté.</span>
          )}

          {error && <span className="error">{error}</span>}
        </form>
      </div>
    );
  }
}
