// Component used to display uplike/downlike buttons

import React from 'react';

import { UserStore, InstanceStore } from '@/reflux';
import API from '@/utils/API';

import FrontComponentBase from '../../FrontComponentBase';
import { Modal } from '@/components/old';
import Login from '../login/Login';

class ThumbsUp extends React.Component {
  render() {
    let fillColor = this.props.fillColor || 'currentColor';

    return (
      <svg viewBox="0 0 512 512">
        <path
          fill={fillColor}
          d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"
        ></path>
      </svg>
    );
  }
}

class VoteButtons extends FrontComponentBase {
  static baseClassName = 'VoteButtons';

  constructor(props) {
    super(props);
    this.state = {
      isLiked: false,
      cantLikeModal: false,
    };
  }

  // set user state
  stores = [UserStore, InstanceStore];

  didMount() {
    let bindLikes = this.getRebindToObjectFunc('likes');
    this.observeSingleEntity((entity) => {
      entity && bindLikes('likes_' + entity._cls, entity._id);
    });
  }

  like = (likeValue) => {
    if (this.state.user) {
      let entity = this.getSingleEntity();
      let { instanceName } = this.state;
      let valueToSet;

      // noinspection EqualityComparisonWithCoercionJS
      if (this.state.likes && this.state.likes.userLikes === likeValue) {
        valueToSet = 0;
      } else {
        valueToSet = likeValue;
      }

      API.post(
        instanceName +
          '/like/' +
          entity._cls +
          '/' +
          encodeURIComponent(entity._id),
        {
          value: valueToSet,
        }
      ).then((likes) => this.setState({ likes }));
    } else {
      this.setState({ cantLikeModal: true });
    }
  };

  onLike = () => this.like(+1);

  onUnlike = () => this.like(-1);

  closeLikeModal = () => {
    this.setState({ cantLikeModal: false });
  };

  render() {
    let entity = this.getSingleEntity();
    let { likes } = this.state;

    if (!entity) return null;

    let componentBaseClass = this.constructor.baseClassName;

    return (
      <div>
        <div className={this.getMainCSSClass()}>
          <button
            className={
              likes && likes.userLikes === 1
                ? componentBaseClass +
                  '-button ' +
                  componentBaseClass +
                  '-button--voted'
                : componentBaseClass + '-button'
            }
            onClick={this.onLike}
            title="J'aime"
          >
            <span
              className={
                componentBaseClass +
                '-button-element ' +
                componentBaseClass +
                '-button-element--upvote'
              }
            >
              <ThumbsUp />
            </span>

            <span className={componentBaseClass + '-button-vote-count'}>
              {(likes && likes.likeCount) || 0}
            </span>
          </button>

          <button
            className={
              likes && likes.userLikes === -1
                ? componentBaseClass +
                  '-button ' +
                  componentBaseClass +
                  '-button--voted'
                : componentBaseClass + '-button'
            }
            onClick={this.onUnlike}
            title="Je n'aime pas"
          >
            <span
              className={
                componentBaseClass +
                '-button-element ' +
                componentBaseClass +
                '-button-element--downvote'
              }
            >
              <ThumbsUp />
            </span>
            <span className={componentBaseClass + '-button-vote-count'}>
              {(likes && likes.unlikeCount) || 0}
            </span>
          </button>
        </div>

        {this.state.cantLikeModal && (
          <Modal
            title="Se connecter"
            size="half"
            onClose={() => this.closeLikeModal()}
            contentClassName={componentBaseClass + '-modal'}
          >
            <p>Vous devez vous connecter :</p>
            <Login onSuccess={this.closeLikeModal} />
          </Modal>
        )}
      </div>
    );
  }
}

export default VoteButtons;
