// @flow
// Simple Instagram SVG icon

import * as React from 'react';

type Props = {| color?: string |};

export default function InstagramIcon({ color: colorProp }: Props): React.Node {
  const color = colorProp || 'currentColor';

  return (
    <svg
      viewBox="0 0 15.6 15.6"
      x="0px"
      y="0px"
      width="15.617px"
      height="15.617px"
    >
      <g>
        <path
          fill={color}
          d="M7.8,1.4c2.1,0,2.3,0,3.2,0c0.8,0,1.2,0.2,1.5,0.3C12.8,1.9,13,2,13.3,2.3s0.4,0.5,0.6,0.9
                		c0.1,0.3,0.2,0.7,0.3,1.5c0,0.8,0,1.1,0,3.2s0,2.3,0,3.2c0,0.8-0.2,1.2-0.3,1.5c-0.1,0.4-0.3,0.6-0.6,0.9s-0.5,0.4-0.9,0.6
                		c-0.3,0.1-0.7,0.2-1.5,0.3c-0.8,0-1.1,0-3.2,0s-2.3,0-3.2,0c-0.8,0-1.2-0.2-1.5-0.3c-0.4-0.1-0.6-0.3-0.9-0.6s-0.4-0.5-0.6-0.9
                		c-0.1-0.3-0.2-0.7-0.3-1.5c0-0.8,0-1.1,0-3.2s0-2.3,0-3.2c0-0.8,0.2-1.2,0.3-1.5C1.9,2.8,2,2.6,2.3,2.3s0.5-0.4,0.9-0.6
                		c0.3-0.1,0.7-0.2,1.5-0.3C5.5,1.4,5.7,1.4,7.8,1.4 M7.8,0C5.7,0,5.4,0,4.6,0c-0.8,0-1.4,0.2-1.9,0.4C2.2,0.6,1.7,0.9,1.3,1.3
                		S0.6,2.2,0.4,2.7C0.2,3.2,0.1,3.8,0,4.6c0,0.8,0,1.1,0,3.2s0,2.4,0,3.2c0,0.8,0.2,1.4,0.4,1.9c0.2,0.5,0.5,0.9,0.9,1.4
                		s0.9,0.7,1.4,0.9c0.5,0.2,1.1,0.3,1.9,0.4c0.8,0,1.1,0,3.2,0s2.4,0,3.2,0c0.8,0,1.4-0.2,1.9-0.4c0.5-0.2,0.9-0.5,1.4-0.9
                		c0.4-0.4,0.7-0.9,0.9-1.4c0.2-0.5,0.3-1.1,0.4-1.9c0-0.8,0-1.1,0-3.2s0-2.4,0-3.2c0-0.8-0.2-1.4-0.4-1.9c-0.2-0.5-0.5-0.9-0.9-1.4
                		c-0.4-0.4-0.9-0.7-1.4-0.9C12.4,0.2,11.9,0.1,11,0C10.2,0,9.9,0,7.8,0L7.8,0z"
        />
        <path
          fill={color}
          d="M7.8,3.8c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S10,3.8,7.8,3.8z M7.8,10.4c-1.4,0-2.6-1.2-2.6-2.6
                		s1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6S9.2,10.4,7.8,10.4z"
        />
        <circle fill={color} cx="12" cy="3.6" r="0.9" />
      </g>
    </svg>
  );
}
