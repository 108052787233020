import React from 'react';
import classnames from 'classnames';

import { Icon, Image } from '@/components/new';
import TournamentGameThumbnail from './TournamentGameThumbnail';
import EntityLink from '../navigation/EntityLink';

export default function TournamentThumbnail({ data = [], options }) {
  const [tournament] = data;
  if (!tournament) return null;

  const { futureGames = [], image, label, teams } = tournament;
  const { title, linkToEntity = true } = options;

  const tournamentContent = (
    <div
      className={classnames('tournament-thumbnail', {
        'is-link': linkToEntity,
      })}
    >
      <div className="first-line">
        <div className="logo_wrapper">
          <Image
            src={image}
            width={150}
            height={90}
            alt={`Logo ${label}`}
            defaultNextImage
          />
        </div>

        <div className="logo-right-part">
          <Icon name="chevrons-down" color="warning" />

          <p className="first-catch">{title}</p>

          <Icon
            name="chevrons-down"
            color="warning"
            additionalClassName="second-chevron"
          />
        </div>
      </div>

      {futureGames.length > 0 && (
        <ul className="matches_wrapper">
          {futureGames.map((game) => {
            return (
              <li className="game" key={game._id}>
                <TournamentGameThumbnail
                  game={game}
                  teams={teams}
                  options={{ dateFormat: 'calendar' }}
                />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );

  return linkToEntity ? (
    <EntityLink entity={tournament}>
      <a>{tournamentContent}</a>
    </EntityLink>
  ) : (
    tournamentContent
  );
}
