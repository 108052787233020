import _ from 'lodash';
import React from 'react';

import { SiteContext } from '@/globals/contexts';
import { UserStore } from '@/reflux';
import withContext from '@/utils/HOC/withContext';

import FrontComponentBase from '../../FrontComponentBase';
import PageLink from '../navigation/PageLinkV1';

class PauseScreenRegister extends FrontComponentBase {
  store = UserStore;

  render() {
    let user = this.state.user;

    return (
      <div className={this.getMainCSSClass()}>
        <div>
          Pour voir l'intégralité de tous les articles et vidéos du site, merci
          de créer un compte
        </div>

        <SiteContext.Consumer>
          {(
            siteContext // TODO use UserButtonsCustom
          ) => {
            const pageId = _.get(
              this.props.siteContext,
              'siteConfig.options.subscribePageId'
            );
            return (
              <div>
                <PageLink route={!pageId && '/register'} pageId={pageId}>
                  <a className={'ContentVideo-register-button standard-button'}>
                    {_.get(siteContext, 'siteConfig.options.registerLabel') ||
                      'Créer un compte'}
                  </a>
                </PageLink>
                {!user && (
                  <PageLink route="/login">
                    <a className={'ContentVideo-login-button standard-button'}>
                      {_.get(siteContext, 'siteConfig.options.loginLabel') ||
                        'Se connecter'}
                    </a>
                  </PageLink>
                )}
              </div>
            );
          }}
        </SiteContext.Consumer>
      </div>
    );
  }
}

export default withContext(SiteContext, 'siteContext', PauseScreenRegister);
