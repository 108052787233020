import React from 'react';

import ComponentBase from '../../ComponentBase';
import { DynImg } from '@/components/old';

const baseClassName = 'Quizz-Question';

class Question extends ComponentBase {
  render() {
    const { questionData, handleAnswerClick, submittedAnswer, options } =
      this.props;

    return (
      <div className={baseClassName}>
        <span>{questionData.label}</span>
        <ul type="none" className={baseClassName + '-list'}>
          {questionData.answers.map((answer, index) => {
            return (
              <li
                key={index}
                onClick={() =>
                  handleAnswerClick(
                    questionData.id,
                    index,
                    questionData.correctAnswerIndex
                  )
                }
                className={
                  submittedAnswer
                    ? `${baseClassName}--${
                        index + 1 === questionData.correctAnswerIndex
                          ? 'correct'
                          : 'wrong'
                      }`
                    : ''
                }
              >
                <input
                  type="radio"
                  checked={
                    !!(submittedAnswer && submittedAnswer.answer === index + 1)
                  }
                  value={index}
                />
                <label>{answer}</label>
              </li>
            );
          })}
        </ul>
        {submittedAnswer && (
          <div
            className={`${baseClassName}-answer ${
              submittedAnswer
                ? submittedAnswer.isCorrect
                  ? `${baseClassName}-answer--right`
                  : `${baseClassName}-answer--wrong`
                : ''
            }`}
          >
            <div className={`${baseClassName}-answer-result`}>
              {submittedAnswer.isCorrect
                ? options.correctAnswerText
                : options.wrongAnswerText}
            </div>
            <div className={`${baseClassName}-answer-details`}>
              {questionData.answerDetails}
            </div>
            {questionData.answerImage && (
              <div className={`${baseClassName}-answer-image`}>
                <DynImg
                  w={600}
                  h={600}
                  src={questionData.answerImage}
                  title={'sample image'}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Question;
