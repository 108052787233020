import * as React from 'react';
import classnames from 'classnames';

const baseClassName = 'Comments';
const newBaseClassName = 'PUF-comment-list';

export default function CommentListV2({ options, mainClassName }) {
  return (
    <div className={classnames(mainClassName, newBaseClassName)}>
      TODO: refaire la liste des commentaires
    </div>
  );
}

CommentListV2.baseClassName = baseClassName;
