// @flow
import _ from 'lodash';
import { withRouter } from 'next/router';
import * as React from 'react';

import { SiteContext } from '@/globals/contexts';

import { RichTextContent } from '@/components/old';

type Options = {|
  copyright?: string,
  displayContactEmail?: boolean,
|};
type Props = {|
  options: Options,
|};

function Footer({ options }: Props): React.Node {
  const { copyright, displayContactEmail } = options;

  return (
    <footer className="PUFFooter">
      <div className="siteWrapper">
        <div className="Footer-wrapper">
          <RichTextContent html={copyright} tag="span" />

          {displayContactEmail && (
            <SiteContext.Consumer>
              {(siteContext) => {
                const email = _.get(
                  siteContext,
                  'siteConfig.options.contactEmail'
                );
                return (
                  email && (
                    <span>
                      {' Contact : '}
                      <a href={'mailto:' + encodeURIComponent(email)}>
                        {email}
                      </a>
                    </span>
                  )
                );
              }}
            </SiteContext.Consumer>
          )}
        </div>
      </div>
    </footer>
  );
}

export default (withRouter(Footer): React.ComponentType<Props>);
