import { useState } from 'react';

import { useSiteOptions } from '@/hooks';

import { Button } from '@/components/new';
import ReportModal from './ReportModal';

export default function ReportButton({ contentId, contentType, contentText }) {
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const siteOptions = useSiteOptions();
  const email = siteOptions.reportEmail;

  if (!email) return null;

  return (
    <>
      <Button
        onClick={() => setIsReportModalOpen(true)}
        additionalClassName="report-button"
        isText
        size="small"
      >
        Signaler
      </Button>

      {isReportModalOpen && (
        <ReportModal
          onClose={() => setIsReportModalOpen(false)}
          contentId={contentId}
          contentType={contentType}
          contentText={contentText}
        />
      )}
    </>
  );
}
