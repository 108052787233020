// Component used to display the content description

import _ from 'lodash';
import React from 'react';

import { SiteContext } from '@/globals/contexts';
import { UserStore } from '@/reflux';
import ApiClient from '@/utils/ApiClient';
import withContext from '@/utils/HOC/withContext';

import FrontComponentBase from '../../FrontComponentBase';
import RawComponent from '../../RawComponent';
import QuestionList from './QuestionList';
import Result from './Result';

class QuizzFull extends FrontComponentBase {
  static baseClassName = 'Quizz';
  store = UserStore;
  topSpanRef = null;

  state = {
    answers: [],
    submitted: false,
    useLocalData: false,
    reader: false,
  };

  didMount() {
    // Load user's answers:
    this.rebindUserQuizzAnswers =
      this.getRebindToCollectionFunc('userQuizzAnswer');

    this.observeSingleEntity((quizz) => {
      if (quizz) {
        this.rebindUserQuizzAnswers('UserQuizzAnswers', {
          queryOne: { quizzId: quizz._id },
        });
      } else {
        this.rebindUserQuizzAnswers(null);
      }
    });
  }

  didUpdate(prevProps, prevState) {
    let quizzData = this.getSingleEntity();
    if (quizzData && quizzData.questions) {
      const { answers } = this.state;
      if (prevState.answers !== answers) {
        if (answers.length === quizzData.questions.length) {
          this.calculateScoreAndSubmit();
        }
      }
    }
  }

  handleAnswerClick = (questionId, answerIndex, rightAnswer) => {
    const { answers } = this.state;
    const elementsIndex = answers.findIndex(
      (element) => element.question === questionId
    );
    if (elementsIndex === -1) {
      this.setState({
        answers: [
          ...answers,
          {
            question: questionId,
            answer: answerIndex + 1,
            isCorrect: rightAnswer === answerIndex + 1,
          },
        ],
      });
    }
  };

  calculateScoreAndSubmit() {
    const { answers } = this.state;
    let quizzData = this.getSingleEntity();

    const correctAnswers = answers.filter((answer) => answer.isCorrect).length;
    this.setState({
      submitted: true,
      score:
        quizzData.questions &&
        `${correctAnswers}/${quizzData.questions.length}`,
    });

    ApiClient.post(
      'users/quizzAnswer',
      null,
      {
        quizzId: quizzData._id,
        answers,
      },
      (resultStats) => {
        this.setState({ resultStats });
      }
    );
  }

  reset = () => {
    this.setState({
      answers: [],
      score: null,
      submitted: false,
      resultStats: null,
      useLocalData: true,
    });
    setTimeout(
      () =>
        window.scrollTo({
          top: 500,
          behavior: 'smooth',
        }),
      200
    );
  };

  render() {
    const quizzData = this.getSingleEntity();
    if (!quizzData) return null;

    const { userQuizzAnswer, userQuizzAnswer_loading } = this.state;
    if (userQuizzAnswer_loading) return <div className="loading" />;

    const { user, score, resultStats, useLocalData } = this.state;
    let baseClassName = this.constructor.baseClassName;

    if (!user) {
      return (
        <div className={this.getMainCSSClass()}>
          <div className={baseClassName + '-need-account-wrapper'}>
            <div className={baseClassName + '-need-account-label'}>
              {quizzData.needAccountLabel ||
                "Vous devez disposer d'un compte pour répondre à ce Quizz"}
            </div>
            <div className={baseClassName + '-need-account-buttons-wrapper'}>
              <RawComponent
                id="UserButtonsCustom"
                options={{
                  buttons: [
                    { buttonType: 'login' },
                    { buttonType: 'register' },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      );
    }

    const { questions } = quizzData;
    const hasAlreadyAnswered = !!userQuizzAnswer && !useLocalData;
    const answers = hasAlreadyAnswered
      ? userQuizzAnswer.answers
      : this.state.answers;
    const submitted = hasAlreadyAnswered || this.state.submitted;

    return (
      <div className={this.getMainCSSClass()}>
        {hasAlreadyAnswered && !quizzData.allowMultipleSubmit && (
          <h3 className="already-answered">
            Merci d'avoir répondu à ce quizz !
          </h3>
        )}

        <QuestionList
          options={_.pick(quizzData, ['correctAnswerText', 'wrongAnswerText'])}
          questions={questions}
          submitted={submitted}
          answers={answers}
          handleAnswerClick={this.handleAnswerClick}
        />

        {submitted && (
          <Result
            stats={resultStats}
            score={score}
            reset={quizzData.allowMultipleSubmit ? this.reset : undefined}
            customResultText={quizzData.resultLabel}
            questionLength={questions.length}
          />
        )}
      </div>
    );
  }
}

export default withContext(SiteContext, 'siteContext', QuizzFull);
