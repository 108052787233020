import _ from 'lodash';
import React from 'react';
import FrontComponentBase from '../../FrontComponentBase';

class EpisodeIndexSdh extends FrontComponentBase {
  static baseClassName = 'EntityProperty';

  render() {
    let entity = this.props.entity || this.getSingleEntity();
    if (!entity || !entity.episodeIndex) return null;

    let label = 'Épisode ';

    if (
      _.includes(entity.contentType, 'amis') ||
      _.includes(entity.contentType, 'asso')
    ) {
      label = 'Évènement ';
    }

    const baseClassName = this.constructor.baseClassName;

    return (
      <div className={this.getMainCSSClass() + ' prop-episodeIndex'}>
        <div className={baseClassName + '-inner'}>
          <span className={baseClassName + '-label'}>{label}</span>
          <span className={baseClassName + '-value'}>
            {entity.episodeIndex}
          </span>
        </div>
      </div>
    );
  }
}

export default EpisodeIndexSdh;
