import React from 'react';
import classnames from 'classnames';
import moment from 'moment';

import { APIContext } from '@/globals/contexts';

import { Button, Flex, Icon } from '@/components/new';
import EntityLink from '@/PUFComponents/base/navigation/EntityLink';
import ScoreBet from './ScoreBet';

function FutureGame({
  game,
  bet,
  style,
  makeBet,
  participant1,
  participant2,
  linkToParticipant,
}) {
  const { getImageUrl } = React.useContext(APIContext);
  const {
    isBettable,
    isDrawAllowed,
    startTs,
    isLive,
    stageLabel,
    allowExactScoreBet,
  } = game;
  const [isBetting, setIsBetting] = React.useState(false);

  const getBetSentence = () => {
    if (!bet.winnerId) return 'Match nul';
    if (bet.winnerId === participant1._id)
      return `Victoire ${participant1.label}`;
    if (bet.winnerId === participant2._id)
      return `Victoire ${participant2.label}`;
  };

  const hasVotedForParticipant1 = bet && bet.winnerId === participant1?._id;
  const hasVotedForParticipant2 = bet && bet.winnerId === participant2?._id;
  const hasVotedForDraw = bet && !bet.winnerId;

  return (
    <article
      className={classnames('future-game', {
        'is-live': isLive,
        'is-bettable': isBettable,
      })}
    >
      <div className="game-info">
        {isLive ? (
          <>
            <Flex
              additionalClassName={classnames('live-tag')}
              verticalAlign
              style={{ gap: '0.5rem' }}
            >
              <Icon name="stopwatch" color="white" size="large" />
              <p className="live-tag__text">Match en cours...</p>
            </Flex>
            <span className="loader" />
          </>
        ) : (
          <p className="time-of-game">{moment.unix(startTs).format('LT')}</p>
        )}
        <p className="stage-label">{stageLabel}</p>
      </div>

      <Flex additionalClassName="teams-score-wrapper">
        <Flex direction="column" additionalClassName="participant">
          <div className="flag-wrapper">
            <img
              src={
                participant1?.image
                  ? getImageUrl(participant1?.image)
                  : '/static/images/afterfoot/dummy-flag.svg'
              }
              width={70}
              height={70}
              alt={`Drapeau ${game.participant1?.label}`}
            />
          </div>

          {participant1 && linkToParticipant ? (
            <EntityLink entity={participant1}>
              <a>
                <p className="team-name">{participant1.label}</p>
              </a>
            </EntityLink>
          ) : (
            <p className="team-name">{participant1?.label || 'Équipe 1'}</p>
          )}

          {isBettable &&
            (hasVotedForParticipant1 ? (
              <Icon name="check" size="large" />
            ) : (
              <Button
                onClick={async () => {
                  setIsBetting(true);
                  await makeBet(game._id, { winnerId: participant1?._id });
                  setIsBetting(false);
                }}
                isOutlined
                disabled={isBetting}
              >
                1
              </Button>
            ))}
        </Flex>

        {isBettable && isDrawAllowed && (
          <Flex style={{ justifyContent: 'space-between' }} direction="column">
            <div />
            {hasVotedForDraw ? (
              <Icon name="check" size="large" />
            ) : (
              <Button
                onClick={async () => {
                  setIsBetting(true);
                  await makeBet(game._id, { winnerId: null });
                  setIsBetting(false);
                }}
                isOutlined
                disabled={isBetting}
              >
                N
              </Button>
            )}
          </Flex>
        )}

        <Flex direction="column" additionalClassName="participant">
          <div className="flag-wrapper">
            <img
              src={
                participant2?.image
                  ? getImageUrl(participant2?.image)
                  : '/static/images/afterfoot/dummy-flag.svg'
              }
              width={70}
              height={70}
              alt={`Drapeau ${participant2?.label}`}
            />
          </div>

          {participant2 && linkToParticipant ? (
            <EntityLink entity={participant2}>
              <a>
                <p className="team-name">{participant2.label}</p>
              </a>
            </EntityLink>
          ) : (
            <p className="team-name">{participant2?.label || 'Équipe 2'}</p>
          )}

          {isBettable &&
            (hasVotedForParticipant2 ? (
              <Icon name="check" size="large" />
            ) : (
              <Button
                onClick={async () => {
                  setIsBetting(true);
                  await makeBet(game._id, { winnerId: participant2?._id });
                  setIsBetting(false);
                }}
                isOutlined
                disabled={isBetting}
              >
                2
              </Button>
            ))}
        </Flex>
      </Flex>

      {allowExactScoreBet && bet && (
        <div className="score-bet-part">
          <p>Score</p>
          <ScoreBet bet={bet} makeBet={makeBet} />
        </div>
      )}

      {bet && (
        <p className="bet-summary">
          <span className="your-bet-prefix">votre pari</span>
          <br /> <span className="your-bet-value">{getBetSentence(bet)}</span>
        </p>
      )}

      {!isBettable && (
        <p className="not-yet-bettable">
          {isLive
            ? 'Vous ne pouvez plus modifier votre pari'
            : 'Les paris ne sont pas encore ouverts'}
        </p>
      )}
    </article>
  );
}

export default React.memo(FutureGame);
