import * as React from 'react';
import { useEffectOnce } from 'usehooks-ts';
import moment from 'moment';

import API from '@/utils/API';
import { useInstance } from '@/hooks';
import { makeComponentSubClass } from '@/helpers/style';
import { prettyPrice } from '@/helpers/format';

import Ticker from 'react-ticker';
import PageVisibility from 'react-page-visibility';

const makeClass = (className) =>
  makeComponentSubClass(ParticipationHistory.baseClassName, className);

export default function ParticipationHistory({ campaign }) {
  const [history, setHistory] = React.useState([]);
  const [instanceId] = useInstance();

  useEffectOnce(() => {
    const fetchHistory = async () => {
      const fetchedHistory = await API.get(
        `${instanceId}/CrowdFundingCampaigns/${campaign._id}/history`,
        { limit: 100 }
      );
      setHistory([...fetchedHistory]);
    };

    fetchHistory();
  });

  if (history.length < 5) return null;

  return (
    <div className={ParticipationHistory.baseClassName}>
      <h3 className={makeClass('title')}>Historique des participations</h3>
      <PageVisibility>
        {(isVisible) => (
          <Ticker speed={4} mode="chain" move={isVisible}>
            {({ index }) => {
              const donation = history[index % history.length];
              const { contributor, amount, message, createTs } = donation;
              const { firstName, lastName } = contributor;

              return (
                <article className={makeClass('donation')}>
                  <div className={makeClass('donation-header-wrapper')}>
                    <h5 className={makeClass('donation-title')}>
                      {`${firstName} ${lastName}`} : {prettyPrice(amount)}
                    </h5>
                    <span className={makeClass('donation-date')}>
                      {createTs && `Le ${moment.unix(createTs).format('LL')}`}
                    </span>
                  </div>

                  <p className={makeClass('donation-message')}>{message}</p>
                </article>
              );
            }}
          </Ticker>
        )}
      </PageVisibility>
    </div>
  );
}

ParticipationHistory.baseClassName = 'participation-history';
