import { prettyPrice } from '@/helpers/format';
import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import moment from 'moment';

import Row from '@/Layout/Row';
import FrontComponentBase from '../../FrontComponentBase';

class Contributors extends FrontComponentBase {
  static defaultProps = {};
  static baseClassName = 'Contributors';

  didMount() {
    const { publicOnly } = this.props.options;

    this.observeOptions((options) => {
      if (options.mode === 'list') {
        this.bindObject('cart', 'getContributors', null, 'contributors');
      } else {
        this.bindObject(
          'cart',
          'getTotalContributors',
          { withAmount: true, publicOnly: !!publicOnly },
          'contributions'
        );
      }
    });
  }

  render() {
    const {
      contributors,
      contributions,
      contributors_loading,
      contributions_loading,
    } = this.state;

    const {
      mode,
      prefix,
      suffix,
      goal,
      fieldToDisplay,
      displayPercent,
      fakeValueToAdd,
    } = this.getOptions();

    if (contributors_loading || contributions_loading) {
      return <div className={this.getMainCSSClass('loading')} />;
    }

    const isAmount = fieldToDisplay === 'amount'; // type fieldToDisplay = 'amount' | 'count'
    const { count, amount } = contributions || {};

    const total = (isAmount ? amount : count) + (fakeValueToAdd || 0);

    let width, percent;
    if (mode === 'bar' && goal) {
      let progress = (total || 0) / goal;
      width = Math.max(0, Math.min(100, progress * 100)).toFixed(5) + '%';
      percent = Math.floor(progress * 100);
    }

    const baseClassName = this.constructor.baseClassName;

    return (
      <div className={this.getMainCSSClass()}>
        {(mode === 'count' && (
          <>
            <span className={baseClassName + '-prefix'}>{prefix}</span>
            <span className={baseClassName + '-number'}>
              {isAmount ? prettyPrice(total) : total}
            </span>
            <span className={baseClassName + '-suffix'}>{suffix}</span>
          </>
        )) ||
          (mode === 'list' && (
            <Row gutters={true} colCount={3}>
              {_.map(contributors, (contrib, i) => (
                <div key={i} className={baseClassName + '-item Col Col--4'}>
                  <span className={baseClassName + '-label'}>
                    {contrib.userName}
                  </span>
                  <span className={baseClassName + '-date'}>
                    , le {moment.unix(contrib.ts).format('LL')}
                  </span>
                </div>
              ))}
            </Row>
          )) ||
          (mode === 'bar' && goal && (
            <>
              <div className={baseClassName + '-bar-wrapper'}>
                <div
                  className={classnames({
                    [baseClassName + '-bar']: true,
                    reached: percent >= 100,
                  })}
                >
                  <div
                    className={baseClassName + '-bar-inside'}
                    style={{ width }}
                  />
                </div>
                {displayPercent && (
                  <span className={baseClassName + '-bar-percent'}>
                    <span className="amount">{percent}</span>
                    <span className="symbol">%</span>
                  </span>
                )}
              </div>
              <div className={baseClassName + '-goal'}>
                {isAmount ? prettyPrice(goal) : goal}
              </div>
            </>
          ))}
      </div>
    );
  }
}

export default Contributors;
