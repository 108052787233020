import * as React from 'react';
import classnames from 'classnames';

import { useGlobal } from '@/hooks';

import { RichTextContent } from '@/components/old';

/*
type Ranking = {|
  accuracy: 0.45,
  count: 56,
  rank: 1,
  score: 25,
  userId: "us370884",
  wonBetCount: 25,
|}
*/

const NB_USER_DISPLAYED = 100;

function RankingTabContent({
  globalRanking,
  userRanking,
  rules,
  highlightedUserRanking,
  participantCount,
}) {
  const { user } = useGlobal();
  const lastDisplayed = Math.min(globalRanking.length, NB_USER_DISPLAYED);
  const displayedRankings = globalRanking.slice(0, lastDisplayed);

  return (
    <section className="ranking">
      {user && userRanking && (
        <div className="table-container my-score">
          <table className="table user-table">
            <thead>
              <tr>
                <th></th>
                <th>Position</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              <tr key={userRanking.userId} className="of-user">
                <th>Moi</th>
                <td>{userRanking.rank}</td>
                <td>{userRanking.score}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      {participantCount && (
        <p className="participant-count">
          <strong>{participantCount}</strong> participants à ce tournoi
        </p>
      )}

      {highlightedUserRanking.length > 0 && (
        <RankingTable
          rankings={highlightedUserRanking}
          user={user}
          additonalClassName="highlighted-player-ranking"
          title="Highlight"
        />
      )}

      {displayedRankings.length > 0 ? (
        <RankingTable
          rankings={displayedRankings}
          user={user}
          additonalClassName="top-ranking"
          title="Top players"
        />
      ) : (
        <p className="no-ranking-placeholder">
          Le classement sera disponible lorsque les premiers résultats seront
          connus
        </p>
      )}

      <RichTextContent html={rules} className="rules" />
    </section>
  );
}

export default React.memo(RankingTabContent);

const RankingTable = ({ rankings, user, additonalClassName, title }) => {
  return (
    <div className={classnames('table-container', additonalClassName)}>
      <table className="table">
        <thead>
          <tr>
            <th>{title}</th>
            <th>Position</th>
            <th>Score</th>
          </tr>
        </thead>

        <tbody>
          {rankings.map((ranking) => {
            const { userId, rank, score, userName } = ranking;

            return (
              <tr
                key={userId}
                className={classnames({
                  'of-current-user': user?._id === userId,
                  'is-first': rank === 1,
                  'is-second': rank === 2,
                  'is-third': rank === 3,
                })}
              >
                <th>
                  <span className="user-name">
                    {userName} {user?._id === userId ? ' (Vous)' : ''}
                  </span>
                </th>
                <td>{rank}</td>
                <td>{score}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
