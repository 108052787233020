// Component used to display the content description

import React from 'react';

import { getFirst } from '@/utils/misc';

import { RichTextContent } from '@/components/old';
import FrontComponentBase from '../../FrontComponentBase';
import EntityLink from '../navigation/EntityLink';

class EntityDesc extends FrontComponentBase {
  static baseClassName = 'EntityDesc';

  render() {
    let entity =
      this.props.content || // retrocompat TODO remove
      this.props.entity ||
      this.getSingleEntity();
    const { linkToEntity } = this.props.options;

    if (!entity) return null;

    let { contentType, desc, biography, body } = entity;

    let componentBaseClass = this.constructor.baseClassName;

    // Onfray specific: add content type to the component main class
    let mainContentType = getFirst(contentType);
    let contentTypeClass = '';
    if (mainContentType) {
      contentTypeClass += ' ' + componentBaseClass + '--' + mainContentType._id;
    }

    // display either description or biography
    let description = desc || biography || body;

    const content = (
      <div className={this.getMainCSSClass() + contentTypeClass}>
        <RichTextContent
          className={componentBaseClass + '-inner'}
          html={description}
        />
      </div>
    );

    return linkToEntity ? (
      <EntityLink entity={entity}>
        <a>{content}</a>
      </EntityLink>
    ) : (
      content
    );
  }
}

export default EntityDesc;
