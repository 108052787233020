// @flow
import LocalStore from 'store';

export const writeToLocalStorage = (key: string, value: any): boolean => {
  if (!LocalStore?.enabled) return false;

  LocalStore.set(key, value);
  return true;
};

export const readFromLocalStorage = (key: string): any =>
  LocalStore?.enabled ? LocalStore.get(key) : null;
