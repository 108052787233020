import React from 'react';

import { useContent } from '@/hooks';

const baseClassName = 'PUF-entity-main-tag';

export default function EntityMainTag({ options, style }) {
  const { mainTag } = useContent();

  if (!mainTag?.label && !mainTag?.text) return null;

  const { Element = 'span' } = options;

  return (
    <Element className={baseClassName} style={style}>
      {mainTag.label || mainTag.text}
    </Element>
  );
}

EntityMainTag.baseClassName = baseClassName;
