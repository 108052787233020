// Component used to display the content title

import React from 'react';

import { getApiUrl } from '@/utils/config';

import IconDownload from './icon-download.svg';

import FrontComponentBase from '../../FrontComponentBase';

class EntityAttachment extends FrontComponentBase {
  static baseClassName = 'EntityAttachment';

  render() {
    let entity =
      this.props.content || // retrocompat TODO remove
      this.props.entity ||
      this.getSingleEntity();

    if (!entity || !entity.attachment) return null;

    let { file, label } = entity.attachment;
    if (!file) return null;

    let fileName = file.split('/').pop();

    return (
      <a
        className={this.getMainCSSClass()}
        href={getApiUrl() + file}
        target="_blank"
        rel="noopener noreferrer"
        download={fileName}
      >
        {label || fileName}
        <img className="icon" src={IconDownload} alt="attachment" />
      </a>
    );
  }
}

export default EntityAttachment;
