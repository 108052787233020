import React from 'react';

import FrontComponentBase from '@/PUFComponents/FrontComponentBase';

export default class VimeoChatEmbed extends FrontComponentBase {
  static baseClassName = 'VimeoChatEmbed';

  render() {
    const content = this.props.content || this.getSingleEntity();

    if (!content?.chatEmbedUrl) return null;

    return (
      <div className={this.getMainCSSClass('vimeo-iframe-container')}>
        <iframe
          title="Vimeo embed"
          src={content.chatEmbedUrl}
          frameBorder="0"
        />
      </div>
    );
  }
}
