import * as React from 'react';

import { COMPONENT_TYPE } from '@/PUFComponents/componentMap';
import { useGlobal } from '@/hooks';
import { shouldDisplay } from '@/helpers/rendering/layout';
import { ContentContext, LayoutLocationContext } from '@/globals/contexts';
import { computeStyleFromStyleProperties } from '@/helpers/rendering/PUFComponent';

import {
  LayoutComponentsContext,
  LayoutDataContext,
  LayoutComponentsDataContext,
} from '../contexts';

import ComplexComponent from '@/PUFComponents/ComplexComponent';
import PUFComponent from '@/PUFComponents';

export default function Component({ component: componentRef }) {
  const { user, ua } = useGlobal();
  const layoutLocation = React.useContext(LayoutLocationContext);

  const componentContext = React.useContext(LayoutComponentsContext);
  const dataContext = React.useContext(LayoutDataContext);
  const componentsDataContext = React.useContext(LayoutComponentsDataContext);

  const component = componentContext[componentRef.uuid];
  const componentData = (componentsDataContext[componentRef.uuid] || [])
    .map((entity) => {
      if (!entity._cls || !entity._id) return null;
      if (!dataContext[entity._cls]) return null;
      return dataContext[entity._cls][entity._id];
    })
    .filter((entity) => !!entity);

  if (!component) return null;

  const { options = {} } = component;
  const { displayConditions = [], styleProperties = [] } = options;

  if (!shouldDisplay(displayConditions, { user, useragent: ua })) return null;

  const style = computeStyleFromStyleProperties(styleProperties);

  if (component._cls === 'ComplexComponent') {
    return <ComplexComponent component={component} style={style} />;
  }

  if (!component.frontComponent) {
    console.error('No matching component found');
    return null;
  }

  const pufComponent = (
    <PUFComponent
      id={component.frontComponent}
      type={COMPONENT_TYPE.RAW}
      mode={component?.options?.mode}
      options={component.options}
      component={component}
      style={style}
      data={componentData}
    />
  );

  const renderedComponent =
    component.frontComponent === 'SingleEntity' && componentData[0] ? (
      <ContentContext.Provider value={componentData[0]}>
        {pufComponent}
      </ContentContext.Provider>
    ) : (
      pufComponent
    );

  return (
    <LayoutLocationContext.Provider
      value={{
        ...layoutLocation,
        path: component.path,
        uuid: component.uuid,
        source: `${layoutLocation.source}---Component:${component.frontComponent}`,
      }}
    >
      {renderedComponent}
    </LayoutLocationContext.Provider>
  );
}
