// Component used to display the content description
import React from 'react';
import _ from 'lodash';

import FrontComponentBase from '../../FrontComponentBase';

const FORMATTERS = {
  min: (ts) => Math.round(ts / 60),

  hms: (ts) => {
    let h = Math.floor(ts / 3600);
    let m = Math.floor((ts % 3600) / 60);
    let s = Math.floor(ts % 60);
    return (
      (h >= 10 ? h : '0' + h) +
      ':' +
      (m >= 10 ? m : '0' + m) +
      ':' +
      (s >= 10 ? s : '0' + s)
    );
  },

  ms: (ts) => {
    let M = Math.floor(ts / 60);
    let s = Math.floor(ts % 60);
    return (M >= 10 ? M : '0' + M) + "'" + (s >= 10 ? s : '0' + s);
  },

  hm: (ts) => {
    let h = Math.floor(ts / 3600);
    let M = Math.floor(ts / 60);
    return (h >= 10 ? h : '0' + h) + 'h' + (M >= 10 ? M : '0' + M);
  },
};

class ContentDuration extends FrontComponentBase {
  static baseClassName = 'ContentDuration';

  didMount() {
    this.observeSingleEntity((content) => {
      if (
        content &&
        content.video &&
        content.video._isRef &&
        !content.video._wasRef
      ) {
        this.bindObject('Video', content.video._id, {}, 'loadedVideo');
      }
    });
  }

  render() {
    let entity = this.props.entity || this.getSingleEntity();
    let video = entity && (entity.video || this.state.loadedVideo);

    if (!video || !video.duration) {
      if (this.props.component && this.props.component._isDraft) {
        return <div className="Entity-draft">Durée du contenu</div>;
      } else return null;
    }

    const duration = video.duration;
    let { format, suffix } = this.getOptions();
    format = format || 'min';

    const baseClassName = this.constructor.baseClassName;

    return (
      <div className={this.getMainCSSClass()}>
        <span className={baseClassName + '-duration'}>
          {_.isNumber(duration)
            ? FORMATTERS[format] && FORMATTERS[format](duration)
            : ''}
        </span>
        <span className={baseClassName + '-suffix'}>{suffix}</span>
      </div>
    );
  }
}

export default ContentDuration;
