// Component used to display a menu and menu items

import _ from 'lodash';
import Router, { withRouter } from 'next/router';
import React from 'react';
import classnames from 'classnames';

import { getFirst } from '@/utils/misc';

import { Icon, Button } from '@/components/new';
import FrontComponentBase from '../../../FrontComponentBase';
import EntityLink from '../EntityLink';
import PageLink from '../PageLinkV1';

function isFullUrl(path) {
  return /^(https?:\/\/|mailto:)/.test(path);
}

const MenuItemLink = ({ item, className, draftMode, onClick, entity }) => {
  const { queryParams, label, icon, tag } = item;
  const query =
    !!queryParams &&
    _.fromPairs(queryParams.split('&').map((param) => param.split('=')));

  const itemLabel = (
    <span className="main-menu-item-content">
      {icon?.name && <Icon name={icon.name} style={{ marginRight: 5 }} />}
      <span className="main-menu-item-label">{label}</span>
      {tag && <span className="tag">{tag}</span>}
    </span>
  );

  return (
    ((item.pageId || item.route) && (
      <PageLink {...item} query={query}>
        <a className={className} onClick={onClick}>
          {itemLabel}
        </a>
      </PageLink>
    )) ||
    (item.link &&
      ((isFullUrl(item.link) && (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
          onClick={onClick}
        >
          {itemLabel}
        </a>
      )) || (
        <a href={item.link} className={className} onClick={onClick}>
          {itemLabel}
        </a>
      ))) ||
    (item.linkToEntity && (
      <EntityLink entity={entity}>
        <a className={className} onClick={onClick}>
          {itemLabel}
        </a>
      </EntityLink>
    )) ||
    (item.isBack && (
      <a
        href="#"
        className={className}
        onClick={(e) => {
          onClick && onClick(e);
          Router.back();
        }}
      >
        {itemLabel}
      </a>
    )) ||
    (item.label && (
      <div className={className} onClick={onClick}>
        {itemLabel}
      </div>
    )) ||
    (draftMode && (
      <div className="draft-placeholder" onClick={onClick}>
        {'empty item/button'}
      </div>
    )) ||
    null
  );
};

const isSubMenu = (item) => item && item._cls === 'Menu';

// When maxItemCount is specified;
const TOTAL_MENU_ITEM_WIDTH = 150 + 20; // max width + margin * 2

export class MainMenuBase extends FrontComponentBase {
  static defaultProps = {};
  static baseClassName = 'MainMenu';

  constructor(props) {
    super(props);

    this.listRef = React.createRef();
    this.state.currentSubmenuRank = null;
  }

  didMount() {
    // click anywhere except on a submenu thanks to stopPropagation
    this.listenToEvent('click', () => {
      this.closeSubMenu();
    });

    const {
      router,
      options: { menuItems, maxDisplayedItems },
    } = this.props;

    if (maxDisplayedItems) {
      const selectedItemIndex = _.findIndex(menuItems, (menuItem) =>
        router.asPath.includes(menuItem.pageId)
      );

      if (this.listRef.current) {
        const nbOfScroll = selectedItemIndex - maxDisplayedItems + 1;
        if (nbOfScroll > 0)
          this.listRef.current.scrollLeft = nbOfScroll * TOTAL_MENU_ITEM_WIDTH;
      }
    }
  }

  getLinkClasses = (componentBaseClass, subClass, linkItem, index) => {
    let currentClass =
      componentBaseClass +
      '-' +
      subClass +
      ' ' +
      (getFirst(linkItem.contentType) || '');

    if (linkItem.pageId) {
      let activePageId = _.get(this.props, 'router.query.pageId');
      currentClass += ' page-' + linkItem.pageId;
      if (linkItem.pageId === activePageId) {
        currentClass += ' ' + componentBaseClass + '-' + subClass + '--active';
      }
    } else if (linkItem.route === this.props.router.route) {
      currentClass += ' ' + componentBaseClass + '-' + subClass + '--active';
    }

    if (index === this.state.currentSubmenuRank) {
      currentClass += ' ' + componentBaseClass + '-' + subClass + '--open';
    }

    if (subClass === 'link') {
      let { mode } = this.getOptions();
      if (mode === 'button') currentClass += ' standard-button';
      else if (mode === 'button_highlight')
        currentClass += ' standard-button highlight';
    }

    return currentClass;
  };

  toggleSubMenu = (index) => {
    this.setState({
      currentSubmenuRank:
        index === this.state.currentSubmenuRank ? null : index,
    });
  };

  openSubMenu = (index) => {
    this.setState({ currentSubmenuRank: index });
  };

  closeSubMenu = () => {
    this.setState({ currentSubmenuRank: null });
  };

  closeMobileMenu = () => {
    this.props.closeMobileMenu && this.props.closeMobileMenu();
  };

  closeAll = () => {
    this.closeMobileMenu();
    this.setState({ currentSubmenuRank: null });
  };

  scroll = (direction) => {
    const operator = direction === 'left' ? -1 : 1;
    this.listRef.current.scrollLeft =
      this.listRef.current.scrollLeft + operator * TOTAL_MENU_ITEM_WIDTH;
  };

  shouldDisplayArrows = () => {
    const { maxDisplayedItems, menuItems } = this.props.options;
    return maxDisplayedItems && menuItems.length > maxDisplayedItems;
  };

  shouldDisplayArrowLeft = () =>
    this.shouldDisplayArrows() &&
    (!this.listRef.current || this.listRef.current.scrollLeft > 0);

  shouldDisplayArrowRight = () =>
    this.shouldDisplayArrows() &&
    (!this.listRef.current ||
      this.listRef.current.scrollLeft <
        this.listRef.current.scrollWidth - this.listRef.current.clientWidth);

  render() {
    let { menuItems, submenuHover } = this.getOptions();
    let entity = this.getSingleEntity();

    let componentBaseClass =
      this.props.baseClassName || this.constructor.baseClassName;

    const displayArrows = this.shouldDisplayArrows();
    const displayArrowLeft = this.shouldDisplayArrowLeft();
    const displayArrowRight = this.shouldDisplayArrowRight();

    return (
      <nav
        className={classnames(this.getMainCSSClass(), {
          'with-arrows': displayArrows,
        })}
      >
        {displayArrowLeft && (
          <Button
            additionalClassName="menu-navigate-left"
            onClick={() => this.scroll('left')}
            size="small"
          >
            <Icon name="angle-left" size="2x" />
          </Button>
        )}
        <ul
          className={componentBaseClass + '-list'}
          ref={(node) => (this.listRef.current = node)}
        >
          {menuItems &&
            menuItems.map((item, i) => {
              const { label, icon, tag } = item;

              return (
                <li
                  key={i}
                  onMouseEnter={() =>
                    submenuHover && isSubMenu(item) && this.openSubMenu(i + 1)
                  }
                  onMouseLeave={() =>
                    submenuHover && isSubMenu(item) && this.closeSubMenu()
                  }
                  className={this.getLinkClasses(
                    componentBaseClass,
                    'item',
                    item
                  )}
                  id={`main-menu-item-${_.kebabCase(label)}`}
                >
                  {!isSubMenu(item) ? ( // Simple item (MenuItem)
                    <MenuItemLink
                      item={item}
                      draftMode={this.state.draftMode}
                      onClick={this.closeAll}
                      className={this.getLinkClasses(
                        componentBaseClass,
                        'link',
                        item
                      )}
                      entity={entity}
                    />
                  ) : (
                    // Sub-menu (Menu)
                    (item.pageId && ( // menu item = page link
                      <PageLink {...item}>
                        <a
                          className={this.getLinkClasses(
                            componentBaseClass,
                            'link',
                            item
                          )}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.toggleSubMenu(i + 1);
                            this.closeMobileMenu();
                          }}
                        >
                          {icon?.name && (
                            <Icon name={icon.name} style={{ marginRight: 5 }} />
                          )}
                          <span className="main-menu-item-label">{label}</span>
                          {tag && <span className="tag">{tag}</span>}
                        </a>
                      </PageLink>
                    )) || ( // menu item = simple button
                      <span
                        className={this.getLinkClasses(
                          componentBaseClass,
                          'link',
                          item,
                          i + 1
                        )}
                        onClick={(e) => {
                          e.stopPropagation();
                          !submenuHover && this.toggleSubMenu(i + 1, e);
                        }}
                      >
                        {icon?.name && (
                          <Icon name={icon.name} style={{ marginRight: 5 }} />
                        )}
                        <span className="main-menu-item-label">{label}</span>
                        {tag && <span className="tag">{tag}</span>}
                      </span>
                    )
                  )}

                  {this.state.currentSubmenuRank === i + 1 && ( // Sub-menu content
                    <div className="MainMenu-submenu">
                      <div className="siteWrapper">
                        <MainMenuBase
                          options={{
                            menuItems: item.items,
                            submenuHover,
                          }}
                          baseClassName="MainMenu-submenu-inner"
                          router={this.props.router}
                          closeMobileMenu={this.closeAll}
                        />
                      </div>
                    </div>
                  )}
                </li>
              );
            })}
        </ul>
        {displayArrowRight && (
          <Button
            additionalClassName="menu-navigate-right"
            onClick={() => this.scroll('right')}
            size="small"
          >
            <Icon name="angle-right" size="2x" />
          </Button>
        )}
      </nav>
    );
  }
}

export default withRouter(MainMenuBase);
