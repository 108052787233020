// @flow
import * as React from 'react';
import moment from 'moment';

import type { GiftInfo as GiftInfoType } from '@/types/stores';

import { InputTextBase, Field, Label } from '@/components/old';
import {
  QuestionMarkTooltip,
  Datepicker,
  Notification,
} from '@/components/new';

type Props = {|
  giftInfo: GiftInfoType,
  disabled: boolean,
  onChange: ($Shape<GiftInfoType>) => void,
|};

export default function MailToBeneficiary({
  giftInfo,
  disabled,
  onChange,
}: Props): React.Node {
  return (
    <>
      <Notification color="warning">
        <p>
          Après votre paiement, un email sera envoyé à votre ami à la date
          précisée, ou immédiatement si celle-ci n'a pas été renseignée.
        </p>
      </Notification>

      <Field>
        <Label>
          Votre nom{' '}
          <QuestionMarkTooltip content="Ce nom apparaîtra au destinataire lors de la réception de son cadeau"></QuestionMarkTooltip>
        </Label>
        <InputTextBase
          value={giftInfo.senderName}
          onChange={(newSenderName) => onChange({ senderName: newSenderName })}
          type="text"
          disabled={disabled}
        />
      </Field>
      <Field>
        <Label>
          Nom du bénéficiaire{' '}
          <QuestionMarkTooltip content="Nom de la personne qui sera inscrite sur la carte cadeau"></QuestionMarkTooltip>
        </Label>
        <InputTextBase
          value={giftInfo.recipient.name}
          onChange={(newRecipientName) =>
            onChange({
              recipient: {
                ...giftInfo?.recipient,
                name: newRecipientName,
              },
            })
          }
          type="text"
          disabled={disabled}
        />
      </Field>
      <Field>
        <Label>
          Adresse mail du bénéficiaire{' '}
          <QuestionMarkTooltip content="Nous enverrons un email à cette adresse pour informer votre ami de la réception de son cadeau !"></QuestionMarkTooltip>
        </Label>
        <InputTextBase
          value={giftInfo.recipient.email}
          onChange={(newRecipientEmail) =>
            onChange({
              recipient: {
                ...giftInfo?.recipient,
                email: newRecipientEmail,
              },
            })
          }
          type="text"
          disabled={disabled}
        />
      </Field>

      <Field>
        <Label>
          <span>
            Date d'envoi (optionel)
            <span className="label-helper">
              , si vide, l'email sera immédiatement envoyé
            </span>{' '}
            <QuestionMarkTooltip content="Décidez de la date à laquelle nous enverrons l'email pour informer votre ami de la réception de son cadeau"></QuestionMarkTooltip>
          </span>
        </Label>
        <Datepicker
          dateType="unix"
          value={
            giftInfo.sendDate ? moment.unix(giftInfo.sendDate).toDate() : null
          }
          onChange={(newSendDate) =>
            onChange({
              sendDate: moment(newSendDate).unix(),
            })
          }
          disabled={disabled}
          minDate={moment().add(1, 'days').toDate()}
        />
      </Field>
    </>
  );
}
