import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { APIContext } from '@/globals/contexts';

import { Flex, Icon } from '@/components/new';
import EntityLink from '@/PUFComponents/base/navigation/EntityLink';

function PlayedGame({
  game,
  bet,
  participant1,
  participant2,
  linkToParticipant,
}) {
  const { getImageUrl } = React.useContext(APIContext);
  const { isPlayed, score } = game;

  const betHasBeenWon = isPlayed && bet?.isWon;
  // const betHasBeenLost = isPlayed && !bet?.isWon;

  return (
    <article
      className={classnames('played-game', {
        'is-played': isPlayed,
        'bet-won': bet?.isWon,
        'bet-lost': !bet?.isWon,
      })}
    >
      <Flex
        style={{
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Flex additionalClassName="teams-score-wrapper" direction={'column'}>
          <div className="participant participant1">
            <div className="flag-wrapper" style={{ marginTop: '0.3rem' }}>
              <img
                src={
                  participant1?.image
                    ? getImageUrl(participant1?.image)
                    : '/static/images/afterfoot/dummy-flag.svg'
                }
                width="40"
                height="40"
                alt={`Drapeau ${participant1?.label}`}
              />
            </div>

            {participant1 && linkToParticipant ? (
              <EntityLink entity={participant1}>
                <a>
                  <p className="team-name">{participant1.label}</p>
                </a>
              </EntityLink>
            ) : (
              <p className="team-name">{participant1?.label || 'Équipe 1'}</p>
            )}

            <div className="score">
              <p
                style={
                  score[0] === _.max(score)
                    ? { opacity: 1, fontWeight: 700 }
                    : { opacity: 0.5, fontWeight: 400 }
                }
              >
                {score[0]}
              </p>
            </div>
          </div>

          <div className="participant participant2">
            <div className="flag-wrapper">
              <img
                src={
                  participant2?.image
                    ? getImageUrl(participant2?.image)
                    : '/static/images/afterfoot/dummy-flag.svg'
                }
                width="40"
                height="40"
                alt={`Drapeau ${participant2?.label}`}
              />
            </div>

            {participant2 && linkToParticipant ? (
              <EntityLink entity={participant2}>
                <a>
                  <p className="team-name">{participant2.label}</p>
                </a>
              </EntityLink>
            ) : (
              <p className="team-name">{participant2?.label || 'Équipe 2'}</p>
            )}

            <div className="score">
              <p
                style={
                  score[1] === _.max(score)
                    ? { opacity: 1, fontWeight: 700 }
                    : { opacity: 0.5, fontWeight: 400 }
                }
              >
                {score[1]}
              </p>
            </div>
          </div>
        </Flex>

        <div className="icone-resultat">
          <Icon
            name={betHasBeenWon ? 'check' : 'close'}
            size="large"
            color={betHasBeenWon ? 'success' : 'danger'}
            text={betHasBeenWon ? 'gagné' : 'perdu'}
          />
        </div>
      </Flex>
    </article>
  );
}

// const PlayedGame = () => {};
// const FutureGame = () => {};

export default React.memo(PlayedGame);
