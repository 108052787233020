/**
 
 * Component used to display answers thumbs
 * a content can have an id, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import moment from 'moment';
import NLink from 'next/link';
import React from 'react';

import FrontComponentBase from '../../../FrontComponentBase';
import VideoPoster from '../../medias/VideoPosterV1';

class AnswersThumb extends FrontComponentBase {
  static baseClassName = 'AnswersThumb';

  /*
        TODO :
        - format date and add content type label
        - add navigation
    */

  render() {
    // TODO !!!
    let content = this.getSingleEntity();

    if (!content) return null;

    let { publishTs, eventTs, label, desc } = content;

    let componentBaseClass = this.constructor.baseClassName;

    moment.locale('fr');
    let publicDate = eventTs || publishTs;
    let displayedDate =
      publicDate && 'Le ' + moment.unix(publicDate).format('DD MMMM YYYY');

    return (
      // data-cc will work?
      <NLink
        href={'/o/' + content._cls + '/' + encodeURIComponent(content._id)}
      >
        <a className={this.getMainCSSClass()}>
          <div className={componentBaseClass + '-poster'}>
            <VideoPoster
              content={content}
              wrappedByLink={true}
              size={this.props.size}
            />
          </div>

          <div className={componentBaseClass + '-infos'}>
            <div className={componentBaseClass + '-title'}>{label}</div>
            <div className={componentBaseClass + '-desc'}>{desc}</div>

            {displayedDate && (
              <div className={componentBaseClass + '-date'}>
                {displayedDate}
              </div>
            )}
          </div>

          <div className={componentBaseClass + '-footer'}>
            <span className={componentBaseClass + '-read-more'}>
              Voir la réponse
            </span>
          </div>
        </a>
      </NLink>
    );
  }
}

export default AnswersThumb;
