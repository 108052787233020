// Component used to display the content description

import React from 'react';

import { useContent } from '@/hooks';

import { RichTextContent } from '@/components/old';

const baseClassName = 'PUF-entity-description';

export default function EntityDescV2({ options, mainClassName, style }) {
  const content = useContent();

  if (!content?.desc) return null;

  const { description } = content;
  const { Element = 'div' } = options;

  return (
    <Element className={mainClassName || baseClassName} style={style}>
      <RichTextContent html={description} />
    </Element>
  );
}

EntityDescV2.baseClassName = baseClassName;
