import React from 'react';
import _ from 'lodash';
import nookies from 'nookies';
import classnames from 'classnames';

import { UserStore, UserActions } from '@/reflux';

import Layout from '@/Layout';
import ComponentBase from '../../ComponentBase';
import { InfoBanner, Modal } from '@/components/old';

export default class Popup extends ComponentBase {
  store = UserStore;
  _timers = [];

  // temp for bottom margin - TODO global popup manager
  static _activePopups = {};

  constructor(props) {
    super(props);
    this.elRef = React.createRef();
  }

  // TODO onchange reset timers

  didMount() {
    this.observeProp(
      'when',
      (displayConditionList) =>
        this.state.userLoaded && this.initPopups(displayConditionList)
    );

    UserActions.set.listen(
      (user) => this.props.when && this.initPopups(this.props.when, user)
    );

    this.listenToEvent('resize', this.handleBodyMargin);
  }

  initPopups(displayConditionList, user) {
    if (!user) user = this.state.user;
    // remove previous timers
    this._timers.forEach((unsub) => unsub());
    this.setState({ open: false });

    console.debug('popup ' + this.props._id + ' mounting', !!user);

    _.forEach(displayConditionList, (cond) => {
      let display = true,
        cssClass = '';
      if (cond.visitorType === 'anonymous') display = !user;
      else if (cond.visitorType === 'registered') display = !!user;
      else if (cond.visitorType === 'freemium')
        display = user && !user.isPremium;
      else if (cond.visitorType === 'premium') display = user && user.isPremium;
      else if (cond.visitorType === 'not_premium')
        display = !user || !user.isPremium;

      if (cond.firstVisit && nookies.get({})['popup_' + this.props._id]) {
        console.debug('already displayed popup', this.props._id);
        display = false;
      }

      if (cond.deviceType && cond.deviceType !== 'all') {
        console.debug('display only on ', cond.deviceType);
        cssClass = 'device-type--' + cond.deviceType;
      }

      if (display) {
        if (cond.delay) {
          console.debug('Set timer ' + cond.delay + 's for pop-up', this.props);
          this._timers.push(
            this.setTimer(() => {
              this.setState({ open: true, cssClass });
            }, cond.delay * 1000)
          );
        } else {
          this.setState({ open: true, cssClass });
        }
      }
    });

    this.handleBodyMargin();
  }

  onClose = () => {
    const { _id, when = [] } = this.props;
    const condWithRepeatDelay = when.find(
      (condition) => !!condition.repeatDelay && condition.repeatDelay > 0
    );
    const repeatDelay = condWithRepeatDelay?.repeatDelay; // In days
    const maxAge =
      repeatDelay && repeatDelay > 0 ? repeatDelay * 24 * 60 * 60 : undefined;

    this.setState({ open: false }, this.removeBodyMargin);
    nookies.set(null, 'popup_' + _id, '1', { path: '/', maxAge });
  };

  handleBodyMargin = () => {
    let el = this.elRef.current;

    if (
      el &&
      this.state.open &&
      !this.state.closeForever &&
      (!this.props.position || this.props.position === 'bottom') &&
      window.getComputedStyle(el).display !== 'none' // could be done by media query also
    ) {
      const h = ~~el.getBoundingClientRect().height; // floor it
      console.debug('Add bottom banner height', h);
      Popup._activePopups[this.props._id] = h;
    } else {
      console.debug('Remove bottom banner height');
      Popup._activePopups[this.props._id] = 0;
    }

    Popup.applyBodyMarginForAllPopups();
  };

  removeBodyMargin = () => {
    Popup._activePopups[this.props._id] = 0;
    Popup.applyBodyMarginForAllPopups();
  };

  static applyBodyMarginForAllPopups() {
    const h = _(Popup._activePopups).values().max();
    let rootStyle = document.documentElement.style;
    rootStyle.setProperty('--app-bottom-padding', h + 'px');
  }

  willUnmount() {
    this.removeBodyMargin();
  }

  render() {
    let {
      layout,
      position,
      uiType,
      title,
      hideable,
      label,
      className: optionClassName,
    } = this.props;

    const className = classnames(
      'PopUp',
      `PopUp-ui-type-${uiType}`,
      `PopUp-${_.kebabCase(label)}`,
      optionClassName ? `Popup-${optionClassName}` : ''
    );

    if (this.state.open && !this.state.closeForever) {
      let popupContent = <Layout layout={layout} isPageWrapper />;

      if (uiType === 'modal') {
        return (
          <Modal
            ref={this.elRef}
            additionalClassName={className}
            onClose={this.onClose}
            title={title}
          >
            {popupContent}
          </Modal>
        );
      } else {
        return (
          <InfoBanner
            ref={this.elRef}
            open={true}
            onClose={this.onClose}
            additionalClassName={classnames(
              this.state.cssClass + ' ' + label,
              className
            )}
            buttonText="OK"
            position={position}
            hideable={hideable}
          >
            {popupContent}
          </InfoBanner>
        );
      }
    }
    return null;
  }
}
