import React from 'react';
import classNames from 'classnames';

import ApiClient from '@/utils/ApiClient';

import ComponentBase from '../../../../ComponentBase';

export default class NewComment extends ComponentBase {
  static baseClassName = 'LeaveAComment';

  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }

  didMount() {
    this.observeProp(
      'content._id',
      () => this.setState({ error: null, text: '' }),
      true
    );
  }

  validate() {
    const { maxCommentLength } = this.props;
    const { text } = this.state;
    return !!text && text.length > 1 && text.length < maxCommentLength;
  }

  onSend = () => {
    if (!this.validate()) return;

    const { parentComment, content, onAfterPost } = this.props;
    const { text } = this.state;
    this.setState({ error: null });

    ApiClient.post(
      'UserComment',
      null,
      {
        text,
        content: {
          _id: content._id,
          _cls: content._cls,
        },
        parentId: (parentComment && parentComment._id) || null,
        rootId:
          (parentComment && (parentComment.rootId || parentComment._id)) ||
          null,
      },
      () => {
        this.setState({ error: null, text: '' });
        onAfterPost && onAfterPost();
        ApiClient.onDataUpdate('UserComment');
      },
      (err) => this.setState({ error: err.message })
    );
  };

  render() {
    const { parentComment, onClose, maxCommentLength } = this.props;
    const { text, error } = this.state;
    const componentBaseClass = this.constructor.baseClassName;

    return (
      <div className={componentBaseClass}>
        <div className={`${componentBaseClass}-inner`}>
          <form className={`${componentBaseClass}-form`}>
            <div
              className={`${componentBaseClass}-textarea-wrapper RegularForm-field`}
            >
              <textarea
                className={classNames(
                  `${componentBaseClass}-textarea`,
                  'input-textarea',
                  'post-a-comment-textarea',
                  'white',
                  {
                    'form-alert-element': error,
                  }
                )}
                placeholder={
                  parentComment
                    ? `Votre réponse à "${parentComment.text}"`
                    : 'Votre commentaire...'
                }
                value={text}
                onChange={(e) => this.setState({ text: e.target.value })}
              />
            </div>

            <div className={`${componentBaseClass}-footer`}>
              <div>
                <div className={`${componentBaseClass}-count`}>
                  <span className={`${componentBaseClass}-count-total`}>
                    {text.length}
                  </span>
                  <span className={`${componentBaseClass}-count-max`}>
                    &nbsp;/ {maxCommentLength}
                  </span>
                </div>

                <div className={`${componentBaseClass}-error`}>
                  {error && <div className="form-alert">{error}</div>}
                </div>
              </div>

              <div className={`${componentBaseClass}-submit`}>
                {onClose && (
                  <button
                    style={{ marginRight: 10 }}
                    type="button"
                    className={`${componentBaseClass}-submit-button small`}
                    onClick={onClose}
                  >
                    Annuler
                  </button>
                )}

                <button
                  type="button"
                  className={`${componentBaseClass}-submit-button small`}
                  onClick={this.onSend}
                  disabled={!this.validate()}
                >
                  <span>
                    {parentComment
                      ? `Répondre à ${parentComment.authorName}`
                      : 'Envoyer mon commentaire'}
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
