// @flow
import * as React from 'react';
import classnames from 'classnames';
import { isInteger } from 'lodash';

import type {
  ComponentMap,
  ComponentType,
  Component,
  RawComponentId,
} from '@/types/component';
import type { EntityType, Entity } from '@/types/models';

import withComponentMap from './withComponentMap';
import withInstance, { type WithInstanceProps } from '@/utils/HOC/withInstance';
import { useContent, useGlobal } from '@/hooks';
import { getPUFComponent } from './componentMap';
import {
  LayoutSizeDivisionContext,
  VersionContext,
  LayoutLocationContext,
} from '@/globals/contexts';

export type ComponentProps = {
  size?: number,
  entity?: Entity,
  ...
};

type Props = {|
  id: RawComponentId | EntityType,
  type: ComponentType,
  mode?: string,
  component?: Component,
  componentProps?: ComponentProps,
  options?: any,
  innerRef?: any,
|};

type WithComponentMapProps = {|
  ...Props,
  ...WithInstanceProps,
  componentMap: ComponentMap,
|};

function PUFComponent({
  id,
  type,
  mode = 'default',
  componentMap,
  component: componentProp = {},
  componentProps = {},
  options: optionsProp = {},
  innerRef,
  instanceName,
  featureFlags,
  ...restProps
}: WithComponentMapProps): React.Node {
  const layoutLocation = React.useContext(LayoutLocationContext);
  const content = useContent();
  const sizeDivisisons = React.useContext(LayoutSizeDivisionContext);
  const globals = useGlobal();
  const versionContext = React.useContext(VersionContext);

  // $FlowIgnore
  const componentVersion = optionsProp?.version;

  if (!componentMap) return null;

  const [Component, computedMode] = getPUFComponent(
    componentMap,
    {
      type,
      id,
      mode,
      forcedVersion: isInteger(componentVersion)
        ? componentVersion
        : versionContext,
    },
    {
      featureFlags,
    }
  );

  if (!Component) return null;

  const options = {
    ...(componentProp.options || {}),
    ...(optionsProp || {}),
  };

  const makeMainClassName = getClassName(options, computedMode, mode);

  // $FlowIgnore
  const className = makeMainClassName(Component.baseClassName || '');

  return (
    <Component
      {...restProps}
      {...globals}
      {...componentProps}
      innerRef={innerRef}
      mode={mode}
      computedMode={computedMode}
      component={componentProp}
      options={options}
      componentProps={componentProps}
      instanceName={instanceName}
      featureFlags={featureFlags}
      entity={content}
      makeMainClassName={makeMainClassName}
      mainClassName={className}
      sizeDivisisons={sizeDivisisons}
      layoutLocation={layoutLocation}
    />
  );
}

/**
 * Will select the right component based on: type, id, mode.
 *
 * Will forward to component: all componentProps, after overriding mode, component in it.
 *
 */
export default (withComponentMap(
  withInstance(PUFComponent)
): React.ComponentType<Props>);

export const getClassName =
  (
    options: Object,
    computedMode: ?string,
    mode: ?string
  ): ((string) => string) =>
  (baseClass: string) => {
    let { marginBottom, cssClass, className } = options;

    // marginBottom half / single / double

    return classnames(
      'FrontComponent',
      baseClass,
      cssClass,
      className,
      marginBottom ? `marginBottom--${marginBottom}` : '',
      mode ? `mode--${mode} ${baseClass}--${mode}` : '',
      computedMode ? `computedMode--${computedMode}` : ''
    );
  };
