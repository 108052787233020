/* eslint-disable react/jsx-no-target-blank */

import { useRouter } from 'next/router';
import * as React from 'react';
import classnames from 'classnames';
import { useEffectOnce } from 'usehooks-ts';
import { findIndex, kebabCase } from 'lodash';

import { isLinkActive } from '@/helpers/url';
import { useLinks } from '@/hooks';

import { Icon, Button } from '@/components/new';
import PageLink from '../PageLinkV2';
import PUFComponent from '@/PUFComponents';

// When maxItemCount is specified;
const TOTAL_MENU_ITEM_WIDTH = 150 + 20; // max width + margin * 2
const baseClassName = 'PUF-main-menu';

const getSEOAttributes = (mode) => {
  switch (mode) {
    case 'header':
      return {
        role: 'navigation',
        'aria-label': 'Menu principal',
      };
    case 'secondary':
      return {
        role: 'navigation',
        'aria-label': 'Menu secondaire',
      };
    default:
      return {};
  }
};

export default function Menu({
  options,
  additionalClassName = '',
  mainClassName,
  style,
}) {
  const listRef = React.useRef();
  const router = useRouter();
  const { dynamicPages: dynamicLinks } = useLinks();
  const { menuItems = [], maxDisplayedItems, id, mode } = options;

  useEffectOnce(() => {
    if (!maxDisplayedItems) return;

    const selectedItemIndex = findIndex(menuItems, (menuItem) =>
      router.asPath.includes(menuItem.pageId)
    );

    if (listRef.current) {
      const nbOfScroll = selectedItemIndex - maxDisplayedItems + 1;
      if (nbOfScroll > 0)
        listRef.current.scrollLeft = nbOfScroll * TOTAL_MENU_ITEM_WIDTH;
    }
  });

  const scroll = React.useCallback((direction) => {
    const listElement = listRef.current;
    if (!listElement) return;

    const operator = direction === 'left' ? -1 : 1;
    listElement.scrollLeft =
      listElement.scrollLeft + operator * TOTAL_MENU_ITEM_WIDTH;
  }, []);

  const shouldDisplayArrows = React.useCallback(() => {
    const listElement = listRef.current;
    if (!listElement) return;
    return maxDisplayedItems && menuItems.length > maxDisplayedItems;
  }, [maxDisplayedItems, menuItems]);

  const shouldDisplayArrowLeft = React.useCallback(() => {
    const listElement = listRef.current;
    if (!listElement) return;
    return (
      shouldDisplayArrows() && (!listElement || listElement.scrollLeft > 0)
    );
  }, [shouldDisplayArrows]);

  const shouldDisplayArrowRight = React.useCallback(() => {
    const listElement = listRef.current;
    if (!listElement) return;
    return (
      shouldDisplayArrows() &&
      listElement.scrollLeft < listElement.scrollWidth - listElement.clientWidth
    );
  }, [shouldDisplayArrows]);

  const displayArrows = shouldDisplayArrows();
  const displayArrowLeft = shouldDisplayArrowLeft();
  const displayArrowRight = shouldDisplayArrowRight();

  return (
    <nav
      className={classnames(mainClassName, additionalClassName, {
        'with-arrows': displayArrows,
      })}
      id={id}
      {...getSEOAttributes(mode)}
      style={style}
    >
      {displayArrowLeft && (
        <Button
          additionalClassName="menu-navigate-left"
          onClick={() => scroll('left')}
          size="small"
        >
          <Icon name="angle-left" size="2x" />
        </Button>
      )}

      <ul className={baseClassName + '-list'} ref={listRef}>
        {menuItems.map((menuItem) => {
          const {
            _cls: itemClass,
            link,
            label,
            subItems = [],
            icon,
            tag,
            hasSeperatorAfter,
          } = menuItem;
          const hasSubItems = Array.isArray(subItems) && subItems.length > 0;

          if (itemClass !== 'MenuItem') return null;

          if (!link) return null;

          return (
            <React.Fragment key={label}>
              <li
                className={classnames({
                  'is-active': isLinkActive(router.asPath, link, {
                    dynamicLinks,
                  }),
                  'has-submenu': hasSubItems,
                })}
                id={`main-menu-item-${kebabCase(label)}`}
              >
                <PageLink link={link}>
                  {(href) => (
                    <a
                      href={href}
                      target={link?.isNewWindow ? '_blank' : undefined}
                      rel={link?.isNewWindow ? 'noreferrer' : undefined}
                    >
                      {icon?.name && (
                        <Icon name={icon.name} style={{ marginRight: 10 }} />
                      )}
                      <span className="main-menu-item-label">{label}</span>
                      {tag && <span className="tag">{tag}</span>}
                    </a>
                  )}
                </PageLink>

                {hasSubItems && (
                  <PUFComponent
                    id="Menu"
                    type="raw"
                    mode="default"
                    options={{
                      menuItems: subItems,
                    }}
                    componentProps={{
                      additionalClassName: 'sub-menu',
                    }}
                  />
                )}
              </li>

              {hasSeperatorAfter && <li className="seperator" />}
            </React.Fragment>
          );
        })}
      </ul>

      {displayArrowRight && (
        <Button
          additionalClassName="menu-navigate-right"
          onClick={() => scroll('right')}
          size="small"
        >
          <Icon name="angle-right" size="2x" />
        </Button>
      )}
    </nav>
  );
}

Menu.baseClassName = baseClassName;
