// @flow

export const CEE_ISO_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
];

export const COUNTRIES_VALUES = [
  {
    label: 'FRANCE & DOM TOM (FR)',
    value: 'FR',
  },
  {
    label: 'AFGHANISTAN (AF)',
    value: 'AF',
  },
  {
    label: 'AFRIQUE DU SUD (ZA)',
    value: 'ZA',
  },
  {
    label: 'ALBANIE (AL)',
    value: 'AL',
  },
  {
    label: 'ALGERIE (DZ)',
    value: 'DZ',
  },
  {
    label: 'ALLEMAGNE (DE)',
    value: 'DE',
  },
  {
    label: 'ANDORRE (AD)',
    value: 'AD',
  },
  {
    label: 'ANGOLA (AO)',
    value: 'AO',
  },
  {
    label: 'ANTIGUA-ET-BARBUDA (AG)',
    value: 'AG',
  },
  {
    label: 'ANTILLES NEERLANDAISES (AN)',
    value: 'AN',
  },
  {
    label: 'ARABIE SAOUDITE (SA)',
    value: 'SA',
  },
  {
    label: 'ARGENTINE (AR)',
    value: 'AR',
  },
  {
    label: 'ARMENIE (AM)',
    value: 'AM',
  },
  {
    label: 'AUSTRALIE (AU)',
    value: 'AU',
  },
  {
    label: 'AUTRICHE (AT)',
    value: 'AT',
  },
  {
    label: 'AZERBAIDJAN (AZ)',
    value: 'AZ',
  },
  {
    label: 'BAHAMAS (BS)',
    value: 'BS',
  },
  {
    label: 'BAHREIN (BH)',
    value: 'BH',
  },
  {
    label: 'BANGLADESH (BD)',
    value: 'BD',
  },
  {
    label: 'BARBADE (BB)',
    value: 'BB',
  },
  {
    label: 'BELGIQUE (BE)',
    value: 'BE',
  },
  {
    label: 'BELIZE (BZ)',
    value: 'BZ',
  },
  {
    label: 'BENIN (BJ)',
    value: 'BJ',
  },
  {
    label: 'BHOUTAN (BT)',
    value: 'BT',
  },
  {
    label: 'BIELORUSSIE (BY)',
    value: 'BY',
  },
  {
    label: 'BIRMANIE (MM)',
    value: 'MM',
  },
  {
    label: 'BOLIVIE (BO)',
    value: 'BO',
  },
  {
    label: 'BONAIRE, SAINT EUSTACHE ET SABA (BQ)',
    value: 'BQ',
  },
  {
    label: 'BOSNIE-HERZEGOVINE (BA)',
    value: 'BA',
  },
  {
    label: 'BOTSWANA (BW)',
    value: 'BW',
  },
  {
    label: 'BRESIL (BR)',
    value: 'BR',
  },
  {
    label: 'BRUNEI (BN)',
    value: 'BN',
  },
  {
    label: 'BULGARIE (BG)',
    value: 'BG',
  },
  {
    label: 'BURKINA (BF)',
    value: 'BF',
  },
  {
    label: 'BURUNDI (BI)',
    value: 'BI',
  },
  {
    label: 'CAMBODGE (KH)',
    value: 'KH',
  },
  {
    label: 'CAMEROUN (CM)',
    value: 'CM',
  },
  {
    label: 'CANADA (CA)',
    value: 'CA',
  },
  {
    label: 'CAP-VERT (CV)',
    value: 'CV',
  },
  {
    label: 'CENTRAFRICAINE (REPUBLIQUE) (CF)',
    value: 'CF',
  },
  {
    label: 'CHILI (CL)',
    value: 'CL',
  },
  {
    label: 'CHINE (CN)',
    value: 'CN',
  },
  {
    label: 'CHYPRE (CY)',
    value: 'CY',
  },
  {
    label: 'COLOMBIE (CO)',
    value: 'CO',
  },
  {
    label: 'COMORES (KM)',
    value: 'KM',
  },
  {
    label: 'CONGO (CG)',
    value: 'CG',
  },
  {
    label: 'CONGO (REPUBLIQUE DEMOCRATIQUE) (CD)',
    value: 'CD',
  },
  {
    label: 'COREE (REPUBLIQUE DE) (KR)',
    value: 'KR',
  },
  {
    label: 'COREE DU NORD (KP)',
    value: 'KP',
  },
  {
    label: 'COSTA RICA (CR)',
    value: 'CR',
  },
  {
    label: "COTE D'IVOIRE (CI)",
    value: 'CI',
  },
  {
    label: 'CROATIE (HR)',
    value: 'HR',
  },
  {
    label: 'CUBA (CU)',
    value: 'CU',
  },
  {
    label: 'CURAÇAO (CW)',
    value: 'CW',
  },
  {
    label: 'DANEMARK (DK)',
    value: 'DK',
  },
  {
    label: 'DJIBOUTI (DJ)',
    value: 'DJ',
  },
  {
    label: 'DOMINICAINE (REPUBLIQUE) (DO)',
    value: 'DO',
  },
  {
    label: 'DOMINIQUE (DM)',
    value: 'DM',
  },
  {
    label: 'EGYPTE (EG)',
    value: 'EG',
  },
  {
    label: 'EL SALVADOR (SV)',
    value: 'SV',
  },
  {
    label: 'EMIRATS ARABES UNIS (AE)',
    value: 'AE',
  },
  {
    label: 'EQUATEUR (EC)',
    value: 'EC',
  },
  {
    label: 'ERYTHREE (ER)',
    value: 'ER',
  },
  {
    label: 'ESPAGNE (ES)',
    value: 'ES',
  },
  {
    label: 'ESTONIE (EE)',
    value: 'EE',
  },
  {
    label: 'ETATS-UNIS (US)',
    value: 'US',
  },
  {
    label: 'ETHIOPIE (ET)',
    value: 'ET',
  },
  {
    label: 'FIDJI (FJ)',
    value: 'FJ',
  },
  {
    label: 'FINLANDE (FI)',
    value: 'FI',
  },
  {
    label: 'GABON (GA)',
    value: 'GA',
  },
  {
    label: 'GAMBIE (GM)',
    value: 'GM',
  },
  {
    label: 'GEORGIE (GE)',
    value: 'GE',
  },
  {
    label: 'GHANA (GH)',
    value: 'GH',
  },
  {
    label: 'GIBRALTAR (GI)',
    value: 'GI',
  },
  {
    label: 'GRECE (GR)',
    value: 'GR',
  },
  {
    label: 'GRENADE (GD)',
    value: 'GD',
  },
  {
    label: 'GROENLAND (GL)',
    value: 'GL',
  },
  {
    label: 'GUATEMALA (GT)',
    value: 'GT',
  },
  {
    label: 'GUINEE (GN)',
    value: 'GN',
  },
  {
    label: 'GUINEE EQUATORIALE (GQ)',
    value: 'GQ',
  },
  {
    label: 'GUINEE-BISSAU (GW)',
    value: 'GW',
  },
  {
    label: 'GUYANA (GY)',
    value: 'GY',
  },
  {
    label: 'HAITI (HT)',
    value: 'HT',
  },
  {
    label: 'HONDURAS (HN)',
    value: 'HN',
  },
  {
    label: 'HONG-KONG (HK)',
    value: 'HK',
  },
  {
    label: 'HONGRIE (HU)',
    value: 'HU',
  },
  {
    label: 'INDE (IN)',
    value: 'IN',
  },
  {
    label: 'INDONESIE (ID)',
    value: 'ID',
  },
  {
    label: 'IRAN (IR)',
    value: 'IR',
  },
  {
    label: 'IRAQ (IQ)',
    value: 'IQ',
  },
  {
    label: 'IRLANDE, ou EIRE (IE)',
    value: 'IE',
  },
  {
    label: 'ISLANDE (IS)',
    value: 'IS',
  },
  {
    label: 'ISRAEL (IL)',
    value: 'IL',
  },
  {
    label: 'ITALIE (IT)',
    value: 'IT',
  },
  {
    label: 'JAMAIQUE (JM)',
    value: 'JM',
  },
  {
    label: 'JAPON (JP)',
    value: 'JP',
  },
  {
    label: 'JORDANIE (JO)',
    value: 'JO',
  },
  {
    label: 'KAZAKHSTAN (KZ)',
    value: 'KZ',
  },
  {
    label: 'KENYA (KE)',
    value: 'KE',
  },
  {
    label: 'KIRGHIZISTAN (KG)',
    value: 'KG',
  },
  {
    label: 'KIRIBATI (KI)',
    value: 'KI',
  },
  {
    label: 'KOSOVO (XK)',
    value: 'XK',
  },
  {
    label: 'KOWEIT (KW)',
    value: 'KW',
  },
  {
    label: 'LAOS (LA)',
    value: 'LA',
  },
  {
    label: 'LESOTHO (LS)',
    value: 'LS',
  },
  {
    label: 'LETTONIE (LV)',
    value: 'LV',
  },
  {
    label: 'LIBAN (LB)',
    value: 'LB',
  },
  {
    label: 'LIBERIA (LR)',
    value: 'LR',
  },
  {
    label: 'LIBYE (LY)',
    value: 'LY',
  },
  {
    label: 'LIECHTENSTEIN (LI)',
    value: 'LI',
  },
  {
    label: 'LITUANIE (LT)',
    value: 'LT',
  },
  {
    label: 'LUXEMBOURG (LU)',
    value: 'LU',
  },
  {
    label: 'MACAO (MO)',
    value: 'MO',
  },
  {
    label: 'MACEDOINE (MK)',
    value: 'MK',
  },
  {
    label: 'MADAGASCAR (MG)',
    value: 'MG',
  },
  {
    label: 'MALAISIE (MY)',
    value: 'MY',
  },
  {
    label: 'MALAWI (MW)',
    value: 'MW',
  },
  {
    label: 'MALDIVES (MV)',
    value: 'MV',
  },
  {
    label: 'MALI (ML)',
    value: 'ML',
  },
  {
    label: 'MALOUINES, OU FALKLAND (ILES) (FK)',
    value: 'FK',
  },
  {
    label: 'MALTE (MT)',
    value: 'MT',
  },
  {
    label: 'MAROC (MA)',
    value: 'MA',
  },
  {
    label: 'MARSHALL (ILES) (MH)',
    value: 'MH',
  },
  {
    label: 'MAURICE (MU)',
    value: 'MU',
  },
  {
    label: 'MAURITANIE (MR)',
    value: 'MR',
  },
  {
    label: 'MEXIQUE (MX)',
    value: 'MX',
  },
  {
    label: 'MICRONESIE (ETATS FEDERES DE) (FM)',
    value: 'FM',
  },
  {
    label: 'MOLDAVIE (MD)',
    value: 'MD',
  },
  {
    label: 'MONACO (MC)',
    value: 'MC',
  },
  {
    label: 'MONGOLIE (MN)',
    value: 'MN',
  },
  {
    label: 'MONTENEGRO (ME)',
    value: 'ME',
  },
  {
    label: 'MOZAMBIQUE (MZ)',
    value: 'MZ',
  },
  {
    label: 'NAMIBIE (NA)',
    value: 'NA',
  },
  {
    label: 'NAURU (NR)',
    value: 'NR',
  },
  {
    label: 'NEPAL (NP)',
    value: 'NP',
  },
  {
    label: 'NICARAGUA (NI)',
    value: 'NI',
  },
  {
    label: 'NIGER (NE)',
    value: 'NE',
  },
  {
    label: 'NIGERIA (NG)',
    value: 'NG',
  },
  {
    label: 'NORVEGE (NO)',
    value: 'NO',
  },
  {
    label: 'NOUVELLE-ZELANDE (NZ)',
    value: 'NZ',
  },
  {
    label: 'OCEAN INDIEN (IO)',
    value: 'IO',
  },
  {
    label: 'OMAN (OM)',
    value: 'OM',
  },
  {
    label: 'OUGANDA (UG)',
    value: 'UG',
  },
  {
    label: 'OUZBEKISTAN (UZ)',
    value: 'UZ',
  },
  {
    label: 'PAKISTAN (PK)',
    value: 'PK',
  },
  {
    label: 'PALAOS (ILES) (PW)',
    value: 'PW',
  },
  {
    label: 'PALESTINE (Etat de) (PS)',
    value: 'PS',
  },
  {
    label: 'PANAMA (PA)',
    value: 'PA',
  },
  {
    label: 'PAPOUASIE-NOUVELLE-GUINEE (PG)',
    value: 'PG',
  },
  {
    label: 'PARAGUAY (PY)',
    value: 'PY',
  },
  {
    label: 'PAYS-BAS (NL)',
    value: 'NL',
  },
  {
    label: 'PEROU (PE)',
    value: 'PE',
  },
  {
    label: 'PHILIPPINES (PH)',
    value: 'PH',
  },
  {
    label: 'PITCAIRN (ILE) (PN)',
    value: 'PN',
  },
  {
    label: 'POLOGNE (PL)',
    value: 'PL',
  },
  {
    label: 'PORTO RICO (PR)',
    value: 'PR',
  },
  {
    label: 'PORTUGAL (PT)',
    value: 'PT',
  },
  {
    label: 'QATAR (QA)',
    value: 'QA',
  },
  {
    label: 'ROUMANIE (RO)',
    value: 'RO',
  },
  {
    label: 'ROYAUME-UNI (GB)',
    value: 'GB',
  },
  {
    label: 'RUSSIE (RU)',
    value: 'RU',
  },
  {
    label: 'RWANDA (RW)',
    value: 'RW',
  },
  {
    label: 'SAHARA OCCIDENTAL (EH)',
    value: 'EH',
  },
  {
    label: 'SAINT-CHRISTOPHE-ET-NIEVES (KN)',
    value: 'KN',
  },
  {
    label: 'SAINT-MARIN (SM)',
    value: 'SM',
  },
  {
    label: 'SAINT-MARTIN (PARTIE NEERLANDAISE) (SX)',
    value: 'SX',
  },
  {
    label: 'SAINT-VINCENT-ET-LES GRENADINES (VC)',
    value: 'VC',
  },
  {
    label: 'SAINTE HELENE (SH)',
    value: 'SH',
  },
  {
    label: 'SAINTE-LUCIE (LC)',
    value: 'LC',
  },
  {
    label: 'SALOMON (ILES) (SB)',
    value: 'SB',
  },
  {
    label: 'SAMOA AMERICAINES (AS)',
    value: 'AS',
  },
  {
    label: 'SAMOA OCCIDENTALES (WS)',
    value: 'WS',
  },
  {
    label: 'SAO TOME-ET-PRINCIPE (ST)',
    value: 'ST',
  },
  {
    label: 'SENEGAL (SN)',
    value: 'SN',
  },
  {
    label: 'SERBIE (RS)',
    value: 'RS',
  },
  {
    label: 'SEYCHELLES (SC)',
    value: 'SC',
  },
  {
    label: 'SIERRA LEONE (SL)',
    value: 'SL',
  },
  {
    label: 'SINGAPOUR (SG)',
    value: 'SG',
  },
  {
    label: 'SLOVAQUIE (SK)',
    value: 'SK',
  },
  {
    label: 'SLOVENIE (SI)',
    value: 'SI',
  },
  {
    label: 'SOMALIE (SO)',
    value: 'SO',
  },
  {
    label: 'SOUDAN (SD)',
    value: 'SD',
  },
  {
    label: 'SOUDAN DU SUD (SS)',
    value: 'SS',
  },
  {
    label: 'SRI LANKA (LK)',
    value: 'LK',
  },
  {
    label: 'SUEDE (SE)',
    value: 'SE',
  },
  {
    label: 'SUISSE (CH)',
    value: 'CH',
  },
  {
    label: 'SURINAME (SR)',
    value: 'SR',
  },
  {
    label: 'SWAZILAND (SZ)',
    value: 'SZ',
  },
  {
    label: 'SYRIE (SY)',
    value: 'SY',
  },
  {
    label: 'TADJIKISTAN (TJ)',
    value: 'TJ',
  },
  {
    label: 'TAIWAN (TW)',
    value: 'TW',
  },
  {
    label: 'TANZANIE (TZ)',
    value: 'TZ',
  },
  {
    label: 'TCHAD (TD)',
    value: 'TD',
  },
  {
    label: 'TCHEQUE (REPUBLIQUE) (CZ)',
    value: 'CZ',
  },
  {
    label: 'THAILANDE (TH)',
    value: 'TH',
  },
  {
    label: 'TIMOR ORIENTAL (TL)',
    value: 'TL',
  },
  {
    label: 'TOGO (TG)',
    value: 'TG',
  },
  {
    label: 'TONGA (TO)',
    value: 'TO',
  },
  {
    label: 'TRINITE-ET-TOBAGO (TT)',
    value: 'TT',
  },
  {
    label: 'TUNISIE (TN)',
    value: 'TN',
  },
  {
    label: 'TURKMENISTAN (TM)',
    value: 'TM',
  },
  {
    label: 'TURQUIE (TR)',
    value: 'TR',
  },
  {
    label: 'TUVALU (TV)',
    value: 'TV',
  },
  {
    label: 'UKRAINE (UA)',
    value: 'UA',
  },
  {
    label: 'URUGUAY (UY)',
    value: 'UY',
  },
  {
    label: 'VANUATU (VU)',
    value: 'VU',
  },
  {
    label: 'VATICAN, ou SAINT-SIEGE (VA)',
    value: 'VA',
  },
  {
    label: 'VENEZUELA (VE)',
    value: 'VE',
  },
  {
    label: 'VIERGES BRITANNIQUES (ILES) (VG)',
    value: 'VG',
  },
  {
    label: 'VIET NAM (VN)',
    value: 'VN',
  },
  {
    label: 'YEMEN (YE)',
    value: 'YE',
  },
  {
    label: 'ZAMBIE (ZM)',
    value: 'ZM',
  },
  {
    label: 'ZIMBABWE (ZW)',
    value: 'ZW',
  },
];
