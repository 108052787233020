import React from 'react';

import { InputCheckboxBase } from '@/components/old';
import PageLink from '../navigation/PageLinkV1';

export default function AcceptConditions(props) {
  return (
    <div className={props.className}>
      {props.cgvPageId && (
        <InputCheckboxBase
          label={
            <span>
              Je certifie avoir pris connaissance des{' '}
              <PageLink target="_blank" pageId={props.cgvPageId}>
                <a target="_blank" onClick={(e) => e.stopPropagation()}>
                  Conditions Générales de Vente
                </a>
              </PageLink>{' '}
              et d’utilisation et en accepte les termes
            </span>
          }
          clickableLabel={true}
          value={props.acceptCgv}
          onChange={props.onChangeCgv} // calling onValidate everywhere is not very nice // TODO
          additionalClassName="cgv-checkbox"
        />
      )}
      {props.rgpdPageId && (
        <InputCheckboxBase
          label={
            <span>
              Je consens à ce que les données personnelles collectées durant la
              présente procédure d’inscription fassent l’objet de traitements
              conformément à la politique de données personnelles dont j’ai pris
              connaissance par le lien suivant :{' '}
              <PageLink pageId={props.rgpdPageId}>
                <a target="_blank" onClick={(e) => e.stopPropagation()}>
                  politique des données personnelles
                </a>
              </PageLink>
            </span>
          }
          clickableLabel={true}
          value={props.acceptRgpd}
          onChange={props.onChangeRgpd}
          additionalClassName="rgpd-checkbox"
        />
      )}
    </div>
  );
}
