// @flow
import * as React from 'react';

import type { CartItem } from '@/types/models';

import { RichTextContent } from '@/components/old';

type Props = {|
  item: CartItem<*>,
  id: string,
|};

export default function DefaultItemComponent({ item }: Props): React.Node {
  const { entity } = item;

  return (
    <>
      <div className="entity-name">{entity.label}</div>

      {entity.cartDecription && (
        <RichTextContent
          className="entity-description"
          html={entity.cartDecription}
        />
      )}
    </>
  );
}
