// @flow

import config from '@/utils/config';
import { values } from 'lodash';
import * as React from 'react';
// import * as Sentry from '@sentry/browser';

import type { FeatureFlags } from '@/types/models';
import type {
  ComponentMap,
  ComponentType,
  PUFComponentType,
} from '@/types/component';

import { getInstanceComponentFolderName } from '@/helpers/models/instance';

import getTemplate1RawComponentMap from './template1/rawComponentMap';
import getTemplate1EntityComponentMap from './template1/entityComponentMap';

export const COMPONENT_TYPE: { [key: string]: ComponentType } = {
  ENTITY: 'entity',
  RAW: 'raw',
};

export const getInstanceComponentMap = (
  siteId: string,
  featureFlags: FeatureFlags
): ComponentMap => {
  const folderName = getInstanceComponentFolderName(siteId);
  let rawComponentMap, entityComponentMap;

  switch (folderName) {
    case 'template1':
      rawComponentMap = getTemplate1RawComponentMap(featureFlags);
      entityComponentMap = getTemplate1EntityComponentMap(featureFlags);
      break;
    default:
      throw new Error(`Unrecognized folder name, got ${folderName}`);
  }

  return {
    raw: rawComponentMap,
    entity: entityComponentMap,
  };
};

type ComponentDescription = {
  type: ComponentType,
  id: string,
  mode?: string,
  forcedVersion?: number,
};

type Options = {
  featureFlags: FeatureFlags,
};

export const getPUFComponent = (
  componentMap: ComponentMap,
  { type, id, mode, forcedVersion }: ComponentDescription,
  { featureFlags }: Options
): [?PUFComponentType<*, *, *, *>, ?string] => {
  if (![COMPONENT_TYPE.ENTITY, COMPONENT_TYPE.RAW].includes(type)) {
    throw new Error(
      `Provided type ${type} is not one of ${values(COMPONENT_TYPE).join(', ')}`
    );
  }

  const { raw: rawComponentMap, entity: entityComponentMap } = componentMap;

  const typedComponentMap =
    type === COMPONENT_TYPE.ENTITY ? entityComponentMap : rawComponentMap;

  const { pufComponentV2 } = featureFlags;

  if (!typedComponentMap[id]) {
    console.error(`Did not find component with id ${id}`);
    return [null, null];
  }

  let component;
  if (pufComponentV2 === true || forcedVersion === 2) {
    component = typedComponentMap[id].v2 ?? typedComponentMap[id].v1;
  } else {
    component = typedComponentMap[id].v1;
  }

  if (!component) {
    const errorMessage = `No PUF component for ${id}`;
    if (config.PUF_ENV === 'prod' || config.PUF_ENV === 'preprod') {
      // Sentry.captureException(errorMessage); // Generates too many errors and consumes our Sentry quota
    }
    console.error(errorMessage);
    return [null, null];
  }

  if (mode) {
    const modedComponent = component[mode];

    if (!modedComponent) {
      // TODO: We have a lot of them, fix them
      // const errorMessage = `PUF component found for id ${id}, but not for mode ${mode}`;
      // Sentry.captureException(errorMessage);
      // console.error(errorMessage);
    } else {
      return [modedComponent, mode];
    }
  }

  return [component.default, 'default'];
};

// $FlowIgnore
export const ComponentMapContext = React.createContext<ComponentMap>({
  entity: {},
  raw: {},
});
