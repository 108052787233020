// @flow
import * as React from 'react';

import type { User, FeatureFlags } from '@/types/models';
import type { FullCartState, CartStepId } from '@/types/stores';
import type { Options } from '../components/CommonWrapper';

import { CartStoreActions } from '@/reflux';
import { CHECKOUT_STEP } from '@/reflux/CartStore/constants';
import { hasError } from '@/reflux/CartStore/helpers';
import { featureEnabled } from '@/helpers/models/instance';

import RawComponent from '@/PUFComponents/RawComponent';
import { Button, Notification } from '@/components/new';
import Donation from '../components/Donation';
import Gift from '../components/Gift';
import CartItem from '../components/CartItem';
import ErrorBanner from '../components/ErrorBanner';
import Total from './Total';
import Addresses from '../components/Addresses';
import PaymentErrorBanner from '../components/PaymentErrorBanner';

type Props = {|
  user: ?User,
  cartState: FullCartState,
  options: Options,
  goBackToProducts?: () => void,
  featureFlags: FeatureFlags,
|};

const PAYMENT_LABEL: { [CartStepId]: string } = {
  [CHECKOUT_STEP.CART_REVIEW]: 'Valider',
  [CHECKOUT_STEP.ADDRESS_AND_PAYMENT]: 'Modifier mon panier',
  [CHECKOUT_STEP.SUCCESSFUL_PAYMENT]: 'Paiement réussi ! Un instant...',
};

export default function Step2CartReview({
  options,
  user,
  cartState,
  goBackToProducts,
  featureFlags,
}: Props): React.Node {
  const {
    cartItems,
    currentStep,
    donation,
    transitioningStep,
    validating,
    uiErrors: errors,
    paymentError,
  } = cartState;
  const hasItems = Object.keys(cartItems).length > 0;

  const inPaymentStep = [
    CHECKOUT_STEP.ADDRESS_AND_PAYMENT,
    CHECKOUT_STEP.SUCCESSFUL_PAYMENT,
  ].includes(currentStep);

  const disabled = validating || transitioningStep;
  const discountCodeEnabled = featureEnabled('DiscountCode', featureFlags);

  return (
    <>
      {discountCodeEnabled && (
        <RawComponent
          id="DiscountOrGiftInput"
          options={{
            label: options.discountLabel,
            placeholderLabel: options.discountPlaceholderLabel,
          }}
        />
      )}
      <div className="step-2-cart-review">
        <h2 className="step-title">Récapitulatif</h2>

        <div className="details">
          <h5>Détails de ma commande</h5>
          {goBackToProducts && (
            <Button isText onClick={goBackToProducts}>
              Voir les autres produits
            </Button>
          )}
        </div>

        <div className="cart-items">
          {hasItems ? (
            <>
              {Object.keys(cartItems).map((itemId) => (
                <CartItem
                  key={itemId}
                  item={cartItems[itemId]}
                  id={itemId}
                  errors={errors.cartItems[itemId]}
                  disabled={inPaymentStep}
                />
              ))}
            </>
          ) : (
            <div className="empty-cart">
              <h4 className="empty-cart-title">
                Vous n'avez pas d'article dans votre panier
              </h4>
              <Button onClick={goBackToProducts}>Voir les offres</Button>
            </div>
          )}
        </div>

        {hasItems && <Gift cartState={cartState} disabled={inPaymentStep} />}

        <hr className="separator" />

        {options.allowDonation && (
          <Donation
            maxDonation={options.maxDonation}
            donationLabel={options.donationLabel}
            donation={donation}
            disabled={inPaymentStep}
          />
        )}

        <hr className="separator" />

        <Addresses cartState={cartState} disabled={inPaymentStep} />

        <hr className="separator" />

        <Total cartState={cartState} />

        {!user && (
          <Notification color="warning">
            Connectez vous pour valider votre panier et passer au paiement
          </Notification>
        )}

        {(hasItems || donation > 0) && !!user && (
          <>
            {hasError(errors) && (
              <>
                <hr className="separator" />
                <ErrorBanner errors={errors} cartItems={cartItems} />
              </>
            )}

            {paymentError && <PaymentErrorBanner error={paymentError} />}

            <div className="submit-wrapper">
              <Button
                disabled={disabled}
                color={disabled || inPaymentStep ? 'light' : undefined}
                onClick={
                  inPaymentStep
                    ? CartStoreActions.goToPreviousStep
                    : CartStoreActions.goToNextStep
                }
              >
                {disabled ? 'Validation...' : PAYMENT_LABEL[currentStep]}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}
