/**
 
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import React from 'react';

import EntityLink from '../../../base/navigation/EntityLink';
import FrontComponentBase from '../../../FrontComponentBase';
import { DynImg, RichTextContent } from '@/components/old';

class AuthorsThumb extends FrontComponentBase {
  static baseClassName = 'AuthorsThumb';
  static ratio = 1;

  /*
        TODO :
        - format date and add content type label
        - add navigation
    */

  render() {
    let entity =
      this.props.content || this.props.entity || this.getSingleEntity();
    if (!entity) return null;

    let {
      shortDesc,
      image,
      label,
      biography,
      occupation,
      firstName,
      lastName,
    } = entity;
    let componentBaseClass = this.constructor.baseClassName;

    let thumbWidth = 310; // largest thumb size

    let options = this.getOptions();
    let mode = this.props.mode || options.mode || 'simple';

    let ratio = this.constructor.ratio;

    if (mode === 'detailed') {
      ratio = 1.5;
    }

    return (
      <EntityLink entity={entity}>
        <a className={this.getMainCSSClass()}>
          {image && (
            <div className={componentBaseClass + '-image'}>
              <DynImg
                src={image}
                alt={label}
                w={thumbWidth}
                h={thumbWidth / ratio}
                assumeNoAlpha={true}
              />
            </div>
          )}

          {label && (
            <div className={componentBaseClass + '-infos'}>
              <div className={componentBaseClass + '-infos-inner'}>
                <div className={componentBaseClass + '-title'}>
                  {(firstName && lastName && (
                    <>
                      <span className={componentBaseClass + '-title-firstName'}>
                        {firstName}
                      </span>{' '}
                      <span className={componentBaseClass + '-title-lastName'}>
                        {lastName}
                      </span>
                    </>
                  )) ||
                    label}
                </div>

                {occupation && (
                  <div className={componentBaseClass + '-occupation'}>
                    {occupation}
                  </div>
                )}

                {mode !== 'simple' && (
                  <div className={componentBaseClass + '-desc'}>
                    {shortDesc}
                  </div>
                )}

                {(mode === 'large' || mode === 'wide') && (
                  <RichTextContent
                    className={componentBaseClass + '-bio'}
                    html={biography}
                  />
                )}
              </div>
            </div>
          )}
        </a>
      </EntityLink>
    );
  }
}

export default AuthorsThumb;
