// @flow
import * as React from 'react';
import classnames from 'classnames';
import useDebouncedEffect from 'use-debounced-effect';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/browser';

import type { PUFComponentProps } from '@/types/component';
import type { CartStoreState } from '@/types/stores';
import type {
  CheckDiscountResponse,
  CheckGiftResponse,
} from '@/types/requests';

import { CartStoreActions } from '@/reflux';
import { useStore, useInstance } from '@/hooks';
import API from '@/utils/API';

import { InputTextBase, Field, Label } from '@/components/old';
import { Control, Icon } from '@/components/new';

type Options = {|
  label?: string,
  placeholderLabel?: string,
|};
type RequiredProps = {||};
type Mode = 'default';
type Props = PUFComponentProps<RequiredProps, Options, Mode, void>;

export default function DiscountOrGiftCodeInput({
  mainClassName,
  options,
}: Props): React.Node {
  const cartStoreState = useStore<CartStoreState>('CartStore');
  const router = useRouter();
  const { query } = router;
  const [code, setCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [error, setError] = React.useState<?string>(null);
  const { label, placeholderLabel } = options;
  const [instanceName] = useInstance();

  React.useEffect(() => {
    if (query.token) {
      setCode(query.token);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (cartStoreState?.discountCode) {
      setCode(cartStoreState.discountCode);
      setValid(true);
    }
  }, [cartStoreState?.discountCode]);

  useDebouncedEffect(
    () => {
      const effect = async () => {
        setError('');
        setLoading(true);
        setValid(false);

        if (code.length === 0) {
          CartStoreActions.forceSetState(
            {
              discountCode: '',
              discountedItems: {},
            },
            { validate: true }
          );
          setLoading(false);
          return;
        }

        const giftResult = await API.get<CheckGiftResponse>(
          `${instanceName}/gift/${code}/check`
        );

        if (giftResult?.isValid) {
          setLoading(false);
          router.push(`/redeem-code?token=${code}`);
          return;
        }

        try {
          const discountResult = await API.get<CheckDiscountResponse>(
            `${instanceName}/discounts/${code}`
          );

          if (!discountResult.isValid) {
            setError(discountResult.reason);
            CartStoreActions.forceSetState(
              {
                discountCode: '',
                discountedItems: {},
              },
              { validate: true }
            );
            return;
          }

          CartStoreActions.forceSetState(
            {
              discountCode: discountResult.discount.code,
              discountedItems: discountResult.discountedItems,
            },
            { validate: true }
          );
          setValid(true);
        } catch (e) {
          Sentry.captureException(e);
          console.error(e);
        } finally {
          setLoading(false);
        }
      };

      effect();
    },
    1000,
    [code]
  );

  return (
    <Field
      additionalClassName={classnames(mainClassName, {
        valid,
      })}
    >
      <Label>{label || 'Entrez votre code'}</Label>
      <Control hasIconsRight={valid || !!error} isLoading={loading}>
        <InputTextBase
          placeholder={placeholderLabel || 'Entrez votre code'}
          value={code}
          onChange={setCode}
          type="text"
        />
        {valid && <Icon name="check" additionalClassName="is-right" />}
        {!!error && <Icon name="times" additionalClassName="is-right" />}
      </Control>
      {error && <p className="error">{error}</p>}
    </Field>
  );
}

DiscountOrGiftCodeInput.baseClassName = 'DiscountCodeInput';
