import * as React from 'react';
import * as Sentry from '@sentry/nextjs';

import FrontComponentBase from '../../FrontComponentBase';
import PageLink from './PageLinkV1';

export default class ImmanensArticleLink extends FrontComponentBase {
  render() {
    const { pageNumber, magazineIssue, _id } = this.getSingleEntity();

    if (!magazineIssue) {
      Sentry.captureException(
        `Missing magazineIssue on MagazineArticle ${_id}`
      );
      return null;
    }

    return (
      <PageLink
        pageId="liseuse"
        query={{ magazineIssueNumber: magazineIssue.number }}
        hash={pageNumber}
      >
        <a className="standard-button">Lire l'article en version numérique</a>
      </PageLink>
    );
  }
}
