// Component used to display an "ask a question" form

import _ from 'lodash';
import React from 'react';
import store from 'store';

import API from '@/utils/API';
import { UserStore, UserActions } from '@/reflux';
import { goToPage } from '../navigation/PageLinkV1';

import { InputSelectBase, Modal, RichTextContent } from '@/components/old';
import FrontComponentBase from '../../FrontComponentBase';
import RawComponent from '../../RawComponent';

class AskAQuestion extends FrontComponentBase {
  static baseClassName = 'AskAQuestion';

  /**
   * TODO : handle display type (questions archive or content single), handle errors + success, handle user + modal, handle local storage question value, handle submit, etc
   **/

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      questionValue: (store.enabled && store.get('savedQuestion')) || '',
      theme: null,
      isOpened: true,
      cantAskModal: false,
    };
  }

  // set user state
  stores = [UserStore];

  logout = () => {
    UserActions.logout();
  };

  didMount() {
    this.observeOptions(() => {
      let options = this.getOptions();
      if (options.showTheme) {
        this.bindCollection(
          'QuestionCategory',
          {
            sortBy: 'label',
            filter: { questionType: { $in: ['question', null] } },
          },
          (themes) =>
            this.setState({
              themes,
            })
        );
      }
    });
  }

  setQuestion(txt) {
    let max = this.getOptions().max || 280;
    let questionValue = txt.substring(0, max);
    this.setState({ questionValue });
    store.enabled && store.set('savedQuestion', questionValue);
  }

  setTheme(theme) {
    this.setState({ theme });
  }

  submitQuestion(e) {
    e.preventDefault();
    if (this.state.user) {
      let options = this.getOptions();

      API.post(this.state.instanceName + '/data/Question', {
        _cls: 'Question',
        label: this.state.questionValue,
        questionType: options.questionType,
        questionCategory: options.showTheme &&
          this.state.theme && {
            _cls: this.state.theme._cls,
            _id: this.state.theme._id,
            _isRef: true,
          },
      })
        .then(this.onQuestionSubmited)
        .catch((e) => {
          if (e && e.code === 403) {
            UserActions.logout();
            this.setState({ cantAskModal: true });
          } else console.error(e);
        });
    } else {
      this.setState({ cantAskModal: true });
    }
  }

  onQuestionSubmited = () => {
    this.setState({
      questionValue: '',
      theme: '',
      sent: true,
    });

    store.enabled && store.set('savedQuestion', '');

    const { pageIdAfter } = this.getOptions();
    pageIdAfter && goToPage(pageIdAfter);
  };

  onCloseAskModal = () => {
    this.setState({ cantAskModal: false });
  };

  render() {
    if (!this.props.component) return null;
    let options = this.getOptions();
    let { themes, sent, theme, questionValue } = this.state;

    let themesOptions = _.map(themes, (a) => ({ value: a, label: a.label }));

    let canSend = !(options.showTheme && !theme) && questionValue;

    let baseClassName = this.constructor.baseClassName;

    return (
      <div
        className={this.getMainCSSClass({
          [baseClassName + '--opened']: this.state.isOpened,
        })}
      >
        <div className={baseClassName + '-title'}>
          <div className={baseClassName + '-title-inner'}>
            {options.label || 'Poser une question'}
          </div>
        </div>

        <form className={baseClassName + '-form'}>
          {(!sent && (
            <div className={baseClassName + '-textarea-wrapper'}>
              {options.showTheme && (
                <div className={baseClassName + '-options'}>
                  <InputSelectBase
                    useValue={true}
                    value={theme}
                    options={themesOptions}
                    onChange={(theme) => this.setTheme(theme)}
                    placeholder="Choisissez votre thématique..."
                    required={true}
                  />
                </div>
              )}

              <textarea
                className={baseClassName + '-textarea'}
                placeholder={options.placeholder || 'Votre question'}
                value={questionValue}
                onChange={(e) => this.setQuestion(e.target.value)}
              />
            </div>
          )) ||
            (options.textAfter && (
              <RichTextContent
                html={options.textAfter}
                className={baseClassName + '-text-after'}
              />
            ))}

          <div className={baseClassName + '-footer'}>
            {(!sent && (
              <>
                <div className={baseClassName + '-count'}>
                  <span className={baseClassName + '-count-total'}>
                    {this.state.questionValue.length}
                  </span>
                  <span className={baseClassName + '-count-max'}>
                    &nbsp;/ {options.max || 280}
                  </span>
                </div>

                <div className={baseClassName + '-submit'}>
                  <button
                    className={baseClassName + '-submit-button'}
                    onClick={(e) => this.submitQuestion(e)}
                    disabled={sent || !canSend}
                  >
                    Envoyer
                  </button>
                </div>
              </>
            )) ||
              (!options.textAfter && (
                <div className={baseClassName + '-sent-message'}>
                  Merci !
                  {options.questionType === 'question' &&
                    ' Votre question sera publiée après modération.'}
                </div>
              ))}
          </div>
        </form>

        {this.state.cantAskModal && (
          <Modal
            title="Se connecter"
            size="half"
            onClose={this.onCloseAskModal}
            contentClassName={baseClassName + '-modal'}
          >
            <p>
              Vous devez vous abonner ou vous connecter pour pouvoir poser une
              question :
            </p>
            <RawComponent
              id="Login"
              componentProps={{ onSuccess: this.onCloseAskModal }}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default AskAQuestion;
