// @flow
import * as React from 'react';

import { InformationBubble } from '@/components/new';

type Props = {|
  errors: Array<string>,
|};

export default function GiftErrors({ errors }: Props): React.Node {
  return (
    <InformationBubble type="danger">
      {errors.length > 0 ? (
        <ul>
          {errors.map((error) => (
            <li>{error}</li>
          ))}
        </ul>
      ) : (
        <p>{errors[0]}</p>
      )}
    </InformationBubble>
  );
}
