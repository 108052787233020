import moment from 'moment';
import React from 'react';

import { useContent } from '@/hooks';

const baseClassName = 'PUF-entity-date';

export default function EntityDateV2({ options, style }) {
  const content = useContent();

  if (!content) return null;

  const { publishTs } = content;
  const { prefix, format = 'DD/MM/YYYY', Element = 'span' } = options;

  const displayedDate = moment.unix(publishTs).format(format);

  return (
    <Element className={baseClassName} style={style}>
      {prefix ? `${prefix} ` : ''}
      {displayedDate}
    </Element>
  );
}

EntityDateV2.baseClassName = baseClassName;
