import React from 'react';
import classnames from 'classnames';
import moment from 'moment';

import { useGlobal, useSiteContext } from '@/hooks';

import { ReportButton } from '@/PUFComponents/components';
import { Button } from '@/components/new';
import Answers from './Answers';
import NewComment from '../NewComment';
import LikeButtons from './LikeButtons';

const baseClassName = 'PUF-comment';

export default function Comment({
  contentId,
  comment,
  isRoot,
  isAnswer,
  onSubmitComment: onSubmitCommentProp,
}) {
  const { siteId, site } = useSiteContext();
  const { user } = useGlobal();
  const { reportableContents } = site;
  const isReportable = reportableContents?.includes('UserComment');
  const [answering, setAnswering] = React.useState(false);
  const [answers, setAnswers] = React.useState([]);

  const {
    _id: commentId,
    _cls: commentCls,
    text,
    createTs,
    authorName,
    recipient,
  } = comment;

  const onSubmitComment = React.useCallback(
    (newComment) => {
      setAnswers([...answers, newComment]);
    },
    [answers]
  );

  return (
    <div
      className={classnames(baseClassName, {
        'has-parent': isAnswer,
      })}
    >
      <div className="content">
        <div className="author-date-wrapper">
          <span className="author">{authorName}</span>
          <span className="publish-date">
            {moment.unix(createTs).fromNow()}
          </span>

          {comment.status === 'prepub' && (
            <span className="error">[EN ATTENTE DE MODÉRATION]</span>
          )}
        </div>

        <div className="comment-text">
          <p>
            {recipient && (
              <span className="parent-author-name">
                <a>{`@${recipient}`}</a>
              </span>
            )}

            {text}
          </p>
        </div>

        <div className="actions">
          <div className="likes">
            <LikeButtons user={user} siteId={siteId} comment={comment} />

            {!answering && (
              <Button
                disabled={!user}
                onClick={() => setAnswering(true)}
                style={{ marginLeft: 10 }}
                size="small"
                isText
              >
                Répondre
              </Button>
            )}
          </div>
          <div className="commands">
            {isReportable && (
              <ReportButton
                contentId={commentId}
                contentType={commentCls}
                contentText={text}
              />
            )}
          </div>
        </div>
      </div>

      {answering && (
        <div className="new-comment-wrapper">
          <NewComment
            contentId={contentId}
            rootCommentId={comment.rootId ?? commentId}
            parentCommentId={commentId}
            comment={comment}
            onAfterSubmit={(comment) => {
              setAnswering(false);
              onSubmitCommentProp
                ? onSubmitCommentProp(comment)
                : onSubmitComment(comment);
            }}
          />
        </div>
      )}

      {isRoot && (
        <Answers
          answers={answers}
          setAnswers={setAnswers}
          comment={comment}
          contentId={contentId}
          onSubmitComment={onSubmitComment}
        />
      )}
    </div>
  );
}
