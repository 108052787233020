// Component used to display the content title

import React from 'react';
import { truncate } from 'lodash';

import { useContent } from '@/hooks';

import EntityLink from '../navigation/EntityLink';
import EntityIsFreeLabel from './EntityIsFreeLabel';

const baseClassName = 'EntityTitle';

export default function EntityTitleV1({
  Element,
  options = {},
  mainClassName,
}) {
  const entity = useContent();

  if (!entity) return null;

  let { label, text, firstName, lastName } = entity;

  label = label || text; // retrocompat Tag

  let { useH1, linkToEntity, truncateLength } = options;

  label = truncateLength ? truncate(label, { length: truncateLength }) : label;

  label =
    (firstName && lastName && (
      <>
        <span className={baseClassName + '-title-firstName'}>{firstName}</span>{' '}
        <span className={baseClassName + '-title-lastName'}>{lastName}</span>
      </>
    )) ||
    label;

  const isFreeLabel = (
    <EntityIsFreeLabel
      entity={entity}
      options={{
        label: options.freeLabel,
        premiumLabel: options.premiumLabel,
      }}
    />
  );

  let titleComponent;

  if (Element) {
    titleComponent = (
      <Element className={baseClassName + '-label t'}>
        {label} {isFreeLabel}
      </Element>
    );
  } else if (useH1) {
    titleComponent = (
      <h1 className={baseClassName + '-label t'}>
        {label} {isFreeLabel}
      </h1>
    );
  } else {
    titleComponent = (
      <div className={baseClassName + '-label t'}>
        {label} {isFreeLabel}
      </div>
    );
  }

  return linkToEntity ? (
    <EntityLink entity={entity}>
      <a className={mainClassName}>{titleComponent}</a>
    </EntityLink>
  ) : (
    <div className={mainClassName}>{titleComponent}</div>
  );
}

EntityTitleV1.baseClassName = baseClassName;
