import _ from 'lodash';
import React from 'react';

import { SiteContext } from '@/globals/contexts';
import API from '@/utils/API';
import { COUNTRIES_VALUES } from '@/constants/countries';
import withContext from '@/utils/HOC/withContext';

import ComponentBase from '../../ComponentBase';
import {
  InputSelectBase,
  InputTextBase,
  Button,
  Modal,
} from '@/components/old';

// TODO restore title? (was not in mockup, but used by Aboweb...)

const ADDRESS_FIELDS_FR = [
  {
    field: 'title',
    name: 'title',
    label: 'civilité',
    values: [
      { label: 'M.', value: 'M.' },
      { label: 'Mme', value: 'Mme' },
    ],
    // placeholder (=label)
    // autocomplete (=name)
    // values (for <select>)

    // TODO max length ?
    // TODO required
  },
  {
    field: 'lastName',
    name: 'family-name',
    label: 'Nom*',
    maxlength: '38',
  },
  {
    field: 'firstName',
    name: 'given-name',
    label: 'Prénom*',
    maxlength: '38',
  },
  {
    field: 'company',
    name: 'organization',
    label: 'Société',
    maxlength: '38',
  },
  {
    // this is not actually the first line of the normalized addres...
    field: 'line1', // street_1 ?
    name: 'address',
    autocomplete: 'address-line1',
    label: 'Numéro, rue, ...*',
    //required    : true, // line1 OR line2 is required
    maxlength: '38',
  },
  {
    // this should be line 1, but autocomplete tend to put the street address here
    // TODO change this everywhere and just swap autocomplete ?
    field: 'line2',
    name: 'address2', // street_2 ?
    autocomplete: 'address-line2',
    label: "Complément d'adresse",
    //required    : true, // line1 OR line2 is required
    maxlength: '38',
  },
  {
    field: 'line3', // street_3 ?
    name: 'address3',
    autocomplete: 'address-line3',
    label: 'Lieu-dit, BP...',
    onlyFr: true,
    maxlength: '38',
  },
  {
    field: 'postalCode',
    name: 'zip',
    autocomplete: 'postal-code',
    label: 'Code postal*',
    required: true,
    maxlength: '38',
  },
  {
    field: 'city',
    name: 'city',
    autocomplete: 'locality',
    label: 'Ville*',
    required: true,
    maxlength: '38',
  },
];

const ADDRESS_FIELDS_INT = [
  {
    field: 'title',
    name: 'title',
    label: 'civilité',
    values: [
      { label: 'M.', value: 'M.' },
      { label: 'Mme', value: 'Mme' },
    ],
    // placeholder (=label)
    // autocomplete (=name)
    // values (for <select>)

    // TODO max length ?
    // TODO required
  },
  {
    field: 'lastName',
    name: 'family-name',
    label: 'Nom*',
    maxlength: '38',
  },
  {
    field: 'firstName',
    name: 'given-name',
    label: 'Prénom*',
    maxlength: '38',
  },
  {
    field: 'company',
    name: 'organization',
    label: 'Société',
    maxlength: '38',
  },
  {
    // this is not actually the first line of the normalized addres...
    field: 'line1', // street_1 ?
    name: 'address',
    autocomplete: 'address-line1',
    label: 'Numéro, rue, ...*',
    //required    : true, // line1 OR line2 is required
    maxlength: '38',
  },
  {
    // this should be line 1, but autocomplete tend to put the street address here
    // TODO change this everywhere and just swap autocomplete ?
    field: 'line2',
    name: 'address2', // street_2 ?
    autocomplete: 'address-line2',
    label: "Complément d'adresse",
    //required    : true, // line1 OR line2 is required
    maxlength: '38',
  },
  {
    field: 'line3', // street_3 ?
    name: 'address3',
    autocomplete: 'address-line3',
    label: 'Lieu-dit, BP...',
    onlyFr: true,
    maxlength: '38',
  },
  {
    field: 'postalCode',
    name: 'zip',
    autocomplete: 'postal-code',
    label: 'Code postal*',
    required: true,
    maxlength: '38',
  },
  {
    field: 'city',
    name: 'city',
    autocomplete: 'locality',
    label: 'Ville*',
    required: true,
    maxlength: '38',
  },
  {
    field: 'vatIdentificationNumber',
    name: 'VAT identification number',
    label: 'N° TVA intracommunautaire*',
    maxlength: '38',
    condition: (obj, props) =>
      props.isBilling &&
      obj &&
      obj.company &&
      CEE_ISO_COUNTRIES.includes(obj.country),
    required: true,
  },
];

/**
 * To display address after validation
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const AddressDisplay = (props) =>
  props.address && (
    /*props.address.normalized ?
                      <div className={props.className}>
                          {props.address.normalized.map((line, i) =>
                              line && <div key={i}>{line}</div>
                          )}
                      </div>
                      :*/
    <div className={props.className} style={{ textAlign: 'left' }}>
      <div>{props.address.line2}</div>
      <div>{props.address.line1}</div>
      <div>{props.address.line3}</div>
      <div>{props.address.postalCode}</div>
      <div>{props.address.city}</div>
      <div>{props.address.country}</div>
    </div>
  );

const AddressChoiceButton = (props) => (
  <div className="AddressForm-choice" onClick={props.onClick}>
    {props.title && (
      <div className="AddressForm-choice-title">{props.title}</div>
    )}
    {props.label && (
      <div className="AddressForm-choice-label">{props.label}</div>
    )}
    {props.address && (
      <AddressDisplay
        className="AddressForm-choice-address"
        address={props.address}
      />
    )}
    <Button additionalClassName="AddressForm-choice-button">
      C'est mon adresse
    </Button>
  </div>
);

/**
 * Prevent button from submitting form
 */

/*const Button = (props) => (
    <button {...props} onClick={e => e.preventDefault() || props.onClick()}/>
);*/

const CEE_ISO_COUNTRIES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
];

class AddressForm extends ComponentBase {
  static baseClassName = 'AddressForm';

  state = {
    inputAddress: {}, // User inputs
    propositions: null, // List of 1 or mode corrected addresses {label: "...", value: [address object]}
    validatedAddress: null, // Final address object to use
  };

  constructor(props) {
    super();
    this._valueCached = props.value || {};
  }

  /**
   * Controlled component onChange
   */
  onChange = (address) => {
    console.debug('New value for address:', address);
    this.props.onChange(address);
  };

  didUpdate(prevProps) {
    if (!_.isEqual(prevProps.value, this.props.value)) {
      //console.debug("<==didUpdate==>", prevProps.value, this.props.value, this._valueCached);
      this._valueCached = this.props.value || {};
    }
  }

  onChangeField = (fieldInfo, value) => {
    this.setState({ validatedAddress: null });

    const newAddress = {
      ...(this.props.value || {}),
      [fieldInfo.field]: value,
    };

    // Special case for aboweb: if international, remove line3
    if (fieldInfo.field === 'country' && value !== 'FR' && newAddress.line3) {
      newAddress.line2 = [newAddress.line2 || '', newAddress.line3 || '']
        .join(', ')
        .toUpperCase();
      newAddress.line3 = '';
    }

    newAddress.normalized = null; // reset normalized since we just changed the address
    newAddress._isComplete = this.isInputAddressComplete(newAddress);

    if (this.props.siteContext.site.useAmabis === false) {
      newAddress._isValid = newAddress._isComplete;
    }

    //this.onChange(newState.inputAddress);
    this.onChange(newAddress);
  };

  /**
   * Read input adress from state
   * @returns {*} Address object
   * @private
   */
  _getInputAddress = () => {
    /*return _.pick(
                this.state,
                _.map(ADDRESS_FIELDS, "field")
            );*/
    //return this.state.inputAddress;
    return this.props.value || {};
  };

  isInputAddressComplete = (address) => {
    let inputAddress = address || this._getInputAddress();
    return (
      inputAddress &&
      inputAddress.country &&
      inputAddress.firstName &&
      inputAddress.lastName &&
      (inputAddress.line1 || inputAddress.line2) &&
      !_.some(
        (inputAddress.country !== 'FR'
          ? ADDRESS_FIELDS_INT
          : ADDRESS_FIELDS_FR
        ).map(
          (f) =>
            f.required && // required,
            (!f.condition || f.condition(inputAddress, this.props)) && // displayed,
            (!inputAddress[f.field] || !inputAddress[f.field].trim()) // and empty
        )
      )
    );
  };

  /**
   * Call webservice to check address
   */
  onCheckInputAddress = () => {
    // Either set state "propositions" or "validatedAddress"
    this.setState({
      addressToConfirm: null,
      propositions: null,
      validatedAddress: null,
    });

    const {
      company,
      country,
      firstName,
      lastName,
      line1,
      line2,
      line3,
      city,
      postalCode,
      title, // civility
    } = this._getInputAddress();

    // TODO simply remove validate button when international
    if (country !== 'FR' || this.props.siteContext.site.useAmabis === false) {
      // skip validation
      const validatedAddress = {
        ...this._getInputAddress(),
        _isComplete: true,
        _isValid: true,
      };

      this.setState({ validatedAddress });
      this.onChange(validatedAddress);
      this.onSubmit();
      return;
    }

    const instance = this.props.siteContext.site._id; // because not an InstanceStore, same as this.state.instance

    API.post(
      instance + '/verifyAddress',
      {
        company,
        country,
        firstName,
        lastName,
        line1,
        line2,
        line3,
        city,
        postalCode,
        title, // civility
      },
      'verify_address'
    )
      .then(({ address, propositions, status }) => {
        switch (status) {
          case 'valid':
            const validatedAddress = {
              ...this._getInputAddress(),
              //...{line1: null, line2: null},
              ...address,
              _isComplete: true,
              _isValid: true,
            };

            this.setState({
              validatedAddress,
              status,
            });

            this.onChange(validatedAddress);
            this.onSubmit();

            break;

          case 'invalid':
          case 'rejected':
            // TODO do not propose when RE G ?
            /*
                                "status": "rejected",
                                "statusFromAmabis": "RE G ",
                                "message": "Adresse rejetee, adresse laissee intacte"
                                "propositions": null,
                                "address" : remplie mais incorrecte...
                            */
            if (!_.isEmpty(propositions)) {
              this.setState({
                propositions: propositions.map((propositionItem) => ({
                  label: propositionItem.proposition,
                  value: propositionItem.propositionLADR,
                })),
                status,
              });
            } else {
              this.setState({
                addressToConfirm: {
                  ...this._getInputAddress(),
                  //...{line1: null, line2: null},
                  ...address,
                },
                status,
              });
            }
            break;
          default:
            console.warn('neither valid invalid or rejected');
            break;
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  /**
   * Call webservice to get address from proposition (CLEVOIE...)
   */
  onGetInputAddressFromSelection = (propositionValue) => {
    // TODO call webservice
    // Either set state "propositions" or "validatedAddress"
    console.debug('onGetInputAddressFromSelection...', propositionValue);
    const inputAddressModified = this._getInputAddress();

    this.setState({
      addressToConfirm: null,
      propositions: null,
      validatedAddress: null,
      //loading         : true, // TODO
    });

    const instance = this.props.siteContext.site._id; // because not an InstanceStore, same as this.state.instance

    API.post(
      instance + '/verifyAddress',
      {
        rawAddress: propositionValue,
        country:
          /*propositionValue[propositionValue.length-1] || */ inputAddressModified.country,
      },
      'verify_address'
    )
      .then(({ address, status }) => {
        if (status === 'rejected')
          // Should never happen:
          window.alert('Adresse rejetée, veuillez re-vérifier');

        const validatedAddress = {
          ...inputAddressModified,
          //...{line1: null, line2: null},
          ...address,
          _isComplete: true,
          _isValid: true,
        };

        this.setState({ validatedAddress });
        this.onChange(validatedAddress);
        this.onSubmit();
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  /**
   * User action: back to edit address
   */
  onRetryInput = () => {
    if (this.props.disabled) return;

    this.setState({
      addressToConfirm: null,
      propositions: null,
      validatedAddress: null,
    });

    const inputAddressModified = this._getInputAddress();
    if (inputAddressModified._isValid) {
      this.onChange({ ...this._getInputAddress(), _isValid: false });
    }
  };

  /**
   * User action: accept one proposition
   */
  onSelectProposition = (proposition) => {
    console.debug('Use this proposition:', proposition);
    this.onGetInputAddressFromSelection(proposition.value);
  };

  /**
   * User action: accept one proposition
   */
  onSelectModifiedAddress = (address) => {
    console.debug('Use modified address:', address);
    const validatedAddress = {
      ...address,
      _isComplete: true,
      _isValid: true,
    };

    this.setState({
      addressToConfirm: null,
      propositions: null,
      validatedAddress,
    });

    this.onChange(validatedAddress);
    this.onSubmit();
  };

  /**
   * User action: force input
   */
  onRejectPropositions = () => {
    console.debug('Keep original input');

    const forcedAddress = { ...this._getInputAddress(), _isValid: true };

    this.setState({
      addressToConfirm: null,
      propositions: null,
      validatedAddress: forcedAddress,
    });

    this.onChange(forcedAddress); // useless
    this.onSubmit();
  };

  onSubmit = () => !!this.props.onSubmit && this.props.onSubmit();

  render() {
    const { label, name, disabled } = this.props;

    const inputAddress = this._getInputAddress();
    const { propositions, addressToConfirm, status } = this.state;
    const baseClassName = this.constructor.baseClassName;
    const useAmabis = this.props.siteContext.site.useAmabis !== false;
    const validatedAddress =
      this.state.validatedAddress ||
      (useAmabis && inputAddress._isValid && inputAddress);

    return (
      <div
        className={`${baseClassName} RegularForm-section LegacyAddressForm`}
        style={{ textAlign: 'left' }}
      >
        <label className={baseClassName + '-label label--title'}>{label}</label>
        {(addressToConfirm && (
          <Modal
            title={
              status === 'rejected'
                ? 'Adresse non reconnue, veuillez confirmer votre adresse :'
                : 'Veuillez confirmer votre adresse :'
            }
            size="half"
            onClose={this.onRetryInput}
            contentClassName={baseClassName + ' ' + baseClassName + '-modal'}
          >
            <AddressChoiceButton
              title="Adresse corrigée :"
              address={addressToConfirm}
              onClick={() => this.onSelectModifiedAddress(addressToConfirm)}
            />

            <AddressChoiceButton
              title="Vous avez saisi l'adresse suivante :"
              address={inputAddress}
              onClick={this.onRejectPropositions}
            />

            <div className={baseClassName + '-modal-buttons'}>
              <Button
                onClick={this.onRetryInput}
                additionalClassName={baseClassName + '-button edit'}
              >
                Je modifie ma saisie
              </Button>
            </div>
          </Modal>
        )) ||
          (propositions && (
            <Modal
              title="Merci de sélectionner votre adresse parmi les choix ci-dessous :"
              size="half"
              onClose={this.onRetryInput}
              contentClassName={baseClassName + ' ' + baseClassName + '-modal'}
            >
              {propositions.map((p, i) => (
                <div key={i}>
                  <Button
                    onClick={() => this.onSelectProposition(p)}
                    additionalClassName={baseClassName + '-button'}
                  >
                    {p.label}
                  </Button>
                </div>
              ))}

              <AddressChoiceButton
                title="J'ai saisi la bonne adresse :"
                address={inputAddress}
                onClick={this.onRejectPropositions}
              />

              <div className={baseClassName + '-modal-buttons'}>
                <Button
                  onClick={this.onRetryInput}
                  additionalClassName={baseClassName + '-button edit'}
                >
                  Je modifie ma saisie
                </Button>
              </div>
            </Modal>
          )) ||
          (validatedAddress && inputAddress && (
            <div>
              <AddressDisplay address={validatedAddress} />
              {!disabled && (
                <div className={baseClassName + '-modal-buttons'}>
                  <Button onClick={this.onRetryInput} isText>
                    Modifier l'adresse {this.props.type}
                  </Button>
                </div>
              )}
            </div>
          )) || (
            <div className="Address-form-wrapper">
              {(inputAddress.country && inputAddress.country !== 'FR'
                ? ADDRESS_FIELDS_INT
                : ADDRESS_FIELDS_FR
              ) //.filter(f => inputAddress.country === "FR" || !f.onlyFr)
                .filter(
                  (f) => !f.condition || f.condition(inputAddress, this.props)
                )
                .map((f) => {
                  const fieldName = ((name && name + '-') || '') + f.name;
                  const autoComplete =
                    ((name && name + ' ') || '') + (f.autocomplete || f.name);

                  return (
                    <div
                      className={
                        'RegularForm-field Address-form-input Address-form-input--' +
                        f.field
                      }
                      key={f.field}
                    >
                      {(f.values && (
                        <InputSelectBase
                          name={fieldName}
                          autoComplete={autoComplete}
                          text={f.label}
                          placeholder={f.placeholder || f.label}
                          value={inputAddress[f.field]}
                          onChange={(v) => this.onChangeField(f, v)}
                          required={f.required}
                          useValue={true}
                          options={f.values}
                          disabled={disabled}
                        />
                      )) || (
                        <InputTextBase
                          type={f.type || 'text'}
                          name={fieldName}
                          autoComplete={autoComplete}
                          text={f.label}
                          placeholder={f.placeholder || f.label}
                          required={f.required}
                          value={inputAddress[f.field]}
                          onChange={(v) => this.onChangeField(f, v)}
                          maxLength={f.maxlength}
                          disabled={disabled}
                        />
                      )}
                    </div>
                  );
                })}
              <div className="RegularForm-field" style={{ width: '100%' }}>
                <InputSelectBase
                  name="country"
                  autoComplete="country"
                  text="Pays"
                  placeholder="Pays"
                  value={inputAddress.country}
                  onChange={(v) => this.onChangeField({ field: 'country' }, v)}
                  //required={true}
                  useValue={true}
                  options={COUNTRIES_VALUES}
                  disabled={disabled}
                />
              </div>
              <div style={{ width: '100%' }}>
                {this.isInputAddressComplete() ? (
                  useAmabis && (
                    <div className={baseClassName + '-submit-wrapper'}>
                      <Button
                        isSubmit={true}
                        onClick={this.onCheckInputAddress}
                        disabled={disabled}
                      >
                        VALIDER MON ADRESSE
                      </Button>
                    </div>
                  )
                ) : (
                  <div className="error">
                    Merci de saisir votre adresse complète
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    );
  }
}

export default withContext(SiteContext, 'siteContext', AddressForm);
