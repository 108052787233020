// Template for a simple image

import React from 'react';

import FrontComponentBase from '../../FrontComponentBase';
import { DynImg } from '@/components/old';
import PageLink from '../navigation/PageLinkV1';

const COL_SIZE = 1480 / 12;

export default class SimpleImageV1 extends FrontComponentBase {
  static baseClassName = 'SimpleImage';

  render() {
    let options = this.getOptions();
    let baseClassName = this.constructor.baseClassName;

    let { image, baseline, mode, ratioWH, width, height, percent, className } =
      this.getOptions();

    let w, h;
    if (ratioWH && (width || this.props.size)) {
      w = width || this.props.size * COL_SIZE;
      h = w / ratioWH;
    }

    let imgStyle = {},
      compStyle = { display: 'block' };
    if (percent) {
      compStyle.width = percent + '%';
    } else {
      if (width) {
        imgStyle.width = width + 'px';
      }
      if (height) {
        imgStyle.height = height + 'px';
      }
    }

    let imgComponent = (
      <DynImg
        style={imgStyle}
        src={image}
        alt={baseline}
        className={baseClassName + '-image'}
        w={w || width || null}
        h={h}
      />
    );

    if (options.pageId) {
      return (
        <PageLink pageId={options.pageId}>
          <a className={this.getMainCSSClass(className)} style={compStyle}>
            {imgComponent}
            {mode && (
              <div className={this.constructor.baseClassName + '-baseline'}>
                {baseline}
              </div>
            )}
          </a>
        </PageLink>
      );
    } else if (options.link) {
      return (
        <a
          href={options.link}
          target={/https?:\/\//.test(options.link) ? '_blank' : undefined}
          rel="noopener noreferrer"
          className={this.getMainCSSClass(className)}
          style={compStyle}
        >
          {imgComponent}
          {mode && (
            <div className={this.constructor.baseClassName + '-baseline'}>
              {baseline}
            </div>
          )}
        </a>
      );
    } else {
      return (
        <div className={this.getMainCSSClass(className)} style={compStyle}>
          {imgComponent}
          {mode && (
            <div className={this.constructor.baseClassName + '-baseline'}>
              {baseline}
            </div>
          )}
        </div>
      );
    }
  }
}
