import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { SiteContext } from '@/globals/contexts';
import { UserStore, InstanceStore, UserActions } from '@/reflux';
import ApiClient from '@/utils/ApiClient';
import { gtmEventRegister, setGtmUserStatus } from '@/utils/googleTagManager';
import { withContext, withLinks } from '@/utils/HOC';
import { isValidEmail, now } from '@/utils/misc';
import { featureEnabled } from '@/helpers/models/instance';
import { withInstance } from '@/utils/HOC';

import PageLink, { goToPage } from '../navigation/PageLinkV1';
import FrontComponentBase from '../../FrontComponentBase';
import {
  FormField,
  InputCheckboxBase,
  InputSelectBase,
  Modal,
  Button,
  EyeIconOn,
  EyeIconOff,
  RichTextContent,
} from '@/components/old';
import AcceptConditions from './AcceptConditions';
import AddressForm from '../user/LegacyAddressForm';
class Register extends FrontComponentBase {
  static baseClassName = 'Register';

  constructor(props) {
    super(props);

    this.state = {
      login: props.login || '',
      pass: '',
      firstName: props.prefill?.firstName || '',
      lastName: props.prefill?.lastName || '',
      title: props.prefill?.title || null,
      nickname: '',
      viewPass: false,
      address: {
        country: 'FR',
      },
      receiveNewsletter: false, // new - RGPD: opt-in NL
    };

    this.stores = [InstanceStore, UserStore];
  }

  didMount() {
    this.observeProp('login', (login) => this.setState({ login }));
  }

  toggleViewPassword = () => {
    this.setState({ viewPass: !this.state.viewPass }, this.validate);
  };

  isMagellanEnabled = () => {
    const { featureFlags } = this.props;
    return featureEnabled('magellan', featureFlags);
  };

  onRegister = () => {
    const { metadata, onSuccess, onClose } = this.props;

    if (this.validate()) {
      // ! see also FIELDS_FOR_PROFILE_UPDATE in server
      const {
        login,
        pass,
        firstName,
        lastName,
        nickname,
        birthDate,
        address,
        acceptCgv,
        acceptRgpd,
        tel,
        title,
        receiveNewsletter,
        receiveSpam,
      } = this.state;

      const customFields = _.map(
        _.get(
          this.props.siteContext,
          'siteConfig.userProfileTemplate.fields',
          []
        ),
        'field'
      );

      this.setState({ registerLoading: true });

      UserActions.register(
        {
          login,
          pass,
          firstName,
          lastName,
          nickname,
          address,
          tel,
          title,
          receiveNewsletter,
          receiveSpam,
          ..._.pick(this.state, customFields),
          name: nickname || firstName + ' ' + lastName,
          birthDateTs: moment(birthDate).isValid()
            ? moment(birthDate).unix()
            : null,
          metadata,
          acceptedCgvTs: acceptCgv ? now() : null, // mandatory so will never be false here
          acceptedRgpdTs: acceptRgpd ? now() : null, // mandatory so will never be false here
        },
        null, // registration context
        (err, user) => {
          this.setState({
            error: err && err.message,
            registerLoading: false,
          });
          !err && onSuccess && onSuccess(user, 'register');

          if (!err) {
            user && gtmEventRegister();
            setGtmUserStatus(user);
          }

          if (!this.getOptions().stayOnPage) {
            const pageId = _.get(
              this.props,
              'siteContext.siteConfig.options.afterRegisterPageId'
            );
            if (pageId) {
              onClose && onClose();
              goToPage(pageId);
            }
          }
        }
      );
    }
  };

  checkLoginExists = _.debounce(
    (login) => {
      this.setState({ login, loginExists: false, loginError: null }, () => {
        this.validate();

        if (isValidEmail(login)) {
          UserActions.checkLoginExists(login, (loginExists) => {
            this.setState(
              {
                loginExists: loginExists && login === this.state.login, // check we haven't typed something else meanwhile
              },
              this.validate
            );
          });
        }
      });
    },
    500,
    { trailing: true }
  );

  onChangeLogin = (login) => {
    this.setState({ login });
    this.checkLoginExists(login);
  };

  onChangeNickname = (nickname) => {
    if (nickname !== this.state.nickname) {
      this.setState({ nickname, errorNickname: null }, this.validate);
      if (nickname) {
        UserActions.checkNickNameExists(nickname, (nicknameExists) => {
          this.setState(
            {
              errorNickname: nicknameExists
                ? 'Le pseudonyme existe déjà'
                : null, // check we haven't typed something else meanwhile
            },
            this.validate
          );
        });
      }
    }
  };

  onChangePass = (pass) => {
    this.setState({ pass }, this.validate);
  };

  onChangeConfirmPass = (confirmPass) => {
    this.setState({ confirmPass }, this.validate);
  };

  onChangeFieldAndSyncAddress = (field, value) => {
    let { address } = this.state;
    // noinspection EqualityComparisonWithCoercionJS
    if (address && (address[field] || null) === (this.state[field] || null)) {
      this.setState(
        (state) => _.set(state, ['address', field], value),
        this.validate
      );
    }
    this.setState({ [field]: value });
  };

  onChangeField = (field, value) => this.setState({ [field]: value });

  validate = () => {
    let {
      login,
      pass,
      confirmPass,
      viewPass,
      firstName,
      lastName,
      nickname,
      errorNickname,
      loginExists,
      address,
      acceptCgv,
      acceptRgpd,
    } = this.state;

    const noCgv = !_.get(
      this.props,
      'siteContext.siteConfig.options.cgvPageId'
    );
    const noRgpd = !_.get(
      this.props,
      'siteContext.siteConfig.options.rgpdPageId'
    );

    let isValid = true;
    this.setState({ error: null });

    if (!isValidEmail(login)) {
      this.setState({ error: 'Veuillez saisir un e-mail valide' });
      isValid = false;
    } else if (this.isMagellanEnabled() && !loginExists) {
      this.setState({
        error:
          "Votre email n'est pas reconnu, si vous n'avez pas de compte, cliquer ci-dessous pour vous abonner",
      });
      isValid = false;
    } else if (!pass) {
      this.setState({ error: 'Veuillez saisir un mot de passe' });
      isValid = false;
    } else if (!loginExists) {
      if (!this.getOptions().simpleMode) {
        if (!viewPass && pass !== confirmPass) {
          this.setState({
            error: 'Vous avez saisi des mots de passe différents',
          });
          isValid = false;
        } else if (!firstName || !lastName) {
          this.setState({ error: 'Merci de saisir vos nom et prénom' });
          isValid = false;
        }
      } else if (!nickname) {
        // simple mode
        this.setState({ error: 'Merci de saisir un nom ou un pseudonyme' });
      }
    }

    if (isValid && this.state.instanceName === 'frontpop') {
      // SPECIFIC FRONTPOP :(
      if (!address || !address._isComplete) {
        this.setState({ error: 'Merci de saisir votre adresse' });
        isValid = false;
      } else if (!address._isValid) {
        this.setState({ error: 'Merci de valider votre adresse' });
        isValid = false;
      }
    }

    if (isValid && errorNickname) {
      this.setState({ error: errorNickname });
      isValid = false;
    }

    if (isValid && !loginExists && !noCgv && !acceptCgv) {
      this.setState({
        error: 'Vous devez accepter les Conditions Générales de Vente',
      });
      isValid = false;
    }

    if (isValid && !loginExists && !noRgpd && !acceptRgpd) {
      this.setState({
        error: 'Vous devez accepter la Politique de Données personnelles',
      });
      isValid = false;
    }

    this.props.validate && this.props.validate(isValid);
    return isValid;
  };

  onLogin = () => {
    let { login, pass } = this.state;
    this.setState({ loginLoading: true });
    UserActions.login(
      {
        login: login,
        password: pass,
        //rememberMe:
      },
      (err) => (!err ? this.onUserLoggedIn() : this.onLoadUserError(err))
    );
  };

  onUserLoggedIn = () => {
    this.setState({ loginLoading: false });
    this.props.onSuccess && this.props.onSuccess(this.state.user, 'login');
    this.props.onClose && this.props.onClose();

    // TODO propre
    ApiClient.updateAll();
  };

  onLoadUserError = (err) => {
    UserActions.set(null);
    this.setState({
      loginLoading: false,
      loginError: err.message,
      loginErrorCode: err.code,
    }); // TEMP
  };

  onOpenModal = () => {
    this.setState({ registerModalOpen: true });
  };

  onCloseModal = () => {
    this.setState({ registerModalOpen: false });
  };

  componentWillReceiveProps(nextProps, previousProps) {
    if (
      !!nextProps.prefill &&
      JSON.stringify(nextProps.prefill) !==
        JSON.stringify(previousProps.prefill)
    ) {
      this.setState({
        title: nextProps.prefill.title,
        firstName: nextProps.prefill.firstName,
        lastName: nextProps.prefill.lastName,
      });
    }
  }

  render() {
    const { links, siteContext, componentProps } = this.props;
    const options = this.getOptions();
    const componentBaseClass = 'RegularForm';
    const magellanEnabled = this.isMagellanEnabled();
    const { withTitle = true } = componentProps || {};

    let {
      login,
      pass,
      confirmPass,
      viewPass,
      firstName,
      lastName,
      nickname,
      birthDate,
      user,
      error,
      loginExists,
      loginError,
      loginErrorCode,
      acceptCgv,
      acceptRgpd,
      registerLoading,
    } = this.state;

    const { cgvPageId, rgpdPageId, registerDataPolicy } = _.get(
      siteContext,
      'siteConfig.options',
      {}
    );

    if (user && options.simpleMode) {
      return null;
    }
    // if !simpleMode then this is update my profile I guess?

    const showSubmit = login && pass && !user && !loginExists;
    const canRegister =
      !registerLoading &&
      showSubmit &&
      (!cgvPageId || acceptCgv) &&
      (!rgpdPageId || acceptRgpd) &&
      !error;

    const form = (
      <form
        className={this.getMainCSSClass([
          componentBaseClass,
          componentBaseClass + '--register',
        ])}
      >
        <div className={componentBaseClass + '-section'}>
          {withTitle
            ? (options.label && ( ////////////////////////////// Part 1 : login ///////////////////////////////////////
                <label className={`${componentBaseClass}-label`}>
                  {options.label}
                </label>
              )) ||
              (loginExists === false && (
                <label className={componentBaseClass + '-label'}>
                  Créez votre compte
                </label>
              )) ||
              (loginExists === true && (
                <label className={componentBaseClass + '-label'}>
                  Connectez-vous
                </label>
              )) || (
                <label className={componentBaseClass + '-label'}>
                  Connectez-vous ou créez un compte
                </label>
              )
            : null}
          <div className={componentBaseClass + '-field'}>
            <label className="RegularForm-field-label">
              <span className="RegularForm-field-label-inner">Email :</span>
            </label>

            <input
              name="email" // Needs name otherwise auto-complete does not trigger onChange!
              placeholder="Email*"
              type="email"
              autoComplete="email"
              className={
                loginExists
                  ? `${componentBaseClass}-text-input ${componentBaseClass}-text-input--error`
                  : `${componentBaseClass}-text-input`
              }
              value={login || ''}
              onChange={(e) => {
                this.onChangeLogin(e.target.value);
              }}
            />
          </div>

          {loginExists && (
            <div className={componentBaseClass + '-success'}>
              Heureux de vous retrouver !
              <br />
              Veuillez saisir votre mot de passe :
            </div>
          )}

          <div className={componentBaseClass + '-field '}>
            <label className="RegularForm-field-label">
              <span className="RegularForm-field-label-inner">
                Mot de passe :
              </span>
            </label>

            <div className={componentBaseClass + '-field--row'}>
              <input
                name="password"
                placeholder="Mot de passe*"
                className={componentBaseClass + '-text-input pass'}
                type={viewPass ? 'text' : 'password'}
                autoComplete={loginExists ? 'current-password' : 'new-password'}
                value={pass || ''}
                onChange={(e) => this.onChangePass(e.target.value)}
              />

              <span
                onClick={this.toggleViewPassword}
                className={
                  viewPass ? 'display-password active' : 'display-password'
                }
                title="Afficher le mot de passe"
              >
                {viewPass ? <EyeIconOn /> : <EyeIconOff />}
              </span>
            </div>
          </div>

          {!loginExists && !viewPass && !options.simpleMode && (
            <div className={componentBaseClass + '-field'}>
              <label className="RegularForm-field-label">
                <span className="RegularForm-field-label-inner">
                  Confirmer le mot de passe :
                </span>
              </label>

              <input
                name="password"
                placeholder="Confirmer le mot de passe*"
                className={componentBaseClass + '-text-input'}
                type="password"
                autoComplete={loginExists ? 'current-password' : 'new-password'}
                value={confirmPass || ''}
                onChange={(e) => this.onChangeConfirmPass(e.target.value)}
              />
            </div>
          )}

          {!loginExists && options.simpleMode && (
            <>
              <div className={componentBaseClass + '-field'}>
                <label className="RegularForm-field-label">
                  <span className="RegularForm-field-label-inner">
                    Nom affiché :
                  </span>
                </label>

                <input
                  name="nickname"
                  autoComplete="nickname"
                  placeholder="Pseudonyme"
                  className={componentBaseClass + '-text-input'}
                  type="text"
                  value={nickname}
                  onChange={(e) => this.onChangeNickname(e.target.value)}
                />
              </div>

              <div className={componentBaseClass + '-field'}>
                <label className="RegularForm-field-label">
                  <span className="RegularForm-field-label-inner">
                    Téléphone :
                  </span>
                </label>

                <input
                  name="telephone"
                  autoComplete="tel"
                  placeholder="Téléphone"
                  className={componentBaseClass + '-text-input'}
                  type="tel"
                  value={this.state.tel}
                  onChange={(e) =>
                    this.setState({ tel: e.target.value }, this.validate)
                  }
                />
              </div>
            </>
          )}

          {loginError && (
            <div className={componentBaseClass + '-error'}>
              {loginErrorCode === 403 ? (
                <span>
                  Mot de passe non reconnu. Veuillez réessayer ou{' '}
                  <PageLink link={links.login.forgotPass}>
                    <a className="LoginBase-forgot-pass-link">
                      le réinitialiser si vous l'avez oublié
                    </a>
                  </PageLink>
                </span>
              ) : (
                loginError
              )}
            </div>
          )}
        </div>

        {isValidEmail(login) && ////////////////////////////// Part 2 : profile //////////////////////////////////
          ((!user &&
            loginExists && ( // login  mode
              <div className={componentBaseClass + '-section'}>
                <div
                  className={
                    componentBaseClass + '-submit-wrapper login-submit-wrapper'
                  }
                >
                  <Button isSubmit={false} onClick={this.onLogin}>
                    Se connecter
                  </Button>
                </div>
              </div>
            )) ||
            (magellanEnabled && (
              <div className={componentBaseClass + '-section'}>
                <div className={componentBaseClass + '-submit-wrapper'}>
                  <PageLink link={links.login.register}>
                    <Button isSubmit={false} onClick={this.onLogin}>
                      S'abonner
                    </Button>
                  </PageLink>
                </div>
              </div>
            )) ||
            (!options.simpleMode && ( // register mode
              <div className={componentBaseClass + '-section'}>
                <div className={componentBaseClass + '-field'}>
                  <label className="RegularForm-field-label">
                    <span className="RegularForm-field-label-inner">
                      Civilité :
                    </span>
                  </label>

                  <InputSelectBase
                    useValue={true}
                    options={[
                      { label: 'M.', value: 'M.' },
                      { label: 'Mme', value: 'Mme' },
                    ]}
                    placeholder="Civilité"
                    name={'title'}
                    autoComplete="title"
                    required={false}
                    type="text"
                    value={this.state.title}
                    onChange={(v) =>
                      this.onChangeFieldAndSyncAddress('title', v)
                    }
                  />
                </div>

                <div className={componentBaseClass + '-field'}>
                  <label className="RegularForm-field-label">
                    <span className="RegularForm-field-label-inner">Nom :</span>
                  </label>

                  <input
                    name="lastName"
                    autoComplete="family-name"
                    placeholder="Nom*"
                    className={componentBaseClass + '-text-input'}
                    type="text"
                    value={lastName}
                    onChange={(e) =>
                      this.onChangeFieldAndSyncAddress(
                        'lastName',
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className={componentBaseClass + '-field'}>
                  <label className="RegularForm-field-label">
                    <span className="RegularForm-field-label-inner">
                      Prénom :
                    </span>
                  </label>

                  <input
                    name="firstName"
                    autoComplete="given-name"
                    placeholder="Prénom*"
                    className={componentBaseClass + '-text-input'}
                    type="text"
                    value={firstName}
                    onChange={(e) =>
                      this.onChangeFieldAndSyncAddress(
                        'firstName',
                        e.target.value
                      )
                    }
                  />
                </div>

                <div>
                  Si vous souhaitez que votre nom n'apparaîsse pas sur le site,
                  merci de choisir un pseudonyme :
                </div>
                <div className={componentBaseClass + '-field'}>
                  <label className="RegularForm-field-label">
                    <span className="RegularForm-field-label-inner">
                      Pseudonyme :
                    </span>
                  </label>

                  <input
                    name="nickname"
                    autoComplete="nickname"
                    placeholder="Pseudonyme"
                    className={componentBaseClass + '-text-input'}
                    type="text"
                    value={nickname}
                    onChange={(e) => this.onChangeNickname(e.target.value)}
                  />
                </div>

                <div className={componentBaseClass + '-field'}>
                  <label className="RegularForm-field-label">
                    <span className="RegularForm-field-label-inner label-needed">
                      Date de naissance :
                    </span>
                  </label>

                  <input
                    name="birthDate"
                    autoComplete="birthDate"
                    placeholder="Date de naissance"
                    className={componentBaseClass + '-text-input'}
                    type="date"
                    value={birthDate}
                    onChange={(e) =>
                      this.setState(
                        { birthDate: e.target.value },
                        this.validate
                      )
                    }
                  />
                </div>

                <div className={componentBaseClass + '-field'}>
                  <label className="RegularForm-field-label">
                    <span className="RegularForm-field-label-inner">
                      Téléphone :
                    </span>
                  </label>

                  <input
                    name="telephone"
                    autoComplete="tel"
                    placeholder="Téléphone"
                    className={componentBaseClass + '-text-input'}
                    type="tel"
                    value={this.state.tel}
                    onChange={(e) =>
                      this.setState({ tel: e.target.value }, this.validate)
                    }
                  />
                </div>

                {_.get(
                  this.props.siteContext,
                  'siteConfig.userProfileTemplate.fields',
                  []
                ).map((f) => {
                  if (f.type === 'address') {
                    return (
                      <AddressForm
                        label={f.label + ' :'}
                        name={f.autocomplete || f.name} // this is the base name for autocomplete etc. so we use autocomplete instead of name !! (TODO rename "name")
                        value={this.state[f.field]}
                        onChange={(v) => this.onChangeField(f.field, v)}
                        isBilling={true} // requires VAT number for CEE companies
                      />
                    );
                  }

                  return (
                    <FormField
                      key={f.field}
                      fieldInfo={f}
                      value={this.state[f.field]}
                      onChange={(v) => this.onChangeFieldAndSyncAddress(f, v)}
                    />
                  );
                })}

                {this.state.instanceName === 'frontpop' && ( // SPECIFIC FRONTPOP // TODO remove and use userProfileTemplate
                  <AddressForm
                    label="Adresse"
                    name=""
                    value={this.state.address}
                    onChange={(address) =>
                      this.setState(
                        (state) => ({
                          ...state,
                          address: { ...state.address, ...address },
                        }),
                        this.validate
                      )
                    }
                    isBilling={true}
                  />
                )}
              </div>
            )))}

        {isValidEmail(login) &&
          !user &&
          !loginExists &&
          !magellanEnabled &&
          (cgvPageId || rgpdPageId) && ( ///////////////// Part 3 : consent ////////////////////////
            <AcceptConditions
              className={componentBaseClass + '-section'}
              cgvPageId={cgvPageId}
              rgpdPageId={rgpdPageId}
              siteName={_.get(this.props, 'siteContext.site.domain')}
              acceptCgv={acceptCgv}
              acceptRgpd={acceptRgpd}
              onChangeCgv={(acceptCgv) =>
                this.setState({ acceptCgv }, this.validate)
              }
              onChangeRgpd={(acceptRgpd) =>
                this.setState({ acceptRgpd }, this.validate)
              }
            />
          )}

        {isValidEmail(login) && !user && !loginExists && !magellanEnabled && (
          <>
            <InputCheckboxBase
              label={
                'Je souhaite m’abonner à la Newsletter de ' +
                _.get(
                  this.props.siteContext,
                  'siteConfig.options.siteTitle',
                  'ce site'
                )
              }
              clickableLabel={true}
              value={this.state.receiveNewsletter}
              onChange={(receiveNewsletter) =>
                this.setState({ receiveNewsletter })
              }
              additionalClassName="subscribe-newsletter-checkbox"
            />

            <InputCheckboxBase
              label={
                'J’accepte de recevoir les communications de ' +
                _.get(
                  this.props.siteContext,
                  'siteConfig.options.siteTitle',
                  'ce site'
                )
              }
              clickableLabel={true}
              value={this.state.receiveSpam}
              onChange={(receiveSpam) => this.setState({ receiveSpam })}
              additionalClassName="accept-communication-checkbox"
            />
          </>
        )}

        {showSubmit && ( /////////////////////////// Submit //////////////////////////////
          <div
            className={
              componentBaseClass + '-submit-wrapper register-submit-wrapper'
            }
          >
            <Button
              isSubmit={true}
              disabled={!canRegister}
              onClick={this.onRegister}
            >
              Créer mon compte
            </Button>
          </div>
        )}

        {error && <div className={componentBaseClass + '-error'}>{error}</div>}

        {registerDataPolicy && !loginExists && (
          <RichTextContent
            className="data-policy-message"
            html={registerDataPolicy}
          />
        )}
      </form>
    );

    if (options.modal) {
      // warning: not using instance's Modal (ex terranima)
      return (
        <div>
          <Button
            onClick={this.onOpenModal}
            additionalClassName="standard-button"
          >
            {options.label || 'Créez votre compte'}
          </Button>
          {this.state.registerModalOpen && (
            <Modal
              title={options.label || 'Créez votre compte'}
              size="half"
              onClose={this.onCloseModal}
            >
              {form}
            </Modal>
          )}
        </div>
      );
    } else {
      return <div>{form}</div>;
    }
  }
}

export default withInstance(
  withContext(SiteContext, 'siteContext', withLinks(Register))
);
