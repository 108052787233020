export default function Title({ options, style, mainClassName }) {
  const { Element = 'h1', content } = options;

  if (!content || !Element) return null;

  return (
    <Element className={mainClassName} style={style}>
      {content}
    </Element>
  );
}

Title.baseClassName = 'PUFTitle';
