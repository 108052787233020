import * as React from 'react';

import TextContentInternalPaywall from './TextContentInternalPaywall';
import VideoContentInternalPaywall from './VideoContentInternalPaywall';

export default function ContentInternalPaywall({ content, fields, onSuccess }) {
  const { unauthorizedDetails, authorized } = content;
  if (
    !content ||
    !('authorized' in content) ||
    authorized ||
    !unauthorizedDetails
  )
    return null;

  switch (content._cls) {
    case 'TextContent':
      return (
        <TextContentInternalPaywall
          content={content}
          fields={fields}
          onSuccess={onSuccess}
        />
      );
    case 'Video':
      return (
        <VideoContentInternalPaywall
          content={content}
          onSuccess={onSuccess}
          fields={fields}
        />
      );
    default:
      return (
        <TextContentInternalPaywall
          content={content}
          onSuccess={onSuccess}
          fields={fields}
        />
      );
  }
}
