import { INSTANCES } from '@/constants/instances';

// Answer entity
import AnswersThumb from '../base/answers/thumb/AnswersThumb';

// Author entity
import AuthorsThumb from './authors/thumb/AuthorsThumb';
import EntityTitleV1 from '../base/entity/EntityTitleV1';

// Bookmark entity
import BookmarkThumb from '../base/contents/thumb/BookmarkThumb';

// ComplexComponent entity
import ComplexComponent from '../ComplexComponent';

// Content entity
import ContentThumbV1 from './contents/thumb/ContentThumbV1';
import ContentThumbCiceron from '../base/contents/thumb/ContentThumbCiceron';
import ContentThumbFrontpopV1 from '../base/contents/thumb/ContentThumbFrontpop';

import EpisodeIndexSdh from './contents/EpisodeIndexSdh';

import CrowdFundingCampaign from '../base/CrowdFundingCampaign';

import PauseScreenPremium from '../base/medias/PauseScreenPremium';
import PauseScreenRegister from '../base/medias/PauseScreenRegister';

// News entity
import NewsThumb from '../base/news/thumb/NewsThumb';

// Question entity
import QuestionsThumb from './questions/thumb/QuestionsThumb';
import QuestionsThumbDetailed from './questions/thumb/QuestionsThumbDetailed';

// Shop entity
import ShopsThumb from '../base/shops/thumb/ShopsThumb';

// Subscription entity
import SubscriptionItem from './subscription/SubscriptionItem';
import SubscriptionItemPurchaseHistory from '../base/subscription/SubscriptionItemPurchaseHistory';

// Tournament
import TournamentFull from '../base/tournament/FullTournament';
import TournamentParticipantFull from '../base/tournament/FullParticipant';
import TournamentThumbnail from '../base/tournament/Thumbnail';

import QuizzFull from '../base/quizz/QuizzFull';

// User entity
import UserName from '../base/user/UserName';

const getEntityComponentMap = (featureFlags: FeatureFlags) => ({
  Answer: {
    v1: {
      default: AnswersThumb,
    },
  },
  Author: {
    v1: { default: AuthorsThumb, name: EntityTitleV1 },
  },
  Bookmark: { v1: { default: BookmarkThumb } },
  ComplexComponent: { v1: { default: ComplexComponent } },

  CrowdFundingCampaign: {
    v1: {
      default: CrowdFundingCampaign,
    },
  },

  Event: { v1: { default: SubscriptionItem } },

  MagazineArticle: {
    v1: { frontpop: ContentThumbFrontpopV1 },
  },

  News: { v1: { default: NewsThumb } },

  Question: {
    v1: { default: QuestionsThumb, detailed: QuestionsThumbDetailed },
  },

  Quizz: { v1: { default: QuizzFull } },

  ShopItem: { v1: { default: ShopsThumb } },

  SubscriptionFormula: {
    v1: {
      default: SubscriptionItem,
      history: SubscriptionItemPurchaseHistory,
    },
  },

  Tag: { v1: { default: EntityTitleV1 } },

  TextContent: {
    v1: {
      default: ContentThumbV1,
      frontpop: ContentThumbFrontpopV1,
      pauseScreenPremium: PauseScreenPremium,
      pauseScreenRegister: PauseScreenRegister,
      sidebar: EpisodeIndexSdh,
      simple: ContentThumbCiceron,
    },
  },

  Tournament: {
    v1: {
      full: TournamentFull,
      thumbnail: TournamentThumbnail,
      default: TournamentThumbnail,
    },
  },

  TournamentParticipant: {
    v1: {
      full: TournamentParticipantFull,
      default: TournamentParticipantFull,
    },
  },

  VideoContent: {
    v1: {
      default: ContentThumbV1,
      frontpop: ContentThumbFrontpopV1,
      pauseScreenPremium: PauseScreenPremium,
      pauseScreenRegister: PauseScreenRegister,
      sidebar: EpisodeIndexSdh,
      simple: ContentThumbCiceron,
    },
  },

  User: { v1: { name: UserName } },
  id: INSTANCES.TEMPLATE1,
});

export default getEntityComponentMap;
