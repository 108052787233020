export function addButton(
  videojs,
  videojsPlayer,
  buttonClass,
  insertBeforeComponentName,
  onClick
) {
  // TODO button text ?
  // tried everywhere - no simple way to do this - must build DOM manually...

  let videoJsButtonClass = videojs.getComponent('Button');
  let myButtonClass = videojs.extend(videoJsButtonClass, {
    constructor: function () {
      videoJsButtonClass.call(this, videojsPlayer);
    },
    handleClick: onClick,
  });

  let myButtonInstance;

  if (insertBeforeComponentName) {
    // ex. "ProgressControl"
    let component = videojsPlayer.controlBar.getChild(
      insertBeforeComponentName
    );
    let index = videojsPlayer.controlBar.children_.indexOf(component);
    myButtonInstance = videojsPlayer.controlBar.addChild(
      new myButtonClass(),
      {},
      index
    );
  } else {
    myButtonInstance = videojsPlayer.controlBar.addChild(new myButtonClass());
  }

  myButtonInstance.addClass(buttonClass);
}
