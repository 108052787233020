// @flow
import * as React from 'react';

import type { GiftInfo as GiftInfoType } from '@/types/stores';

import { useStore, useInstance } from '@/hooks';

import { InputTextBase, Field, Label } from '@/components/old';
import { QuestionMarkTooltip, Notification, Icon } from '@/components/new';

type Props = {|
  giftInfo: GiftInfoType,
  disabled: boolean,
  onChange: ($Shape<GiftInfoType>) => void,
|};

export default function GiftCard({
  giftInfo,
  disabled,
  onChange,
}: Props): React.Node {
  const [instanceName] = useInstance();
  const cartStore = useStore('CartStore');
  const { cartItems } = cartStore;
  const firstProductId = Object.keys(cartItems)[0];

  return (
    <>
      <Notification color="warning">
        <p>
          Après votre paiement, vous pourrez télécharger une carte cadeau à
          imprimer et à offrir à votre ami. Vous la recevrez également par
          email.
        </p>
      </Notification>

      <Field>
        <Label>
          Votre nom{' '}
          <QuestionMarkTooltip content="Ce nom apparaîtra au destinataire lors de la réception de son cadeau"></QuestionMarkTooltip>
        </Label>
        <InputTextBase
          value={giftInfo.senderName}
          onChange={(newSenderName) => onChange({ senderName: newSenderName })}
          type="text"
          disabled={disabled}
        />
      </Field>

      <Field>
        <Label>
          Nom du bénéficiaire{' '}
          <QuestionMarkTooltip content="Nom de la personne qui sera inscrite sur la carte cadeau"></QuestionMarkTooltip>
        </Label>
        <InputTextBase
          value={giftInfo.recipient.name}
          onChange={(newRecipientName) =>
            onChange({
              recipient: {
                ...giftInfo?.recipient,
                name: newRecipientName,
              },
            })
          }
          type="text"
          disabled={disabled}
        />
      </Field>

      {giftInfo.senderName && giftInfo.recipient.name && (
        <div style={{ textAlign: 'right' }}>
          <a
            className="gift-card-preview"
            href={`/frontApi/giftCard?instanceId=${instanceName}&senderName=${giftInfo.senderName}&recipientName=${giftInfo.recipient.name}&productId=${firstProductId}&isPreview=true`}
            target="_blank"
            rel="noreferrer"
          >
            <Icon
              name="eye"
              size="large"
              style={{
                verticalAlign: 'middle',
                marginRight: 5,
                paddingBottom: 2,
              }}
            />
            <span>Prévisualisez votre carte cadeau </span>
          </a>
        </div>
      )}
    </>
  );
}
