import * as React from 'react';

import { VersionContext } from '@/globals/contexts';

import { getFeatureFlags, featureEnabled } from '@/helpers/models/instance';
import { useSite } from '@/hooks';

export default function getVersionedComponent(ComponentPerVersion) {
  function VersionedComponent(props) {
    const site = useSite();
    const versionContext = React.useContext(VersionContext);

    const V2Enabled =
      versionContext === 2 ||
      featureEnabled('pufComponentV2', getFeatureFlags(site));

    const Component = V2Enabled
      ? ComponentPerVersion.V2
      : ComponentPerVersion.V1;

    return <Component {...props} />;
  }

  VersionedComponent.baseClassName =
    ComponentPerVersion.V1.baseClassName ||
    ComponentPerVersion.V2.baseClassName;

  return VersionedComponent;
}
