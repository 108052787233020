// Simple back to top button
import React from 'react';

import FrontComponentBase from '../../FrontComponentBase';

export default class BackToTop extends FrontComponentBase {
  static baseClassName = 'BackToTop';

  constructor(props) {
    super(props);

    this._isMounted = false;
    this._animationFrame = null;
  }

  didMount() {
    this._isMounted = true;
  }

  willUnmount() {
    if (this._animationFrame) window.cancelAnimationFrame(this._animationFrame);
    this._animationFrame = null;
  }

  scrollAnimation() {
    let scrollPosition = window.scrollY;

    window.scroll(0, scrollPosition - 40);

    if (scrollPosition > 0) {
      this._animationFrame = window.requestAnimationFrame(
        this.scrollAnimation.bind(this)
      );
    } else {
      if (this._animationFrame)
        window.cancelAnimationFrame(this._animationFrame);
      this._animationFrame = null;
    }
  }

  scrollToTop() {
    this._isMounted && this.scrollAnimation();
  }

  render() {
    return (
      <div className="BackToTop">
        <button
          className="BackToTop-button"
          onClick={this.scrollToTop}
          title="Retour en haut de la page"
        >
          Retour en haut
        </button>
      </div>
    );
  }
}
