// Template for a simple image

import React from 'react';

import { Image } from '@/components/new';
import PageLink from '../navigation/PageLinkV2';

const baseClassName = 'PUF-simple-image';

const ratioClassNames = {
  [16 / 9]: 'ratio-16-9',
  [4 / 3]: 'ratio-4-3',
  [1 / 1]: 'ratio-1-1',
  [3 / 4]: 'ratio-3-4',
  [35 / 1]: 'ratio-2_35-1',
  [5 / 1]: 'ratio-5-1',
  [6 / 1]: 'ratio-6-1',
};

export default function SimpleImageV2({ options, mainClassName, style }) {
  const { image: src, baseline, ratioWH, to, priority } = options;

  const image = (
    <Image
      src={src}
      alt={baseline}
      ratioClassName={ratioClassNames[ratioWH]}
      priority={priority}
    />
  );

  return (
    <div className={mainClassName} style={style}>
      <PageLink link={to}>
        {(fullHref) => (fullHref ? <a href={fullHref}>{image}</a> : image)}
      </PageLink>
    </div>
  );
}

SimpleImageV2.baseClassName = baseClassName;
