// Component used to display the published date of a component

import React from 'react';
import classnames from 'classnames';

import { MAX_WIDTH_BY_COL_SIZE } from '@/utils/config';
import { EMPTY_HTML_PATTERN } from '@/utils/misc';

import { DynImg } from '@/components/old';
import EntityLink from '../navigation/EntityLink';
import { FullScreenImageViewer } from '../components';

const baseClassName = 'EntityImage';

export default function EntityImage({
  allowFullScreen,
  content,
  entity: entityProp,
  draftMode,
  options,
  component,
  mainClassName,
  size,
}) {
  const [isViewerOpen, setIsViewerOpen] = React.useState(false);

  const openImageViewer = React.useCallback(() => {
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = React.useCallback(() => {
    setIsViewerOpen(false);
  }, []);

  const entity = content || entityProp;

  if (!entity) {
    if (draftMode) {
      // "no display" condition
      return <div className="draft-placeholder">{component.label}</div>;
    }
    return null;
  }

  // note: percent not yet in options
  let {
    ratioWH,
    linkToEntity,
    width,
    hasAlpha,
    percent,
    displayButtons,
    useImage2,
    cropAlgo,
  } = options;

  let w = width || (size && MAX_WIDTH_BY_COL_SIZE[size]) || 1440; // TODO actualSize? replace size by actualSize?
  let h;

  if (ratioWH) {
    h = w / ratioWH;
  }

  let imgStyle = {},
    compStyle = {};
  if (percent) {
    compStyle.width = percent + '%';
  } else if (width) {
    imgStyle.width = width + 'px';
  }

  const componentBaseClass = baseClassName;
  const componentMainClass = classnames(mainClassName, {
    'has-video': entity.video,
    'has-text': entity.body && !EMPTY_HTML_PATTERN.test(entity.body),
  });
  const imageSrc = useImage2 & entity.image2 || entity.image;

  let componentContent = (
    <DynImg
      className={componentBaseClass + '-thumb'}
      style={imgStyle}
      src={imageSrc}
      title={entity.label}
      w={w}
      h={h}
      assumeNoAlpha={!hasAlpha}
      algo={cropAlgo}
    />
  );

  const buttonsComponent = displayButtons ? (
    <div className={componentBaseClass + '-navigation'}>
      {entity.video && (
        <span className={componentBaseClass + '-navigation-video'}>
          Voir la vidéo
        </span>
      )}
      {entity.video && entity.body && !EMPTY_HTML_PATTERN.test(entity.body) && (
        <span className={componentBaseClass + '-navigation-text'}>
          Lire le texte
        </span>
      )}
    </div>
  ) : null;

  return linkToEntity ? (
    <>
      <EntityLink entity={entity}>
        <a className={componentMainClass} style={compStyle}>
          {componentContent}
          {buttonsComponent}
        </a>
      </EntityLink>
    </>
  ) : (
    <>
      <div
        className={componentMainClass}
        style={compStyle}
        onClick={openImageViewer}
      >
        {componentContent}
        {buttonsComponent}
      </div>
      {allowFullScreen && (
        <FullScreenImageViewer
          isViewerOpen={isViewerOpen}
          closeImageViewer={closeImageViewer}
        >
          <DynImg
            className={componentBaseClass + '-thumb'}
            style={imgStyle}
            src={imageSrc}
            title={entity.label}
          />
        </FullScreenImageViewer>
      )}
    </>
  );
}

EntityImage.baseClassName = baseClassName;
