/**
 
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import React from 'react';

import { getApiUrl } from '@/utils/config';

import FrontComponentBase from '../../../FrontComponentBase';
import EntityLink from '../../../base/navigation/EntityLink';

class ShopsThumb extends FrontComponentBase {
  /*
        TODO :
        - format date and add content type label
        - add navigation
    */

  render() {
    let content = this.props.content || this.getSingleEntity();

    if (!content) return null;

    let { shortDesc, image, label } = content;
    let componentBaseClass = 'ShopsThumb';

    image = getApiUrl() + image;

    return (
      <EntityLink entity={content}>
        <a className={componentBaseClass}>
          <div className={componentBaseClass + '-top'}>
            {image && (
              <div className={componentBaseClass + '-image'}>
                <div className="portrait-wrapper">
                  <div className="portrait-inner">
                    <img src={image} alt={label} />
                  </div>
                </div>
              </div>
            )}

            {label && (
              <div className={componentBaseClass + '-infos'}>
                <div className={componentBaseClass + '-name'}>{label}</div>

                <div className={componentBaseClass + '-tag'}>TODO main tag</div>
              </div>
            )}
          </div>

          <div className={componentBaseClass + '-bottom'}>
            {shortDesc && (
              <div className={componentBaseClass + '-desc'}>{shortDesc}</div>
            )}

            <div className={componentBaseClass + '-footer'}>
              <span className={componentBaseClass + '-read-more'}>
                En savoir +
              </span>
            </div>
          </div>
        </a>
      </EntityLink>
    );
  }
}

export default ShopsThumb;
