// @flow
import * as React from 'react';

import type { Layout as LayoutType } from '@/types/layout';

import {
  LayoutComponentsContext,
  LayoutDataContext,
  LayoutComponentsDataContext,
  LayoutCustomModesContext,
} from './contexts';

import Row from './Row';

type Props = {|
  layout: LayoutType,
|};

export default function Layout({ layout }: Props): React.Node {
  const componentContext = React.useContext(LayoutComponentsContext);
  const dataContext = React.useContext(LayoutDataContext);
  const componentsDataContext = React.useContext(LayoutComponentsDataContext);
  const customModesContext = React.useContext(LayoutCustomModesContext);

  if (!layout) return null;

  const {
    components,
    data,
    componentsData,
    structure = [],
    customModes,
  } = layout;

  return (
    <LayoutComponentsContext.Provider value={components || componentContext}>
      <LayoutDataContext.Provider value={data || dataContext}>
        <LayoutComponentsDataContext.Provider
          value={componentsData || componentsDataContext}
        >
          <LayoutCustomModesContext.Provider
            value={customModes || customModesContext}
          >
            {structure.map((row, i) => (
              <Row key={i} row={row} />
            ))}
          </LayoutCustomModesContext.Provider>
        </LayoutComponentsDataContext.Provider>
      </LayoutDataContext.Provider>
    </LayoutComponentsContext.Provider>
  );
}
