// Search form component

import React from 'react';
import { withRouter } from 'next/router';
import cn from 'classnames';
import _ from 'lodash';
import Router from 'next/router';

import FrontComponentBase from '../../FrontComponentBase';

class SearchBase extends FrontComponentBase {
  static baseClassName = 'Search';

  constructor(props) {
    super(props);
    this.state = {
      searchTxt: _.get(props, 'router.query.q', ''),
    };

    this.inputRef = React.createRef();
  }

  goSearch = (e) => {
    e.preventDefault();
    if (('' + this.state.searchTxt).length > 2) {
      Router.push('/search?q=' + encodeURIComponent(this.state.searchTxt));
    } else {
      this.inputRef.current.focus();
    }
  };

  render() {
    let baseClassName = this.constructor.baseClassName;
    let placeholder = this.props.placeholder || 'Rechercher';
    let searchValid = ('' + this.state.searchTxt).length > 2;

    return (
      <form
        className={baseClassName}
        action="/search"
        method="GET"
        onSubmit={this.goSearch}
        onClick={(e) => e.stopPropagation()} // to avoid closing mobile menu
        role="search"
      >
        <input
          type="text"
          name="q"
          placeholder={placeholder}
          className={baseClassName + '-text'}
          value={this.state.searchTxt}
          onChange={(e) => this.setState({ searchTxt: e.target.value })}
          ref={this.inputRef}
        />
        <button
          className={cn(baseClassName + '-submit', { valid: searchValid })}
          type="submit"
          aria-label="Lancer la recherche de contenus"
        >
          <svg width="24px" height="24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            ></path>
          </svg>
        </button>
      </form>
    );
  }
}

export default withRouter(SearchBase);
