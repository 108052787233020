/**
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import React from 'react';

import { MAX_WIDTH_BY_COL_SIZE } from '@/utils/config';
import { EMPTY_HTML_PATTERN } from '@/utils/misc';

import { DynImg } from '@/components/old';
import FrontComponentBase from '../../FrontComponentBase';
import EntityLink from '../navigation/EntityLink';

class VideoPoster extends FrontComponentBase {
  static baseClassName = 'VideoPoster';

  /*
    TODO :
    - format date and add content type label
    - add navigation
  */

  render() {
    let content = this.props.content || this.getSingleEntity();

    let { size, thumbWidth } = this.props;
    const ratioWH = 16 / 9;
    let w, h;

    if (thumbWidth) {
      w = thumbWidth; // TODO devicePixelRatio (unavailable in SSR...)
    } else {
      w = MAX_WIDTH_BY_COL_SIZE[size] || 950; // TODO actualSize? replace size by actualSize?
    }
    h = w / ratioWH;

    let label, image;
    if (content) {
      label = content.label;
      image = content.image;
    } else {
      return null;
    }

    let componentBaseClass = this.constructor.baseClassName;

    return (
      // if this root div useful?
      <div>
        {(this.props.wrappedByLink && (
          <div className={this.getMainCSSClass()}>
            <div className={componentBaseClass + '-navigation'}>
              {content && content.video && (
                <span className={componentBaseClass + '-navigation-video'}>
                  Voir la vidéo
                </span>
              )}
              {content &&
                content.video &&
                content.body &&
                !EMPTY_HTML_PATTERN.test(content.body) && (
                  <span className={componentBaseClass + '-navigation-text'}>
                    Lire le texte
                  </span>
                )}
            </div>

            <div className={componentBaseClass + '-inner'}>
              <div className={componentBaseClass + '-thumb'}>
                <DynImg
                  className={componentBaseClass + '-image'}
                  src={image}
                  alt={label}
                  w={w}
                  h={h}
                  assumeNoAlpha={true}
                />
              </div>
            </div>
          </div>
        )) || (
          <EntityLink entity={content}>
            <a className={componentBaseClass}>
              <div className={componentBaseClass + '-navigation'}>
                {content && content.video && (
                  <span className={componentBaseClass + '-navigation-video'}>
                    Voir la vidéo
                  </span>
                )}
                {content && content.body && (
                  <span className={componentBaseClass + '-navigation-text'}>
                    Lire le texte
                  </span>
                )}
              </div>

              <div className={componentBaseClass + '-inner'}>
                <div className={componentBaseClass + '-thumb'}>
                  <DynImg
                    className={componentBaseClass + '-image'}
                    src={image}
                    alt={label}
                    w={w}
                    h={h}
                    assumeNoAlpha={true}
                  />
                </div>
              </div>
            </a>
          </EntityLink>
        )}
      </div>
    );
  }
}

export default VideoPoster;
