import * as React from 'react';

import { COMPONENT_TYPE } from './componentMap';
import { useContent } from '@/hooks';
import {
  LayoutComponentsContext,
  LayoutCustomModesContext,
} from '@/Layout/contexts';

import ComplexComponent from './ComplexComponent';
import PUFComponent from './';

export default function SingleEntity({ mode, options }) {
  const entity = useContent();
  const componentContext = React.useContext(LayoutComponentsContext);
  const customModesContext = React.useContext(LayoutCustomModesContext);

  if (!entity) return null;

  const customModeComponentUuid =
    customModesContext[entity._cls] && customModesContext[entity._cls][mode];

  if (entity._cls === 'ComplexComponent')
    return <ComplexComponent component={entity} />;

  if (customModeComponentUuid && componentContext[customModeComponentUuid]) {
    const complexComponent = componentContext[customModeComponentUuid];
    return <ComplexComponent component={complexComponent} />;
  }

  return (
    <PUFComponent
      id={entity._cls}
      type={COMPONENT_TYPE.ENTITY}
      mode={mode}
      options={options}
    />
  );
}
