import Router from 'next/router';
import React from 'react';
import Reflux from 'reflux';
import { UserStore } from '@/reflux';
import { getFeatureFlags, featureEnabled } from '@/helpers/models/instance';
import { SiteContext } from '@/globals/contexts';
import { withContext } from '@/utils/HOC';

const watchUser = (Component, expectedLoginState, goToHome) =>
  class extends Reflux.Component {
    store = UserStore;

    checkLoginState() {
      return !!this.state.user === expectedLoginState;
    }

    handleUserLoaded() {
      const userState = !!this.state.user;
      if (!this.checkLoginState()) {
        const target = goToHome || userState ? '/' : '/login';
        console.debug(
          'User loaded/component mounted - ',
          userState ? '' : 'not',
          'logged in => go to ',
          target
        );
        Router.push(target);
      }
    }

    componentDidMount() {
      if (this.state.userLoaded) this.handleUserLoaded();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (!prevState.userLoaded && this.state.userLoaded)
        this.handleUserLoaded();
    }

    render() {
      if (!this.state.userLoaded) return null;

      if (!this.checkLoginState()) {
        return (
          <div>
            <p>
              Vous devez être {expectedLoginState ? 'connecté' : 'déconnecté'}{' '}
              pour accéder à cette page
            </p>
          </div>
        );
      }

      return <Component {...this.props} />;
    }
  };

export const requiresUser = (Component, goToHome = false) =>
  watchUser(Component, true, goToHome);
export const requiresAnonymous = (Component, goToHome = false) =>
  watchUser(Component, false, goToHome);
export const prohibitedWhenConnectedToMagellanHOC = (Component) => {
  function prohibitedWhenConnectedToMagellan({ siteContext, ...rest }) {
    const { site, siteConfig } = siteContext;
    const { magellan: magellanOptions } = siteConfig;
    const featureFlags = getFeatureFlags(site);
    const magellanEnabled = featureEnabled('magellan', featureFlags);

    if (magellanEnabled && !!magellanOptions && typeof window !== 'undefined') {
      window.location = magellanOptions.subscribeUrl;
      return null;
    }

    return <Component {...rest} />;
  }

  return withContext(
    SiteContext,
    'siteContext',
    prohibitedWhenConnectedToMagellan
  );
};
