// @flow
import * as React from 'react';

import type { CartDerivedErrors } from '@/types/stores';
import type { CartItems } from '@/types/models';

import invariant from '@/helpers/invariant';

import { InformationBubble, Text } from '@/components/new';

type Props = {| cartItems: CartItems, errors: CartDerivedErrors |};

const errorTypeLabels = {
  gift: 'Cadeau',
  donation: 'Don',
  promotionalCode: 'Code promo',
  payment: 'Paiement',
  cartItems: 'Éléments du panier',
  billingAddress: 'Adresse de facturation',
  shippingAddress: 'Addresse de livraison',
  general: 'Général',
};

export default function ErrorBanner({ errors, cartItems }: Props): React.Node {
  return (
    <InformationBubble
      type="danger"
      style={{ marginBottom: 20 }}
      additionalClassName="error-banner"
    >
      <ul>
        {Object.keys(errors)
          .filter((errorType) => {
            const notItemErrors = errorType !== 'cartItems'; // Handled separately
            return notItemErrors;
          })
          .map((errorType) => {
            const typeErrors = errors[errorType];

            invariant(
              Array.isArray(typeErrors),
              'typeErrors should be an array'
            );

            if (typeErrors.length === 0) return null;

            return (
              <li key={errorType}>
                <Text weight="bold">{errorTypeLabels[errorType]}</Text>
                <br />
                {typeErrors.length > 0 ? (
                  <ul className="subtype-errors">
                    {typeErrors.map((error) => (
                      <li>{error}</li>
                    ))}
                  </ul>
                ) : (
                  typeErrors[0]
                )}
              </li>
            );
          })}

        {Object.keys(errors.cartItems).map((itemId) => {
          const itemErrors = errors.cartItems[itemId];

          if (itemErrors.length === 0) return null;

          return (
            <li key={itemId}>
              <Text weight="bold">{cartItems[itemId].entity.label}</Text>
              <br />
              {itemErrors.length > 0 ? (
                <ul className="subtype-errors">
                  {itemErrors.map((error) => (
                    <li>{error}</li>
                  ))}
                </ul>
              ) : (
                itemErrors[0]
              )}
            </li>
          );
        })}
      </ul>
    </InformationBubble>
  );
}
