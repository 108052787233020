// @flow
// Component to select subscription type

import * as React from 'react';
import classnames from 'classnames';

import type { PUFComponentProps } from '@/types/component';
import type { SubscriptionFormula } from '@/types/models';
import type { CartStoreState } from '@/types/stores';

import { prettyPrice } from '@/utils/misc';
import {
  getPaymentLabelJSX,
  getIntervalLabel,
} from '@/globals/TempTraductions';
import { useStore } from '@/hooks';
import { capitalizeFirstLetter } from '@/helpers/string';

import RawComponent from '../../RawComponent';
import { RichTextContent } from '@/components/old';

type Options = {|
  allowSubmit: boolean,
  mode: string, // ?
|};

type ComponentProps = {|
  formula: SubscriptionFormula,
  onSelect: (SubscriptionFormula) => any,
  selected: boolean,
  discount: any,
|};

type Mode = 'default' | 'receive-gift' | 'detailed' | 'purchase_history';

type Props = PUFComponentProps<ComponentProps, Options, Mode, void>;

const CAN_SUBMIT_MODES: Array<Mode> = ['default', 'detailed'];

const getDescription = (formula: SubscriptionFormula, mode: Mode) => {
  switch (mode) {
    case 'receive-gift':
      return formula.recipientConfirmationText || formula.description;
    case 'purchase_history':
      return formula.confirmationText;
    default:
      return formula.description || formula.desc;
  }
};

export default function SubscriptionItem({
  mainClassName,
  mode: modeProp,
  options,
  componentProps,
  entity: formula,
}: Props): React.Node {
  const {
    cartItems,
    discountedItems = {},
    discountCode,
  } = useStore<CartStoreState>('CartStore');
  const buttonRef = React.useRef();
  const { onSelect: onSelectProp } = componentProps; // TODO component-style?

  const isCartMode = !onSelectProp;

  if (!formula) return null;

  const selected = isCartMode
    ? cartItems && !!cartItems[formula._id]
    : componentProps.selected;
  const onSelect = isCartMode ? buttonRef.current?.onClick : onSelectProp;

  const { allowSubmit = true, mode: modeOption } = options;
  const mode = modeProp || modeOption;
  const canSubmit = allowSubmit && CAN_SUBMIT_MODES.includes(mode);

  const componentBaseClass = SubscriptionItem.baseClassName;

  const discountSet = !!discountCode && canSubmit;
  const discountedPrice = discountedItems[formula._id]?.discountPrice;
  const hasDiscount = discountSet && !!discountedPrice;
  const description = getDescription(formula, mode);

  return (
    <div
      className={classnames(mainClassName, {
        [componentBaseClass + '--selected']: selected,
        'no-discount': discountSet && !discountedPrice,
        'has-discount': discountSet && !!discountedPrice,
      })}
      onClick={onSelect ? () => onSelect(formula) : undefined}
    >
      {hasDiscount && <span className="tag promotion">En promo</span>}

      <div className="SubscriptionItem-inner">
        <h1 className={componentBaseClass + '-title'}>
          {capitalizeFirstLetter(formula.label, { resetCase: true })}
        </h1>

        {/* c/c components/subscription/SubscriptionItem */}
        {(mode === 'detailed' || discountedPrice) && (
          <Details
            formula={formula}
            discountedPrice={hasDiscount ? discountedPrice : undefined}
            baseClassName={componentBaseClass}
          />
        )}

        {description && (
          <div className={componentBaseClass + '-description RichTextBlock'}>
            <RichTextContent html={description} />
          </div>
        )}

        {/* specific */}
        {canSubmit && !!formula.maxTickets && formula.maxTickets > 0 && (
          <div className={componentBaseClass + '-remaining'}>
            {Math.max(0, formula.maxTickets - (formula._purchaseCount || 0))}{' '}
            restant sur {formula.maxTickets}
          </div>
        )}

        {/* c/c components/subscription/SubscriptionItem */}
        {canSubmit &&
          (isCartMode ? ( // cart mode
            <RawComponent
              id="AddToCartButton"
              //mode={options.mode}
              options={{
                mode: options.mode,
                addLabel: formula.buttonLabel,
                removeLabel: formula.removeLabel || '✓ Ajouté',
              }}
              innerRef={(node) => buttonRef.current}
              componentProps={{
                entity: formula,
              }}
            />
          ) : (
            <div className={componentBaseClass + '-button'}>
              <button
                className={componentBaseClass + '-button-text standard-button'}
              >
                {!selected ? formula.buttonLabel : "Je m'abonne"}
              </button>
            </div>
          ))}

        <div className="decoration-left" />
        <div className="decoration-right" />
      </div>
    </div>
  );
}

SubscriptionItem.baseClassName = 'SubscriptionItem';

type DetailsProps = {|
  baseClassName: string,
  formula: SubscriptionFormula,
  discountedPrice?: number,
|};

function Details({ baseClassName, formula, discountedPrice }: DetailsProps) {
  if (!discountedPrice) {
    return (
      <>
        <h2
          className={classnames(`${baseClassName}-price-title`, {
            'has-subtitle': !!formula.priceSubLabel,
          })}
        >
          {formula.priceLabel || (
            <span className={baseClassName + '-price-wrapper'}>
              <span
                className={
                  formula.recurringPrice
                    ? baseClassName + '-initial-price'
                    : baseClassName + '-price'
                }
              >
                {prettyPrice(formula.price)}
              </span>
              {formula.recurringPrice && (
                <>
                  <span className={baseClassName + '-then'}>{', puis '}</span>
                  <span className={baseClassName + '-recurring-price'}>
                    {prettyPrice(formula.recurringPrice)}
                  </span>
                </>
              )}
              {getIntervalLabel(formula) && (
                <span className={baseClassName + '-interval'}>
                  {' '}
                  / {getIntervalLabel(formula)}
                </span>
              )}
            </span>
          )}
        </h2>
        {formula.priceSubLabel && (
          <h3 className={baseClassName + '-price-subtitle'}>
            {formula.priceSubLabel}
          </h3>
        )}
      </>
    );
  }

  return (
    <>
      <h2 className={baseClassName + '-discounted-price'}>
        {getPaymentLabelJSX(discountedPrice, formula.intervalType)}
      </h2>

      <h4 className={baseClassName + '-original-price'}>
        au lieu de {getPaymentLabelJSX(formula.price, formula.intervalType)}
      </h4>
    </>
  );
}
