// @flow
import * as React from 'react';

import type { ComponentMap } from '@/types/component';

import { ComponentMapContext } from './componentMap';

// storeNameList is a list of store name like ['SchemaStore', 'UserStore'];
export default function withComponentMap<P>(
  Component: React.ComponentType<P>
): React.ComponentType<{ ...P, componentMap: ComponentMap }> {
  class WithComponentMapComponent extends React.Component<{
    ...P,
    componentMap: ComponentMap,
  }> {
    render() {
      return (
        <ComponentMapContext.Consumer>
          {(componentMap) => (
            <Component componentMap={componentMap} {...this.props} />
          )}
        </ComponentMapContext.Consumer>
      );
    }
  }

  return WithComponentMapComponent;
}
