import * as React from 'react';
import _ from 'lodash';
import { NBSP } from '@/utils/misc';

export const INTERVAL_TR_NOUN = {
  // TODO trads
  year: 'an',
  month: 'mois',
  '6month': 'semestre',
  '3month': 'trimestre',
  '2weeks': '2 semaines',
  week: 'semaine',
  day: 'jour',
  //"never" : "",
};

// Put only label that are different here:
export const INTERVAL_TR_NOUN_PLURAL = {
  year: 'ans',
  week: 'semaines',
  day: 'jours',
};

export function getIntervalLabel(
  formula,
  singularTraductionOverrides = {},
  pluralTraductionOverrides = {}
) {
  const { intervalType, intervalCount } = formula || {};
  const countAllowed = ['day', 'week', 'month', 'year'].includes(intervalType);
  const singularTraductions = {
    ...INTERVAL_TR_NOUN,
    ...singularTraductionOverrides,
  };
  const pluralTraductions = {
    ...INTERVAL_TR_NOUN_PLURAL,
    ...pluralTraductionOverrides,
  };

  return singularTraductions[intervalType]
    ? (countAllowed && intervalCount && intervalCount !== 1
        ? intervalCount + ' '
        : '') +
        ((intervalCount > 1 && pluralTraductions[intervalType]) ||
          singularTraductions[intervalType])
    : '';
}

export function getPaymentLabelJSX(
  recurringPrice,
  intervalType,
  firstPrice,
  intervalCount
) {
  let prefix = _.isNumber(firstPrice) ? firstPrice + NBSP + '€, puis ' : '';

  if (recurringPrice > 0) {
    return (
      <>
        <span className="prefix">{prefix}</span>
        <span className="recurring-price">
          {recurringPrice}
          {NBSP + '€'}
        </span>
        <span className="interval">
          {INTERVAL_TR_NOUN[intervalType]
            ? '/' + getIntervalLabel({ intervalCount, intervalType })
            : ''}
        </span>
      </>
    );
  } else if (parseInt(recurringPrice) === 0) {
    return (
      <>
        <span className="prefix">{prefix}</span> +{' '}
        <span className="recurring-price">gratuit</span>
      </>
    );
  } else {
    console.error(
      'Error getting formula price label with:',
      recurringPrice,
      intervalType,
      firstPrice
    );
    return 'ERREUR: prix non défini !';
  }
}

export function getPaymentLabel(
  recurringPrice,
  intervalType,
  firstPrice,
  intervalCount
) {
  let prefix = _.isNumber(firstPrice) ? firstPrice + NBSP + '€, puis ' : '';

  if (recurringPrice > 0) {
    return (
      prefix +
      recurringPrice +
      NBSP +
      '€' +
      (INTERVAL_TR_NOUN[intervalType]
        ? '/' + getIntervalLabel({ intervalCount, intervalType })
        : '')
    );
  } else if (parseInt(recurringPrice) === 0) {
    return prefix + 'gratuit';
  } else {
    console.error(
      'Error getting formula price label with:',
      recurringPrice,
      intervalType,
      firstPrice
    );
    return 'ERREUR: prix non défini !';
  }
}
