// Component used to display the content tags

import React from 'react';
import _ from 'lodash';

import FrontComponentBase from '../../FrontComponentBase';
import EntityLink from '../navigation/EntityLink';
import PageLink from '../navigation/PageLinkV1';

class TagList extends FrontComponentBase {
  static baseClassName = 'TagList';

  render() {
    // you can use props "content" for direct usage
    let mainTags = this.props.tags || this.getEntityList();
    let options = this.getOptions();

    const { label: labelIfSingle, labelIfMultiple } = options;
    const label =
      !!mainTags && !!labelIfMultiple && mainTags.length > 0
        ? labelIfMultiple
        : labelIfSingle;

    // TODO maybe use currrent content's tags if no list specified ?
    // if (!mainTags && !options.list && !options.list.length && this.getSingleEntity()) mainTags = this.getSingleEntity().mainTags;

    let componentBaseClass = 'EntityTags'; // TODO update CSS class

    return (
      (!_.isEmpty(mainTags) && (
        <div className={this.getMainCSSClass()}>
          <h3 className={componentBaseClass + '-title'}>
            {label || 'Thèmes abordés'}
          </h3>
          <div className={componentBaseClass + '-list labelsList'}>
            {mainTags.map(
              (tag) =>
                tag &&
                ((tag.pageId && (
                  <PageLink pageId={tag.pageId} key={tag._id}>
                    <a
                      key={tag._id}
                      className={
                        componentBaseClass + '-list-item labelsList-item link'
                      }
                    >
                      {tag.label || tag.text}
                    </a>
                  </PageLink>
                )) || (
                  <EntityLink entity={tag} key={tag._id}>
                    <span
                      key={tag._id}
                      className={
                        componentBaseClass + '-list-item labelsList-item link'
                      }
                    >
                      {tag.label || tag.text}
                    </span>
                  </EntityLink>
                ))
            )}
          </div>
        </div>
      )) ||
      null
    );
  }
}

export default TagList;
