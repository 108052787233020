/* eslint-disable react/jsx-no-target-blank */
import { capitalize, pickBy, mapKeys } from 'lodash';
import React from 'react';
import classnames from 'classnames';

import { useContent } from '@/hooks';
import { LayoutLocationContext, ContentContext } from '@/globals/contexts';
import { LayoutComponentsDataContext } from '@/Layout/contexts';

import Layout from '@/Layout';
import EntityLink from '../base/navigation/EntityLink';
import PageLink from '../base/navigation/PageLinkV2';

export default function ComplexComponentV2({ component }) {
  const { layout, isLink, link, objectType } = component;
  const content = useContent();
  const layoutLocation = React.useContext(LayoutLocationContext);
  const componentsDataContext = React.useContext(LayoutComponentsDataContext);

  const classNames = classnames(
    'complex-component',
    component._id || '',
    component.className || '',
    component?.options?.className || '',
    content?.className || '',
    (component.objectType || []).map((objectType) => `type--${objectType}`),
    (component.objectType || []).map(
      (objectType) => `${objectType}${capitalize(component.mode)}`
    ),
    {
      [`mode--${component.mode}`]: !!component.mode,
      'ComplexComponent-has-overlay': component.useOverlay,
      [component._id]: !component.objectType && component._id,
      'is-link': isLink,
      'for-content': !!objectType && objectType.length > 1,
    }
  );

  const innerContent = (
    <div className={classNames}>
      <Layout layout={layout} />
    </div>
  );

  let renderedContent = innerContent;

  if (isLink && link) {
    renderedContent = (
      <PageLink link={link}>
        {(href) => (
          <a
            href={href}
            target={link.isNewWindow ? '_blank' : undefined}
            rel={link.isNewWindow ? 'noreferrer' : undefined}
          >
            {innerContent}
          </a>
        )}
      </PageLink>
    );
  } else if (isLink && content) {
    renderedContent = (
      <EntityLink entity={content}>
        <a>{innerContent}</a>
      </EntityLink>
    );
  }

  const dataSignature = content
    ? `${component._id}.${content._cls}_${content._id}.`
    : `${component._id}.`;
  const newComponentsDataContext = mapKeys(
    pickBy(componentsDataContext, (value, key) =>
      key.startsWith(dataSignature)
    ),
    (value, key) => key.replace(dataSignature, '')
  );

  return (
    <ContentContext.Provider value={objectType ? content : null}>
      <LayoutComponentsDataContext.Provider value={newComponentsDataContext}>
        <LayoutLocationContext.Provider
          value={{
            ...layoutLocation,
            source: `${layoutLocation.source}---ComplexComponent:${component._id}:layout`,
          }}
        >
          {renderedContent}
        </LayoutLocationContext.Provider>
      </LayoutComponentsDataContext.Provider>
    </ContentContext.Provider>
  );
}
