import _ from 'lodash';
import classNames from 'classnames';
import React from 'react';

import { UserStore } from '@/reflux';
import ApiClient from '@/utils/ApiClient';
import PaginatedData from '@/utils/PaginatedData';
import { hasSufficientRole, DEFAULT_SUFFICIENT_ROLE } from '@/helpers/roles';
import withContext from '@/utils/HOC/withContext';
import withRecoilState from '@/utils/HOC/withRecoilState';
import { loginSubscriptionModalState } from '@/recoil/atoms';
import { SiteContext } from '@/globals/contexts';

import FrontComponentBase from '../../../../FrontComponentBase';
import NewComment from './NewComment';
import Comment, { ANSWERS_TO_LOAD, COMMENTS_TO_LOAD } from './Comment';
import Header from './Header';

const MAX_COMMENT_LENGTH = 280;

class CommentList extends FrontComponentBase {
  static defaultProps = {
    maxCommentLength: MAX_COMMENT_LENGTH,
  };

  static baseClassName = 'Comments';
  stores = [UserStore];

  constructor(props) {
    super(props);

    this.state = {
      commentsDisplayed: false,
    };
  }

  showComments = () => {
    const { comments } = this.state;

    if (this.canRead()) {
      this.setState({ commentsDisplayed: true });

      if (!comments) {
        this.load(this.getSingleEntity());
      }
    }
  };

  hideComments = () => this.setState({ commentsDisplayed: false });

  closeModal = () => {
    this.setState({ notConnectedModal: false });
  };

  render() {
    const { maxCommentLength } = this.props;
    const { foldable, answersToLoad, answersToLoadMore } = this.getOptions();
    const entity = this.getSingleEntity();
    const contentId = entity && entity._id;

    if (!contentId) return null;

    const hasMore = this.paginatedComments && this.paginatedComments.hasMore();
    const { user, commentsCount, commentsDisplayed, comments } = this.state;
    const baseClassName = this.constructor.baseClassName;
    const { read, write } = this.getCommentsRights();

    const userCanComment = hasSufficientRole(user, write);
    const displayCommentsContent =
      (commentsDisplayed && comments && comments.length > 0) || userCanComment;

    return (
      <div className={this.getMainCSSClass()}>
        <Header
          baseClassName={baseClassName}
          foldable={foldable}
          commentsCount={commentsCount}
          commentsDisplayed={commentsDisplayed}
          onShow={this.showComments}
          onHide={this.hideComments}
          canComment={userCanComment}
          onComment={this.canWrite}
        />

        {displayCommentsContent && (
          <div className={`${baseClassName}-content`}>
            <div className={`${baseClassName}-leave-comment`}>
              {userCanComment && (
                <NewComment
                  content={entity}
                  parentComment={null}
                  onAfterPost={this.showComments}
                  maxCommentLength={maxCommentLength}
                />
              )}
            </div>

            {commentsDisplayed && (
              <div
                className={classNames(`${baseClassName}-comments-list`, {
                  loading: !comments && hasSufficientRole(user, read),
                })}
              >
                {comments && comments.length > 0 ? (
                  <>
                    {comments.map((comment) => (
                      <Comment
                        comment={comment}
                        user={user}
                        key={comment._id}
                        maxCommentLength={maxCommentLength}
                        canWrite={this.canWrite}
                        answersToLoad={answersToLoad}
                        answersToLoadMore={answersToLoadMore}
                      />
                    ))}
                    {hasMore && (
                      <div className={`${baseClassName}-load-more`}>
                        <a onClick={() => this.loadMore(entity)}>
                          Plus de commentaires...
                        </a>
                      </div>
                    )}
                  </>
                ) : (
                  <h4 style={{ textAlign: 'center' }}>
                    Aucun commentaire, soyez le premier à donner votre avis !{' '}
                  </h4>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  load = (entity) => {
    const { _id: entityId, _cls: entityCls } = entity;
    console.debug('Load entity ' + entityId + ' comments...');
    const { comments } = this.state;
    const { commentsToLoad, commentsToLoadMore, answersToLoad } =
      this.getOptions();

    this.paginatedComments && this.paginatedComments.stopLoading();
    this.setState({ comments: comments || [] });

    this.paginatedComments = new PaginatedData(
      'UserComment',
      {
        filter: { 'content._id': entityId, 'content._cls': entityCls },
        answersLimit: _.isInteger(answersToLoad)
          ? answersToLoad
          : ANSWERS_TO_LOAD,
        depDepth: 0,
        withAnswers: true,
        //withMyLikes : true, // TODO
        sortBy: { createTs: -1 },
      },
      {
        perPage: _.isInteger(commentsToLoadMore)
          ? commentsToLoadMore
          : COMMENTS_TO_LOAD,
        initialCount: commentsToLoad,
      },
      (data, count) => {
        this.setState({
          comments: data,
          //"commentsCount": count || data && data.length || 0, // wrong value (root comments only)
        });
      },
      true // load now
    );

    this.unsubWatch && this.unsubWatch();
    this.unsubWatch = ApiClient.watch('UserComment', () => {
      let entity = this.getSingleEntity();
      console.debug(
        'User comments changed, reload for entity',
        entity && entity._id
      );
      entity && this.load(entity);
    });
  };

  didMount = () => {
    let { foldable } = this.getOptions();
    const { user } = this.state;
    const { read } = this.getCommentsRights();

    this.setState({
      commentsDisplayed: !foldable && hasSufficientRole(user, read),
    });

    this.bindCount = this.getRebindToCollectionFunc('commentsCount');

    this.observeSingleEntity((entity) => {
      this.setState({ comments: null, commentsCount: 0, isOpen: false });

      if (entity) {
        if (!foldable) {
          console.debug('Load user comments for', entity._id);
          this.load(entity);
        }
        console.debug('Load user comments count only for', entity._id);
        this.bindCount('UserComment', {
          filter: { 'content._id': entity._id, 'content._cls': entity._cls },
          sortBy: { createTs: -1 },
          answersLimit: 0,
          depDepth: 0,
          countOnly: true,
        });
      }
    });
  };

  loadMore = (entity) => {
    this.paginatedComments && this.paginatedComments.loadMore(entity);
  };

  willUnmount = () => {
    this.paginatedComments && this.paginatedComments.stopLoading();
    this.unsubWatch && this.unsubWatch();
  };

  getCommentsRights = () => {
    const { siteContext } = this.props;
    return !!siteContext &&
      !!siteContext.site &&
      !!siteContext.site.commentsAccess
      ? siteContext.site.commentsAccess
      : DEFAULT_SUFFICIENT_ROLE.COMMENTS;
  };

  canWrite = () => {
    const { setLoginModal } = this.props;
    const { user } = this.state;
    const { write } = this.getCommentsRights();

    if (!hasSufficientRole(user, write)) {
      setLoginModal({
        titleEnd: 'rédiger un commentaire',
        neededRole: write,
      });
      return false;
    }

    return true;
  };

  canRead = () => {
    const { setLoginModal } = this.props;
    const { user } = this.state;
    const { read } = this.getCommentsRights();

    if (!hasSufficientRole(user, read)) {
      setLoginModal({
        titleEnd: 'voir les commentaires',
        neededRole: read,
      });
      return false;
    }

    return true;
  };
}

const DecoratedCommentList = withRecoilState(
  'loginModal',
  loginSubscriptionModalState
)(withContext(SiteContext, 'siteContext', CommentList));

export default DecoratedCommentList;
