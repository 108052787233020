/**
 *
 * This component obviously handle the video player
 * It is also constantly listening to currentTime and sending it to parent component (useful for annotations for example)
 *
 * @see https://docs.videojs.com/tutorial-react.html
 */

import _ from 'lodash';
import moment from 'moment';
import nookies from 'nookies';
import React from 'react';
import store from 'store';

import config from '@/utils/config';
import { getFeatureFlags, featureEnabled } from '@/helpers/models/instance';
import { GlobalPlaybackStore, GlobalPlaybackStoreActions } from '@/reflux';
import { addButton } from './videojsUtils';
import ApiClient from '@/utils/ApiClient';
import ComponentBase from '../../ComponentBase';
import { withSite } from '@/utils/HOC';

import { cloudflareLoader } from '@/components/new';

import 'video.js/dist/video-js.css';
import '@silvermine/videojs-chromecast/dist/silvermine-videojs-chromecast.css';
import videojs from 'video.js';
import 'videojs-hotkeys';
//import 'videojs-youtube'; // Puts tracking cookies :(
import './videojs-plugins/videojs-quality-selector'; // Auto-registers

if (typeof window !== 'undefined') {
  require('@silvermine/videojs-chromecast')(videojs, {
    preloadWebComponents: true,
  });
}

const getPosterUrl = (url, { site }) => {
  if (!url) return null;

  const { STATIC_ROOT_URL, STATIC_IMAGES_URL } = config;
  const cloudflareOptimizationEnabled = featureEnabled(
    'cloudflareImageOptimization',
    getFeatureFlags(site)
  );

  if (cloudflareOptimizationEnabled && STATIC_ROOT_URL && STATIC_IMAGES_URL) {
    return cloudflareLoader({ src: url.split('/upload')[1], width: 840 });
  }

  return url;
};

class VideoPlayer extends ComponentBase {
  constructor(props) {
    super(props);

    this.state = {
      currentTime: 0,
      playStatus: 'stopped',
    };

    this.errorCount = 0;
    this.videoNodeRef = React.createRef();

    this.store = GlobalPlaybackStore;
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  updateVideo2(curTime = 0) {
    console.debug('VID> updateVide2');
    this._cancelVideoRequest && this._cancelVideoRequest();

    if (this.props.video) {
      this._cancelVideoRequest = ApiClient.getObject(
        'Video',
        this.props.video._id,
        { videoData: true },
        (video) => {
          console.debug(
            'VID> updateVideo2: got new video, set src + start play'
          );
          this.setSource(video);
          this._videoJs.play();

          if (curTime) {
            console.debug('VID> VideoPlayer> continue at ', curTime);
            this._videoJs.currentTime(curTime);
          }
        }
      );
    } else {
      console.error('VID> VideoPlayer> no video object');
    }
  }

  didMount() {
    // autoplay now handled by videojs: https://blog.videojs.com/video-js-7-1-and-6-11-autoplay-and-fullscreen-changes/
    let { autoplay, isPriorityPlayback } = this.props;

    autoplay = autoplay && nookies.get({}).disableAutoplay !== 'true';

    if (!autoplay) {
      this.initVideoJs2(false);
    } else if (autoplay === 'withSound') {
      this.initVideoJs2('any');
    } else if (autoplay === 'muted') {
      this.initVideoJs2('muted');
    } else {
      this.initVideoJs2(autoplay);
    }

    if (isPriorityPlayback) {
      console.debug('VID> get priority playback mutex');
      GlobalPlaybackStoreActions.priorityPlay(true);
    }

    // Note: observeProp / bindPRops... are done in initVideoJs2
  }

  didUpdate(prevProps, prevState) {
    if (!this.props.isPriorityPlayback) {
      let { priorityPlaybackPlaying, playStatus } = this.state;

      // noinspection EqualityComparisonWithCoercionJS
      if (
        this._videoJs &&
        priorityPlaybackPlaying !== prevState.priorityPlaybackPlaying
      ) {
        console.debug(
          'VID> priority playback changed -> ',
          priorityPlaybackPlaying,
          this.props.video && this.props.video._id,
          playStatus,
          this._wasPlaying ? 'was playing' : 'was not...'
        );
        if (priorityPlaybackPlaying && playStatus === 'playing') {
          console.debug(
            'VID> Pause playback because of priority playback',
            this.props.video && this.props.video._id
          );
          this._videoJs.pause();
          this._wasPlaying = true;
        } else if (
          this._wasPlaying &&
          !priorityPlaybackPlaying &&
          playStatus !== 'playing'
        ) {
          console.debug(
            'VID> Resume playback after priority playback',
            this.props.video && this.props.video._id
          );
          this._videoJs.play();
          this._wasPlaying = false;
        }
      }
    }
  }

  setPlayStatus(status) {
    this.setState({ playStatus: status });
    this.props.onPlayStatus && this.props.onPlayStatus(status);
  }

  initVideoJs(initAutoplay, muteAutoplay) {
    if (!initAutoplay) {
      this.initVideoJs2(false);
    } else {
      if (muteAutoplay) {
        this.initVideoJs2('muted');
      } else {
        this.initVideoJs2('play');
      }
    }
  }

  /**
   * @param autoplaySetting "muted" / "play" / "any" / true / false
   */
  initVideoJs2(autoplaySetting) {
    const { poster, site } = this.props;

    if (!this.videoNodeRef.current) return;

    // Should be fixed with later versions of videojs
    // https://github.com/videojs/videojs-contrib-hls/issues/1011
    if (
      navigator &&
      navigator.userAgent &&
      /Trident\/7.0/i.test(navigator.userAgent) &&
      /rv:11.0/.test(navigator.userAgent) &&
      videojs.browser &&
      videojs.browser.IE_VERSION === null
    ) {
      console.debug('VID> Manually detected IE11');
      videojs.browser.IE_VERSION = 11;
    }

    var options = {
      autoplay: autoplaySetting,
      preload: 'auto',
      loop: this.props.loop,
      controls: true,
      playbackRates: [1, 1.25, 1.5, 1.75, 2],
      //controls  : this.props.controls !== false,
      //inactivityTimeout: 0, // to keep control bar visible
      poster: getPosterUrl(poster, { site }),
      //muted     : muteAutoplay || this.props.muted,
      muteToggle: true, //muteAutoplay, // big unmute button

      html5: {
        nativeVideoTracks: false,
        hls: {
          overrideNative: true, // to make HLS work everywhere
        },
      },

      techOrder: ['chromecast', 'html5'],

      fluid: true,
      //responsive: true, // permet d'avoir des classes reflétant la taille réelle du player au lieu de media-queries
    };

    if (this.props.controls === false) {
      options.controlBar = false;
      options.bigPlayButton = false;
      options.plugins = {
        chromecast: {},
      };
    } else {
      options.plugins = {
        hotkeys: {
          // https://github.com/ctd1500/videojs-hotkeys#options
          enableHoverScroll: true, // Only changes volume when the mouse is hovering over the volume control elements
        },
        chromecast: {},
      };

      options.controlBar = {
        volumePanel: {
          inline: false,
        },
        pictureInPictureToggle: true,
      };
    }

    let videoNode = this.videoNodeRef.current;
    this._videoJs = videojs(videoNode, options, () => {
      if (this.props.onNext) {
        // TODO
        addButton(
          videojs,
          this._videoJs,
          'vjs-next-button',
          'ProgressControl',
          this.props.onNext
        );
      }

      console.debug('VID> VideoJS ready');

      this.observeProp('video._id', () => {
        console.debug('VID> changed video, reset time');
        this._videoJs.currentTime(0);
      });

      this.bindPropToObject(
        'Video',
        'video._id',
        { videoData: true },
        (video) => {
          console.debug('VID> changed video or update, reloaded', video);
          video && this.setSource(video);
        }
      );

      this.observeProp('poster', () => {
        const { poster, site } = this.props;
        this._videoJs.poster(getPosterUrl(poster, { site }));
      });

      this._videoJs.on('error', (e) => {
        let currentVideo = this.props.video;
        if (
          moment().unix() >=
          currentVideo.tokenTs + currentVideo.tokenValidity - 1
        ) {
          // TODO Handle wrong local time
          // TODO not based on content ID ?
          console.debug('VID> Video token likely expired, update...', e);
          this.updateVideo2(this._videoJs.currentTime());
        } else {
          console.debug('VID> Video error', e);
          this.errorCount++;
          if (this.errorCount <= 3) {
            // was disbled - reenable it to work with API load-balancer.
            this.updateVideo2(this._videoJs.currentTime());
          }
        }
      });

      this._videoJs.tech().on('retryplaylist', (e) => {
        console.debug(
          'VID> retryplaylist (this most probably means network error)',
          e
        );
        this.errorCount++;
        if (this.errorCount <= 3) {
          // was disbled - reenable it to work with API load-balancer.
          this.updateVideo2(this._videoJs.currentTime());
        }
      });
      //this._videoJs.tech().on('usage', (e) => console.debug("VID> usage", e)); // works but fires a lot more

      var previousPlayingTime = null;

      this._videoJs.on('contextmenu', (e) => {
        e.preventDefault();
      });

      this._videoJs.on('timeupdate', (e) => {
        let t = this._videoJs.currentTime(),
          playingTime = 0;
        if (t >= 0 && t < this._videoJs.duration() + 1) {
          if (this._videoJs.paused()) {
            previousPlayingTime = null;
          } else {
            if (previousPlayingTime !== null)
              playingTime = t - previousPlayingTime;
            previousPlayingTime = t;
          }

          if (playingTime >= 0 && playingTime < 100000) {
            this.props.onPlayerTime &&
              this.props.onPlayerTime(
                t,
                this._videoJs.duration(),
                playingTime,
                this._sourceMode
              );
          } else
            console.warn(
              'VID> Error playing time:',
              previousPlayingTime,
              t,
              '=>',
              playingTime
            );
        } else
          console.warn(
            'VID> Error current time:',
            t,
            'dur:',
            this._videoJs.duration()
          );
      });

      this._videoJs.on('seeking', (e) => {
        previousPlayingTime = null;
      });

      this._videoJs.on('seeked', (e) => {
        previousPlayingTime = null;
      });

      this._videoJs.on('fullscreenchange', (e) => {
        this.props.onFullscreen &&
          this.props.onFullscreen(this._videoJs.isFullscreen());
      });

      this._videoJs.on('ended', (e) => {
        this.setPlayStatus('ended');
        this.props.onEnd && this.props.onEnd();
        previousPlayingTime = null;
        this._wasPlaying = false;
        this._videoJs.exitFullscreen();
      });

      this._videoJs.on('play', (e) => {
        this.setPlayStatus('playing');
        this.props.onPlay && this.props.onPlay(true);
      });

      this._videoJs.on('pause', (e) => {
        this.setPlayStatus('paused');
        this.props.onPlay && this.props.onPlay(false);
        // TODO only if user clicks this._wasPlaying    = false;
      });

      this._videoJs.on('loadedmetadata', (e) => {
        // let currentVideo = this.props.video; // because here "video" variable is the first one not the current one!
        // if ((!currentVideo.full || !currentVideo.full.hls) && currentVideo.duration) {
        //   disabled: never worked very well, works VERY bad on last versions
        //   console.debug("VID> patch duration", currentVideo.duration);
        //   this._videoJs.duration(currentVideo.duration);
        // }

        if (autoplaySetting !== false && this.props.autoplay) {
          console.debug(
            'VID> on load meta - start play',
            this._videoJs.paused()
          );
          this._videoJs.play();
        }

        // useless here? TODO test every platform
        if (this.props.startTime) {
          console.debug(
            'VID> loaded metadata, set video start time',
            this.props.startTime
          );
          this._videoJs.currentTime(this.props.startTime);
        }
      });

      // remember volume and share it amongst players
      this._videoJs.on('volumechange', (e) => {
        store.enabled && store.set('persistedVolume', this._videoJs.volume());
      });

      this._videoJs.on('touchstart', (e) => {
        this._thisIsATapEvent = true;
      });

      this._videoJs.on('touchmove', (e) => {
        this._thisIsATapEvent = false;
      });

      this._videoJs.on(['click', 'tap', 'touchend'], (e) => {
        // click: ok
        // tap: does not work
        if (e.type === 'tap') alert('tap');

        // touchend: must ignore scrolling
        if (e.type === 'touchend' && !this._thisIsATapEvent) return;
        this._thisIsATapEvent = false;

        if (
          this.props.onVideoClick &&
          e.target.tagName.toUpperCase() === 'VIDEO'
        ) {
          e.preventDefault(); // does not prevent pause (happens before...)
          //e.stopPropagation();  // does not prevent pause
          this.props.onVideoClick();
        }
      });

      if (store.enabled && store.get('persistedVolume') !== null) {
        this._videoJs.volume(store.get('persistedVolume'));
      }

      if (this.props.startTime) {
        console.debug('VID> set video start time', this.props.startTime);
        this._videoJs.currentTime(this.props.startTime);
      }
    });

    window.VJS = this._videoJs;

    console.debug('VID> mounted player');
  }

  setSource(video) {
    let streams;
    this._sourceMode = null;

    if (video.full && (video.full.hls || video.full.progressive)) {
      console.debug('VID> Play FULL movie');
      streams = video.full;
      this._sourceMode = 'full';
    } else if (video.trailer) {
      console.debug('VID> Play trailer');
      streams = video.trailer;
      this._sourceMode = 'trailer';
    } else if (video.type === 'youtube') {
      console.error('VID> youtube not handled');
      /*this._videoJs.options({ techOrder: ['youtube'] });
      this._videoJs.src([{ type: 'video/youtube', src: video.youtubeUrl }]);
      console.debug(video.youtubeUrl);
      this._videoJs.width('100%');
      this._videoJs.height('100%');
      this._sourceMode = 'youtube';*/
      return;
    }

    if (streams) {
      let sources = _.concat(
        (streams.hls &&
          this.errorCount <= 2 && [
            {
              key: 'auto',
              name: 'Auto',
              src: streams.hls,
              type: 'application/x-mpegURL',
            },
          ]) ||
          [],
        _.sortBy(
          _.map(streams.progressive || {}, (stream, id) => ({
            key: id,
            name: id + 'p',
            src: stream,
            type: 'video/mp4',
          })),
          (s) => -1 * s.key
        )
      );

      // stored quality or auto
      let defaultSource = store.get('defaultQuality') || 'auto';
      // check if quality available
      if (!_.find(sources, { key: defaultSource })) {
        defaultSource = 480; // not too high, for mobile connections
      }
      if (!_.find(sources, { key: defaultSource }))
        defaultSource = sources[0] && sources[0].key;

      let qualitySelectorOptions = {
        text: 'HD',
        sources: sources,
        defaultSource: defaultSource,
        autoplay:
          nookies.get({}).disableAutoplay !== 'true' && !!this.props.autoplay,
        onFormatSelected: function (sourceKey) {
          store.set('defaultQuality', sourceKey);
        },
      };

      console.debug('VID> update sources:', qualitySelectorOptions);

      this._videoJs.qualityselector(qualitySelectorOptions);
      ///////this._videoJs.chromecast();
    } else {
      console.error('VID> No video streams in Video object!');
    }
  }

  willUnmount() {
    console.debug('VID> destroy videojs');

    this.cancelRequest && this.cancelRequest();
    this._cancelVideoRequest && this._cancelVideoRequest();

    this._videoJs && this._videoJs.dispose();

    // then apparently the fullscreen event fires too late and has and throws an error, so:
    this.props.onFullscreen && this.props.onFullscreen(false);

    if (this.props.isPriorityPlayback) {
      console.debug('VID> release priority playback mutex');
      GlobalPlaybackStoreActions.priorityPlay(false);
    }
  }

  closeHighlightedPlayer() {
    this.props.closeHighlightedPlayer && this.props.closeHighlightedPlayer();
  }

  seekTo(t) {
    // public function
    if (this._videoJs) this._videoJs.currentTime(t);
  }

  render() {
    // TODO video-wrapper vs videoWrapper classes RETROCOMPATIBILIY!!!

    return (
      <div className="video-wrapper videoWrapper">
        <div className="video-inner videoWrapper-inner">
          <div
            className="video-content videoWrapper-content"
            ref="videoPlayerContainer"
            data-vjs-player
          >
            <span
              className={'ContentVideo-close-player'}
              onClick={() => this.closeHighlightedPlayer()}
            >
              ✖
            </span>

            <video
              playsInline={this.props.inlineOnIphone || false}
              ref={this.videoNodeRef}
              className="video-js"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withSite(VideoPlayer);
