import React from 'react';
import moment from 'moment';
import classNames from 'classnames';

import { InstanceStore } from '@/reflux';
import API from '@/utils/API';
import { SiteContext } from '@/globals/contexts';
import { withContext } from '@/utils/HOC';

import ComponentBase from '../../../../../ComponentBase';
import NewComment from '../NewComment';
import { ReportButton } from '@/PUFComponents/components';
class RawComment extends ComponentBase {
  static baseClassName = 'RawComment';
  stores = [InstanceStore];

  state = {
    answering: false,
  };

  // type = 'like' | 'unlike';
  handleLike = (type) => {
    const { comment, user } = this.props;
    const { instanceName, likes } = this.state;

    const likedByUser = likes && likes.userLikes;
    const newLikeValue =
      type === 'like'
        ? likedByUser === 1
          ? 0
          : 1
        : likedByUser === -1
        ? 0
        : -1;

    if (user) {
      API.post(
        `${instanceName}/like/${comment._cls}/${encodeURIComponent(
          comment._id
        )}`,
        { value: newLikeValue }
      ).then((likes) => this.setState({ likes }));
    }
  };

  render() {
    const {
      comment,
      user,
      level,
      maxCommentLength,
      canWrite,
      additionalClassName,
    } = this.props;
    const { answering, posted, newStatus, likes } = this.state;

    const prepub = (newStatus || comment.status) === 'prepub';
    const likedByUser = likes && likes.userLikes;
    const likeCount = !!likes ? likes.likeCount : 0;
    const unlikeCount = !!likes ? likes.unlikeCount : 0;

    const componentBaseClass = this.constructor.baseClassName;
    const thumbsUp = (
      <svg viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M104 224H24c-13.255 0-24 10.745-24 24v240c0 13.255 10.745 24 24 24h80c13.255 0 24-10.745 24-24V248c0-13.255-10.745-24-24-24zM64 472c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zM384 81.452c0 42.416-25.97 66.208-33.277 94.548h101.723c33.397 0 59.397 27.746 59.553 58.098.084 17.938-7.546 37.249-19.439 49.197l-.11.11c9.836 23.337 8.237 56.037-9.308 79.469 8.681 25.895-.069 57.704-16.382 74.757 4.298 17.598 2.244 32.575-6.148 44.632C440.202 511.587 389.616 512 346.839 512l-2.845-.001c-48.287-.017-87.806-17.598-119.56-31.725-15.957-7.099-36.821-15.887-52.651-16.178-6.54-.12-11.783-5.457-11.783-11.998v-213.77c0-3.2 1.282-6.271 3.558-8.521 39.614-39.144 56.648-80.587 89.117-113.111 14.804-14.832 20.188-37.236 25.393-58.902C282.515 39.293 291.817 0 312 0c24 0 72 8 72 81.452z"
        ></path>
      </svg>
    );

    const { reportableContents } = this.props.siteContext.site;
    const isReportable = reportableContents?.includes('UserComment');

    return (
      <div
        className={classNames(componentBaseClass, additionalClassName, {
          [`${componentBaseClass}--prepub`]: prepub,
        })}
      >
        <div
          className={classNames(
            `${componentBaseClass}-inner`,
            `${componentBaseClass}-inner--level-${level}`
          )}
        >
          <div className={`${componentBaseClass}-header`}>
            <div>
              <strong className={`${componentBaseClass}-author`}>
                {comment.authorName}
              </strong>
              <span className={`${componentBaseClass}-date`}>
                {moment.unix(comment.createTs).fromNow()}
              </span>

              {prepub && (
                <span className="error">[EN ATTENTE DE MODÉRATION]</span>
              )}
            </div>

            {isReportable && (
              <ReportButton
                contentId={comment._id}
                contentType={comment._cls}
                contentText={comment.text}
              />
            )}
          </div>

          {comment.text && (
            <div className={`${componentBaseClass}-desc`}>{comment.text}</div>
          )}

          {answering && (
            <NewComment
              content={comment.content}
              parentComment={comment}
              onAfterPost={() =>
                this.setState({ answering: false, posted: true })
              }
              onClose={() => this.setState({ answering: false })}
              maxCommentLength={maxCommentLength}
            />
          )}

          {posted && (
            <div className={`${componentBaseClass}-new-comment-posted`}>
              Votre réponse a bien été envoyée ! Elle sera validée par les
              modérateurs.
            </div>
          )}

          {!!user && !answering && (
            <div className={`${componentBaseClass}-footer`}>
              {!!likes && (
                <span className={`${componentBaseClass}-votes`}>
                  <span
                    className={classNames(`${componentBaseClass}-like`, {
                      [`${componentBaseClass}-like--liked`]: likedByUser === 1,
                    })}
                  >
                    <button
                      className={`${componentBaseClass}-like-button`}
                      onClick={() => this.handleLike('like')}
                    >
                      {thumbsUp}

                      <span>{likeCount || 0}</span>
                    </button>
                  </span>

                  <span
                    className={classNames(`${componentBaseClass}-unlike`, {
                      [`${componentBaseClass}-unlike--unliked`]:
                        likedByUser === -1,
                    })}
                  >
                    <button
                      className={`${componentBaseClass}-unlike-button`}
                      onClick={() => this.handleLike('dislike')}
                    >
                      {thumbsUp}

                      <span>{unlikeCount || 0}</span>
                    </button>
                  </span>
                </span>
              )}

              <span className={`${componentBaseClass}-answer`}>
                <button
                  className={`${componentBaseClass}-answer-button`}
                  onClick={() => {
                    if (canWrite()) {
                      this.setState({ answering: true });
                    }
                  }}
                >
                  Répondre
                </button>
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  didMount() {
    let bindLikes = this.getRebindToObjectFunc('likes');
    this.observeProp(
      'comment._id',
      (commentId, old) => {
        bindLikes('likes_' + this.props.comment._cls, commentId);
      },
      false
    );
  }
}

export default withContext(SiteContext, 'siteContext', RawComment);
