// Component used to display the published date of a component

import moment from 'moment';
import React from 'react';

import FrontComponentBase from '../../FrontComponentBase';

class EntityDate extends FrontComponentBase {
  static baseClassName = 'EntityDate';

  render() {
    let entity =
      this.props.content || // retrocompat TODO remove
      this.props.entity ||
      this.getSingleEntity();
    const { options } = this.props;
    const { format } = options;

    if (!entity) return null;

    let { eventTs, publishTs } = entity;

    let baseClassName = this.constructor.baseClassName;
    // add content type to the component main class
    let componentMainClass = baseClassName;

    const date = moment.unix(eventTs || publishTs);

    const displayedDate =
      format === 'from-now'
        ? date.fromNow()
        : `Le ${date.format('Do MMMM YYYY')}`;

    return <div className={componentMainClass}>{displayedDate}</div>;
  }
}

export default EntityDate;
