// Component used to display news thumbs

import React from 'react';

import { getApiUrl } from '@/utils/config';

import FrontComponentBase from '../../../FrontComponentBase';
import EntityLink from '../../navigation/EntityLink';

class NewsThumb extends FrontComponentBase {
  static baseClassName = 'NewsThumb';

  /*
        TODO :
        - format date and add content type label
        - add navigation
    */

  render() {
    let content = this.props.content || this.getSingleEntity();

    if (!content) return null;

    let { shortDesc, image, label } = content;

    let baseClassName = this.constructor.baseClassName;

    image = getApiUrl() + image;

    return (
      <EntityLink entity={content}>
        <a className={baseClassName}>
          <div className={baseClassName + '-top'}>
            {image && (
              <div className={baseClassName + '-image'}>
                <div className="portrait-wrapper">
                  <div className="portrait-inner">
                    <img src={image} alt={label} />
                  </div>
                </div>
              </div>
            )}

            {label && (
              <div className={baseClassName + '-infos'}>
                <div className={baseClassName + '-name'}>{label}</div>

                <div className={baseClassName + '-tag'}>TODO main tag</div>
              </div>
            )}
          </div>

          <div className={baseClassName + '-bottom'}>
            {shortDesc && (
              <div className={baseClassName + '-desc'}>{shortDesc}</div>
            )}

            <div className={baseClassName + '-footer'}>
              <span className={baseClassName + '-read-more'}>En savoir +</span>
            </div>
          </div>
        </a>
      </EntityLink>
    );
  }
}

export default NewsThumb;
