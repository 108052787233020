import _ from 'lodash';
import React from 'react';

import { CartStore } from '@/reflux';
import { withLinks } from '@/utils/HOC';

import FrontComponentBase from '../../FrontComponentBase';
import PageLink from '../navigation/PageLinkV1';

class CartButton extends FrontComponentBase {
  static baseClassName = 'CartButton';

  store = CartStore;

  render() {
    const {
      links: { cart: cartLink },
    } = this.props;
    let { cartItems } = this.state;
    const { label, pageId, displayIfEmpty } = this.getOptions();
    const baseClassName = this.constructor.baseClassName;

    const number = _.size(cartItems);

    const CartPageLink = ({ children }) =>
      !!pageId ? (
        <PageLink pageId={pageId}>{children}</PageLink>
      ) : (
        <PageLink link={cartLink}>{children}</PageLink>
      );

    return displayIfEmpty || number > 0 ? (
      <div
        className={this.getMainCSSClass({ active: number > 0 })}
        key="_cartButton"
      >
        <span>
          {/* workaround for NextJS bug (Nlink ?) wrong className (mixup of classNames with following ssibling) */}
          <CartPageLink>
            <a className={baseClassName + '-inner'}>
              <span className={baseClassName + '-label'}>
                {label || 'Panier'}
              </span>
              {number > 0 && (
                <span className={baseClassName + '-quantity'}>{number}</span>
              )}
            </a>
          </CartPageLink>
        </span>
      </div>
    ) : null;
  }
}

export default withLinks(CartButton);
