import moment from 'moment';
import nookies from 'nookies';

import ApiClient from '@/utils/ApiClient';

export default class PlayStatsMgr {
  playStats = {};

  onPlayTime(
    currentTime,
    playingTime,
    content,
    user,
    playlistRank,
    playerMode
  ) {
    let now = moment().unix();
    let stats = this.playStats;

    let mode = playlistRank > 0 ? 'pl_' + playerMode : playerMode; // full / trailer / pl_full / pl_trailer

    // New diff on content change, or mode change which should not happen here
    if (
      content._id !== stats.entityId ||
      content._cls !== stats.entityType ||
      mode !== stats.mode
    ) {
      console.debug('Init new play stats for ' + content._id);
      // send previous playstats
      if (stats.lastUpdateTime && stats.lastUpdateTime > stats.lastSendTime) {
        this._sendStats(now);
      }

      let visitorId = null;
      if (!user) {
        visitorId = nookies.get({}).visitor; // TODO use CookieContext
      }

      // noinspection AssignmentResultUsedJS
      stats = this.playStats = {
        entityId: content._id,
        entityType: content._cls,
        videoId: content.video._id,
        userId: user && user._id,
        visitorId: visitorId,
        diffusionTs: now,
        mode: mode,
        minutes: {},
        totalTime: 0,
        diffTime: 0,
        currentTime: 0,
        lastUpdateTime: now,
        lastSendTime: now,
        playlistRank: playlistRank || null,
      };
    }

    if (playingTime) {
      // if is playing
      stats.totalTime += playingTime;
      stats.diffTime += playingTime;
      let minute = ~~(currentTime / 60);
      stats.minutes[minute] = (stats.minutes[minute] || 0) + playingTime;
      if (currentTime > 0) stats.currentTime = currentTime;

      if (now - stats.lastSendTime > 60) {
        this._sendStats(now);
      }
    }

    // just in case
    if (user) stats.userId = user._id;

    stats.lastUpdateTime = now;
  }

  onEnd(content) {
    if (content._id === this.playStats.entityId) {
      let now = moment().unix();
      this.playStats.currentTime = content.video.duration;
      this.playStats.lastUpdateTime = now;
      this._sendStats(now);
    }
  }

  onClose() {
    if (
      this.playStats.lastUpdateTime &&
      this.playStats.lastUpdateTime > this.playStats.lastSendTime
    ) {
      this._sendStats(this.playStats.lastUpdateTime);
    }
  }

  _sendStats(sendTime) {
    let now = moment().unix();
    this.playStats.localTs = now;
    ApiClient.post('playStats', null, this.playStats);
    this.playStats.lastSendTime = sendTime;
    this.playStats.minutes = {};
    this.playStats.diffTime = 0;
  }

  get(stat) {
    return this.playStats[stat];
  }
}
