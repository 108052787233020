export const shouldDisplay = (displayConditions, { useragent, user }) => {
  if (!displayConditions || displayConditions.length === 0) return true;

  return displayConditions.some((displayCondition) => {
    let display = true;

    if (displayCondition.visitorType === 'anonymous') display = !user;
    else if (displayCondition.visitorType === 'registered') display = !!user;
    else if (displayCondition.visitorType === 'freemium')
      display = user && !user.isPremium;
    else if (displayCondition.visitorType === 'premium')
      display = user && user.isPremium;
    else if (displayCondition.visitorType === 'not_premium')
      display = !user || !user.isPremium;

    if (displayCondition.deviceType && displayCondition.deviceType !== 'all') {
      display &=
        (displayCondition.deviceType === 'phone' && useragent.isMobile) ||
        (displayCondition.deviceType === 'desktop' && !useragent.isMobile);
    }

    return display;
  });
};
