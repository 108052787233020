import * as React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import API from '@/utils/API';
import { prettyPrice } from '@/helpers/format';
import { useInstance } from '@/hooks';
import { makeComponentSubClass } from '@/helpers/style';

import { Countdown } from '@/PUFComponents/base/components';
import { RichTextContent } from '@/components/old';
import { Button } from '@/components/new';
import StripeProvider from '@/pageComponents/contexts/StripeProvider';
import ParticipationModal from './ParticipationModal';
import History from './History';

export const getServerSideProps = ({ id, instanceId }) =>
  API.get(`${instanceId}/CrowdFundingCampaigns/${id}/progress`);

const EMPTY_PROGRESS = {
  count: 0,
  total: 0,
};

const makeClass = (className) =>
  makeComponentSubClass(CrowdFundingCampaign.baseClassName, className);

export default function CrowdFundingCampaign({
  mainClassName,
  options,
  data = [],
}) {
  const [campaign] = data;
  const [instanceId] = useInstance();
  const [participationModal, toggleParticipationModal] = React.useState(false);
  const {
    callToAction,
    title,
    description,
    thanksText,
    fakeCountToAdd,
    fakeTotalToAdd,
    displayTotal,
    displayProgress,
    displayCount,
    displayDeadline,
    displayHistory,
  } = options;
  const [{ count: realCount, total: realTotal }, setProgress] =
    React.useState(EMPTY_PROGRESS);
  const [donation, setDonation] = React.useState(null);

  const { target, deadline } = campaign;
  const total = realTotal + fakeTotalToAdd;
  const count = realCount + fakeCountToAdd;

  const fetchCampaignProgress = React.useCallback(async () => {
    const fetchedProgress = await getServerSideProps({
      instanceId,
      id: campaign._id,
    });
    setProgress(fetchedProgress[0] || EMPTY_PROGRESS);
  }, [campaign._id, instanceId]);

  React.useEffect(() => {
    fetchCampaignProgress();
  }, [fetchCampaignProgress]);

  const onDonationSuccess = React.useCallback(
    async (donation) => {
      await fetchCampaignProgress();

      setDonation(donation);
    },
    [fetchCampaignProgress]
  );

  const targetProgress = total / target;
  const width =
    Math.max(0, Math.min(100, targetProgress * 100)).toFixed(5) + '%';
  const percent = Math.floor(targetProgress * 100);

  return (
    <StripeProvider>
      <div className={mainClassName}>
        <div className="campaign">
          <h2 className={makeClass('title')}>
            {title || `Crowdfunding de la campagne ${campaign.label}`}
          </h2>
          {displayDeadline && (
            <h4 className={makeClass('subtitle')}>
              Objectif {prettyPrice(target)} avant le{' '}
              {moment.unix(deadline).format('LL')}
            </h4>
          )}

          {description && (
            <div className={makeClass('description')}>
              <RichTextContent html={description} />
            </div>
          )}

          <div className={makeClass('progress')}>
            <div className={makeClass('progress-title')}>
              {displayCount && (
                <h3>
                  Déjà <span className="value">{count}</span> contributeurs !
                </h3>
              )}
              {displayTotal && (
                <h4>
                  Pour un total de{' '}
                  <span className="value">{prettyPrice(total)}</span>
                </h4>
              )}
            </div>

            {displayProgress && (
              <div className={makeClass('progress-graphical')}>
                <div
                  className={classnames(makeClass('progress-graphical-bar'), {
                    reached: percent >= 100,
                  })}
                >
                  <div
                    className={makeClass('progress-graphical-bar-inside')}
                    style={{ width }}
                  />
                </div>

                <span className={makeClass('progress-graphical-bar-percent')}>
                  <span className="amount">{percent}</span>
                  <span className="symbol">%</span>
                </span>
              </div>
            )}
          </div>

          {displayDeadline && <Countdown deadline={deadline} />}

          <div className={makeClass('conclusion-message')}>
            {callToAction || 'Nous avons besoin de vous'}
          </div>

          {!!donation ? (
            <p className={makeClass('success-message')}>
              Votre don de {prettyPrice(donation.amount)} a bien été enregistré.
              <br />
              {thanksText || "Merci d'avoir aidé à financer cette campagne."}
            </p>
          ) : (
            <>
              <div className={makeClass('action')}>
                <Button
                  onClick={() => toggleParticipationModal(true)}
                  size="large"
                >
                  Je participe
                </Button>
              </div>

              {participationModal && (
                <ParticipationModal
                  title={title}
                  campaign={campaign}
                  onClose={() => toggleParticipationModal(false)}
                  onSuccess={onDonationSuccess}
                  options={options}
                />
              )}
            </>
          )}
        </div>

        <div className="decoration-right" />
        <div className="decoration-left" />
        <div className="decoration-top" />
        <div className="decoration-bottom" />

        {displayHistory && <History campaign={campaign} />}
      </div>
    </StripeProvider>
  );
}

CrowdFundingCampaign.baseClassName = 'crowdfunding-campaign';
