// @flow

import * as React from 'react';

import type { RawComponentId } from '@/types/component';

import { COMPONENT_TYPE } from './componentMap';

import PUFComponent from './';

type ComponentProps = any;

type Props = {|
  id: RawComponentId,
  mode?: string,
  innerRef?: any,
  options?: Object,
  componentProps?: ComponentProps,
|};

export default function RawComponent({
  id,
  mode,
  options,
  innerRef,
  componentProps = {},
}: Props): React.Node {
  return (
    <PUFComponent
      id={id}
      type={COMPONENT_TYPE.RAW}
      mode={mode}
      options={options}
      componentProps={componentProps}
      innerRef={innerRef}
    />
  );
}
