// Component used to display share buttons

import _ from 'lodash';
import React from 'react';

import { PageContext, SiteContext } from '@/globals/contexts';
import { getFirst } from '@/utils/misc';
import { getLinkToEntity } from '../../navigation/EntityLink';

import FrontComponentBase from '../../../FrontComponentBase';
import {
  TwitterIcon,
  FacebookIcon,
  PinterestIcon,
  LinkedInIcon,
} from '@/components/new/icons';

class SharingButtonsV1 extends FrontComponentBase {
  static baseClassName = 'Sharing';

  getLinkEncoded(pageContext, siteContext) {
    let entity = this.getSingleEntity();
    if (entity) {
      return encodeURIComponent(
        pageContext.baseUrl + getLinkToEntity(entity).href
      );
    } else {
      return encodeURIComponent(pageContext.fullUrl);
    }
  }

  getTitleEncoded(pageContext, siteContext) {
    let entity = this.getSingleEntity();
    if (entity) {
      return encodeURIComponent(
        entity.label || _.get(siteContext, 'siteConfig.options.siteTitle', '')
      );
    } else {
      return encodeURIComponent(_.get(pageContext, 'label'));
    }
  }

  render() {
    const entity = this.getSingleEntity();
    const options = this.getOptions();

    let baseClassName = this.constructor.baseClassName;
    // add content type to the component main class
    let mainContentType = entity && getFirst(entity.contentType);
    let componentMainClass =
      baseClassName +
      (mainContentType ? ' ' + baseClassName + '--' + mainContentType : '');

    // TODO plug "related" query var in twitter sharing link with twitter account in the site options

    return (
      <SiteContext.Consumer>
        {(siteContext) => (
          <PageContext.Consumer>
            {(pageContext) => (
              <div className={componentMainClass}>
                {options.twitter !== false && (
                  <a
                    href={
                      'https://twitter.com/share?url=' +
                      this.getLinkEncoded(pageContext, siteContext) +
                      '&text=' +
                      this.getTitleEncoded(
                        pageContext,
                        siteContext
                      ) /*+ '&via='*/
                    } // TODO
                    target="_blank"
                    rel="noopener noreferrer"
                    className={baseClassName + '-twitter'}
                    title="Tweeter ce contenu"
                  >
                    <TwitterIcon />
                    <span className={baseClassName + '-text'}>Tweeter</span>
                  </a>
                )}

                {options.facebook !== false && (
                  <a
                    href={
                      'https://www.facebook.com/sharer.php?u=' +
                      this.getLinkEncoded(pageContext, siteContext) +
                      '&t=' +
                      this.getTitleEncoded(pageContext, siteContext)
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={baseClassName + '-facebook'}
                    title="Partager ce contenu"
                  >
                    <FacebookIcon />
                    <span className={baseClassName + '-text'}>Partager</span>
                  </a>
                )}

                {options.linkedin === true && (
                  <a // TODO use https://www.linkedin.com/sharing/share-offsite/?url=... now ?
                    href={
                      'https://www.linkedin.com/shareArticle?mini=true&url=' +
                      this.getLinkEncoded(pageContext, siteContext) +
                      '&title=' +
                      this.getTitleEncoded(pageContext, siteContext)
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={baseClassName + '-linkedin'}
                    title="Partager ce contenu"
                  >
                    <LinkedInIcon />
                    <span className={baseClassName + '-text'}>Partager</span>
                  </a>
                )}

                {options.pinterest === true && (
                  <a
                    href={
                      'http://pinterest.com/pin/create/button/?url=' +
                      this.getLinkEncoded(pageContext, siteContext) +
                      '&description=' +
                      this.getTitleEncoded(pageContext, siteContext)
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={baseClassName + '-pinterest'}
                    title="Partager ce contenu"
                  >
                    <PinterestIcon />
                    <span className={baseClassName + '-text'}>Partager</span>
                  </a>
                )}
              </div>
            )}
          </PageContext.Consumer>
        )}
      </SiteContext.Consumer>
    );
  }
}

export default SharingButtonsV1;
