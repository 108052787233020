/**
 
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import _ from 'lodash';
import moment from 'moment';
import React from 'react';

import { getFirst } from '@/utils/misc';

import EntityLink from '../../../base/navigation/EntityLink';
import FrontComponentBase from '../../../FrontComponentBase';
import RawComponent from '../../../RawComponent';
import VideoPoster from '../../../template1/medias/VideoPosterV1';

// TODO use everywhere
function getContentDateToDisplay(content, format = 'DD/MM/YYYY') {
  let { eventTs, publishTs, eventDateText } = content;
  return (
    eventDateText ||
    ((eventTs || publishTs) &&
      moment.unix(eventTs || publishTs).format('DD/MM/YYYY')) ||
    ''
  );
}

export default class ContentThumbFrontpop extends FrontComponentBase {
  static baseClassName = 'ContentThumb';

  render() {
    let content = this.props.content || this.getSingleEntity();

    if (this.state.draftMode && !content) {
      // "no display" condition
      return (
        <div className="draft-placeholder">{this.props.component.label}</div>
      );
    }

    let { contentType, mainTags, authors, video } = content; // TODO eventDateText sur ts composants

    let componentBaseClass = this.constructor.baseClassName;
    let mainContentType = getFirst(contentType);
    let mainTag = getFirst(mainTags);
    let isPremium = video ? !video.isFree : !content.isTextFree;

    let displayedDate = getContentDateToDisplay(content);

    return (
      <div
        className={this.getMainCSSClass({
          [componentBaseClass + '--' + mainContentType]: mainContentType,
          [componentBaseClass + '--with-author']: !_.isEmpty(authors),
        })}
      >
        <EntityLink entity={content}>
          <a
            className={componentBaseClass + '-link'}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            ref={this.zoomableRef}
          >
            <div className={componentBaseClass + '-header'}>
              <div className={componentBaseClass + '-content-type'}>
                {mainContentType}
              </div>
              {mainTag && (
                <div className={componentBaseClass + '-tag'}>
                  {mainTag.label || mainTag.text}
                </div>
              )}
              {isPremium && (
                <div className={componentBaseClass + '-premium'}></div>
              )}
            </div>

            <RawComponent
              id="EntityTitle"
              componentProps={{ entity: content }}
            />

            <div className={componentBaseClass + '-author-wrapper'}>
              <RawComponent
                id="EntityAuthors"
                componentProps={{ entity: content }}
              />
            </div>

            <div className={componentBaseClass + '-poster'}>
              <VideoPoster
                content={content}
                wrappedByLink={true}
                size={this.props.size}
              />
            </div>

            <div className={componentBaseClass + '-infos'}>
              <RawComponent
                id="EntityDesc"
                componentProps={{ entity: content }}
              />
            </div>

            <div className={componentBaseClass + '-footer'}>
              {displayedDate && (
                <div className={componentBaseClass + '-date'}>
                  {displayedDate}
                </div>
              )}
              <RawComponent
                id="CommentsCount"
                componentProps={{ entity: content }}
              />
            </div>
          </a>
        </EntityLink>
      </div>
    );
  }
}
