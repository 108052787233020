// Component used to display the content description

import React from 'react';

import { useContent } from '@/hooks';

import Link from '@/PUFComponents/base/navigation/Link';

const baseClassName = 'PUF-entity-ediis-cart-link';

export default function EntityEdiisCartLink({ options, mainClassName, style }) {
  const content = useContent();
  const { label } = options;

  if (!content?.ediisCartLink) return null;

  const { ediisCartLink } = content;

  return (
    <Link
      options={{
        label,
        isButton: true,
        size: 'normal',
        to: {
          url: ediisCartLink,
          openInANewTab: true,
        },
      }}
    />
  );
}

EntityEdiisCartLink.baseClassName = baseClassName;
