// @flow
// Simple YouTube SVG icon

import * as React from 'react';

type Props = {| color?: string |};

export default function YoutubeIcon({ color: colorProp }: Props): React.Node {
  const color = colorProp || 'currentColor';

  return (
    <svg x="0px" y="0px" width="19.1px" height="13.4px" viewBox="0 0 19.1 13.4">
      <path
        fill={color}
        d="M13.3,6.7L7,10.4V3.1L13.3,6.7z M19.1,10.1V3.3c0,0,0-3.3-3.3-3.3H3.3C3.3,0,0,0,0,3.3v6.8c0,0,0,3.3,3.3,3.3
	h12.5C15.8,13.4,19.1,13.4,19.1,10.1"
      />
    </svg>
  );
}
