import * as React from 'react';

import { isValidEmail } from '@/utils/misc';
import API from '@/utils/API';
import { useInstance, useStore, useNumberState } from '@/hooks';
import { makeRef } from '@/helpers/models/schema';
import { prettyPrice } from '@/helpers/format';

import {
  Modal,
  InputTextBase,
  InputCheckboxBase,
  Field,
  Label,
  Textarea,
} from '@/components/old';
import { InformationBubble, Button, Flex } from '@/components/new';
import { PaymentInput } from '@/PUFComponents/components';

const MINIMUM_AMOUNT = 1;
const MAXIMUM_AMOUNT = 1000;

export default function ParticipationModal({
  campaign,
  onClose,
  onSuccess,
  title,
  options,
}) {
  const [instanceId] = useInstance();
  const { user } = useStore('UserStore');
  const [participantInfo, setParticipantInfo] = React.useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [message, setMessage] = React.useState('');
  const [remainInformed, setRemainInformed] = React.useState(false);
  const [isPublic, setPublic] = React.useState(false);
  const [error, setError] = React.useState(null); // validateEmail with isValidEmail
  const [submitting, setSubmitting] = React.useState(false); // validateEmail with isValidEmail
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [amount, setAmount] = useNumberState({
    integerOnly: true,
    min: MINIMUM_AMOUNT,
  });
  const [crowdFundingDonation, setCrowdFundingDonation] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const displayPaymentForm =
    crowdFundingDonation?.status === 'incomplete' &&
    crowdFundingDonation?.intent;
  const { displayHistory } = options;

  const updateParticipantInfo = React.useCallback(
    (participantInfoShape) =>
      setParticipantInfo((previousInfo) => ({
        ...previousInfo,
        ...participantInfoShape,
      })),
    [setParticipantInfo]
  );

  const validate = () => {
    let valid = true;

    if (!isValidEmail(participantInfo.email)) {
      setError("L'adresse mail est invalide");
      valid = false;
    }

    return valid;
  };

  React.useEffect(() => {
    setError(null);
    if (hasSubmitted) validate();
  }, [hasSubmitted, participantInfo, setError]); // eslint-disable-line react-hooks/exhaustive-deps

  const createDonation = React.useCallback(async () => {
    const createdCrowdFundingDonation = await API.post(
      `${instanceId}/CrowdFundingCampaigns/${campaign._id}/donations`,
      {
        donation: {
          contributor: {
            userId: user?._id,
            ...participantInfo,
          },
          amount: parseInt(amount, 10),
          remainInformed,
          isPublic,
          message,
          participant: user ? makeRef(user) : null,
        },
      }
    );

    setCrowdFundingDonation(createdCrowdFundingDonation);
  }, [
    amount,
    participantInfo,
    remainInformed,
    isPublic,
    message,
    campaign._id,
    instanceId,
    user,
  ]);

  const updateDonationIntent = React.useCallback(
    async (intent) => {
      const updatedCrowdFundingDonation = await API.put(
        `${instanceId}/CrowdFundingCampaigns/${campaign._id}/donations/${crowdFundingDonation._id}`,
        {
          stripeIntent: intent,
        }
      );

      setCrowdFundingDonation(updatedCrowdFundingDonation);
      setSuccess(true);
    },
    [crowdFundingDonation, campaign._id, instanceId, setSuccess]
  );

  const submit = async () => {
    setError(false);
    setHasSubmitted(true);

    if (!validate()) return;

    setSubmitting(true);
    await createDonation();
    setSubmitting(false);
  };

  return (
    <Modal
      title={title || `Participer à la campagne de dons pour ${campaign.label}`}
      onClose={onClose}
      additionalClassName="participation-modal"
    >
      <form className="RegularForm is-small" onSubmit={submit}>
        <Flex>
          <Field style={{ marginRight: 10 }}>
            <Label>Prénom (optionnel)</Label>

            <InputTextBase
              name="firstName"
              placeholder="Votre prénom"
              type="text"
              value={participantInfo.firstName}
              onChange={(newFirstName) =>
                updateParticipantInfo({ firstName: newFirstName })
              }
            />
          </Field>

          <Field style={{ marginLeft: 10 }}>
            <Label>Nom (optionnel)</Label>

            <InputTextBase
              name="lastName"
              placeholder="Votre nom"
              type="text"
              value={participantInfo.lastName}
              onChange={(newLastName) =>
                updateParticipantInfo({ lastName: newLastName })
              }
            />
          </Field>
        </Flex>

        <Field style={{ marginBottom: 25, marginTop: 0 }}>
          <Label>Email</Label>

          <InputTextBase
            name="login"
            autoComplete="email"
            placeholder="Votre E-mail"
            type="email"
            value={participantInfo.email}
            onChange={(newEmail) => updateParticipantInfo({ email: newEmail })}
          />
        </Field>

        <Field id="donation-input">
          <Label>Montant du don (€)</Label>

          <InputTextBase
            placeholder="1€"
            min={MINIMUM_AMOUNT}
            max={MAXIMUM_AMOUNT}
            value={amount}
            onChange={(newAmount) => setAmount(newAmount)}
            type="number"
          />
        </Field>

        <Field id="remain-informed">
          <InputCheckboxBase
            label="Rester informé"
            value={remainInformed}
            onChange={(newRemainInformed) =>
              setRemainInformed(newRemainInformed)
            }
            clickableLabel
          />

          {remainInformed && (
            <InformationBubble type="warning" size="small">
              Vous recevrez un email lorsque la campagne de don aura atteint ses
              objectifs et quand elle sera terminée.
            </InformationBubble>
          )}
        </Field>

        {displayHistory && (
          <>
            <Field>
              <InputCheckboxBase
                label="Rendre ma participation publique"
                value={isPublic}
                onChange={(newIsPublic) => setPublic(newIsPublic)}
                clickableLabel
              />
            </Field>

            {isPublic && (
              <Field id="message">
                <Label>Votre message (optionnel)</Label>

                <InformationBubble type="warning" size="small">
                  Votre message sera publié dans la liste des contributions
                </InformationBubble>

                <Textarea
                  name="message"
                  placeholder="Votre message"
                  value={message}
                  onChange={(newMessage) => setMessage(newMessage)}
                />
              </Field>
            )}
          </>
        )}

        <div className="modal-footer">
          {error && (
            <InformationBubble
              type="danger"
              style={{ marginBottom: 20 }}
              additionalClassName="error-banner"
              isOutlined
            >
              {error}
            </InformationBubble>
          )}

          {(success || displayPaymentForm) && <div className="overlay" />}

          {!success && !displayPaymentForm && (
            <div className="buttons" style={{ justifyContent: 'center' }}>
              <Button
                onClick={submit}
                size="large"
                disabled={submitting || error}
              >
                Je donne
              </Button>
            </div>
          )}
        </div>
      </form>

      {displayPaymentForm && (
        <PaymentInput
          user={participantInfo}
          actionLabel={`Donner ${amount}€`}
          intent={crowdFundingDonation.intent}
          onPaymentInformationEntered={updateDonationIntent}
        />
      )}

      {success && (
        <>
          <p
            className="has-text-success"
            style={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}
          >
            Merci pour votre don de {prettyPrice(crowdFundingDonation.amount)}
          </p>
          <div className="buttons" style={{ justifyContent: 'center' }}>
            <Button
              onClick={() => onSuccess(crowdFundingDonation)}
              size="large"
            >
              Terminer
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}
