import * as React from 'react';
import classnames from 'classnames';

import { Image } from '@/components/new';
import EntityLink from '../navigation/EntityLink';

export default function TournamentParticipantThumbnail({
  entity: team,
  options = {},
  prefix,
}) {
  const { linkToEntity, displayName } = options;
  const { label = 'Equipe X', image } = team || {};

  const participantThumbnail = (
    <div
      className={classnames('tournament-participant-thumbnail', {
        'is-link': linkToEntity,
      })}
    >
      {prefix}

      <Image
        src={image || '/upload/dummy-flag.svg'}
        width={40}
        height={40}
        alt={`Drapeau ${label}`}
        defaultNextImage
        additionalClassName="participant-image"
      />

      {displayName && <p className="participant-label">{label}</p>}
    </div>
  );

  return linkToEntity && team ? (
    <EntityLink entity={team}>
      <a>{participantThumbnail}</a>
    </EntityLink>
  ) : (
    participantThumbnail
  );
}
