import * as React from 'react';
import classNames from 'classnames';

import { pluralize } from '@/helpers/string';

export default function Header({
  baseClassName,
  foldable,
  commentsCount,
  commentsDisplayed,
  onShow,
  onHide,
  onComment,
  canComment,
}) {
  return (
    <div className={`${baseClassName}-header`}>
      <span
        className={classNames(`${baseClassName}-header-button`, {
          [`${baseClassName}-header-button--active`]: commentsDisplayed,
        })}
        onClick={commentsDisplayed ? onHide : onShow}
      >
        <span>
          <span className={`${baseClassName}-header-button-count`}>
            {commentsCount}
          </span>
          {` `}
          {pluralize('commentaire', 'commentaires', commentsCount)}
        </span>
      </span>

      {foldable && commentsCount > 0 && (
        <span
          className={classNames(`${baseClassName}-header-button`, {
            [`${baseClassName}-header-button--active`]: commentsDisplayed,
          })}
          onClick={commentsDisplayed ? onHide : onShow}
        >
          {commentsDisplayed ? 'Masquer' : 'Afficher'}
        </span>
      )}

      {!canComment && (
        <span
          className={classNames(`${baseClassName}-header-button`, {
            [`${baseClassName}-header-button--active`]: !commentsDisplayed,
          })}
          onClick={onComment}
        >
          Commenter
        </span>
      )}
    </div>
  );
}
