import * as React from 'react';

import API from '@/utils/API';
import { useSiteContext } from '@/hooks';
import { pluralize } from '@/helpers/string';

import { Icon, Button } from '@/components/new';
import Comment from './';

const LIMIT = 20;

export default function Answers({
  comment,
  contentId,
  answers,
  setAnswers,
  onSubmitComment,
}) {
  const { siteId } = useSiteContext();

  const [currentPage, setCurrentPage] = React.useState(0);

  const [answersToggled, _toggleAnswers] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { answersCount, _id: commentId } = comment;

  const hasMore = answers.length < answersCount;

  const fetchAnswers = React.useCallback(
    async function () {
      if (!hasMore) return;

      try {
        setLoading(true);

        const { answers: receivedAnswers } = await API.get(
          `${siteId}/comments/${commentId}/answers`,
          {
            offset: currentPage * LIMIT,
            limit: LIMIT,
          }
        );

        const newAnswers = [...answers, ...receivedAnswers];
        setAnswers(newAnswers);

        setCurrentPage(currentPage + 1);
      } finally {
        setLoading(false);
      }
    },
    [siteId, answers, currentPage, commentId, hasMore, setAnswers]
  );

  const toggleAnswers = React.useCallback(async () => {
    if (answersToggled || currentPage > 0) {
      _toggleAnswers(!answersToggled);
      return;
    }

    await fetchAnswers();
    _toggleAnswers(!answersToggled);
  }, [_toggleAnswers, currentPage, answersToggled, fetchAnswers]);

  if (!answersCount && answers.length === 0) return null;
  const displayedAnswerCount = answersCount || answers.length || 0;

  return (
    <div className="answers-wrapper">
      <Button
        onClick={toggleAnswers}
        isText
        size="small"
        disabled={loading}
        additionalClassName="toggle-answers"
      >
        {answersToggled ? 'Cacher' : 'Afficher'}
        {` ${displayedAnswerCount} ${pluralize(
          'réponse',
          'réponses',
          displayedAnswerCount
        )}`}
        {loading ? ' (en cours ...)' : ''}
        <Icon
          name={answersToggled ? 'caret-down' : 'caret-up'}
          style={{ marginLeft: 5 }}
        />
      </Button>

      {answersToggled && answers.length > 0 && (
        <div className="answers">
          {answers
            .filter((answer) => !!answer)
            .map((answer) => (
              <Comment
                key={answer._id}
                contentId={contentId}
                comment={answer}
                isAnswer
                onSubmitComment={onSubmitComment}
              />
            ))}

          {!loading && hasMore && (
            <div className="load-more-button-wrapper">
              <Button onClick={fetchAnswers} isText>
                Charger plus de réponses...
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
