// @flow
import * as React from 'react';

import { CHECKOUT_STEP, CHECKOUT_STEPS } from '@/reflux/CartStore/constants';

import { Text, Stepper } from '@/components/new';
import wrapCheckout, {
  type ProvidedProps,
  type OwnProps,
} from '../components/CommonWrapper';
import Step1Register from '../components/Step1Register';
import Step2CartReview from './Step2CartReview';
import Step3PaymentAndBillingAddress from '../components/Step3PaymentAndBillingAddress';

const STEPS = [
  {
    id: 'CONNECTION',
    label: 'Connexion',
  },
  {
    id: CHECKOUT_STEP.CART_REVIEW,
    label: 'Informations',
  },
  {
    id: CHECKOUT_STEP.ADDRESS_AND_PAYMENT,
    label: 'Paiement',
  },
];

function CartCheckout({
  options,
  componentProps,
  user,
  siteOptions,
  cartState,
  goBackToProducts,
  featureFlags,
}: ProvidedProps): React.Node {
  const { label: title } = options;

  const { requiresShipping } = cartState;
  const currentStep = !!user
    ? CHECKOUT_STEPS.indexOf(cartState.currentStep) + 1
    : 0;

  return (
    <>
      {title && (
        <Text
          isTitle
          size={4}
          element="h1"
          additionalClassName="component-title"
        >
          {title}
        </Text>
      )}

      <div className="stepper-wrapper">
        <Stepper currentStep={currentStep} fullWidth steps={STEPS} />
      </div>

      {!user && (
        <Step1Register
          allowSimpleRegister={!requiresShipping}
          prefill={cartState.shippingAddress}
        />
      )}

      <Step2CartReview
        user={user}
        cartState={cartState}
        options={options}
        goBackToProducts={goBackToProducts}
        featureFlags={featureFlags}
      />

      {!!user &&
        cartState.currentStep === CHECKOUT_STEP.ADDRESS_AND_PAYMENT && (
          <Step3PaymentAndBillingAddress
            user={user}
            cartState={cartState}
            options={options}
          />
        )}
    </>
  );
}

export default (wrapCheckout(CartCheckout): React.ComponentType<OwnProps>);
