import _ from 'lodash';
import React from 'react';

import { prettyPrice } from '@/utils/misc';

import FrontComponentBase from '../../FrontComponentBase';

class EntityPrice extends FrontComponentBase {
  static baseClassName = 'EntityPrice';

  render() {
    let entity = this.props.entity || this.getSingleEntity();

    if (!entity) return null;
    if (!_.isNumber(entity.price)) return null;

    const options = this.getOptions();
    const { forceDigits, currencyType, label } = options;
    const baseClassName = this.constructor.baseClassName;

    return (
      <div className={this.getMainCSSClass()}>
        {!entity.variablePrice && (
          <>
            <span className={baseClassName + '-label'}>{label}</span>

            <span className={baseClassName + '-price'}>
              {prettyPrice(entity.price, {
                forceDigits,
                currencyDisplay: currencyType || 'none',
              })}
            </span>
          </>
        )}
      </div>
    );
  }
}

export default EntityPrice;
