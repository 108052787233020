// @flow
import * as React from 'react';

import type { User } from '@/types/models';
import type { FullCartState, CartStepId } from '@/types/stores';
import type { Options } from '../components/CommonWrapper';

import { CHECKOUT_STEP } from '@/reflux/CartStore/constants';
import CartStoreActions from '@/reflux/CartStore/actions';

import { Button, Notification } from '@/components/new';
import Donation from '../components/Donation';
import Gift from '../components/Gift';
import ErrorBanner from '../components/ErrorBanner';
import PaymentErrorBanner from '../components/PaymentErrorBanner';
import Addresses from '../components/Addresses';

type Props = {|
  user: ?User,
  cartState: FullCartState,
  options: Options,
  goBackToProducts?: () => void,
|};

const PAYMENT_LABEL: { [CartStepId]: string } = {
  [CHECKOUT_STEP.CART_REVIEW]: 'Valider',
  [CHECKOUT_STEP.ADDRESS_AND_PAYMENT]: 'Modifier mon panier',
  [CHECKOUT_STEP.SUCCESSFUL_PAYMENT]: 'Paiement réussi ! Un instant...',
};

export default function Step2CartReview({
  options,
  user,
  cartState,
  goBackToProducts,
}: Props): React.Node {
  const {
    cartItems,
    currentStep,
    donation,
    transitioningStep,
    validating,
    uiErrors: errors,
    hasUiErrors: hasError,
    paymentError,
  } = cartState;
  const inPaymentStep = [
    CHECKOUT_STEP.ADDRESS_AND_PAYMENT,
    CHECKOUT_STEP.SUCCESSFUL_PAYMENT,
  ].includes(currentStep);
  const disabled = validating || transitioningStep;
  const hasItems = Object.keys(cartItems).length > 0;

  return (
    <div className="step-2-cart-review">
      {hasItems && (
        <>
          {hasItems && <Gift cartState={cartState} disabled={inPaymentStep} />}
          <hr className="separator" />
        </>
      )}

      {options.allowDonation && (
        <>
          <Donation
            maxDonation={options.maxDonation}
            donationLabel={options.donationLabel}
            donation={donation}
            disabled={inPaymentStep}
          />
          <hr className="separator" />
        </>
      )}

      <Addresses cartState={cartState} disabled={inPaymentStep} />

      {!user && (
        <Notification color="warning">
          Connectez vous pour valider votre panier et passer au paiement
        </Notification>
      )}

      {(hasItems || donation > 0) && !!user && (
        <>
          <hr className="separator" />

          {hasError && <ErrorBanner errors={errors} cartItems={cartItems} />}
          {paymentError && <PaymentErrorBanner error={paymentError} />}

          <div className="submit-wrapper">
            <Button
              disabled={disabled}
              color={disabled || inPaymentStep ? 'light' : undefined}
              onClick={
                inPaymentStep
                  ? CartStoreActions.goToPreviousStep
                  : CartStoreActions.goToNextStep
              }
            >
              {disabled ? 'Validation...' : PAYMENT_LABEL[currentStep]}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
