import * as React from 'react';
import moment from 'moment';

import ParticipantThumbnail from './ParticipantThumbnail';

const getStartDate = (gameMoment, format) => {
  if (format === 'calendar') {
    const day = gameMoment.calendar(null, {
      sameDay: "[Aujourd'hui]",
      nextDay: '[Demain]',
      nextWeek: 'dddd',
      lastDay: '[Hier]',
      lastWeek: '[Dernier] dddd',
      sameElse: 'DD/MM/YYYY',
    });

    const time = gameMoment.format('HH:mm');
    return `${day} ${time}`;
  }

  return gameMoment.format(format);
};

export default function TournamentGameThumbnail({ game, teams, options = {} }) {
  const {
    game: { participant1, participant2, startTs },
  } = game;
  const team1 = teams[participant1?._id];
  const team2 = teams[participant2?._id];
  const { dateFormat = 'DD/MM/YY HH:mm' } = options;

  return (
    <div className="tournament-game-thumbnail">
      <p className="stage-tag">{game.stage.label}</p>
      <p className="time">{getStartDate(moment.unix(startTs), dateFormat)}</p>
      <div className="teams_wrapper">
        <ParticipantThumbnail entity={team1} />
        <ParticipantThumbnail entity={team2} />
      </div>
    </div>
  );
}
