// @flow
// Simple Twitter SVG icon

import * as React from 'react';

type Props = {| color?: string |};

export default function TwitterIcon({ color: colorProp }: Props): React.Node {
  const color = colorProp || 'currentColor';

  return (
    <svg
      x="0px"
      y="0px"
      width="19.216px"
      height="15.617px"
      viewBox="0 0 19.216 15.617"
    >
      <path
        fill={color}
        d="M19.216,1.849c-0.707,0.314-1.467,0.526-2.265,0.621c0.814-0.488,1.439-1.261,1.733-2.181
            	c-0.762,0.452-1.605,0.78-2.503,0.957C15.462,0.479,14.438,0,13.304,0c-2.178,0-3.942,1.765-3.942,3.942
            	c0,0.309,0.035,0.61,0.102,0.898C6.187,4.676,3.281,3.107,1.337,0.722c-0.339,0.583-0.533,1.26-0.533,1.982
            	c0,1.368,0.696,2.575,1.754,3.282C1.911,5.965,1.304,5.788,0.771,5.492c0,0.017,0,0.033,0,0.049c0,1.91,1.358,3.503,3.162,3.866
            	c-0.33,0.091-0.679,0.138-1.038,0.138c-0.254,0-0.501-0.023-0.742-0.07c0.502,1.566,1.958,2.707,3.683,2.738
            	C4.487,13.27,2.787,13.9,0.94,13.9c-0.318,0-0.633-0.019-0.94-0.055c1.744,1.118,3.816,1.771,6.043,1.771
            	c7.252,0,11.217-6.008,11.217-11.218c0-0.171-0.004-0.341-0.012-0.51C18.019,3.334,18.688,2.639,19.216,1.849z"
      />
    </svg>
  );
}
