/**
 
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import React from 'react';

import { MAX_WIDTH_BY_COL_SIZE } from '@/utils/config';

import FrontComponentBase from '../../FrontComponentBase';
import { DynImg } from '@/components/old';
import EntityLink from '../../base/navigation/EntityLink';

class VideoPosterV1 extends FrontComponentBase {
  static baseClassName = 'VideoPoster';

  /*
        TODO :
        - format date and add content type label
        - add navigation
    */

  render() {
    let content = this.props.content || this.getSingleEntity();

    let { size, thumbWidth } = this.props;
    const ratioWH = 16 / 9;
    let w, h;

    if (thumbWidth) {
      w = thumbWidth; // TODO devicePixelRatio (unavailable in SSR...)
    } else {
      w = MAX_WIDTH_BY_COL_SIZE[size] || 950; // TODO actualSize? replace size by actualSize?
    }
    h = w / ratioWH;

    let label, image;
    if (content) {
      label = content.label;
      image = content.image;
    } else {
      return null;
    }

    //let {image, label} = this.props.content;
    let componentBaseClass = this.constructor.baseClassName;

    return (
      <div className={this.getMainCSSClass()}>
        {(this.props.wrappedByLink && (
          <div className="videoWrapper">
            <div className={componentBaseClass + '-navigation'}>
              {content && content.video && (
                <span className={componentBaseClass + '-navigation-video'}>
                  Voir la vidéo
                </span>
              )}
              {/* disabled - c'est moche
                         content && (content.body && !EMPTY_HTML_PATTERN.test(content.body)) &&
                          <span className={componentBaseClass + "-navigation-text"}>Lire le texte</span>
                         */}
            </div>

            <div className="videoWrapper-inner">
              <div className="videoWrapper-thumb">
                <DynImg
                  className={componentBaseClass + '-image'}
                  src={image}
                  alt={label}
                  w={w}
                  h={h}
                  assumeNoAlpha={true}
                />
              </div>
            </div>
          </div>
        )) || (
          <EntityLink entity={content}>
            <a className="videoWrapper">
              <div className={componentBaseClass + '-navigation'}>
                {content && content.video && (
                  <span className={componentBaseClass + '-navigation-video'}>
                    Voir la vidéo
                  </span>
                )}
                {content && content.body && (
                  <span className={componentBaseClass + '-navigation-text'}>
                    Lire le texte
                  </span>
                )}
              </div>

              <div className="videoWrapper-inner">
                <div className="videoWrapper-thumb">
                  <DynImg
                    className={componentBaseClass + '-image'}
                    src={image}
                    alt={label}
                    w={thumbWidth}
                    assumeNoAlpha={true}
                  />
                </div>
              </div>
            </a>
          </EntityLink>
        )}
      </div>
    );
  }
}

export default VideoPosterV1;
