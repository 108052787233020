import { sortBy, toPairs } from 'lodash';
import React from 'react';
import classNames from 'classnames';

const baseClassName = 'Quizz-Result';

export default function Result({
  stats,
  score,
  reset,
  questionLength,
  customResultText,
}) {
  return (
    <div>
      {score && <h2>Votre score: {score}</h2>}

      <div
        className={classNames(baseClassName, {
          'with-stats': !!stats,
        })}
      >
        {!!stats && (
          <>
            <h3>
              {stats.totalParticipants} personnes ont participé à ce quizz.
            </h3>
            <h4>
              {!!customResultText ? customResultText : 'Voici le résultat'}
            </h4>

            <ul>
              {sortBy(
                toPairs(stats.percentages),
                ([correctAnswerCount]) => -correctAnswerCount
              ).map(([correctAnswerCount, percentage]) => {
                const participantsScore = `${correctAnswerCount}/${questionLength}`;

                return (
                  <li
                    className={score === participantsScore ? 'user-score' : ''}
                  >
                    <span>{participantsScore}</span> bonnes réponses:{' '}
                    <span>
                      {Math.round(100 * stats.percentages[correctAnswerCount])}%
                    </span>
                  </li>
                );
              })}
            </ul>

            {!!reset && (
              <button type="button" className="standard-button" onClick={reset}>
                Recommencer le quizz !
              </button>
            )}
          </>
        )}

        {!stats && !!reset && (
          <button type="button" className="standard-button" onClick={reset}>
            Recommencer le quizz !
          </button>
        )}
      </div>
    </div>
  );
}
