import * as React from 'react';

import { PaywallContext } from '@/globals/contexts';
import { useLinks } from '@/hooks';

import { Button, Text } from '@/components/new';
import PageLink from '@/PUFComponents/base/navigation/PageLinkV1';

export default function VideoContentInternalPaywall({
  content,
  fields,
  onSuccess,
}) {
  const { subscribe: subscribeLink } = useLinks();
  const { openModal } = React.useContext(PaywallContext);
  const { unauthorizedDetails, percentageOfMissingContent } = content;
  const { description, solutions, reason } = unauthorizedDetails;

  let title = 'Contenu non accessible';

  switch (reason) {
    case 'limit_reached':
      title = 'Contenu disponible gratuitement';
      break;
    case 'unsufficient_rights':
      title = 'Contenu réservé aux abonnés';
      break;
    default:
      title = 'Contenu non accessible';
  }

  return (
    <div
      className="ContentVideo-overlay ContentPausescreenpremium"
      style={{ background: 'rgba(0, 0, 0, 0.8)' }}
    >
      <div className="paywall-content-internal-component  for-Video">
        <Text isTitle size={6} element="p">
          {title}
        </Text>
        {percentageOfMissingContent && (
          <p className="text-content-left-to-read">
            <span className="percent">{percentageOfMissingContent}</span> % de
            ce contenu restent à découvrir !
          </p>
        )}

        <p className="description">{description}</p>

        <div className="solutions">
          {solutions.includes('register') && (
            <Button
              onClick={() =>
                openModal({
                  content,
                  choice: 'register',
                  options: { fields },
                  onSuccess,
                })
              }
              additionalClassName="register"
              size="small"
            >
              Créer un compte
            </Button>
          )}

          {solutions.includes('login') && (
            <Button
              onClick={() =>
                openModal({
                  content,
                  choice: 'login',
                  options: { fields },
                  onSuccess,
                })
              }
              additionalClassName="login"
              size="small"
            >
              Se connecter
            </Button>
          )}

          {solutions.includes('subscribe') && (
            <PageLink link={subscribeLink}>
              <Button additionalClassName="subscribe" size="small">
                <span>S'abonner</span>
              </Button>
            </PageLink>
          )}
        </div>
      </div>
    </div>
  );
}
