// @flow
import * as React from 'react';
import moment from 'moment';

import type { PUFComponentProps } from '@/types/component';
import type { PropertyRef } from '@/types/models';

import { ContentContext } from '@/globals/contexts';

import { RichTextContent } from '@/components/old';
import SingleEntity from '@/PUFComponents/SingleEntity';

type Options = {|
  propertyRef?: PropertyRef,
  defaultRef?: PropertyRef,
  defaultValue?: any,
  suffix?: string,
  formatter?: string,
  hideIfNull?: boolean,
  displayConditions?: Array<any>,
|};

type Mode = 'default';

type Props = PUFComponentProps<void, Options, Mode, void>;

const BASE_CLASS_NAME = 'EntityProperty';

function EntityPropertyV1({
  entity,
  options,
  mainClassName,
  mode,
}: Props): React.Node {
  if (!entity) return null;

  const {
    propertyRef: propertyRefOption,
    defaultRef,
    hideIfNull,
    defaultValue,
    suffix,
    formatter,
  } = options;

  const propertyRef = propertyRefOption || defaultRef;
  if (!propertyRef) return null;

  const valueKey = propertyRef.prop.key;
  if (!valueKey) return null;
  const value = entity[valueKey];
  if (!value && hideIfNull) return null;

  const classNames = {
    main: `${mainClassName}
       prop-${propertyRef.prop.key.replace('.', '_')}`,
    inner: `${BASE_CLASS_NAME}-inner`,
    label: `${BASE_CLASS_NAME}-label`,
    value: `${BASE_CLASS_NAME}-value`,
    suffix: `${BASE_CLASS_NAME}-label suffix`,
  };

  return (
    <div className={classNames.main}>
      <div className={classNames.inner}>
        {propertyRef.label ? (
          <span className={classNames.label}>{propertyRef.label}</span>
        ) : null}

        <span className={classNames.value}>
          {value && (
            <PropertyValue
              propertyRef={propertyRef}
              value={value}
              defaultValue={defaultValue}
              formatter={formatter}
              mode={mode}
            />
          )}
        </span>

        {suffix && <span className={classNames.suffix}>{suffix}</span>}
      </div>
    </div>
  );
}

EntityPropertyV1.baseClassName = BASE_CLASS_NAME;

export default EntityPropertyV1;

type PropertyValueProps = {|
  propertyRef: PropertyRef,
  value: any,
  defaultValue: any,
  formatter?: string,
  mode: Mode,
|};

function PropertyValue({
  propertyRef,
  value,
  defaultValue,
  formatter,
  mode,
}: PropertyValueProps): React.Node {
  if ((value || defaultValue) && formatter) {
    return applyFormatter(formatter, value || defaultValue);
  }

  if (!propertyRef.prop) return convertToString(value);
  if (!value) return null;

  if (value?._cls) {
    return (
      <ContentContext.Provider value={value}>
        <SingleEntity mode={mode} />
      </ContentContext.Provider>
    );
  }

  switch (propertyRef.prop.type) {
    case 'timestamp':
      return moment.unix(parseInt(value)).format(propertyRef.format || 'LL');
    case 'richtext':
      return <RichTextContent tagName="span" html={value} />;
    case 'list':
      return convertToString(value.map((item) => item?.label));
    default:
      return convertToString(value);
  }
}

function applyFormatter(formatter: string = '', value: any): string | null {
  try {
    //$FlowIgnore
    return Function('value', '"use strict"; return (' + formatter + ')')(value); // eslint-disable-line no-new-func
  } catch (e) {
    console.warn(
      'EntityProperty> Formatter',
      formatter,
      'returned error',
      e.message,
      'for value',
      value
    );
  }
  return null;
}

function convertToString(value: any): null | string {
  if (typeof value === 'string') {
    return value;
  } else if (Array.isArray(value)) {
    return value.join(', ');
  } else if (typeof value === 'object') {
    return JSON.stringify(value);
  } else if (typeof value === 'number') {
    return value.toString();
  } else {
    console.warn("Can't display this type : ", typeof value);
    return null;
  }
}
