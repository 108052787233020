import * as React from 'react';

export default function Iframe({ options }) {
  if (!options.src) return null;

  return (
    <iframe
      title={options.title}
      width={options.width}
      height={options.height}
      src={options.src}
      frameBorder="0"
    />
  );
}
