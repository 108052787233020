import React from 'react';
import PastGame from './PastGame';
import { Flex } from '@/components/new';

function PastGames({ games, participantId, teams }) {
  return (
    <Flex direction="column" additionalClassName="past-games">
      <h4>Derniers résultats</h4>

      {games.length > 0 ? (
        <ul className="games">
          {games.slice(0, 3).map((game) => {
            return (
              <PastGame
                game={game}
                participantId={participantId}
                teams={teams}
                key={game._id}
              />
            );
          })}
        </ul>
      ) : (
        <p className="empty-match-placeholder">Pas de match connus</p>
      )}
    </Flex>
  );
}

export default PastGames;
