// Component used to display a content grid with a sort/filter menu

import _ from 'lodash';
import React from 'react';

import { CartStore, CartStoreActions } from '@/reflux';
import ApiClient from '@/utils/ApiClient';

import { InputSelectBase } from '@/components/old';
import FrontComponentBase from '../../FrontComponentBase';
import { goToEntityPage } from '../navigation/EntityLink';

class EntitySelect extends FrontComponentBase {
  static baseClassName = 'EntitySelect';

  stores = [CartStore];

  onChange = (id) => {
    this.onSelectEntity(_.find(this.getEntityList(), { _id: id }));
  };

  didMount() {
    const options = this.getOptions();

    if (options.redirectToFirst) {
      console.debug('auto-redirect to first item');
      this.observeProps(
        ['component.data', 'data.componentList', 'data.mainList'],
        () => {
          let entities = this.getEntityList();
          if (entities && entities[0]) this.onSelectEntity(entities[0]);
        }
      );
    }

    if (options.action === 'addToCart') {
      // TEMP ! // To refresh _purchaseCount
      ApiClient.onDataUpdate('Event');
      ApiClient.onDataUpdate('SubcriptionFormula');
      // Usually the Page will contain events etc.
      ApiClient.onDataUpdate('Page');
    }
  }

  onSelectEntity(entity) {
    let action = this.getOptions().action;
    if (action === 'addToCart') {
      CartStoreActions.removeItems(
        (this.getEntityList() || []).map((entity) => entity?._id)
      );
      entity && CartStoreActions.addItem(entity);
    } else {
      goToEntityPage(entity);
    }
  }

  render() {
    let entities = this.getEntityList();
    let options = this.getOptions();
    let mainObjectId;
    if (options.action === 'addToCart') {
      mainObjectId = _.intersection(
        _.map(entities, '_id'),
        _.map(this.state.cartItems, 'entity._id')
      ).pop();
      console.debug('inter', mainObjectId);
    } else {
      mainObjectId = this.getMainObjectId();
    }

    if (this.state.draftMode && _.isEmpty(options.list)) {
      // "no display" condition
      return (
        <div className="draft-placeholder">{this.props.component.label}</div>
      );
    }

    return (
      <div className={this.getMainCSSClass()}>
        <InputSelectBase
          value={mainObjectId}
          placeholder={options.placeholder}
          useValue={true}
          onChange={this.onChange}
          label={options.label}
          options={entities.map((entity) => ({
            value: entity._id,
            label:
              entity.label +
              (options.action === 'addToCart' && entity.maxTickets
                ? ' - ' +
                  Math.max(
                    0,
                    entity.maxTickets - (entity._purchaseCount || 0)
                  ) +
                  ' places restantes'
                : ''),
            disabled:
              options.action === 'addToCart' &&
              entity.maxTickets &&
              entity.maxTickets <= entity._purchaseCount,
          }))}
        />
      </div>
    );
  }
}

export default EntitySelect;
