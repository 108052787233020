// @flow
import * as React from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';

import { Button, Text, Flex } from '@/components/new';
import { Field } from '@/components/old';

import { useStyle } from '@/hooks';

type ElementType = 'cardNumber' | 'cardExpiry' | 'cardCvc';

type Props = {|
  title?: string,
  actionLabel: string,
  onSubmit: any,
  error: string | null,
  submitting: boolean,
  disabled?: boolean,
|};

type StripeEvent = {|
  brand: 'visa',
  complete: boolean, // Has the user filled the form
  elementType: ElementType,
  empty: boolean,
  error?: {|
    code: string,
    message: string,
    type: string,
  |},
|};

export default function StripeCardInput({
  title,
  actionLabel,
  onSubmit,
  error,
  submitting,
  disabled,
}: Props): React.Node {
  const instanceStyle = useStyle();

  const [cardEvents, setCardEvents] = React.useState<{
    [key: ElementType]: null | StripeEvent,
  }>({
    cardNumber: null,
    cardExpiry: null,
    cardCvc: null,
  });

  const hasError = Object.keys(cardEvents).some(
    (elementType: ElementType) => !!cardEvents[elementType]?.error
  );
  const complete = Object.keys(cardEvents).every(
    (elementType: ElementType) => !!cardEvents[elementType]?.complete
  );

  const setCardEvent = (event: StripeEvent) => {
    if (!event) return;
    if (!event?.elementType) return null;
    setCardEvents((previousCardEvents) => ({
      ...previousCardEvents,
      // $FlowIgnore
      [event.elementType]: event,
    }));
  };

  const style = {
    base: {
      fontSize: '16px',
      color: instanceStyle.textColor,
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: instanceStyle.dangerColor,
    },
  };

  return (
    <form onSubmit={onSubmit} className="payment-form">
      <div className="payment-form-card-wrapper">
        <h4 className="payment-form-title">
          {title || 'Entrez vos informations de carte bancaire'}
        </h4>

        <Field>
          <div className="Input" id="card-number">
            <CardNumberElement options={{ style }} onChange={setCardEvent} />
          </div>
          {cardEvents?.cardNumber?.error && (
            <div className="error">{cardEvents.cardNumber.error.message}</div>
          )}
        </Field>
        <Field>
          <div className="Input" id="card-expiry">
            <CardExpiryElement options={{ style }} onChange={setCardEvent} />
          </div>
          {cardEvents?.cardExpiry?.error && (
            <div className="error">{cardEvents.cardExpiry.error.message}</div>
          )}
        </Field>
        <Field>
          <div className="Input" id="card-cvc">
            <CardCvcElement options={{ style }} onChange={setCardEvent} />
          </div>
          {cardEvents?.cardCvc?.error && (
            <div className="error">{cardEvents.cardCvc.error.message}</div>
          )}
        </Field>

        <Flex direction="column" style={{ alignItems: 'end' }}>
          <div className="bank-card-indicator">Carte bancaire</div>

          <Text
            transformation="italic"
            size={7}
            element="p"
            style={{ marginTop: 0 }}
            align="right"
            weight="bold"
            additionalClassName="bank-details-not-saved"
          >
            Nous ne conservons pas vos coordonnées bancaires
          </Text>
        </Flex>
      </div>

      {error && <div className="error">{error}</div>}

      <div>
        <Button
          onClick={onSubmit}
          color="success"
          size="large"
          additionalClassName="payment-submit"
          disabled={!complete || hasError || submitting || disabled}
        >
          {submitting ? 'En cours...' : actionLabel}
        </Button>
      </div>
    </form>
  );
}
