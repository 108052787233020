/**
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import React from 'react';
import _ from 'lodash';

import FrontComponentBase from '../../FrontComponentBase';

class ContentPlaybackProgress extends FrontComponentBase {
  static baseClassName = 'ContentPlaybackProgress';

  didMount() {
    const rebindData = this.getRebindToObjectFunc('userWatched');

    this.observeSingleEntity((content) => {
      this.setState({ userWatched: null });
      rebindData('users/watched', content && content._id);
    });
  }

  render() {
    let { userWatched } = this.state;
    if (!userWatched || !userWatched.duration) return null;
    let progress = userWatched.time / userWatched.duration;

    const label = Math.round(progress * 100) + '% vus';

    return (
      _.isNumber(progress) && (
        <div className={this.getMainCSSClass()}>
          <span className={ContentPlaybackProgress.baseClassName + '-bar'}>
            <span
              className={ContentPlaybackProgress.baseClassName + '-bar-inner'}
              style={{ width: (progress * 100).toFixed(3) + '%' }}
              title={label}
            >
              {label}
            </span>
          </span>
        </div>
      )
    );
  }
}

export default ContentPlaybackProgress;
