// Component used to display a user name

import _ from 'lodash';
import React from 'react';

import { getFirst } from '@/utils/misc';

import FrontComponentBase from '../../FrontComponentBase';
import EntityLink from '../navigation/EntityLink';

class UserName extends FrontComponentBase {
  static baseClassName = 'UserName';

  render() {
    let entity =
      this.props.content || // retrocompat TODO remove
      this.props.entity ||
      this.getSingleEntity();

    if (!entity) return null;

    let options = this.getOptions();

    // for now, the only change from entityTitle is 'name' instead of 'label'
    // and CSS class
    let { contentType, name } = entity;
    let baseClassName = this.constructor.baseClassName;

    // add entity type to the component main class
    let mainContentType = getFirst(contentType);
    let componentMainClass =
      baseClassName +
      (mainContentType ? ' ' + baseClassName + '--' + mainContentType : '');

    let useH1 = options.useH1 || false;
    let createLink = _.get(options, 'createLink', true); // TODO in admin

    const title = (useH1 && <h1>{name}</h1>) || <span>{name}</span>;

    return createLink ? (
      <EntityLink entity={entity}>
        <a className={componentMainClass}>{title}</a>
      </EntityLink>
    ) : (
      <div className={componentMainClass}>{title}</div>
    );
  }
}

export default UserName;
