import NLink from 'next/link';
import Router from 'next/router';
import React from 'react';

import { serializeQueryString } from '@/utils/misc';

export function getLinkToEntity(entity, query = null) {
  if (!entity?.path) return {};

  let qs = query && serializeQueryString(query, false);

  return {
    href: `${entity.path}${qs ? '?' + qs : ''}`,
  };
}

export function goToEntityPage(entity, query = null) {
  let { as, href } = getLinkToEntity(entity, query);

  if (!href) return null;

  Router.push(href, as);
}

/**
 *
 * @param props { objectType, objectId [, query] } with query = {param1: value1, ... }
 * @returns {*}
 * @constructor
 */
export default function EntityLink({ entity, children, query, render }) {
  if (!entity) return children;

  const { as, href } = getLinkToEntity(entity, query);

  if (!href) return children;

  if (render) {
    return (
      <NLink as={as} href={href}>
        {render(href)}
      </NLink>
    );
  }

  return (
    <NLink as={as} href={href}>
      {children}
    </NLink>
  );
}
