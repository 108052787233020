// Component used to display the content text/video switch link
import React from 'react';

import { getFirst } from '@/utils/misc';

import FrontComponentBase from '../../FrontComponentBase';
import EntityLink from '../navigation/EntityLink';

class ContentSwitchDisplay extends FrontComponentBase {
  static baseClassName = 'ContentSwitchDisplay';

  render() {
    let content = this.props.content || this.getSingleEntity();

    if (!content) return null;

    let { contentType } = content;

    let baseClassName = this.constructor.baseClassName;
    // add content type to the component main class
    let mainContentType = getFirst(contentType);
    let componentMainClass =
      baseClassName +
      (mainContentType ? ' ' + baseClassName + '--' + mainContentType : '');

    return (
      <div className={componentMainClass}>
        <EntityLink entity={content}>
          <a className={baseClassName + '-link'}>
            TODO afficher "Texte" ou "Vidéo"
          </a>
        </EntityLink>
      </div>
    );
  }
}

export default ContentSwitchDisplay;
