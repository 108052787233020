import React from 'react';
import { isInteger } from 'lodash';

import { useContent } from '@/hooks';
import { pluralize } from '@/helpers/string';

import { Icon } from '@/components/new';

const baseClassName = 'PUF-entity-comments-count';

export default function EntityCommentsCount({ options, style }) {
  const content = useContent();
  const { iconInsteadOfText } = options || {};

  if (!content) return null;

  const { commentsCount: count } = content;

  if (!isInteger(count)) return null;

  return (
    <div className={baseClassName} style={style}>
      {iconInsteadOfText && <Icon name="comment-alt" />}
      <span className="count">{count}</span>{' '}
      {!iconInsteadOfText && (
        <span className="comments-text">
          {pluralize('commentaire', 'commentaires', count)}
        </span>
      )}
    </div>
  );
}

EntityCommentsCount.baseClassName = baseClassName;
