import * as React from 'react';
import { get } from 'lodash';

import { withLinks } from '@/utils/HOC';

import { Modal } from '@/components/old';
import { Button } from '@/components/new';
import PageLink from '../../navigation/PageLinkV1';

function PreviewStartModal({ user, onClose, siteContext, links }) {
  const { login: loginLink, register: registerLink } = links.login;

  return (
    <Modal
      title={
        !!user
          ? 'Abonnez-vous pour lire la revue'
          : 'Connectez-vous ou abonnez-vous pour lire la revue'
      }
      titleCentered
      size="one-third"
      onClose={onClose}
      additionalClassName="immanens-paywall-modal"
    >
      <div className="buttons">
        {!user && (
          <PageLink link={loginLink}>
            <Button>
              {get(siteContext, 'siteConfig.options.loginLabel') ||
                'Se connecter'}
            </Button>
          </PageLink>
        )}

        <PageLink link={registerLink}>
          <Button>S'abonner</Button>
        </PageLink>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <a onClick={onClose}>Lire l'extrait</a>
      </div>
    </Modal>
  );
}

export default withLinks(PreviewStartModal);
