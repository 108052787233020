import * as React from 'react';
import moment from 'moment';
import { useInterval } from 'usehooks-ts';

const computeRemainingDays = (deadline) => {
  const remainingDays = moment
    .duration(moment.unix(deadline).diff(moment()))
    .asDays();
  return remainingDays > 0 ? remainingDays : 0;
};

export default function Countdown({ deadline, text }) {
  const [remainingDays, setRemainingDays] = React.useState(
    computeRemainingDays(deadline)
  );

  useInterval(() => setRemainingDays(computeRemainingDays(deadline)), 1000);

  return (
    <div className="countdown">
      {Math.floor(remainingDays)} {text || 'jours restants'}
    </div>
  );
}
