// Component used to display the content description

import React from 'react';

import { UserStore } from '@/reflux';
import { APIContext } from '@/globals/contexts';
import API from '@/utils/API';
import { withContext } from '@/utils/HOC';

import { InputCheckboxBase, InputFileUpload } from '@/components/old';
import FrontComponentBase from '../../FrontComponentBase';
import RawComponent from '../../RawComponent';

class SendAFile extends FrontComponentBase {
  static baseClassName = 'SendAFile';
  stores = [UserStore];

  constructor() {
    super();
    this.fileUploadRef = React.createRef();
  }

  onFileSent = async (fileUrl) => {
    const { user } = this.state;
    const {
      apiContext: { userContributionPath },
    } = this.props;

    this.setState({ canSend: false });

    try {
      const userContribution = await API.post(userContributionPath, {
        userId: user._id,
        file: fileUrl,
      });
      this.setState({ sent: true, userContribution });
    } catch (e) {
      this.setState({ canSend: true });
    }
  };

  render() {
    let baseClassName = this.constructor.baseClassName;
    const options = this.getOptions();
    const { isConsent, canSend, sent, user, userContribution } = this.state;
    const {
      apiContext: { userContributionUrl },
    } = this.props;

    return (
      (!user && <RawComponent id="Login" />) || (
        <div className={this.getMainCSSClass()}>
          {options.label && (
            <div className={baseClassName + '-title'}>{options.label}</div>
          )}
          <InputFileUpload
            ref={this.fileUploadRef}
            onSent={this.onFileSent}
            onDrop={() => this.setState({ canSend: true })}
            manualSend={true}
            accept={null}
          />
          {options.consentLabel && (
            <InputCheckboxBase
              label={options.consentLabel}
              clickableLabel={true}
              value={isConsent}
              onChange={(isConsent) => this.setState({ isConsent })}
            />
          )}
          {canSend && isConsent && (
            <div className="actions">
              <button
                className="standard-button"
                title={options.buttonLabel || 'Envoyer'}
                onClick={() => this.fileUploadRef.current.sendFiles()}
              >
                {options.buttonLabel || 'Envoyer'}
              </button>
            </div>
          )}
          {sent && (
            <div className="thank-you">
              {options.confirmationLabel || 'Merci !'}

              <p>
                Votre fichier :{' '}
                <a
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${userContributionUrl}/show/${encodeURIComponent(
                    userContribution.file
                  )}`}
                >
                  {userContribution.file}
                </a>
              </p>
            </div>
          )}
        </div>
      )
    );
  }
}

export default withContext(APIContext, 'apiContext', SendAFile);
