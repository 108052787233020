// Component used to display the content title

import React from 'react';

import FrontComponentBase from '../../FrontComponentBase';

class AnswerQuestionAuthor extends FrontComponentBase {
  static baseClassName = 'AnswerQuestionAuthor';

  didMount() {
    const bindQuestion = this.getRebindToObjectFunc('question');
    const bindAuthor = this.getRebindToObjectFunc('author');

    this.observeSingleEntity((entity) => {
      bindQuestion('Question', null);
      bindAuthor('User', null);

      // May be question or answer (or any entity with a .question ref, with dependency loaded!!)
      let question = entity._cls === 'Question' ? entity : entity.question;

      if (question) {
        const author = question.author;

        if (question._id && !author) {
          // <=> question._isRef && !question._wasRef
          bindQuestion('Question', question._id, { depDepth: 1 });
        } else if (author._isRef && !author._wasRef) {
          // ref only, to load
          bindAuthor('User', author._id, { depDepth: 0 });
        } else {
          // we have a full aothor object
          this.setState({ author });
        }
      }
    });
  }

  render() {
    let { author, question } = this.state; // author loaded?
    if (!author && question) author = question.author; // question loaded?
    if (!author) return null;

    let options = this.getOptions();

    let componentBaseClass = this.constructor.baseClassName;

    let questionAuthorName =
      author &&
      (author.name ||
        author.nickname ||
        author.firstName + ' ' + author.lastName);

    return (
      (author && (
        <div className={this.getMainCSSClass()}>
          {options.prefix || ''}
          <span className={componentBaseClass + '-name'}>
            {questionAuthorName}
          </span>
          {options.suffix || ' demande :'}
        </div>
      )) ||
      null
    );
  }
}

export default AnswerQuestionAuthor;
