import React from 'react';
import classnames from 'classnames';

import { ImageContext, LayoutSizeDivisionContext } from '@/globals/contexts';
import { computeStyleFromStyleProperties } from '@/helpers/rendering/PUFComponent';

import { Column as LayoutColumn } from '@/components/new';
import ContentsManager from './ContentsManager';

export const getClassName = (column) => {
  const { options = {} } = column;
  const { className, align } = options;

  return classnames(className, {
    aligned: !!align && align !== 'left',
    [`aligned-${align}`]: !!align && align !== 'left',
  });
};

export default function ColumnV2({ column }) {
  const currentSizeDivision = React.useContext(LayoutSizeDivisionContext);
  const {
    size,
    children: childrenProp,
    options = {},
    loadImagesSync,
    offset,
  } = column;
  const { styleProperties = [] } = options;

  const { loadImagesSync: parentLoadImagesSync } =
    React.useContext(ImageContext);
  const shouldLoadImagesSync =
    loadImagesSync === undefined ? parentLoadImagesSync : loadImagesSync;

  const style = computeStyleFromStyleProperties(styleProperties);

  const children = childrenProp || <ContentsManager column={column} />;

  const newSizeDivision = currentSizeDivision * (12 / size);

  return (
    <ImageContext.Provider value={{ loadImagesSync: shouldLoadImagesSync }}>
      <LayoutSizeDivisionContext.Provider value={newSizeDivision}>
        <LayoutColumn
          size={size}
          offset={offset || 0}
          data-size-division={newSizeDivision}
          additionalClassName={getClassName(column)}
          style={style}
        >
          {children}
        </LayoutColumn>
      </LayoutSizeDivisionContext.Provider>
    </ImageContext.Provider>
  );
}
