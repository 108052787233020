import React from 'react';
import { Flex, Image } from '@/components/new';
import EntityLink from '../../navigation/EntityLink';
import classnames from 'classnames';

function PastGame({ game, participantId, teams }) {
  const { score } = game;
  const { participant1, participant2 } = game.game;

  const opponentId =
    participant1._id === participantId ? participant2._id : participant1._id;
  const opponentIndex = participant1._id === participantId ? 0 : 1;
  const currentTeamIndex = opponentIndex === 0 ? 1 : 0;
  const opponentScore = score[opponentIndex];
  const currentTeamScore = score[currentTeamIndex];
  const isWon = currentTeamScore > opponentScore;

  const opponent = teams[opponentId];
  const { image, label } = opponent;

  return (
    <Flex
      direction="column"
      horizontalAlign
      verticalAlign
      style={{ width: '100%' }}
    >
      <p className="date-hour">&nbsp;</p>
      <div className="game">
        <EntityLink entity={opponent}>
          <a style={{ fontSize: 0 }} className="flag-name_wrapper">
            <Image
              src={image}
              width={40}
              height={40}
              alt={`Drapeau ${label}`}
              defaultNextImage
              additionalClassName="participant-image"
            />
            <p>{label}</p>
          </a>
        </EntityLink>

        <p
          className={classnames('score', {
            'is-lost': !isWon,
            'is-won': isWon,
          })}
        >
          <span>{opponentScore}</span> - <span>{currentTeamScore}</span>
        </p>
      </div>
    </Flex>
  );
}

export default PastGame;
