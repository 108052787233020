import React from 'react';
import classnames from 'classnames';

import { PageContext } from '@/globals/contexts';
import { useContent, useSiteOptions } from '@/hooks';

import { Icon, Button } from '@/components/new';

const baseClassName = 'Sharing';

export default function SharingButtonsV2({ options, mainClassName }) {
  const content = useContent();
  const siteOptions = useSiteOptions();
  const pageContext = React.useContext(PageContext);
  const { baseUrl } = pageContext;

  if (!content) return null;

  const { path, label, mainContentType } = content;
  const { iconOnly } = options;

  const encodedLink = encodeURIComponent(baseUrl + path);
  const encodedTitle = encodeURIComponent(label || siteOptions.siteTitle);

  return (
    <div
      className={classnames(mainClassName, {
        [`main-content-type-${mainContentType?._id}`]: !!mainContentType,
      })}
    >
      {options.twitter !== false && (
        <Button
          href={`https://twitter.com/share?url=${encodedLink}&text=${encodedTitle}`}
          target="_blank"
          rel="noopener noreferrer"
          className={baseClassName + '-twitter'}
          title="Tweeter ce contenu"
          size="small"
          iconButton={iconOnly}
        >
          <Icon name="twitter" type="brand" />
          {!iconOnly && (
            <span className={baseClassName + '-text'}>Tweeter</span>
          )}
        </Button>
      )}

      {options.facebook !== false && (
        <Button
          href={`https://www.facebook.com/sharer.php?u=${encodedLink}&t=${encodedTitle}`}
          target="_blank"
          rel="noopener noreferrer"
          className={baseClassName + '-facebook'}
          title="Partager ce contenu sur Facebook"
          size="small"
          iconButton={iconOnly}
        >
          <Icon name="facebook-f" type="brand" />
          {!iconOnly && (
            <span className={baseClassName + '-text'}>Partager</span>
          )}
        </Button>
      )}

      {options.linkedin === true && (
        <Button
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}&title=${encodedTitle}`}
          target="_blank"
          rel="noopener noreferrer"
          className={baseClassName + '-linkedin'}
          title="Partager ce contenu sur Linkedin"
          size="small"
          iconButton={iconOnly}
        >
          <Icon name="linkedin" type="brand" />
          {!iconOnly && (
            <span className={baseClassName + '-text'}>Partager</span>
          )}
        </Button>
      )}

      {options.pinterest === true && (
        <Button
          href={`http://pinterest.com/pin/create/button/?url=${encodedLink}&description=${encodedTitle}`}
          target="_blank"
          rel="noopener noreferrer"
          className={baseClassName + '-pinterest'}
          title="Partager ce contenu sur Pinterest"
          size="small"
          iconButton={iconOnly}
        >
          <Icon name="pinterest" type="brand" />
          {!iconOnly && (
            <span className={baseClassName + '-text'}>Partager</span>
          )}
        </Button>
      )}
    </div>
  );
}

SharingButtonsV2.baseClassName = baseClassName;
