// @flow
import type { Link } from '@/types/models';

import { interpolateContent, type InterpolateOptions } from './interpolation';

export const getCookieDomain = (): string => {
  if (typeof window === 'undefined') return '';
  const url = new URL(window.location.href);
  const hostname = url.hostname.replace('www', '');
  if (hostname.startsWith('localhost')) return 'localhost';
  return `.${hostname}`;
};

export function formatQueryParams(
  queryParams: string | Object,
  interpolateOptions: InterpolateOptions
): string {
  const { fullUrl } = interpolateOptions;

  switch (typeof queryParams) {
    case 'string':
      return queryParams
        .split('&')
        .map((chunk) => {
          const [key, value] = chunk.split('=');
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            interpolateContent(value, { fullUrl })
          )}`;
        })
        .join('&');
    case 'object':
      const builtQueryParams = Object.keys(queryParams)
        .map((key) => {
          const value = queryParams[key];
          return `${encodeURIComponent(key)}=${encodeURIComponent(
            interpolateContent(value, { fullUrl })
          )}`;
        })
        .join('&');
      return builtQueryParams;
    default:
      return queryParams;
  }
}

export const isPathnameActive = (
  fullPathname: string = '',
  pathname: string = ''
): boolean => fullPathname.startsWith(pathname);

export const isLinkActive = (
  fullPathname: string = '',
  link: Link,
  { dynamicLinks }: any
): boolean => {
  if (!link) return false;
  if (link.url) return fullPathname.startsWith(link.url);
  if (link.pageId && dynamicLinks)
    return fullPathname.startsWith(dynamicLinks[link.pageId]?.path);
  return false;
};
