import * as React from 'react';
import { groupBy, sortBy } from 'lodash';
import moment from 'moment';
import classnames from 'classnames';

import FutureGame from './games/FutureGame';
import PlayedGame from './games/PlayedGame';

export default function OrderedGames({
  games,
  order = 1,
  userBets,
  makeBet,
  teams,
  linkToParticipant,
}) {
  const groupedByDay = groupBy(games, 'startDayTs');

  return (
    <div className="ordered-games">
      {sortBy(Object.keys(groupedByDay), (dayTs) => order * dayTs).map(
        (dayTs, dayIndex) => {
          const dayGames = groupedByDay[dayTs];

          const isToday = moment
            .unix(dayTs)
            .startOf('day')
            .isSame(moment().startOf('day'));
          // const isLast = dayIndex === Object.keys(groupedByDay).length - 1;

          return (
            <div
              key={dayIndex}
              className={classnames('day-games', {
                'is-today': isToday,
              })}
            >
              <h3 className="day-title">
                {isToday ? "Aujourd'hui" : moment.unix(dayTs).format('LL')}
              </h3>

              <ul className="games-of-the-day">
                {sortBy(dayGames, 'startTs').map((game) => {
                  return (
                    <li key={game._id} className="game-wrapper">
                      {game.isPast ? (
                        <PlayedGame
                          key={game._id}
                          game={game}
                          bet={userBets[game._id]}
                          participant1={teams[game.participant1?._id]}
                          participant2={teams[game.participant2?._id]}
                          linkToParticipant={linkToParticipant}
                        />
                      ) : (
                        <FutureGame
                          key={game._id}
                          game={game}
                          makeBet={makeBet}
                          bet={userBets[game._id]}
                          participant1={teams[game.participant1?._id]}
                          participant2={teams[game.participant2?._id]}
                          linkToParticipant={linkToParticipant}
                        />
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        }
      )}
    </div>
  );
}
