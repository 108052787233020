// @flow
import * as React from 'react';
import { useRouter } from 'next/router';

import type { FullCartState } from '@/types/stores';
import type { User } from '@/types/models';
import type { PaymentIntent } from '@/types/stripe';
import type { Options } from '../components/CommonWrapper';

import { CartStoreActions } from '@/reflux';
import { assert } from '@/helpers/invariant';
import API from '@/utils/API';

import { PaymentInput } from '@/PUFComponents/components';

type Props = {|
  user: User,
  cartState: FullCartState,
  options: Options,
|};

export default function Step3PaymentAndBillingAddress({
  user,
  cartState,
}: Props): React.Node {
  const { shippingAddress, shippingAddressRequired, total, instanceId } =
    cartState;
  const paymentIntent = assert(
    cartState.paymentIntent,
    'Payment intent should be defined at this point'
  );
  const [paying, setPaying] = React.useState(false);
  const wrapperRef = React.useRef(null);
  const router = useRouter();

  const updatePaymentIntent = async (intent: PaymentIntent) => {
    setPaying(true);
    CartStoreActions.updatePaymentIntent(intent, {
      onSuccess: async () => {
        const [payment] = await API.get(`${instanceId}/data/Payment`, {
          filter: { 'intent.id': intent.id },
        });
        await router.push(`/payments/${payment._id}`);
        return;
      },
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (wrapperRef.current)
        wrapperRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }, [wrapperRef]);

  return (
    <div className="step-3-address-and-payment" ref={wrapperRef}>
      <div className="payment-field">
        <label className="payment-input-label">Paiement</label>

        <PaymentInput
          disabled={
            paying || (shippingAddressRequired && !shippingAddress?._isValid)
          }
          user={user}
          actionLabel={`REGLER ${total}€`}
          intent={paymentIntent}
          onPaymentInformationEntered={updatePaymentIntent}
        />
      </div>
    </div>
  );
}
