import React from 'react';

import OrderedGames from './components/OrderedGames';

function PastGames({ pastGames, userBets, makeBet, teams, linkToParticipant }) {
  return (
    <section className="past-games">
      <OrderedGames
        games={pastGames}
        order={-1}
        userBets={userBets}
        makeBet={makeBet}
        teams={teams}
        linkToParticipant={linkToParticipant}
      />
    </section>
  );
}

export default PastGames;
