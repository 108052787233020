// not used - can be done with a CC

import React from 'react';

import FrontComponentBase from '../../FrontComponentBase';

class ProductContributors extends FrontComponentBase {
  static baseClassName = 'ProductContributors';

  render() {
    let {
      prefixContribs,
      suffixContribs,
      prefixMax,
      suffixMax,
      noContribsLabel,
    } = this.getOptions();

    let entity = this.getSingleEntity();
    if (!entity) return null;
    if (!entity._purchaseCount && !noContribsLabel) return null;

    const baseClassName = this.constructor.baseClassName;

    return (
      (entity._purchaseCount > 0 && (
        <div className={this.getMainCSSClass()}>
          <span className={baseClassName + '-label'}>{prefixContribs}</span>
          <span className={baseClassName + '-value'}>
            {entity._purchaseCount}
          </span>
          <span className={baseClassName + '-label'}>{suffixContribs}</span>
          {entity.maxTickets && (
            <>
              <span className={baseClassName + '-label'}>{prefixMax}</span>
              <span className={baseClassName + '-value'}>
                {entity.maxTickets}
              </span>
              <span className={baseClassName + '-label'}>{suffixMax}</span>
            </>
          )}
        </div>
      )) || (
        <div className={baseClassName + '-inner'}>
          <span className={baseClassName + '-label'}>{noContribsLabel}</span>
        </div>
      )
    );
  }
}

export default ProductContributors;
