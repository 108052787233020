import React from 'react';

import { useContent } from '@/hooks';

const baseClassName = 'PUF-entity-content-type';

export default function EntityContentTypeV2({ options, style }) {
  const { mainContentType } = useContent();

  if (!mainContentType?.label) return null;

  const { Element = 'span' } = options;

  return (
    <Element className={baseClassName} style={style}>
      {mainContentType.label}
    </Element>
  );
}

EntityContentTypeV2.baseClassName = baseClassName;
