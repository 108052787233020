import React from 'react';

import PaginatedData from '@/utils/PaginatedData';

import ComponentBase from '../../../../../ComponentBase';
import RawComment from './RawComment';

export const COMMENTS_TO_LOAD = 30;
export const ANSWERS_TO_LOAD = 5;
export const ANSWERS_TO_LOAD_MORE = 50;

// TODO css classes
export default class Comment extends ComponentBase {
  static baseClassName = 'Comment';

  render() {
    const { comment, user, maxCommentLength, canWrite } = this.props;
    const { moreAnswers } = this.state;

    const initiallyLoadedAnswers = comment.answers || [];
    const answers = initiallyLoadedAnswers.concat(moreAnswers || []);
    const hasMore = answers.length < comment.answersCount;
    const componentBaseClass = this.constructor.baseClassName;

    return (
      <div className={componentBaseClass}>
        <RawComment
          comment={comment}
          user={user}
          level={0}
          maxCommentLength={maxCommentLength}
          canWrite={canWrite}
          additionalClassName="original-comment"
        />

        {answers.map((answser) => (
          <RawComment
            comment={answser}
            user={user}
            level={1}
            key={answser._id}
            maxCommentLength={maxCommentLength}
            canWrite={canWrite}
            additionalClassName="answer"
          />
        ))}

        {hasMore && (
          <p className={`${componentBaseClass}-load-more`}>
            <a
              className={`${componentBaseClass}-load-more-button`}
              onClick={this.loadMoreAnswers}
            >
              Charger plus de réponses...
            </a>
          </p>
        )}
      </div>
    );
  }

  didMount = () => {
    const { comment, answersToLoad, answersToLoadMore } = this.props;

    this.observeProp(
      'comment._id',
      (commentId, old) => {
        // TODO clear answers?

        // We don't have all the answers ? set up for paginated loading
        if (comment.answers && comment.answers.length < comment.answersCount) {
          console.debug('Load comment ' + commentId + ' answers...');

          this.paginatedAnswers && this.paginatedAnswers.stopLoading();

          this.paginatedAnswers = new PaginatedData(
            'UserComment',
            {
              filter: { rootId: commentId },
              depDepth: 0,
              withAnswers: false,
              withMyLikes: true,
              sortBy: { createTs: -1 },
            },
            {
              perPage:
                answersToLoadMore || answersToLoad || ANSWERS_TO_LOAD_MORE,
              startOffset: answersToLoad || ANSWERS_TO_LOAD,
            },
            (data, count) => {
              this.setState({
                moreAnswers: data,
              });
            },
            false // don't load now !!
          );
        }
      },
      false
    );
  };

  loadMoreAnswers = () => {
    this.paginatedAnswers && this.paginatedAnswers.loadMore();
  };

  willUnmount = () => {
    this.paginatedAnswers && this.paginatedAnswers.stopLoading();
  };
}
