import React from 'react';

import OrderedGames from './components/OrderedGames';

function FutureGames({
  futureGames,
  userBets,
  makeBet,
  teams,
  linkToParticipant,
}) {
  return (
    <section className="future-games">
      <OrderedGames
        games={futureGames}
        order={1}
        userBets={userBets}
        makeBet={makeBet}
        teams={teams}
        linkToParticipant={linkToParticipant}
      />
    </section>
  );
}

export default FutureGames;
