// @flow
import { omit, find } from 'lodash';
import { useRouter } from 'next/router';
import * as React from 'react';
import classnames from 'classnames';
import Reflux from 'reflux';

import type {
  UserStoreProps,
  CartStoreProps,
  FullCartState,
} from '@/types/stores';
import type { SiteConfigOptions } from '@/types/site';
import type { File, User, Resource, FeatureFlags } from '@/types/models';
import type { PUFComponentProps } from '@/types/component';

import { withStore } from '@/utils/HOC';
import { CHECKOUT_STEP } from '@/reflux/CartStore/constants';
import CartStoreActions from '@/reflux/CartStore/actions';
import { getDerivedState } from '@/reflux/CartStore/helpers';
import { isDev } from '@/helpers/env';
import { useSite, useSiteOptions, useLinks } from '@/hooks';
import { safeJsonParse } from '@/utils/misc';
import API from '@/utils/API';
import StripeProvider from '@/pageComponents/contexts/StripeProvider';
import { CartContext } from '@/globals/contexts';

import { Button } from '@/components/new';
import { SoftMaintenance } from '@/PUFComponents/components';

export type Mode = 'default' | 'simple';

export type Options = {|
  backPageId?: string,
  label?: string, // Title
  donationLabel?: string,
  thankYouLabel?: string, // On success label
  allowDiscount?: boolean,
  allowDonation?: boolean,
  maxDonation?: number,
  hideJustif?: boolean,
  discountLabel?: string,
  discountPlaceholderLabel?: string,
  discountButtonLabel?: string,
|};
// buttonLabel?: string, // Probably not used
// itemLabel?: string, // Probably not used (Group functionality)
// hideRecap?:  boolean, // Most likely legacy
// allowChangeQuantity?: boolean, // Most likely legacy
// checkIntroText?: string, // Used only in TrainingsSectionCheckout
// checkButtonLabel?: string, // Used only in TrainingsSectionCheckout
// checkSentText?: string, // Used only in TrainingsSectionCheckout
// callIntroText?: string, // Used only in TrainingsSectionCheckout
// callButtonLabel?: string, // Used only in TrainingsSectionCheckout
// callSentText?: string, // Used only in TrainingsSectionCheckout
// addMoreLabel?: string, // Most likely legacy
// paidText?: string, // Used only in TrainingsSectionCheckout
// hideQuantity?: boolean,  // Most likely legacy (Group functionality)

type ComponentProps = {|
  onPaid: () => void,
  justif: ?File,
  hideJustif?: boolean,
|};

export type OwnProps = {|
  ...PUFComponentProps<ComponentProps, Options, Mode, void>,
|};

type Props = {|
  ...OwnProps,
  ...UserStoreProps,
  ...CartStoreProps,
|};

export type ProvidedProps = {|
  user: ?User,
  cartState: FullCartState,
  siteOptions: SiteConfigOptions,
  options: Options,
  componentProps: ComponentProps,
  featureFlags: FeatureFlags,
  goBackToProducts?: () => any,
|};

export default function wrapCheckout(
  CartCheckoutComponent: React.ComponentType<ProvidedProps>
): React.AbstractComponent<OwnProps> {
  function CartCheckoutWrapper({
    options,
    componentProps,
    user,
    mode,
    instanceName,
    featureFlags,
    computedMode,
    ...otherProps
  }: Props): React.Node {
    const router = useRouter();
    const site = useSite();
    const siteOptions = useSiteOptions();
    const { query } = router;
    const [initialized, setInitialized] = React.useState(false);
    const cartState: CartStoreProps = omit(otherProps, [
      'anonymousViewCount',
      'component',
      'innerRef',
      'userLoaded',
    ]);
    const derivedCartState = getDerivedState(cartState);

    const { dynamicPages } = useLinks();

    const goBackToProducts = React.useCallback(() => {
      const backPageId = options?.backPageId ?? siteOptions?.subscribePageId;
      if (!backPageId) return;

      const page = find(dynamicPages, (page) => page._id === backPageId);

      if (!page) return;

      const { path: backPagePath } = page;
      router.push(backPagePath);
    }, [router, options, siteOptions, dynamicPages]);

    // Legacy effect to support old links with item=<json>
    // Fetch and add item if present in query params
    React.useEffect(() => {
      const fetchItemLegacy = async () => {
        const itemRef = safeJsonParse(query.item);
        if (!itemRef) return;

        const item = await API.get<Resource<*>>(
          `${instanceName}/data/${itemRef._cls}/${itemRef._id}`
        );

        CartStoreActions.addItem(item);
      };

      if (query.item) {
        fetchItemLegacy();
      }
    }, [query.item, instanceName]);

    React.useEffect(() => {
      const fetchItem = async () => {
        const itemId = query.itemId;
        const itemCls = query.itemCls;
        if (!itemId || !itemCls) return;

        const item = await API.get<Resource<*>>(
          `${instanceName}/data/${itemCls}/${itemId}`
        );

        if (!item) return;

        CartStoreActions.addItem(item);
      };

      if (query.itemId && query.itemCls) {
        fetchItem();
      }
    }, [query.itemId, query.itemCls, instanceName]);

    React.useEffect(() => {
      if (query.isGift === 'true') {
        CartStoreActions.setIsGift(true);
      }
    }, [query.isGift]);

    React.useEffect(() => {
      CartStoreActions.initializeCheckout({
        user,
        checkoutOptions: options,
      });
      setInitialized(true);
    }, [user?._id]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (
        !user?._id &&
        cartState.currentStep === CHECKOUT_STEP.ADDRESS_AND_PAYMENT
      ) {
        CartStoreActions.goToPreviousStep();
      }
    }, [user?._id, cartState.currentStep]);

    if (!!site.softMaintenanceMode) return <SoftMaintenance />;

    if (!initialized) return null;

    return (
      <StripeProvider>
        <CartContext.Provider
          value={{
            options,
          }}
        >
          <div className={classnames('cart-checkout', computedMode)}>
            <CartCheckoutComponent
              siteOptions={siteOptions}
              options={options}
              componentProps={componentProps}
              user={user}
              cartState={{
                ...derivedCartState,
                ...cartState,
              }}
              goBackToProducts={goBackToProducts}
              featureFlags={featureFlags}
            />

            {isDev() && (
              <Button
                onClick={() => console.log(Reflux.getGlobalState().CartStore)}
              >
                Debug
              </Button>
            )}
          </div>
        </CartContext.Provider>
      </StripeProvider>
    );
  }

  return (withStore(
    ['UserStore', 'CartStore'],
    CartCheckoutWrapper
  ): React.ComponentType<OwnProps>);
}
