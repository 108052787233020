// Template for all user related buttons (login/logout, register...)

import React from 'react';

import { UserStore, UserActions } from '@/reflux';
import { withLinks } from '@/utils/HOC';

import FrontComponentBase from '../../FrontComponentBase';
import { Search } from '../components';
import PageLink from './PageLinkV1';

class UserButtons extends FrontComponentBase {
  static baseClassName = 'UserButtons';
  stores = [UserStore];

  logout = () => {
    UserActions.logout();
  };

  render() {
    const { links } = this.props;
    const {
      login: { login: loginLink, register: registerLink },
      myAccount: myAccountLinks,
    } = links;
    let options = this.getOptions();
    let user = this.state.user;

    let baseClassName = this.constructor.baseClassName;

    return (
      <div className={baseClassName}>
        {options.showSearch !== false && (
          <Search placeholder={options.searchLabel || 'Rechercher'} />
        )}

        {options.showGiftButton && (
          <PageLink link={links.gift}>
            <a className={baseClassName + '-gift'}>
              {options.giftLabel || 'Offrir'}
            </a>
          </PageLink>
        )}

        {!user && (
          <PageLink link={loginLink}>
            <a className={baseClassName + '-login'}>
              {options.loginLabel || <span>Se&nbsp;connecter</span>}
            </a>
          </PageLink>
        )}

        {(!user || !user.isPremium) && (
          <PageLink link={registerLink}>
            <a className={baseClassName + '-subscribe'}>
              {options.subscribeLabel || "S'abonner"}
            </a>
          </PageLink>
        )}

        {user && user.isPremium && (
          <PageLink link={myAccountLinks.profile}>
            <a className={baseClassName + '-account'}>
              {options.myAccountLabel || 'Mon compte'}
            </a>
          </PageLink>
        )}

        {user && (
          <span onClick={this.logout} className={baseClassName + '-logout'}>
            {options.logoutLabel || 'Se déconnecter'}
          </span>
        )}
      </div>
    );
  }
}

export default withLinks(UserButtons);
