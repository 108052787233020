import _ from 'lodash';
import React from 'react';

import { CartStore, CartStoreActions } from '@/reflux';
import { NBSP, prettyPrice } from '@/utils/misc';
import { withGlobal } from '@/utils/HOC';

import { ValueSlider, InputCheckboxBase } from '@/components/old';
import FrontComponentBase from '../../FrontComponentBase';
import { goToPage, followLink } from '../navigation/PageLinkV1';

class AddToCartButton extends FrontComponentBase {
  static baseClassName = 'AddToCartButton';

  store = CartStore;

  state = {
    stock: {
      canBuy: false,
    },
    userPrice: null,
  };

  onSetPrice = (userPrice) => {
    this.setState({ userPrice });
  };

  // public // called by parent
  onClick = () => {
    console.debug('on click called - GTM');
    let entity = this.getSingleEntity();
    let { cartItems } = this.state;
    const isAdded = entity && !!cartItems[entity._id];
    isAdded ? this.onRemove(null) : this.onAdd(null);
  };

  onAdd = (e) => {
    e && e.stopPropagation(); // prevent "double" action on click
    const { globals } = this.props;
    const {
      links,
      siteConfig: { options: siteOptions },
      featureFlags,
    } = globals;

    let entity = this.getSingleEntity();
    console.debug('on add called - GTM -', entity);
    let { userPrice } = this.state;

    // If an external url is set, dont follow the normal flow
    if (entity.afterAddLink?.url) return followLink(entity.afterAddLink);
    if (featureFlags.magellan) return followLink(links.subscribe);

    if (entity.variablePrice) {
      CartStoreActions.addItem(
        entity,
        1,
        userPrice !== null
          ? userPrice
          : _.isNumber(entity.initialPrice)
          ? entity.initialPrice
          : entity.price
      );
    } else {
      CartStoreActions.addItem(entity);
    }

    setTimeout(() => {
      if (entity.afterAddLink) return followLink(entity.afterAddLink);

      let pageId =
        entity.pageIdAfterAdd ||
        siteOptions.cartPageId ||
        this.getOptions().pageId; // TODO which priority?
      if (pageId) {
        goToPage(pageId);
      }
    }, 2000);
  };

  onRemove = (e) => {
    e && e.stopPropagation(); // prevent "double" action on click

    CartStoreActions.removeItem(this.getSingleEntity()?._id);
  };

  didMount() {
    const bindCanBuy = this.getRebindToCollectionFunc('stock');

    this.observeSingleEntity((entity) => {
      if (entity) {
        bindCanBuy(
          entity._cls + '/' + encodeURIComponent(entity._id) + '/stock'
        );
      } else {
        bindCanBuy(null); // just remove binding
      }
    });
  }

  render() {
    let { stock, stock_loading, cartItems, cartError } = this.state;
    const options = this.getOptions();
    let { disabledLabel } = options;
    let entity = this.getSingleEntity();

    if (!entity) return null;
    let isAdded = !!cartItems[entity._id];

    const baseClassName = this.constructor.baseClassName;

    return (
      (stock && (
        <div
          className={this.getMainCSSClass({
            loading: stock_loading,
            unavailable: !stock_loading && !stock.canBuy,
            selected: isAdded,
          })}
        >
          {options.mode === 'checkbox' && (
            <>
              <InputCheckboxBase
                value={isAdded}
                onChange={isAdded ? this.onRemove : this.onAdd}
                disabled={!stock.canBuy}
              />

              <div
                className={baseClassName + '-label'}
                onClick={
                  stock.canBuy
                    ? isAdded
                      ? this.onRemove
                      : this.onAdd
                    : undefined
                }
              >
                {entity.label}
              </div>

              {stock.canBuy && entity.displayLastTickets && (
                <div className={baseClassName + '-last'}>
                  {' '}
                  - Derniers exemplaires
                </div>
              )}

              {!stock.canBuy && (
                <div className={baseClassName + '-disabled'}>
                  {' '}
                  - {disabledLabel || 'Epuisé !'}
                </div>
              )}

              {entity.discountLabel && (
                <div className={baseClassName + '-discount-label'}>
                  {entity.discountLabel}
                </div>
              )}

              <div className={baseClassName + '-price'}>
                {prettyPrice(entity.price)}
              </div>
            </>
          )}

          {stock.canBuy &&
            entity.variablePrice &&
            ((!isAdded && ( // for now, we don't handle multiple variable prices of the same item
              <ValueSlider
                label={
                  options.variablePriceLabel ||
                  'Choisissez le montant de votre don :'
                }
                initialValue={
                  _.isNumber(entity.initialPrice)
                    ? entity.initialPrice
                    : entity.price
                }
                minVal={entity.price}
                maxVal={entity.maxPrice}
                unit={NBSP + '€'}
                valueLabel={options.sliderLabel || 'Votre don :'}
                onChange={this.onSetPrice}
              />
            )) || (
              <div className="EntityPrice-price">
                {options.sliderLabel || 'Votre don :'}{' '}
                {prettyPrice(cartItems[entity._id].userPrice)}
              </div>
            ))}

          <CartButton
            options={options}
            isAdded={isAdded}
            stock={stock}
            stock_loading={stock_loading}
            onRemove={this.onRemove}
            onAdd={this.onAdd}
          />

          {cartError && <span className="error">{cartError.reason}</span>}
        </div>
      )) ||
      null
    );
  }
}

export default withGlobal(AddToCartButton);

function CartButton({
  options,
  stock,
  stock_loading,
  isAdded,
  onRemove,
  onAdd,
}) {
  const { addLabel, removeLabel, disabledLabel, mode } = options;

  if (mode === 'checkbox') return null;

  if (stock_loading) {
    return (
      <button className="standard-button " disabled={true} title={stock.reason}>
        ...
      </button>
    );
  }

  if (!stock.canBuy) {
    return (
      <button className="standard-button " disabled={true} title={stock.reason}>
        {disabledLabel || 'Complet'}
      </button>
    );
  }

  if (isAdded) {
    return (
      <button
        className="standard-button "
        title={'cliquez pour supprimer du panier'}
        onClick={onRemove}
      >
        {removeLabel || 'Annuler'}
      </button>
    );
  }

  return (
    <button
      className="standard-button "
      title={stock.remaining && stock.remaining + ' restants'}
      onClick={onAdd}
    >
      {addLabel || "Je m'abonne"}
    </button>
  );
}
