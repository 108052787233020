import React from 'react';
import classnames from 'classnames';

import { computeStyleFromStyleProperties } from '@/helpers/rendering/PUFComponent';

import { Columns as LayoutColumns } from '@/components/new';
import Column, { getClassName as getColumnClassName } from '../Column/ColumnV2';
import ContentsManager from '../Column/ContentsManager';
import { ImageContext } from '@/globals/contexts';

export default function RowV2({ row = {}, children, isPageWrapper }) {
  const { cols: columns, options = {} } = row;
  const {
    className: optionClassName,
    styleProperties = [],
    loadImagesSync,
    breakpointViewport,
  } = options;

  const { loadImagesSync: parentLoadImagesSync } =
    React.useContext(ImageContext);
  const shouldLoadImagesSync =
    loadImagesSync !== undefined ? loadImagesSync : parentLoadImagesSync;

  const hasMultipleColumns = !!columns && columns.length > 1;
  const style = computeStyleFromStyleProperties(styleProperties);

  if (!columns) {
    return (
      (
        <ImageContext.Provider value={{ loadImagesSync: shouldLoadImagesSync }}>
          {children}
        </ImageContext.Provider>
      ) || null
    ); // Should almost never happen
  } else if (!hasMultipleColumns && columns[0].size === 12) {
    return (
      <ImageContext.Provider value={{ loadImagesSync: shouldLoadImagesSync }}>
        <div
          className={classnames(
            'column-replacer',
            getColumnClassName(columns[0]),
            optionClassName
          )}
          style={style}
        >
          <ContentsManager column={columns[0]} />
        </div>
      </ImageContext.Provider>
    );
  }

  return (
    <ImageContext.Provider value={{ loadImagesSync: shouldLoadImagesSync }}>
      <LayoutColumns
        additionalClassName={classnames(optionClassName)}
        breakpointViewport={breakpointViewport}
        style={style}
      >
        {columns.map((column, i) => (
          <Column column={column} key={column.uuid || i} />
        ))}
      </LayoutColumns>
    </ImageContext.Provider>
  );
}
