import React from 'react';
import { useRouter } from 'next/router';

import { NamespacesContext } from '@/globals/contexts';

import { Icon } from '@/components/new';
import PageLinkV2 from './PageLinkV2';

const baseClassName = 'PUF-breadcrumb';

export default function BreadCrumb({ options }) {
  const namespaces = React.useContext(NamespacesContext);
  const router = useRouter();

  const breadCrumbNamespaces = getBreadCrumbNamespaces(
    router.asPath,
    namespaces
  );

  if (!breadCrumbNamespaces) return null;

  const { homeLabel } = options;

  return (
    <nav aria-label="Breadcrumb" className={baseClassName} role="navigation">
      <ul>
        <li key="/home">
          <PageLinkV2
            link={{
              pageId: 'home',
            }}
          >
            {(href) => (
              <a href={href}>
                <Icon name="home" style={{ marginRight: 5 }} />
                {homeLabel ? ` ${homeLabel}` : ''}
              </a>
            )}
          </PageLinkV2>
        </li>

        {breadCrumbNamespaces.map((namespace) => {
          const breadCrumbElementContent = (
            <span>
              {namespace.breadcrumbIcon?.name && (
                <Icon
                  name={namespace.breadcrumbIcon.name}
                  style={{ marginRight: 5 }}
                />
              )}
              {namespace.breadcrumbLabel}
            </span>
          );

          if (namespace.fullPath === router.asPath)
            return (
              <li className="current" key={namespace.fullPath}>
                {breadCrumbElementContent}
              </li>
            );

          return (
            <li key={namespace.fullPath}>
              <PageLinkV2
                link={{
                  pageId: namespace.homePage,
                }}
              >
                {(href) => <a href={href}>{breadCrumbElementContent}</a>}
              </PageLinkV2>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

BreadCrumb.baseClassName = baseClassName;

const findMatchingNamespace = (pathname, namespaces) => {
  const matchingNamespaces = namespaces.filter(
    (namespace) => pathname.startsWith(namespace.path) && namespace.homePage
  );

  if (matchingNamespaces.length === 0) return null;

  let bestMatchingNamespace = null;

  for (const matchingNamespace of matchingNamespaces) {
    if (
      !bestMatchingNamespace ||
      matchingNamespace.path.length > bestMatchingNamespace.path.length
    )
      bestMatchingNamespace = matchingNamespace;
  }

  return bestMatchingNamespace;
};

const getBreadCrumbNamespaces = (pathname, namespaces) => {
  const bestMatchingNamespace = findMatchingNamespace(pathname, namespaces);

  if (!bestMatchingNamespace) return [];

  return bestMatchingNamespace.chain.filter((namespace) => namespace.homePage);
};
