import React from 'react';

import { InstanceStore, UserStore } from '@/reflux';
import API from '@/utils/API';

import ComponentBase from '../../ComponentBase';
import { Button } from '@/components/old';

export default class ForgotPassBase extends ComponentBase {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      success: false,
    };

    this.stores = [InstanceStore, UserStore];
  }

  onStartLoading = () => {
    this.setState({ loading: true, success: false, error: null });
  };

  onSuccess = (user) => {
    this.setState({ loading: false, success: true, error: null });
    this.props.onSuccess && this.props.onSuccess();
  };

  onError = (err) => {
    this.setState({ loading: false, error: err.message }); // TEMP
  };

  forgotPass = () => {
    let { login, instanceName } = this.state;
    this.onStartLoading();
    API.post(instanceName + '/users/forgotPass', {
      login: login,
    })
      .then(this.onSuccess)
      .catch(this.onError);
  };

  render() {
    let { login, error, loading, success } = this.state;

    return (
      <div className="ForgotPassBase">
        <form className="RegularForm RegularForm--login">
          <p className="description">
            Merci de saisir votre email, nous vous enverrons un message afin de
            réinitialiser votre mot de passe.
          </p>

          <div className="RegularForm-field">
            <input
              name="login"
              autoComplete="email"
              placeholder="E-mail"
              type="email"
              className="RegularForm-text-input"
              value={login || ''}
              onChange={(e) => this.setState({ login: e.target.value })}
            />
          </div>

          <div className="RegularForm-field">
            <Button isSubmit onClick={this.forgotPass} disabled={loading}>
              Recevoir l'e-mail de réinitialisation
            </Button>
          </div>

          {loading && <span>Envoi en cours... </span>}

          {success && (
            <div>
              <h3 style={{ marginBottom: 10 }}>E-mail envoyé !</h3>
              <p style={{ margin: 0 }}>
                Pensez à regarder dans vos spam si vous ne trouvez pas ce mail.
              </p>
            </div>
          )}

          {error && <span className="error">{error}</span>}
        </form>
      </div>
    );
  }
}
