import React from 'react';
import moment from 'moment';

import { Flex, Image } from '@/components/new';
import EntityLink from '../../navigation/EntityLink';

function FutureGame({ game, participantId, teams }) {
  const opponentId =
    game.game.participant1._id === participantId
      ? game.game.participant2._id
      : game.game.participant1._id;
  const opponent = teams[opponentId];
  const { image, label } = opponent;

  return (
    <Flex verticalAlign direction="column">
      <p className="date-hour">
        {moment.unix(game.game.startTs).format('DD/MM/YY HH:mm')}
      </p>

      <Flex verticalAlign additionalClassName="game">
        <p className="vs">vs</p>
        <EntityLink entity={opponent}>
          <a style={{ fontSize: 0 }} className="flag-name_wrapper">
            <Image
              src={image}
              width={40}
              height={40}
              alt={`Drapeau ${label}`}
              defaultNextImage
              additionalClassName="participant-image"
            />
            <p>{label}</p>
          </a>
        </EntityLink>
      </Flex>
    </Flex>
  );
}

export default FutureGame;
