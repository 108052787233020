/**
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import React from 'react';

import FrontComponentBase from '../../../FrontComponentBase';
import RawComponent from '../../../RawComponent';

export default class BookmarkThumb extends FrontComponentBase {
  static baseClassName = 'ContentThumb'; // TODO: corriger classe CSS

  render() {
    let content = this.getSingleEntity();

    return (
      <a
        className={this.getMainCSSClass('BookmarkThumb')}
        target="_blank"
        rel="noopener noreferrer"
        href={content.link}
      >
        <div
          className={
            this.constructor.baseClassName + '-inner BookmarkThumb-inner'
          }
        >
          <RawComponent
            id="EntityImage"
            options={{
              hasAlpha: true,
            }}
            componentProps={this.props}
          />
          <h2 className={'BookmarkThumb-label'}>{content.label}</h2>
        </div>
      </a>
    );
  }
}
