// @flow
import React from 'react';

import type {
  LayoutComponents,
  LayoutData,
  LayoutComponentsData,
  LayoutCustomModes,
} from '@/types/layout';

export const LayoutComponentsContext = (React.createContext(
  {}
): React$Context<LayoutComponents>);

export const LayoutDataContext = (React.createContext(
  {}
): React$Context<LayoutData>);

export const LayoutComponentsDataContext = (React.createContext(
  {}
): React$Context<LayoutComponentsData>);

export const LayoutCustomModesContext = (React.createContext(
  {}
): React$Context<LayoutCustomModes>);
