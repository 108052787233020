import * as React from 'react';

import { computeStyleFromStyleProperties } from '@/helpers/rendering/PUFComponent';

export default function ColumnGroup({ children, group }) {
  const { options } = group;

  return (
    <div
      style={computeStyleFromStyleProperties(options.styleProperties || [])}
      className={options.className}
      id={options.htmlID}
    >
      {children}
    </div>
  );
}
