import React from 'react';
import { useEffectOnce } from 'usehooks-ts';

import API from '@/utils/API';
import { hasSufficientRole } from '@/helpers/roles';

import Comment from './Comment';
import NewComment from './NewComment';
import { Button, Notification } from '@/components/new';

const FETCH_LIMIT = 20;

const baseClassName = 'PUF-comment-list';

const INITIAL_STATE = {
  loading: true,
  currentPage: 0,
  totalCount: 0,
};

export default function CommentListV2({ entity: content, site, siteId, user }) {
  const [state, setState] = React.useState(INITIAL_STATE);
  const [comments, setComments] = React.useState([]);

  const { _id: contentId } = content;

  const { commentsAccess } = site;

  const canReadComments = hasSufficientRole(user, commentsAccess.read);
  const canWriteComments = hasSufficientRole(user, commentsAccess.write);
  const canReadCommentsRef = React.useRef(canReadComments);

  const { totalCount, currentPage, loading } = state;
  const hasMore = comments.length < totalCount;

  const fetchComments = React.useCallback(async () => {
    const { comments: receivedComments, totalCount } = await API.get(
      `${siteId}/comments/of-content`,
      {
        contentId: contentId,
        offset: currentPage * FETCH_LIMIT,
        limit: FETCH_LIMIT,
      }
    );

    const newComments = [...comments, ...receivedComments];
    setComments(newComments);

    setState((prevState) => {
      return {
        ...prevState,
        totalCount,
        currentPage: currentPage + 1,
        loading: false,
      };
    });
  }, [contentId, siteId, currentPage, comments]);

  useEffectOnce(() => {
    if (canReadComments) fetchComments();
  });

  React.useEffect(() => {
    if (
      canReadCommentsRef.current &&
      canReadCommentsRef.current !== canReadComments
    ) {
      canReadCommentsRef.current = canReadComments;
      fetchComments();
    }
  }, [canReadComments, fetchComments]);

  if (!canReadComments) {
    return (
      <div className={baseClassName}>
        <div className="empty-placeholder">
          <p>Vous n'avez pas accès aux commentaires de ce contenu.</p>
          <p>
            Pour accéder aux commentaires, veuillez vous connecter ou vous
            abonner.
          </p>
        </div>
      </div>
    );
  }

  if (canReadComments && loading)
    return (
      <div className={baseClassName}>
        <div className="loading-placeholder">
          Chargement des commentaires...
        </div>
      </div>
    );

  return (
    <div className={baseClassName}>
      {canWriteComments ? (
        <NewComment
          contentId={contentId}
          onAfterSubmit={(newComment) => setComments([newComment, ...comments])}
        />
      ) : (
        <Notification color="warning" light>
          Pour participer aux conversations et commenter les articles, vous
          devez être abonné. Si vous l'êtes déjà, pensez à vous connecter !
        </Notification>
      )}

      <div className="list">
        {comments.map((comment) => (
          <Comment
            key={comment._id}
            contentId={contentId}
            comment={comment}
            isRoot
            onAfterComment
          />
        ))}
      </div>

      {hasMore && (
        <div className="load-more-wrapper">
          <Button onClick={fetchComments} isText>
            Charger plus de réponses...
          </Button>
        </div>
      )}
    </div>
  );
}
