// @flow
import { isInteger } from 'lodash';

const NBSP = '\u202F';
export function prettyPrice(
  price: number,
  forceDigits: boolean = false,
  currencyDisplay?: 'symbol' | 'code' | 'name' | 'none' = 'symbol'
): string {
  const digits = isInteger(price) && !forceDigits ? 0 : 2;

  if (typeof Intl === 'undefined') return price.toFixed(digits) + NBSP + '€';

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
  return new Intl.NumberFormat(
    undefined,
    currencyDisplay !== 'none'
      ? {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: digits,
          currencyDisplay: currencyDisplay,
        }
      : {
          style: 'decimal',
          minimumFractionDigits: digits,
        }
  ).format(price);
}
