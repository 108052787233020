import React from 'react';

import API from '@/utils/API';
import { useSiteContext } from '@/hooks';
import { makeRef } from '@/helpers/models/schema';
import { useContent } from '@/hooks';

import { Button } from '@/components/new';
import { Field, Label, Textarea } from '@/components/old';

const baseClassName = 'PUF-new-comment';

export default function NewComment({
  rootCommentId,
  parentCommentId,
  onAfterSubmit,
  comment,
}) {
  const [commentContent, setCommentContent] = React.useState('');
  const { siteId } = useSiteContext();

  const content = useContent();
  const contentRef = makeRef(content);

  const postNewComment = React.useCallback(
    async function () {
      try {
        const newComment = await API.post(`${siteId}/comments`, {
          parentId: parentCommentId,
          rootId: rootCommentId,
          text: commentContent,
          content: contentRef,
        });

        if (newComment) {
          setCommentContent('');
          onAfterSubmit({ ...newComment, recipient: comment?.authorName });
        }
      } catch (e) {
        alert(e.message);
      }
    },
    [
      commentContent,
      parentCommentId,
      rootCommentId,
      onAfterSubmit,
      siteId,
      contentRef,
      comment?.authorName,
    ]
  );

  return (
    <div className={baseClassName}>
      <Field>
        <Label htmlFor="comment-area" additionalClassName="sr-only">
          Votre commentaire...
        </Label>
        <Textarea
          id="comment-area"
          value={commentContent}
          onChange={(newValue) => setCommentContent(newValue)}
          placeholder="Votre commentaire..."
        />
      </Field>

      <div className="submit-wrapper">
        <Button
          type="button"
          onClick={postNewComment}
          disabled={!commentContent}
          size="small"
        >
          Publier
        </Button>
      </div>
    </div>
  );
}
