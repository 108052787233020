// @flow

export const pluralize = (
  singular: string,
  plural: string,
  number: number
): string => (!!number && number > 1 ? plural : singular);

export const capitalizeFirstLetter = (
  strParam: ?string,
  options?: { resetCase: boolean }
): ?string => {
  if (!strParam) return null;
  const { resetCase = false } = options || {};
  const str = resetCase ? strParam.toLowerCase() : strParam;

  return str.charAt(0).toUpperCase() + str.slice(1);
};
