import React from 'react';
import ComponentBase from '../../ComponentBase';
import Question from './Question';

class QuestionList extends ComponentBase {
  static baseClassName = 'Quizz-QuestionList';

  render() {
    const { questions, answers, handleAnswerClick, submitted, options } =
      this.props;

    return (
      <div>
        {questions.map((questionData) => {
          return (
            questionData.id && (
              <Question
                options={options}
                questionData={questionData}
                handleAnswerClick={handleAnswerClick}
                submitted={submitted}
                submittedAnswer={answers.find(
                  (answer) => answer.question === questionData.id
                )}
              />
            )
          );
        })}
      </div>
    );
  }
}

export default QuestionList;
