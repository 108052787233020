/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { withRouter } from 'next/router';

import withStore from '@/utils/HOC/withStore';
import { SiteContext } from '@/globals/contexts';
import withContext from '@/utils/HOC/withContext';
import { getReaderToken, getPreviewToken, getAllIssues } from './apiCalls';
import { getImmanensConfig } from './constants';

import PreviewStartModal from './PreviewStartModal';
import PreviewEndModal from './PreviewEndModal';

const getIssueRightName = (magazineIssueNumber) => {
  switch (magazineIssueNumber) {
    case 1:
      return 'droit-n1';
    case 2:
      return 'droits-n2';
    case 3:
      return 'droits-n3';
    case 4:
      return 'droits-n4';
    case 5:
      return 'droits-n5';
    case 6:
      return 'droits-n6';
    case 7:
      return 'droits-n7';
    default:
      return null;
  }
};

function ImmanensReader({
  user,
  page = 1,
  temporaryHackyVersion = true,
  el = '#reader-root',
  router,
  siteContext,
}) {
  const magazineIssueNumber = parseInt(router.query.magazineIssueNumber, 10);
  const hasHash = router.asPath.includes('#');
  const splittedPath = router.asPath.split('#');
  const currentPage = hasHash ? splittedPath[splittedPath.length - 1] : null;
  const [previewStartModalDisplayed, setPreviewStartModalDisplayed] =
    React.useState(false);
  const [previewEndModalDisplayed, setPreviewEndModalDisplayed] =
    React.useState(false);
  const [readerDisplayed, setReaderDisplayed] = React.useState(false);
  const [issue, setIssue] = React.useState(null);
  const [token, setToken] = React.useState(undefined);

  if (!siteContext.siteConfig?.newspaperConfiguration)
    throw new Error('Missing newspaperconfiguration');

  const APP_ID = siteContext.siteConfig?.newspaperConfiguration?.immanensAppId;
  const APP_SECRET =
    siteContext.siteConfig?.newspaperConfiguration?.immanensAppSecret;

  const {
    READER_URL,
    APP_IMMANENS_SERVER_URL,
    ONLY_STORE_ID,
    MAX_PREVIEW_PAGE,
  } = getImmanensConfig(APP_ID);

  const userHasRight = React.useCallback(
    (issue) =>
      !!user &&
      (user.isPremium ||
        user.allRightsGiven['droit-num'] ||
        user.allRightsGiven[getIssueRightName(issue.number)]),
    [user]
  );

  const onLeaveReader = () => {
    if (!!el && el.startsWith('#')) {
      document.getElementById(el.slice(1)).style.display = 'none';
      document.body.style['overflow-y'] = 'auto';
    }
  };

  React.useEffect(() => {
    if (!!issue) {
      setPreviewStartModalDisplayed(
        (!currentPage || currentPage < MAX_PREVIEW_PAGE) && !userHasRight(issue)
      );
      setPreviewEndModalDisplayed(
        !!currentPage && currentPage > MAX_PREVIEW_PAGE && !userHasRight(issue)
      );
    }
  }, [user, currentPage, issue]);

  // 1°) Get Immanens reading token
  React.useEffect(() => {
    const getToken = async () => {
      const allIssues = await getAllIssues({
        baseUrl: APP_IMMANENS_SERVER_URL,
        storeId: ONLY_STORE_ID,
      });
      const issue = allIssues.find(
        (issue) => issue.number === magazineIssueNumber
      );
      setIssue(issue);

      const hasRight = userHasRight(issue);
      const token = await (hasRight
        ? getReaderToken(user, {
            baseUrl: APP_IMMANENS_SERVER_URL,
            appId: APP_ID,
            appSecret: APP_SECRET,
            storeId: ONLY_STORE_ID,
            issueId: issue.issue_id,
            temporaryHackyVersion,
          })
        : getPreviewToken({
            baseUrl: APP_IMMANENS_SERVER_URL,
            issueId: issue.issue_id,
          }));

      // Sauvegarde du token pour l'ouverture de la liseuse
      setToken(token);
    };

    if (!token && magazineIssueNumber) {
      getToken();
    }
  }, [token, magazineIssueNumber]);

  // 2°) Use the Immanens reading token and use it to display the reader
  React.useEffect(() => {
    if (!!token && !!issue) {
      try {
        if (!!el && el.startsWith('#')) {
          document.getElementById(el.slice(1)).style.display = 'block';
          document.body.style['overflow-y'] = 'hidden';
        }

        if (typeof Backbone !== 'undefined') {
          Backbone.history.stop(); // eslint-disable-line no-undef
        }

        // eslint-disable-next-line no-undef
        new immanens.pv5.ReaderPress({
          el,
          pv5api: READER_URL,
          token,
          lang: 'fr',
          publicationId: issue.puc,
          documentId: issue.number,
          page,
          themeApi: 'https://theme-repo.immanens.com/api',
          onHomeClicked: () => {
            if (!!el && el.startsWith('#')) {
              onLeaveReader();
            }

            router.back();
          },
          onPageChanged: (_reader, { page }) => {
            if (!userHasRight(issue) && page > MAX_PREVIEW_PAGE) {
              setPreviewEndModalDisplayed(true);
            }
          },
          googleAnalytics: {
            enable: true,
            separator: '++', // séparateur pour le libellé de l'event
            category: 'reader-pv5-press',
          },
        });

        setReaderDisplayed(true);
      } catch (e) {
        console.error(e);
      }
    }
  }, [token, issue]);

  React.useEffect(() => {
    if (!!el && el.startsWith('#')) {
      document.getElementById(el.slice(1)).style.display = 'block';
      document.body.style['overflow-y'] = 'hidden';
    }

    return onLeaveReader;
  }, []);

  if (readerDisplayed && previewStartModalDisplayed) {
    return (
      <PreviewStartModal
        user={user}
        onClose={() => setPreviewStartModalDisplayed(false)}
        siteContext={siteContext}
      />
    );
  }

  if (readerDisplayed && previewEndModalDisplayed) {
    return (
      <PreviewEndModal
        user={user}
        onClose={() => setPreviewEndModalDisplayed(false)}
        siteContext={siteContext}
      />
    );
  }

  return null;
}

export default withContext(
  SiteContext,
  'siteContext',
  withStore('UserStore', withRouter(ImmanensReader))
);
