// @flow
import * as Sentry from '@sentry/browser';
import * as React from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';

import type { User } from '@/types/models';

import { useInstance } from '@/hooks';

import API from '@/utils/API';

import RawPaymentInput from './RawPaymentInput';
import { InformationBubble } from '@/components/new';

type Props = {|
  onAfterAdd: () => any,
  user: User,
|};

export default function AddPaymentInput({
  onAfterAdd,
  user,
}: Props): React.Node {
  const [instanceId] = useInstance();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);

  const handleSubmit = async () => {
    const cardNumberElement = elements.getElement(CardNumberElement);
    setSubmitting(true);

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: `${user.firstName} ${user.lastName}`,
          email: user.login,
        },
      });
      if (error) {
        Sentry.captureMessage(
          `Payment method creation declined ${JSON.stringify(error)}`
        );
        console.error(error);
        setError(error.message);
        return;
      }

      await API.post(instanceId + '/users/addPaymentMethod', {
        id: paymentMethod.id,
        setAsDefault: true,
      });

      onAfterAdd();
    } catch (e) {
      console.error(e);
      setError(e.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="add-payment-input">
      <RawPaymentInput
        title="Ajouter un nouveau moyen de paiement"
        actionLabel="Ajouter"
        onSubmit={handleSubmit}
        error={error}
        submitting={submitting}
      />

      <InformationBubble style={{ marginTop: 20 }} type="warning" size="small">
        <p style={{ margin: 0 }}>
          Ce moyen de paiement sera utilisé par défaut, lors de vos prochains
          achats.
        </p>
      </InformationBubble>
    </div>
  );
}
