// Template for all user related buttons (login/logout, register...)

import React from 'react';
import { useRouter } from 'next/router';

import { UserActions } from '@/reflux';
import { useGlobal } from '@/hooks';

import { Button, Icon } from '@/components/new';
import { Search } from '../../components';
import PageLink from '../PageLinkV2';

const baseClassName = 'UserButtonsCustom';

export default function UserButtonsCustomV2({ options, mainClassName }) {
  const buttons = options.buttons
    .filter((button) => !!button?.buttonType)
    .map((button) => {
      return <CustomButton button={button} key={button.buttonType} />;
    });

  return <div className={mainClassName}>{buttons}</div>;
}

UserButtonsCustomV2.baseClassName = baseClassName;

function CustomButton({ button }) {
  const router = useRouter();
  const { user, links } = useGlobal();

  const activePageId = router.query.pageId;
  const activeRoute = router.route;

  const { buttonType, icon, label } = button;

  // special cases (no route)
  if (buttonType === 'logout') {
    if (!user) return null;

    return (
      <Button
        onClick={UserActions.logout}
        className={`type--${buttonType}`}
        key={buttonType}
        size="small"
        isOutlined
      >
        {icon?.name && <Icon name={icon.name} size="small" />}
        {label}
      </Button>
    );
  }

  if (buttonType === 'search') {
    return <Search placeholder={label || 'Rechercher'} key={buttonType} />;
  }

  const { link, defaultLabel, isText, isOutlined } = getButtonInfo(button, {
    user,
    links,
  });

  if (!link) return null;

  const isActive =
    (link.isPageId && activePageId === link.url) || // PageId
    (!link.isPageId && link.internal && activeRoute === link.url); // Internal link but not pageId;

  return (
    <PageLink link={link} key={label || defaultLabel}>
      {(href) => (
        <Button
          isActive={isActive}
          size="small"
          additionalClassName={`type--${button.buttonType}`}
          isText={isText}
          isOutlined={isOutlined}
          href={href}
        >
          {icon?.name && <Icon name={icon.name} size="small" />}
          {label || defaultLabel}
        </Button>
      )}
    </PageLink>
  );
}

const getButtonInfo = (button, { user, links }) => {
  const {
    login: loginLinks,
    myAccount: myAccountLinks,
    gift: giftLink,
  } = links;
  let link = undefined,
    defaultLabel,
    isText = false,
    isOutlined = false;

  if (button.buttonType === 'login' && !user) {
    link = loginLinks.login;
    defaultLabel = <span>Se connecter</span>;
    isOutlined = true;
  } else if (button.buttonType === 'redeemCode' && !user?.isPremium) {
    link = links.redeemCode;
    defaultLabel = <span>J'ai un code cadeau</span>;
  } else if (button.buttonType === 'register' && (!user || !user.isPremium)) {
    link = loginLinks.register;
    defaultLabel = "S'abonner"; // TODO use siteConfig.options.registerLabel
  }

  // DOTO case register + resubrule
  else if (button.buttonType === 'account' && user /* && user.isPremium*/) {
    link = myAccountLinks.profile;
    defaultLabel = 'Mon compte';
  } else if (button.buttonType.split('/')[0] === 'account' && user) {
    let tab = button.buttonType.split('/')[1];
    link = myAccountLinks[tab];
    defaultLabel = {
      profile: 'Mon profil',
      subscriptions: 'Mon abonnement',
      paymentMeans: 'Mes moyens de paiement',
      purchases: 'Mes commandes',
    }[tab];
  } else if (button.buttonType === 'gift') {
    link = giftLink;
    defaultLabel = 'Offrir';
  }

  return { link, defaultLabel, isText, isOutlined };
};
