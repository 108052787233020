export const ROLES = {
  ANONYMOUS: 'anonymous',
  FREEMIUM: 'freemium',
  PREMIUM: 'premium',
  MODERATOR: 'moderator',
};

export const ROLES_ORDER = [
  ROLES.ANONYMOUS,
  ROLES.FREEMIUM,
  ROLES.PREMIUM,
  ROLES.MODERATOR,
];

export const DEFAULT_SUFFICIENT_ROLE = {
  COMMENTS: {
    read: ROLES.ANONYMOUS,
    write: ROLES.PREMIUM,
  },
};

export const hasSufficientRole = (user, neededRole = ROLES.ANONYMOUS) => {
  const userRole = !!user
    ? !!user.isPremium
      ? ROLES.PREMIUM
      : ROLES.FREEMIUM
    : ROLES.ANONYMOUS;
  const userRoleIndex = ROLES_ORDER.indexOf(userRole);
  const neededRoleIndex = ROLES_ORDER.indexOf(neededRole);
  return userRoleIndex >= neededRoleIndex;
};
