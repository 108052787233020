// import { isDev } from '@/helpers/env';

export const TEST_USER_LOGIN = 'test@afterfoot.com';
export const TEST_USER_PASSWORD = 'afterfoot123!';

export function getImmanensConfig(APP_ID) {
  const APP_PROD_IMMANENS_SERVER_URL = `https://phenix2.immanens.com/api/v1/app/${APP_ID}`;
  // const APP_PREPROD_IMMANENS_SERVER_URL = `https://phenix2-preprod.immanens.com/api/v1/app/${APP_ID}`;

  // const APP_IMMANENS_SERVER_URL = !isDev()
  //   ? APP_PROD_IMMANENS_SERVER_URL
  //   : APP_PREPROD_IMMANENS_SERVER_URL;
  const APP_IMMANENS_SERVER_URL = APP_PROD_IMMANENS_SERVER_URL;

  const ONLY_STORE_ID = 'default' || 60;

  // const PREPROD_READER_URL = 'https://pv5web-imm-dev.immanens.com/api';
  const PROD_READER_URL = 'https://pressview5.immanens.com/api';
  // const READER_URL = !isDev() ? PROD_READER_URL : PREPROD_READER_URL;
  const READER_URL = PROD_READER_URL;

  const MAX_PREVIEW_PAGE = 11;

  return {
    APP_IMMANENS_SERVER_URL,
    ONLY_STORE_ID,
    TEST_USER_LOGIN,
    TEST_USER_PASSWORD,
    READER_URL,
    MAX_PREVIEW_PAGE,
  };
}
