import React from 'react';
import { truncate } from 'lodash';

import { useContent } from '@/hooks';

import EntityLink from '../navigation/EntityLink';

const baseClassName = 'PUF-entity-title';

export default function EntityTitle({ options = {}, style }) {
  const entity = useContent();

  if (!entity) return null;

  let { label } = entity;

  const { linkToEntity, Element: ElementProp, truncateLength } = options;

  label = truncateLength ? truncate(label, { length: truncateLength }) : label;

  const Element = ElementProp || 'p';
  const titleComponent = (
    <Element className={baseClassName} style={style}>
      {label}
    </Element>
  );

  return linkToEntity ? (
    <EntityLink entity={entity}>
      <a>{titleComponent}</a>
    </EntityLink>
  ) : (
    titleComponent
  );
}

EntityTitle.baseClassName = EntityTitle;
