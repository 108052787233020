import React from 'react';

import { ROLES } from '@/helpers/roles';
import { loginSubscriptionModalState } from '@/recoil/atoms';
import { withStore, withLinks } from '@/utils/HOC';
import withRecoilState from '@/utils/HOC/withRecoilState';

import FrontComponentBase from '@/PUFComponents/FrontComponentBase';
import PageLink from '@/PUFComponents/base/navigation/PageLinkV1';

/**
 * This is intented to be used by ContentVideo and not as a direct front component (although it would work)
 */
class VimeoEventEmbed extends FrontComponentBase {
  static baseClassName = 'VimeoEventEmbed';

  onCheckRights = () => {
    const { user, userLoaded, setLoginModal } = this.props;

    console.debug('onCheckRights', userLoaded, user?.isPremium);

    if (userLoaded && !user?.isPremium) {
      setLoginModal({
        titleEnd: 'voir le live',
        neededRole: ROLES.PREMIUM,
      });
    }
  };

  didMount() {
    this.observeSingleEntity(this.onCheckRights);
    this.observeProps(['user', 'userLoaded'], this.onCheckRights);
  }

  render() {
    const { user, links } = this.props;
    const content = this.props.content || this.getSingleEntity();

    if (!content?.videoEmbedUrl) return null;

    if (!user?.isPremium) {
      return (
        <div className={this.getMainCSSClass()}>
          <div className="vimeo-no-access">
            <h4>Vous devez être abonné pour voir ce live</h4>
            <div className="action-container">
              <PageLink link={links.subscribe}>
                <a className="RegularForm-submit is-normal">S'abonner</a>
              </PageLink>

              <PageLink link={links.login.login}>
                <a className="RegularForm-submit is-normal">Se connecter</a>
              </PageLink>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={this.getMainCSSClass()}>
        <div className="vimeo-iframe-container">
          <iframe
            title="Vimeo embed"
            src={content.videoEmbedUrl}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </div>
      </div>
    );
  }
}

export default withRecoilState(
  'loginModal', // sets prop 'setLoginModal'
  loginSubscriptionModalState
)(withStore('UserStore', withLinks(VimeoEventEmbed)));
