// Component used to display the content description

import _ from 'lodash';
import React from 'react';

import { UserStore } from '@/reflux';
import { withContexts } from '@/utils/HOC';

import { RichTextContent } from '@/components/old';
import FrontComponentBase from '../../FrontComponentBase';
import PaywallContentInternalComponent from '@/pageComponents/PaywallManager/ContentInternalPaywall';

class EntityBody extends FrontComponentBase {
  static baseClassName = 'EntityBody';
  store = UserStore;

  didMount() {
    const timeLimit =
      _.get(this.props, 'siteContext.site.anonymousPlaybackLimit') || 5;

    this.observeSingleEntity((entity) => {
      this._cancelTimer && this._cancelTimer();
      this._cancelTimer = this.setTimer(() => {
        this.recordLocalView(entity);
      }, timeLimit * 1000);
    });
  }

  recordLocalView(entity) {
    const { paywallContext } = this.props;
    paywallContext.notifiyView(entity);
  }

  render() {
    const entity =
      this.props.content || // retrocompat TODO remove
      this.props.entity ||
      this.getSingleEntity();

    if (!entity) return null;

    const { body } = entity;

    const baseClassName = this.constructor.baseClassName;
    const unauthorized = 'authorized' in entity && !entity.authorized;

    return (
      <div className={`${this.getMainCSSClass()} paywalled`}>
        <RichTextContent
          className={
            baseClassName + '-inner' + (unauthorized ? ' truncated' : '')
          }
          html={body}
        />

        {body && unauthorized && (
          <PaywallContentInternalComponent content={entity} />
        )}
      </div>
    );
  }
}

export default withContexts(['siteContext', 'paywallContext'], EntityBody);
