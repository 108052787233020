// Component used to display the content title

import React from 'react';

import FrontComponentBase from '../../FrontComponentBase';

class EntityIsFreeLabel extends FrontComponentBase {
  static baseClassName = 'EntityIsFreeLabel';

  render() {
    let entity =
      this.props.content || // retrocompat TODO remove
      this.props.entity ||
      this.getSingleEntity();

    let options = this.getOptions();

    if (!entity) return null;

    const isPremium = !entity.isFree;
    const baseClassName = this.constructor.baseClassName;

    return !isPremium ? (
      <span className={this.getMainCSSClass(baseClassName + '--free')}>
        {options.label || 'Gratuit'}
      </span>
    ) : (
      <span className={this.getMainCSSClass(baseClassName + '--premium')}>
        {options.premiumLabel || 'Abonnés'}
      </span>
    );
  }
}

export default EntityIsFreeLabel;
