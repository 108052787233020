import React, { useEffect, useState } from 'react';
import ApiClient from '@/utils/ApiClient';
import moment from 'moment';
import { isEmpty } from 'lodash';

import { isValidEmail } from '@/utils/misc';
import { useStore } from '@/hooks';

import { Button, Field, Label, Text } from '@/components/new';
import { InputTextBase, Modal, Textarea } from '@/components/old';

export default function ReportModal({
  onClose,
  contentId,
  contentType,
  contentText,
}) {
  const { user } = useStore('UserStore');

  const [informantInfo, setInformantInfo] = useState({
    informantUserId: user?._id || null,
    informantName: user?.name || null,
    informantEmail: user?.login || null,
    informantComment: '',
  });
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isReportSubmitted, setIsReportSubmitted] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    let newErrorMessages = [];
    if (!informantInfo.informantEmail) {
      newErrorMessages.push('Vous devez remplir votre e-mail');
    }
    if (
      informantInfo.informantEmail &&
      !isValidEmail(informantInfo.informantEmail)
    ) {
      newErrorMessages.push("Votre e-mail n'est pas valide.");
    }
    if (!informantInfo.informantName) {
      newErrorMessages.push('Vous devez remplir votre nom.');
    }
    if (!informantInfo.informantComment) {
      newErrorMessages.push(
        'Vous devez indiquer la raison pour laquelle vous souhaitez signaler ce contenu'
      );
    }
    setErrorMessages(newErrorMessages);
  }, [informantInfo]);

  const handleSubmit = () => {
    const sendReport = async () => {
      if (isEmpty(errorMessages)) {
        await ApiClient.post('ContentReport', null, {
          reportStatus: 'pending',
          reportDate: moment().unix(),
          contentId,
          contentType,
          contentText,
          ...informantInfo,
        });
        setIsReportSubmitted(true);
      }
    };

    setHasSubmitted(true);

    sendReport();
  };

  if (isReportSubmitted) {
    return (
      <Modal
        title="Nous vous remercions pour votre signalement. Nous vous informerons par e-mail du traitement de votre demande."
        onClose={onClose}
        additionalClassName="report-modal success"
        size="half"
      >
        <Button onClick={onClose} style={{ width: '100%' }}>
          Bien compris
        </Button>
      </Modal>
    );
  }

  return (
    <Modal
      title="Signalement de contenu"
      onClose={onClose}
      additionalClassName="report-modal"
      size="half"
    >
      <Field>
        <Label>Contenu signalé</Label>
        <div className="reported-content">
          <Text>{contentText}</Text>
        </div>
      </Field>
      <Field>
        <Label>Votre e-mail de contact</Label>
        <InputTextBase
          value={informantInfo.informantEmail}
          onChange={(newEmail) =>
            setInformantInfo({ ...informantInfo, informantEmail: newEmail })
          }
        />
      </Field>
      <Field>
        <Label>Votre nom</Label>
        <InputTextBase
          value={informantInfo.informantName}
          onChange={(newName) =>
            setInformantInfo({ ...informantInfo, informantName: newName })
          }
        />
      </Field>
      <Field>
        <Label>
          Indiquez la raison pour laquelle vous souhaitez signaler ce contenu
        </Label>
        <Textarea
          rows={4}
          value={informantInfo.informantComment}
          onChange={(newComment) =>
            setInformantInfo({ ...informantInfo, informantComment: newComment })
          }
          placeholder="Raison..."
        />
      </Field>
      {hasSubmitted && (
        <Field>
          <div style={{ backgroundColor: '#ffb3b3' }}>
            <Text>
              <ul>
                {errorMessages.map((error) => (
                  <li>{error}</li>
                ))}
              </ul>
            </Text>
          </div>
        </Field>
      )}
      <div style={{ textAlign: 'center', width: '100%', marginTop: '3vh' }}>
        <Button onClick={handleSubmit} style={{ width: '100%' }}>
          Envoyer
        </Button>
      </div>
    </Modal>
  );
}
