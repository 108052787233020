// @flow
import * as React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import type { PUFComponentProps } from '@/types/component';

import { RichTextContent } from '@/components/old';

type PropertyType =
  | 'timestamp'
  | 'richtext'
  | 'list'
  | 'price'
  | 'string'
  | 'int';

export type PropertyRef = {|
  key: string,
  type: PropertyType,
|};

type Options = {|
  propertyRef: PropertyRef,
  prefix?: string,
  suffix?: string,
  linkToEntity?: boolean,
|};

type Mode = 'default';

type Props = PUFComponentProps<void, Options, Mode, void>;

const BASE_CLASS_NAME = 'EntityProperty';

function EntityPropertyV2({
  entity,
  options,
  mainClassName,
  mode,
}: Props): React.Node {
  if (!entity) return null;

  const { propertyRef, Element = 'div' } = options;

  if (!propertyRef) return null;

  const { key, type } = propertyRef;
  if (!key || !type) return null;

  const value = entity[key];

  if (!value) return null;

  return (
    <Element className={classnames(mainClassName, `property-${key}`)}>
      <PropertyValue type={type} value={value} />
    </Element>
  );
}

EntityPropertyV2.baseClassName = BASE_CLASS_NAME;

export default EntityPropertyV2;

type PropertyValueProps = {|
  type: PropertyType,
  value: any,
|};

function PropertyValue({ value, type }: PropertyValueProps): React.Node {
  switch (type) {
    case 'timestamp':
      return moment.unix(parseInt(value)).format('LL');
    case 'richtext':
      return <RichTextContent tagName="div" html={value} />;
    case 'list':
      return convertToString(value.map((item) => item?.label));
    default:
      return convertToString(value);
  }
}

function convertToString(value: any): null | string {
  if (typeof value === 'string') {
    return value;
  } else if (Array.isArray(value)) {
    return value.join(', ');
  } else if (typeof value === 'object') {
    return JSON.stringify(value);
  } else if (typeof value === 'number') {
    return value.toString();
  } else {
    console.warn("Can't display this type : ", typeof value);
    return null;
  }
}
