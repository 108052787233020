/**
 
 * Component used to display content thumbs
 * a content can have an id, a contentType, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 *
 */

import React from 'react';

import { getFirst } from '@/utils/misc';

import EntityIsFreeLabel from '../../../base/entity/EntityIsFreeLabel';
import EntityLink from '../../../base/navigation/EntityLink';
import FrontComponentBase from '../../../FrontComponentBase';
import VideoPoster from '../../medias/VideoPosterV1';

export default class ContentThumbCiceron extends FrontComponentBase {
  static baseClassName = 'ContentThumbCiceron'; // !!!!!!!!!!!!!!!!!!!!!!!

  render() {
    let content = this.props.content || this.getSingleEntity();

    if (this.state.draftMode && !content) {
      // "no display" condition
      return (
        <div className="draft-placeholder">{this.props.component.label}</div>
      );
    }

    let { contentType, label } = content;

    let componentBaseClass = this.constructor.baseClassName;
    let mainContentType = getFirst(contentType);

    return (
      <div
        className={this.getMainCSSClass(
          mainContentType && componentBaseClass + '--' + mainContentType
        )}
      >
        <EntityLink entity={content}>
          <a
            className={componentBaseClass + '-link'}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            ref={this.zoomableRef}
          >
            <div className={componentBaseClass + '-poster '}>
              <VideoPoster
                content={content}
                wrappedByLink={true}
                size={this.props.size}
              />
            </div>

            <div className={componentBaseClass + '-infos'}>
              <div className={componentBaseClass + '-infos-inner'}>
                {label && (
                  <div className={componentBaseClass + '-title'}>
                    {label} <EntityIsFreeLabel entity={content} />
                  </div>
                )}
              </div>
            </div>
          </a>
        </EntityLink>
      </div>
    );
  }
}
