import { useState } from 'react';
import * as React from 'react';
import _, { get, capitalize } from 'lodash';
import { useRecoilState } from 'recoil';
import { withRouter } from 'next/router';

import { withLinks, withStore } from '@/utils/HOC';
import { SiteContext } from '@/globals/contexts';
import withContext from '@/utils/HOC/withContext';
import { loginSubscriptionModalState } from '@/recoil/atoms';
import { ROLES } from '@/helpers/roles';

import PageLink from '../navigation/PageLinkV1';
import RawComponent from '../../RawComponent';
import { Button, Modal } from '@/components/old';

const getTitleStart = (neededRole) => {
  switch (neededRole) {
    case ROLES.FREEMIUM:
      return 'Vous devez être connecté pour';
    case ROLES.PREMIUM:
      return 'Vous devez être abonné pour';
    default:
      throw new Error('Provided needed role is not recognized', neededRole);
  }
};

// TODO: reorganize this modal code to nicely handle a signin/signup/subscription flow
function LoginSubscriptionModal({ user, siteContext, router, links }) {
  const [modalState, setModalState] = useRecoilState(
    loginSubscriptionModalState
  );
  const {
    login: { register: registerLink, login: loginLink },
  } = links;

  const [registerSuccess, setRegisterSuccess] = useState(false);

  if (!modalState) return null;

  const { titleEnd, neededRole } = modalState;
  const onClose = () => {
    setRegisterSuccess(false);
    setModalState(null);
  };

  return (
    <Modal
      title={
        neededRole === ROLES.FREEMIUM && registerSuccess
          ? 'Merci pour votre inscription'
          : `${getTitleStart(neededRole)} ${titleEnd}`
      }
      size="half"
      onClose={onClose}
      additionalClassName="login-subscription-modal"
    >
      <div className="buttons">
        {!user && neededRole === ROLES.PREMIUM && (
          <a
            onClick={() => {
              router.push(loginLink.url);
              setModalState(null);
            }}
            className="RegularForm-submit is-normal"
          >
            {get(siteContext, 'siteConfig.options.loginLabel') ||
              'Se connecter'}
          </a>
        )}

        {neededRole === ROLES.PREMIUM && (
          <PageLink link={registerLink}>
            <a className="RegularForm-submit is-normal" onClick={onClose}>
              S'abonner
            </a>
          </PageLink>
        )}
      </div>

      {neededRole === ROLES.FREEMIUM &&
        (!registerSuccess ? (
          <RawComponent
            id="Register"
            options={{
              simpleMode: _.get(siteContext, 'site.allowSimpleSignup'),
            }}
            componentProps={{
              onClose,
              onSuccess: (user, action) => {
                if (action === 'register') {
                  setRegisterSuccess('Merci pour votre inscription');
                }
                // else it should be closed
              },
            }}
          />
        ) : (
          <div className="buttons">
            <Button
              additionalClassName="RegularForm-submit is-normal"
              onClick={onClose}
            >
              {capitalize(titleEnd)}
            </Button>

            <PageLink link={registerLink}>
              <a className="RegularForm-submit is-normal" onClick={onClose}>
                S'abonner
              </a>
            </PageLink>
          </div>
        ))}
    </Modal>
  );
}

export default withRouter(
  withStore(
    'UserStore',
    withContext(SiteContext, 'siteContext', withLinks(LoginSubscriptionModal))
  )
);
