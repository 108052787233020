// Component for header/footer social buttons

import React from 'react';

import { useRssConfiguration } from '@/hooks';

import {
  TwitterIcon,
  FacebookIcon,
  YoutubeIcon,
  LinkedInIcon,
  InstagramIcon,
} from '@/components/new/icons';
import { Icon } from '@/components/new';

const ExternalLink = (
  props // itemProp="sameAs" needs https://schema.org/Organization
) => (
  <a
    href={props.url}
    target="_blank"
    rel="noopener noreferrer"
    title={props.title}
    className={props.className}
    style={props.style}
  >
    {props.children}
  </a>
);

export default function SocialButtons(props) {
  const baseClassName = 'SocialButtons';
  const options = props.options;
  const rssConfiguration = useRssConfiguration();

  const componentBaseClass = baseClassName;

  return (
    <div className={componentBaseClass}>
      {options.twitterUrl && (
        <ExternalLink
          url={options.twitterUrl}
          title="Twitter"
          className={componentBaseClass + '-twitter'}
        >
          <TwitterIcon />
        </ExternalLink>
      )}

      {options.facebookUrl && (
        <ExternalLink
          url={options.facebookUrl}
          title="Facebook"
          className={componentBaseClass + '-facebook'}
        >
          <FacebookIcon />
        </ExternalLink>
      )}

      {options.youtubeUrl && (
        <ExternalLink
          url={options.youtubeUrl}
          title="Youtube"
          className={componentBaseClass + '-youtube'}
        >
          <YoutubeIcon />
        </ExternalLink>
      )}

      {options.instagramUrl && (
        <ExternalLink
          url={options.instagramUrl}
          title="Instagram"
          className={componentBaseClass + '-instagram'}
        >
          <InstagramIcon />
        </ExternalLink>
      )}

      {options.linkedinUrl && (
        <ExternalLink
          url={options.linkedinUrl}
          title="LinkedIn"
          className={componentBaseClass + '-linkedin'}
        >
          <LinkedInIcon />
        </ExternalLink>
      )}

      {options.telegramUrl && (
        <ExternalLink
          url={options.telegramUrl}
          title="Telegram"
          className={componentBaseClass + '-telegram'}
          style={{ marginLeft: -5 }}
        >
          <Icon name="telegram-plane" type="brand" />
        </ExternalLink>
      )}

      {rssConfiguration?.isActive && (
        <ExternalLink
          url="/rss"
          title="Flux RSS"
          className={componentBaseClass + '-rss'}
        >
          <Icon name="rss" />
        </ExternalLink>
      )}
    </div>
  );
}
