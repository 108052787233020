// Component used to display a content slider

import React from 'react';
import _ from 'lodash';
import Slider from 'react-slick';

import { ALLOW_SLIDER_LAZYLOADING_IMAGES } from '@/utils/config';
import { ContentContext } from '@/globals/contexts';

import SingleEntity from '../../SingleEntity';
import FrontComponentBase from '../../FrontComponentBase';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <span
      className={className}
      onClick={(e) => {
        onClick(e);
        props.onClicked();
      }}
    >
      <span className="slick-arrow-inner">&#10095;</span>
    </span>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <span
      className={className}
      onClick={(e) => {
        onClick(e);
        props.onClicked();
      }}
    >
      <span className="slick-arrow-inner">&#10094;</span>
    </span>
  );
}

class ContentsSlider extends FrontComponentBase {
  static baseClassName = 'ContentsSlider';

  onDisableSlideshowAfterAnim = () => {
    // this.setState rerenders and breaks animation
    this._disableSlideshowAfterChange = true;
  };

  temporarilyDisableSlideshow = () => {
    console.debug('Disable slideshow for 30s');
    this.setState({ paused: true });
    this._removeTimer = this.setTimer(
      () => this.setState({ paused: false }),
      30 * 1000,
      false
    );
    this._disableSlideshowAfterChange = false;
  };

  render() {
    const { data: entities } = this.props;
    let options = this.getOptions();

    if (this.state.draftMode && !entities && _.isEmpty(options.list)) {
      // "no display" condition
      return (
        <div className="draft-placeholder">{this.props.component.label}</div>
      );
    }

    let labelIfSingle = options.label || null;
    let labelIfMultiple = options.labelIfMultiple || null;
    const label =
      !!entities && !!labelIfMultiple && entities.length > 0
        ? labelIfMultiple
        : labelIfSingle;

    let isSlideshow = options.delay > 0;
    let slidesToShow = options.showCount || (isSlideshow ? 1 : 4);
    const calcSlidesToScroll = (toShow) =>
      isSlideshow ? 1 : Math.max(1, Math.floor(toShow / 2));

    // min slides to show
    let minSlidesToShow = isSlideshow ? 1 : 1.25;

    // see https://react-slick.neostack.com/docs/api#settings
    let settings = {
      speed: options.speed || 300, // TODO option
      slidesToShow: slidesToShow,
      slidesToScroll: calcSlidesToScroll(slidesToShow),
      nextArrow: <NextArrow onClicked={this.onDisableSlideshowAfterAnim} />,
      prevArrow: <PrevArrow onClicked={this.onDisableSlideshowAfterAnim} />,
      arrows: !isSlideshow || options.showArrows,
      autoplay: isSlideshow && !this.state.paused,
      infinite: isSlideshow,
      autoplaySpeed: isSlideshow && options.delay * 1000,
      dots: options.showDots || false,
      pauseOnHover: false,
      pauseOnDotsHover: true,
      //pauseOnFocus: true,
      fade: options.animType === 'fade',
      onSwipe: this.onDisableSlideshowAfterAnim,
      afterChange: () =>
        this._disableSlideshowAfterChange && this.temporarilyDisableSlideshow(),

      // TODO voir comment ça se comporte sur mobile:
      swipeToSlide: !isSlideshow,
      //swipe         : false,

      //dotsClass: componentBaseClass+"-dot",

      // TODO test on all sites & devices, seems to work (but not very well with slideshow):
      lazyLoad: ALLOW_SLIDER_LAZYLOADING_IMAGES && !isSlideshow,

      responsive: [
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: Math.max(minSlidesToShow, slidesToShow - 1),
            slidesToScroll: calcSlidesToScroll(Math.max(1, slidesToShow - 1)),
          },
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: Math.max(minSlidesToShow, slidesToShow - 2),
            slidesToScroll: calcSlidesToScroll(Math.max(1, slidesToShow - 2)),
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: Math.max(minSlidesToShow, slidesToShow - 3),
            slidesToScroll: calcSlidesToScroll(Math.max(1, slidesToShow - 3)),
          },
        },
      ],
    };

    let componentBaseClass = this.constructor.baseClassName;

    return (
      <div className={this.getMainCSSClass()}>
        {label && <h2 className={componentBaseClass + '-title'}>{label}</h2>}

        <div className={componentBaseClass + '-inner'}>
          <Slider
            className={componentBaseClass + '-slider'}
            {...settings}
            onClick={() => {
              this.setState({ paused: true });
            }}
          >
            {entities &&
              entities.map &&
              entities.map(
                (entity) =>
                  (entity && entity._id && (
                    <div
                      className={componentBaseClass + '-slide'}
                      key={entity._id}
                    >
                      <ContentContext.Provider value={entity}>
                        <SingleEntity mode={options.mode} />
                      </ContentContext.Provider>
                    </div>
                  )) ||
                  console.warn('un-displayable entity in slider:', entity)
              )}
          </Slider>
        </div>
      </div>
    );
  }
}

export default ContentsSlider;
