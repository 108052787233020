// Component used to display a content grid with a sort/filter menu

import React from 'react';
import { sortBy, isEmpty } from 'lodash';

import { shouldDisplay } from '@/helpers/rendering/layout';
import { resourceMatch } from '@/helpers/models/resource';
import { ContentContext } from '@/globals/contexts';

import { Button } from '@/components/new';
import ContentsGrid from '../../base/lists/ContentsGrid';
import SortFilterMenu from '../../base/menus/SortFilterMenu';
import PageLink from '../../base/navigation/PageLinkV1';
import SingleEntity from '../../SingleEntity';
import Row from '@/Layout/Row';

export default class ContentsGridTemplate1 extends ContentsGrid {
  constructor(props) {
    super(props);

    const { options, componentProps } = props;

    // This is a special case for when data are provided through `this.props.componentProps.data.mainList`
    // and options.sortButtons exists (page search for now)
    if (componentProps?.data?.mainList?.length && options.sortButtons?.length) {
      const initialSortProperty = (
        options.sortButtons.find((sortButton) => sortButton.isDefault) ||
        options.sortButtons[0]
      ).property;
      const sortedData = sortBy(
        componentProps?.data?.mainList,
        (result) => -result[initialSortProperty.key]
      );

      this.state = {
        ...(this.state || {}),
        mainList: componentProps.data.mainList,
        sortedData,
        sortProperty: initialSortProperty,
      };
    }
  }

  sortBy = (sortProperty) => {
    const data = this.props.componentProps.data.mainList;

    this.setState({
      sortedData: sortBy(data, (result) => -result[sortProperty?.key]),
      sortProperty,
    });
  };

  componentDidUpdate() {
    const { sortButtons } = this.props.options;
    const newData = this.props.componentProps?.data?.mainList;
    const { mainList: storedData, sortProperty } = this.state;

    if (newData !== storedData) {
      this.setState({ mainList: newData });
      if (sortButtons?.length) this.sortBy(sortProperty);
    }
  }

  render() {
    const { user, ua, data } = this.props;
    let options = this.getOptions();
    const { filteredEntities, sortedData, sortProperty } = this.state;

    let {
      displayMenu,
      label: labelIfSingle,
      pageId,
      mode,
      alternateMode,
      labelIfMultiple,
      list,
      sortButtons = [],
    } = options;

    const entities =
      data ||
      (sortedData || filteredEntities || this.getEntityList()).filter(
        (entity, entityIndex) => {
          if (!entity) return false;
          if (!list) return true;

          const matchingItem = list.find((item) =>
            resourceMatch(item.content, entity)
          );

          const { displayConditions } = matchingItem || {};

          return shouldDisplay(displayConditions, {
            user,
            useragent: ua,
          });
        }
      );

    let colCount = options.colCount || 1;
    let colSize = colCount ? Math.floor(12 / colCount) : 0;

    if (this.state.draftMode && isEmpty(entities) && !displayMenu) {
      // "no display" condition
      return (
        <div className="draft-placeholder">
          {this.props.component && this.props.component.label}
        </div>
      );
    }

    if (isEmpty(entities)) return null; // empty = "no display" condition

    let componentBaseClass = this.constructor.baseClassName;
    const label =
      !!entities && !!labelIfMultiple && entities.length > 0
        ? labelIfMultiple
        : labelIfSingle;

    const rowCount =
      !!entities && entities.length > 0
        ? Math.ceil(entities.length / colCount)
        : 0;

    return (
      <div className={this.getMainCSSClass()}>
        {label && (
          <h2 className={componentBaseClass + '-title'}>
            {(pageId && (
              <PageLink pageId={pageId}>
                <a>{label}</a>
              </PageLink>
            )) ||
              label}
          </h2>
        )}

        {displayMenu && (
          <SortFilterMenu
            component={this.props.component}
            onUpdateFilters={this.onUpdateFilters}
          />
        )}

        {sortButtons && sortButtons.length > 0 && (
          <div className="filterSortButtons">
            {sortButtons.map(({ property, isDefault }, index) => (
              <Button
                key={index}
                isActive={property.key === sortProperty.key}
                onClick={() => {
                  this.setState({ sortByField: property });
                  this.sortBy(property);
                }}
                size="small"
              >
                {property.label}
              </Button>
            ))}
          </div>
        )}

        {rowCount > 0 ? (
          Array(rowCount)
            .fill(0)
            .map((_val, rowIndex) => {
              const rowEntities = entities.slice(
                rowIndex * colCount,
                (rowIndex + 1) * colCount
              );

              return (
                <Row gutters={true} colCount={colCount} key={rowIndex}>
                  {rowEntities.map(
                    (
                      entity,
                      i // key: allow duplicates entities in list:
                    ) => (
                      <div
                        className={'ContentsGrid-item Col Col--' + colSize}
                        key={entity._id + '_' + i}
                      >
                        <div className="Col-inner">
                          {/* this div is new in template1 */}

                          <ContentContext.Provider value={entity}>
                            <SingleEntity
                              mode={
                                alternateMode && i % 2 ? alternateMode : mode
                              }
                            />
                          </ContentContext.Provider>
                        </div>
                      </div>
                    )
                  )}
                </Row>
              );
            }) // here getEntityList() not empty -> it's the filtered list that is empty
        ) : (
          <div>
            <i>Aucun contenu correspondant à ces critères.</i>
          </div>
        )}
      </div>
    );
  }
}
