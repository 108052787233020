import React from 'react';
import classnames from 'classnames';

import { ImageContext } from '@/globals/contexts';

import ContentsManager from './ContentsManager';

const getClassName = (
  { size, flex, align, options, flexOrder, offset },
  gutters
) => {
  const { className: optionClassName } = options || {};
  const flexClass = !!align ? `${flex || 'col'}-${align}` : flex;

  return classnames(`Col--${size}`, optionClassName, {
    [`is-offset-${offset}`]: !!offset,
    [`Col--flex-${flexClass}`]: !!flexClass,
    Col: gutters !== false,
    [`fall-order-${flexOrder}`]: !!flexOrder,
  });
};

export default function ColumnV1({ column, gutters }) {
  const { noMarginBottom, loadImagesSync } = column;

  const { loadImagesSync: parentLoadImagesSync } =
    React.useContext(ImageContext);
  const shouldLoadImagesSync =
    loadImagesSync !== undefined ? loadImagesSync : parentLoadImagesSync;

  const className = getClassName(column, gutters);

  return (
    <ImageContext.Provider value={{ loadImagesSync: shouldLoadImagesSync }}>
      <div className={className}>
        <div
          className={classnames('Col-inner', {
            'no-margin-bottom': noMarginBottom,
          })}
        >
          <ContentsManager column={column} />
        </div>
      </div>
    </ImageContext.Provider>
  );
}
