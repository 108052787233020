// Component used to display the published date of a component

import React from 'react';

import { useContent } from '@/hooks';

export default function EpisodeNumber({ options, mainClassName, style }) {
  const content = useContent();

  if (!content || content.episodeNumber === 'undefined') return null;

  const { Element = 'div', prefix, suffix, suffixPlural } = options;

  return (
    <Element className={mainClassName}>
      <span className={EpisodeNumber.baseClassName + '-content'}>
        {prefix && `${prefix} `}
        <span className={EpisodeNumber.baseClassName + '-count'}>
          {content.episodeNumber}
        </span>{' '}
        {(content.episodeNumber > 1 && suffixPlural) || suffix}
      </span>
    </Element>
  );
}

EpisodeNumber.baseClassName = 'EpisodeNumber';
