// @flow
import * as React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { Elements } from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/browser';

import { isProd } from '@/helpers/env';
import { SiteContext } from '@/globals/contexts';
import { STRIPE_SCRIPT_ID } from '@/pageComponents/AppComponent/Head';

type Props = {|
  children: React.Node,
|};

export default function StripeIntegrationProvider({
  children,
}: Props): React.Node {
  const siteContext = React.useContext(SiteContext);
  const config = siteContext?.siteConfig.paymentsConfig;
  const stripePublicKey =
    config?.testMode || !isProd()
      ? config?.stripePublishableTestKey
      : config?.stripePublishableKey;
  const [stripeIntegration, setStripeIntegration] = React.useState(null);

  React.useEffect(() => {
    if (!stripePublicKey) return;

    // If stripe script has already been loaded
    if (window.Stripe) {
      try {
        setStripeIntegration(window.Stripe(stripePublicKey));
      } catch (e) {
        console.error('Stripe init error', e);
        Sentry.captureException(e);
      }

      // Otherwise, wait for it to load then setup integration
    } else {
      document
        .querySelector(`#${STRIPE_SCRIPT_ID}`)
        ?.addEventListener('load', () => {
          console.log('loaded');
          try {
            setStripeIntegration(window.Stripe(stripePublicKey));
          } catch (e) {
            console.error('Stripe init error', e);
            Sentry.captureException(e);
          }
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!stripeIntegration) {
    return null;
  }

  return (
    <StripeProvider stripe={stripeIntegration}>
      <Elements stripe={stripeIntegration}>{children}</Elements>
    </StripeProvider>
  );
}
