// Component used to display content sort/filter menus

import _ from 'lodash';
import React from 'react';
import cn from 'classnames';

import ComponentBase from '../../ComponentBase';
import { InputSelectBase } from '@/components/old';
import FrontComponentBase from '../../FrontComponentBase';

// ATTENTION ceci n'est pas vraiment (pour l'instant) un Simple Component mais plutôt un sous-component de ContentGrid

class FilterDropdown extends ComponentBase {
  didMount() {
    const bindValues = this.getRebindToCollectionFunc('values');
    this.observeProp('type', (type) => bindValues(type));
  }

  render() {
    let { value, onChange, label } = this.props;
    let { values } = this.state;

    return (
      <InputSelectBase
        useValue={true}
        placeholder={label}
        value={value}
        options={_.map(values, (obj) => ({
          label: obj.label,
          value: obj,
        }))}
        onChange={onChange}
        required={false}
      />
    );
  }
}

class SortFilterMenu extends FrontComponentBase {
  static baseClassName = 'SortFilterMenu';
  state = {
    activeFilters: [],
  };

  didMount() {
    this.observeOptions((options) => {
      // Set default active filters

      // TODO do this on the server and send directly the right data!

      let activeFilters = _.filter(options.menuFilters, 'default').map((f) =>
        f._cls === 'RequestSortButton' ? { ...f, value: f.sortOrder || 1 } : f
      );
      activeFilters && this.setState({ activeFilters });
      this.props.onUpdateFilters(activeFilters);
    });

    const bindCat = this.getRebindToCollectionFunc('questionCategories');
    this.observeSingleEntity((entity) => {
      entity && entity._cls === 'Question' && bindCat('QuestionCategory');
    });
  }

  isFilterActive(f) {
    return _.find(this.state.activeFilters, f);
  }

  onFilter(f) {
    let { activeFilters } = this.state;
    let newFilters;

    if (this.isFilterActive(f)) {
      newFilters = _.without(activeFilters, f);
    } else {
      // Remove all filters related to this property, then activate filter
      newFilters = activeFilters
        .filter(
          (af) => af._cls !== f._cls || (af.prop !== f.prop && af._id === f._id)
        )
        .concat(f);
    }

    this.props.onUpdateFilters && this.props.onUpdateFilters(newFilters);
    this.setState({ activeFilters: newFilters });
  }

  onSort(f) {
    // Note: f._cls === "RequestSortButton"
    let { activeFilters } = this.state;

    let activeFilter = _.find(activeFilters, { _cls: f._cls, prop: f.prop });

    let value;

    if (!f.sortOrder) {
      value =
        activeFilter && activeFilter.value === 1
          ? -1
          : activeFilter && activeFilter.value === -1
          ? 0
          : 1;
    } else {
      value = !activeFilter ? f.sortOrder : 0;
    }

    // remove all sort
    let newFilters = _.filter(activeFilters, (af) => af._cls !== f._cls);

    if (value) {
      newFilters = newFilters.concat({
        ...f,
        value,
      });
    }

    this.props.onUpdateFilters && this.props.onUpdateFilters(newFilters);
    this.setState({ activeFilters: newFilters });
  }

  onFilterDropdown = (f, value) => {
    // Note: f._cls === "RequestFilterDropdown"
    let { activeFilters } = this.state;

    let newFilters = _.reject(activeFilters, {
      _cls: f._cls,
      propRef: f.propRef,
    });

    if (value) {
      newFilters = newFilters.concat({
        ...f,
        value: value,
      });
    }

    this.props.onUpdateFilters && this.props.onUpdateFilters(newFilters);
    this.setState({ activeFilters: newFilters });
  };

  onChangeSearch(search) {
    let { activeFilters } = this.state;
    let newFilters = _.filter(activeFilters, (f) => !f.search);

    if (search.length >= 2) {
      newFilters.push({ search });
    }

    this.props.onUpdateFilters && this.props.onUpdateFilters(newFilters);
    this.setState({ activeFilters: newFilters });
  }

  render() {
    let options = this.getOptions();
    let list = this.getEntityList();

    let tags = options.menuTags;
    let filters = options.menuFilters;
    let { activeFilters } = this.state;
    let questionCat = options.menuQuestionCat;

    let baseClassName = this.constructor.baseClassName;

    const shouldDislay =
      (filters && filters.length > 0) ||
      (tags && tags.length > 0) ||
      (questionCat && questionCat.length > 0) ||
      !!options.displaySearch ||
      (list && list.length > 0);

    if (!shouldDislay) return null;

    return (
      <div className={baseClassName}>
        <div className={baseClassName + '-filters'}>
          <span className={baseClassName + '-categories'}>
            {_.map(_.filter(filters), (f, i) => {
              let activeFilter = f.prop
                ? _.find(activeFilters, { _cls: f._cls, prop: f.prop })
                : _.find(activeFilters, { _cls: f._cls, propRef: f.propRef });

              let value = activeFilter && activeFilter.value;

              return (
                (f._cls === 'RequestFilterButton' && (
                  <button
                    key={i}
                    className={cn(baseClassName + '-category', {
                      active:
                        activeFilter &&
                        _.isEqual(activeFilter.value, f.value) &&
                        activeFilter.filterOp === f.filterOp,
                    })}
                    onClick={() => this.onFilter(f)}
                  >
                    {f.label || _.get(f, 'value.label')}
                  </button>
                )) ||
                (f._cls === 'RequestSortButton' && (
                  <button
                    key={i}
                    className={cn(baseClassName + '-date', {
                      active: _.get(activeFilter, 'value'),
                    })}
                    onClick={() => this.onSort(f)}
                  >
                    {f.useSortOrderIcon &&
                      ((value === 1 && '▲ ') || (value === -1 && '▼ '))}
                    {(value === 1 && f.ascLabel) ||
                      (value === -1 && f.descLabel) ||
                      f.label}
                  </button>
                )) ||
                (f._cls === 'Tag' && (
                  <button
                    key={i}
                    className={cn(baseClassName + '-tags', {
                      active:
                        activeFilter && _.isEqual(activeFilter.value, f.value),
                    })}
                    onClick={() => this.onFilter(f)}
                  >
                    {f.label}
                  </button>
                )) ||
                (f._cls === 'RequestFilterDropdown' && f.propRef && (
                  <FilterDropdown
                    key={i}
                    label={f.label}
                    value={activeFilter && activeFilter.value}
                    type={
                      f.propRef.objectType ||
                      (f.propRef.itemSchema && f.propRef.itemSchema.objectType) // type == "list" // TODO test
                    }
                    onChange={(v) => this.onFilterDropdown(f, v)}
                  />
                ))
              );
            })}

            {_.map(tags, (tag) => (
              <button
                key={tag._id}
                className={cn(baseClassName + '-tags type--Tag', {
                  active: _.find(activeFilters, tag),
                })}
                onClick={() => this.onFilter(tag)}
              >
                {
                  tag.label || tag.text // text retrocompat
                }
              </button>
            ))}

            {_.map(questionCat, (qcat) => (
              <button
                key={qcat._id}
                className={cn(baseClassName + '-tags type--QuestionCategory', {
                  active: _.find(activeFilters, qcat),
                })}
                onClick={() => this.onFilter(qcat)}
              >
                {
                  qcat.label || qcat.text // text retrocompat
                }
              </button>
            ))}
          </span>

          {options.displaySearch !== false && (
            <input
              type="text"
              placeholder="Rechercher"
              className={
                baseClassName + '-input-text-element form-input-text-element'
              }
              onChange={(e) => this.onChangeSearch(e.target.value)}
            />
          )}
        </div>

        {list && list.length > 0 && (
          <span className={baseClassName + '-count'}>
            <span className={baseClassName + '-count-number'}>
              {list.length}
            </span>
            {(list.length > 1 && <span>&nbsp;contenus</span>) || (
              <span>&nbsp;contenu</span>
            )}
          </span>
        )}
      </div>
    );
  }
}

export default SortFilterMenu;
