// @flow
// Note: Each time we use the AddressForm, we spread the object because the form writes in the object...
import * as React from 'react';

import type { FullCartState } from '@/types/stores';

import { CartStoreActions } from '@/reflux';

import { InputCheckboxBase } from '@/components/old';
import AddressForm from '@/PUFComponents/base/user/LegacyAddressForm';

type Props = {|
  cartState: FullCartState,
  disabled: boolean,
|};

export default function Addresses({ cartState, disabled }: Props): React.Node {
  const {
    requiresShipping,
    shippingAddress,
    billingAddress,
    billingAddressSameAsShippingAddress,
    userWantsABill,
    isGift,
  } = cartState;

  // When gift, shipping address is handled in gift form
  if (!requiresShipping || isGift) {
    return (
      <>
        <InputCheckboxBase
          label={<label>Cochez cette case pour recevoir une facture</label>}
          clickableLabel
          value={userWantsABill}
          disabled={disabled}
          onChange={(newValue) =>
            CartStoreActions.forceSetState({
              userWantsABill: !userWantsABill,
            })
          }
        />

        {userWantsABill && (
          <div className="addresses">
            <h4>Adresse de facturation</h4>
            <AddressForm
              name="billing"
              type="de facturation"
              value={billingAddress}
              disabled={disabled}
              onChange={(address) =>
                CartStoreActions.setAddress('billingAddress', address)
              }
            />
          </div>
        )}
      </>
    );
  }

  return (
    <div className="addresses">
      <h4>Adresse de livraison</h4>
      <p>
        Votre panier contient des produits physiques nécessitant une livraison.
        Nous avons besoin que vous renseigniez précisément l'adresse à laquelle
        vous souhaitez être livré.
      </p>
      <AddressForm
        name="shipping"
        type="de livraison"
        value={shippingAddress}
        disabled={disabled}
        onChange={(address) =>
          CartStoreActions.setAddress('shippingAddress', address)
        }
      />

      <InputCheckboxBase
        label={
          <label>
            Adresse de facturation (si différente de l'adresse de livraison)
          </label>
        }
        clickableLabel
        value={!billingAddressSameAsShippingAddress}
        onChange={(newValue) =>
          CartStoreActions.forceSetState({
            billingAddressSameAsShippingAddress:
              !billingAddressSameAsShippingAddress,
          })
        }
        disabled={disabled}
      />

      {/* <h4>Adresse de facturation (si différente de l'adresse de livraison)</h4> */}
      {!billingAddressSameAsShippingAddress && (
        <AddressForm
          name="billing"
          type="de facturation"
          value={billingAddress}
          disabled={disabled}
          onChange={(address) =>
            CartStoreActions.setAddress('billingAddress', address)
          }
        />
      )}
    </div>
  );
}
