/**
 * Component used to display answers thumbs
 * a content can have an id, a desc, an eventTs, an image (path string), a label, a videoId, a body (full text)
 */

import moment from 'moment';
import NLink from 'next/link';
import React from 'react';

import FrontComponentBase from '../../../FrontComponentBase';
import VideoPoster from '../../medias/VideoPosterV1';

class AnswersThumb extends FrontComponentBase {
  static baseClassName = 'AnswersThumb';

  /*
    TODO :
    - format date and add content type label
    - add navigation
  */

  render() {
    // TODO !!!
    let content = this.getSingleEntity();

    if (!content) return null;

    let { publishTs, eventTs, label } = content;
    let baseClassName = this.constructor.baseClassName;

    moment.locale('fr');
    let publicDate = eventTs || publishTs;
    let displayedDate =
      publicDate && 'Le ' + moment.unix(publicDate).format('DD MMMM YYYY');

    let thumbWidth = 620; // largest thumb width (responsive)

    return (
      // data-cc will work?
      <NLink
        href={'/o/' + content._cls + '/' + encodeURIComponent(content._id)}
      >
        <a className={baseClassName}>
          <div className={baseClassName + '-poster'}>
            <VideoPoster
              content={content}
              wrappedByLink={true}
              thumbWidth={thumbWidth}
            />
          </div>

          <div className={baseClassName + '-infos'}>
            <div className={baseClassName + '-author'}>
              Auteur de la question
            </div>
            <div className={baseClassName + '-desc'}>{label}</div>

            {displayedDate && (
              <div className={baseClassName + '-date'}>{displayedDate}</div>
            )}
          </div>

          <div className={baseClassName + '-footer'}>
            <span className={baseClassName + '-read-more'}>
              Voir la réponse
            </span>
          </div>
        </a>
      </NLink>
    );
  }
}

export default AnswersThumb;
