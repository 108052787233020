import _ from 'lodash';
import cn from 'classnames';

import { InstanceStore } from '@/reflux';

import ComponentBase from './ComponentBase';

export default class FrontComponentBase extends ComponentBase {
  store = InstanceStore;

  /**
   * used to prefix CSS classes in getMainCSSClass
   * @type {undefined}
   * @see getMainCSSClass
   */
  static baseClassName = undefined;

  observeSingleEntity(callback) {
    this.observeProps(
      ['component.data.0', 'data.componentObject', 'data.mainObject'],
      () => callback(this.getSingleEntity())
    );
  }

  observeEntities(callback) {
    this.observeProps(
      ['component.data', 'data.componentList', 'data.mainList'],
      () => callback(this.getEntityList())
    );
  }

  /**
   * Return a single object to display from data:
   * this component's data (from options), or parent component's data (if complex component), or object page data (if object page)
   * @returns {*}
   */
  getSingleEntity(singleOnly = false) {
    let { component, data, entity } = this.props;
    let componentData = component && component.data;
    if (_.isArray(componentData) && !singleOnly) {
      //console.error("Using component", this.constructor.name, "with a list", componentData);
      componentData = componentData[0]; // convert
    }

    let hasList = _.get(component, 'options.list.length', 0) > 0;

    return (
      componentData ||
      (!hasList && data && (data.componentObject || data.mainObject)) ||
      entity ||
      null
    );
  }

  /**
   * Return a list of objects to display from data:
   * this component's data (from options), or parent component's data (if complex component), or object page list (does not exist yet)
   * @returns [*]
   */
  getEntityList() {
    let { component, data, entities } = this.props;
    let componentData = component && component.data;
    if (componentData && !_.isArray(componentData)) {
      console.error(
        'Using component',
        this.constructor.name,
        'with a single object ',
        componentData
      );
      componentData = [componentData]; // convert
    }

    let hasList = _.get(component, 'options.list.length', 0) > 0;

    return (
      componentData ||
      (!hasList && data && (data.componentList || data.mainList)) ||
      entities ||
      []
    );
  }

  getMainObjectId() {
    let data = this.props.data;
    let entity = data.componentObject || data.mainObject;
    return (entity && entity._id) || this.props.objectId;
  }

  getOptions() {
    // if (
    //   this.props.component &&
    //   this.props.component.options &&
    //   this.props.options
    // )
    //   console.warn(
    //     this.constructor.name +
    //       ' has both props.options and components.options - is this intentional ?'
    //   );

    return (
      (this.props.component && this.props.component.options) ||
      this.props.options ||
      {}
    ); // TODO extend ?
  }

  observeOptions(callback) {
    return this.observeProps(['component.options', 'options'], () =>
      callback(this.getOptions())
    );
  }

  getMainCSSClass(additionalClasses) {
    let baseClass =
      this.props.className || this.constructor.baseClassName || '';
    let options = this.getOptions();
    let singleEntity = this.getSingleEntity(true);
    let mode = options.mode || this.props.mode || '';
    let marginBottom = options.marginBottom; // half / single / double

    return cn(
      'FrontComponent',
      baseClass,
      options.cssClass,
      options.className,
      marginBottom && 'marginBottom--' + marginBottom,
      {
        ['mode--' + mode]: !!mode,
        [baseClass + '--' + mode]: !!mode,
      },
      singleEntity && singleEntity.cssClass,
      singleEntity && singleEntity.className,
      additionalClasses
    );
  }
}
