// Component to select subscription type

import _ from 'lodash';
import { withRouter } from 'next/router';
import React from 'react';

import { UserStore, CartStore, CartStoreActions } from '@/reflux';

import Row from '@/Layout/Row';
import {
  InputFileUpload,
  InputRadioBase,
  FadeInAndScrollTo,
  RichTextContent,
} from '@/components/old';
import FrontComponentBase from '../../FrontComponentBase';
import RawComponent from '../../RawComponent';
import SingleEntity from '../../SingleEntity';

class SubscriptionGroupRegister extends FrontComponentBase {
  static baseClassName = 'SubscriptionGroupRegister';
  stores = [UserStore, CartStore];

  didMount() {
    // Specific
    this.bindCollection(
      'Event',
      {
        limit: 1,
        orderBy: { eventTs: 1 },
        filter: { eventTs: { $ne: null } },
      },
      'nextMagazineIssues'
    );

    this.observeProp('router.query.group', (groupId) => {
      if (groupId === 'donation') {
        this.onSelectDonation();
        return;
      }

      let { formulaGroups, specialGroup } = this.getOptions();
      if (formulaGroups) {
        console.debug('pre-select group', groupId);
        let groupToSelect =
          _.find(formulaGroups, { _id: groupId }) ||
          (specialGroup && specialGroup._id === groupId && specialGroup);

        groupToSelect && this.onSelectFormulaGroup(groupToSelect);
      } else {
        console.warn("can't preselect group: gourps not loaded");
      }
    });
  }

  onSelectFormulaGroup = (formulaGroup) => {
    let id = formulaGroup && formulaGroup._id;
    let currentId = this.state.selectedGroup && this.state.selectedGroup._id;
    let selectedGroup = currentId === id ? null : formulaGroup;

    console.debug('select formulaGroup', selectedGroup && selectedGroup.label);

    CartStoreActions.empty();

    // Auto-select if 1 formula:
    // NEW: always autoselect first
    if (selectedGroup && selectedGroup.formulas.length >= 1) {
      this.setState({
        selectedGroup,
        selectedFormula: selectedGroup.formulas[0],
        firstIssue: null,
      });
      CartStoreActions.addItem(selectedGroup.formulas[0]);
    } else {
      this.setState({
        selectedGroup,
        selectedFormula: null,
        openDonation: false,
        firstIssue: null,
      });
    }
  };

  onSelectFormula = (selectedFormula) => {
    this.setState({ selectedFormula });

    // Remove only other formulas
    _.map(
      this.state.cartItems,
      (item) =>
        item.entity._cls === 'SubscriptionFormula' &&
        CartStoreActions.remove(item.entity)
    );

    selectedFormula
      ? CartStoreActions.addItem(selectedFormula)
      : CartStoreActions.remove(selectedFormula);
  };

  onSelectfirstIssue = (firstIssue) => {
    this.setState({ firstIssue });
    CartStoreActions.addItemOptions(this.state.selectedFormula._id, {
      firstIssue,
    });
  };

  onSelectDonation = () => {
    CartStoreActions.empty();
    this.setState({
      openDonation: !this.state.openDonation,
      selectedGroup: null,
    });
  };

  render() {
    let options = this.getOptions();
    let { formulaGroups, mode, specialGroup, donationProduct } = options;

    let { selectedFormula, nextMagazineIssues, selectedGroup, firstIssue } =
      this.state;

    let selectedGroupId = selectedGroup && selectedGroup._id;

    const baseClassName = this.constructor.baseClassName;

    return (
      <div className={this.getMainCSSClass()}>
        <div className="siteWrapper">
          {!this.state.paid && options.title && (
            <div className={baseClassName + '-title-wrapper PageTitle'}>
              <h1>{options.title}</h1>
            </div>
          )}

          {!this.state.paid && specialGroup && (
            <div
              className={
                'flexWrapper Row ' + baseClassName + '-specialoffer-wrapper'
              }
            >
              <div
                className={
                  baseClassName +
                  '-specialoffer-col Col--12' +
                  (specialGroup._id === selectedGroupId
                    ? ' selected'
                    : selectedGroupId
                    ? ' dim'
                    : '')
                }
              >
                <SingleEntity
                  entity={specialGroup}
                  size={12}
                  mode={mode} // ?
                  onClick={() => this.onSelectFormulaGroup(specialGroup)}
                />
              </div>
            </div>
          )}

          {!this.state.paid && (
            <Row
              gutters={true}
              colCount={formulaGroups.length + 1}
              className={baseClassName + '-groups-wrapper'}
            >
              {_.map(
                formulaGroups,
                (fg) =>
                  fg && (
                    <div
                      className={
                        baseClassName +
                        '-groups-col Col--' +
                        ~~(12 / (formulaGroups.length + 1)) +
                        (fg._id === selectedGroupId
                          ? ' selected'
                          : selectedGroupId
                          ? ' dim'
                          : '')
                      }
                      key={fg._id}
                    >
                      <SingleEntity
                        entity={fg}
                        size={4}
                        mode={mode}
                        onClick={() => this.onSelectFormulaGroup(fg)}
                      />
                    </div>
                  )
              )}

              {/* gruge pour mettre le don */}
              <div
                className={
                  baseClassName +
                  '-groups-col Col--3' +
                  (this.state.openDonation ? ' selected' : '')
                }
                onClick={this.onSelectDonation}
              >
                <div className="SubscriptionFormulaGroupSimple special--donation">
                  <div className="EntityTitle">Don</div>

                  {(donationProduct &&
                    donationProduct.desc &&
                    (!donationProduct.desc.contentState ||
                      donationProduct.desc.html !== '<p><br></p>') && ( // TEMP gruge bug contentState pas viré + bug éditeur
                      <RichTextContent html={donationProduct.desc} />
                    )) ||
                    (donationProduct && donationProduct.cartLabel && (
                      <p>{donationProduct.cartLabel}</p>
                    )) || (
                      <p>
                        FAITES UN DON POUR CRÉER LA PLATEFORME NUMÉRIQUE et le
                        réseau social sécurisé DE FRONT POPULAIRE
                      </p>
                    )}
                  <div className="EntityProperty prop-buttonLabel special--donation">
                    <div className="EntityProperty-inner">
                      <span className="EntityProperty-value">Faire un don</span>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          )}

          {!this.state.paid && this.state.openDonation && (
            <div>
              <SingleEntity
                entity={donationProduct}
                size={12}
                mode={'simple'}
              />
            </div>
          )}

          {!this.state.paid && (
            <div
              className={
                'flexWrapper Row ' + baseClassName + '-selected-group-wrapper'
              }
            >
              <FadeInAndScrollTo show={!!selectedGroupId}>
                {selectedGroup && (
                  <div
                    className={
                      baseClassName +
                      '-selected-group ' +
                      selectedGroup.cssClass
                    }
                  >
                    <h2 className={baseClassName + '-selected-group-title'}>
                      {selectedGroup.label}
                    </h2>
                    <RichTextContent
                      html={selectedGroup.desc}
                      className={baseClassName + '-selected-group-desc'}
                    />

                    <h3>Choisissez votre durée d'abonnement</h3>
                    <InputRadioBase
                      additionalClassName={
                        baseClassName + '-selected-group-formulas'
                      }
                      value={selectedFormula}
                      onChange={this.onSelectFormula}
                      radios={_.map(selectedGroup.formulas, (formula) => ({
                        value: formula,
                        label: (
                          <div key={formula._id} className="formula">
                            <div className="formula-infos">
                              <div className="formula-label">
                                {formula.label}
                              </div>
                              <RichTextContent
                                html={formula.description}
                                className="formula-desc"
                              />
                            </div>
                            {formula.discountLabel && (
                              <div className="formula-discount-label">
                                {formula.discountLabel}
                              </div>
                            )}
                            <div className="formula-price">
                              {formula.priceLabel}
                            </div>
                          </div>
                        ),
                      }))}
                    />

                    <FadeInAndScrollTo
                      show={selectedFormula && selectedFormula.requireJustif}
                      noScroll={true}
                    >
                      {selectedFormula && selectedFormula.requireJustif && (
                        <div className={baseClassName + '-upload-justif'}>
                          <div className="RegularForm-section">
                            {selectedFormula.justifText && (
                              <RichTextContent
                                className="RegularForm-label"
                                html={selectedFormula.justifText}
                              />
                            )}
                            <InputFileUpload
                              autoSendWithUser={true}
                              onChange={(value) =>
                                this.setState({ justif: value })
                              }
                              accept={null}
                            />
                          </div>
                        </div>
                      )}
                    </FadeInAndScrollTo>

                    {selectedFormula && selectedFormula.requiresShipping && (
                      <div
                        className={baseClassName + '-product-list first-issue'}
                      >
                        <h3>
                          {options.firstIssueLabel ||
                            'Merci de choisir le premier numéro de votre abonnement'}
                        </h3>
                        <InputRadioBase
                          radios={[
                            // TODO better
                            {
                              label:
                                (nextMagazineIssues &&
                                  nextMagazineIssues[0] &&
                                  nextMagazineIssues[0].label) || // TODO si épuisé
                                'Numéro courant',
                              value: 'current',
                            },
                            { label: 'Numéro suivant', value: 'next' },
                          ]}
                          value={firstIssue}
                          onChange={(v) =>
                            this.onSelectfirstIssue(
                              v,
                              nextMagazineIssues &&
                                nextMagazineIssues[0] &&
                                nextMagazineIssues[0].label
                            )
                          }
                        />
                      </div>
                    )}

                    {nextMagazineIssues && (
                      <div className={baseClassName + '-product-list'}>
                        <h3>
                          {options.nextIssuesLabel ||
                            'Pré-commander le prochain numéro'}
                        </h3>
                        {_.map(
                          nextMagazineIssues,
                          (
                            product // TEMP test
                          ) => (
                            <div className="product" key={product._id}>
                              <RawComponent
                                id="AddToCartButton"
                                options={{
                                  mode: 'checkbox',
                                }}
                                componentProps={{
                                  entity: product,
                                }}
                              />
                            </div>
                          )
                        )}
                      </div>
                    )}
                  </div>
                )}
              </FadeInAndScrollTo>
            </div>
          )}

          {(this.state.paid || // confirmation
            (!_.isEmpty(this.state.cartItems) &&
              !(
                selectedFormula &&
                selectedFormula.requiresShipping &&
                selectedFormula.displayFirstIssue !== false &&
                !firstIssue
              ))) && ( // to pay
            <div className={baseClassName + '-cart-wrapper'}>
              <RawComponent
                id="CartCheckout"
                componentProps={{
                  onPaid: () =>
                    this.setState({
                      paid: true,
                      selectedFormula: null,
                      selectedGroup: null,
                    }),
                  justif: this.state.justif,
                  hideJustif: true,
                }}
                options={{
                  allowDonation:
                    (!selectedFormula || !selectedFormula.requireJustif) &&
                    _.get(options, 'allowDonation', true),
                  maxDonation: _.get(options, 'maxDonation'),
                  donationLabel: _.get(options, 'donationLabel'),
                  paidText: _.get(options, 'paidText'),
                  thankYouLabel: _.get(options, 'thankYouLabel'),
                  itemLabel: 'x',
                  hideQuantity: true,
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(SubscriptionGroupRegister);
