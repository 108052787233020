import React from 'react';
import Slider from 'react-slick';

import { ContentContext, LayoutSizeDivisionContext } from '@/globals/contexts';
import { useWindowSizeListener } from '@/hooks';

import { Icon } from '@/components/new';
import SingleEntity from '../../SingleEntity';

const baseClassName = 'PUF-contents-slider';

export default function ContentsSlider({
  data = [],
  mainClassName,
  options,
  style,
}) {
  const { label, delay, showCount, speed, showArrows, animType } = options;
  const { width: windowWidth } = useWindowSizeListener();
  const currentSizeDivision = React.useContext(LayoutSizeDivisionContext);

  if (!Array.isArray(data) || data.length === 0) return null;

  const isSlideshow = delay > 0;
  const initialSlidesToShow = showCount || (isSlideshow ? 1 : 4);
  const { slidesToShow, slidesToScroll } = getSlidesToShow(
    windowWidth,
    initialSlidesToShow
  );

  // see https://react-slick.neostack.com/docs/api#settings
  const settings = {
    speed: speed || 300,
    slidesToShow,
    slidesToScroll: isSlideshow ? 1 : slidesToScroll,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    arrows: !isSlideshow || showArrows,
    autoplay: isSlideshow,
    infinite: isSlideshow,
    autoplaySpeed: isSlideshow && options.delay * 1000,
    dots: true,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    fade: animType === 'fade',
    swipeToSlide: !isSlideshow,
    lazyLoad: false,
  };

  const newSizeDivision = currentSizeDivision * slidesToShow;

  return (
    <div
      className={mainClassName}
      data-slides-to-show={slidesToShow}
      style={style}
    >
      {label && <h2 className={`${baseClassName}-title`}>{label}</h2>}

      <LayoutSizeDivisionContext.Provider value={newSizeDivision}>
        <div className={`${baseClassName}-inner`}>
          <Slider className={`${baseClassName}-slider`} {...settings}>
            {data.map((content) => {
              return (
                <div
                  className={`${baseClassName}-slide`}
                  key={content._id}
                  data-size-division={newSizeDivision}
                  data-slider-division={slidesToShow}
                >
                  <ContentContext.Provider value={content}>
                    <SingleEntity mode={options.mode} />
                  </ContentContext.Provider>
                </div>
              );
            })}
          </Slider>
        </div>
      </LayoutSizeDivisionContext.Provider>
    </div>
  );
}

function PrevArrow({ onClick, className }) {
  return (
    <div onClick={onClick} className={className}>
      <Icon name="angle-left" />
    </div>
  );
}

function NextArrow({ onClick, className }) {
  return (
    <div onClick={onClick} className={className}>
      <Icon name="angle-right" />
    </div>
  );
}

ContentsSlider.baseClassName = baseClassName;

const calcSlidesToScroll = (slidesToShow) =>
  Math.max(1, Math.floor(slidesToShow / 2));

const getSlidesToShow = (windowWidth, initialSlidesToShow) => {
  if (windowWidth < 480) {
    return {
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  }

  if (windowWidth < 720) {
    const slidesToShow = Math.min(2, initialSlidesToShow);

    return {
      slidesToShow,
      slidesToScroll: calcSlidesToScroll(slidesToShow),
    };
  }

  if (windowWidth < 840) {
    const slidesToShow = Math.min(3, initialSlidesToShow - 1);

    return {
      slidesToShow,
      slidesToScroll: calcSlidesToScroll(slidesToShow),
    };
  }

  return {
    slidesToShow: initialSlidesToShow,
    slidesToScroll: calcSlidesToScroll(initialSlidesToShow),
  };
};
