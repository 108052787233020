import _ from 'lodash';
import React from 'react';

import { CartStore, CartStoreActions } from '@/reflux';
import { prettyPrice } from '@/utils/misc';

import FrontComponentBase from '../../FrontComponentBase';
import { Date } from '@/components/old';
import PageLink from '../navigation/PageLinkV1';

export default class CartGlobalQuantity extends FrontComponentBase {
  static baseClassName = 'CartGlobalQuantity';

  store = CartStore;

  didMount() {
    this.observeSingleEntity((entity) => {
      let { autoAddMainObject, emptyCart } = this.getOptions();

      if (emptyCart) {
        CartStoreActions.empty();
      }

      if (entity && autoAddMainObject) {
        CartStoreActions.empty();
        CartStoreActions.addItem(entity, 1);
      }
    });
  }

  render() {
    let { mode, pageId } = this.getOptions();
    let { multiplicator, cartErrors, cartTotal, cartItems } = this.state;

    let canIncrement = !_.find(
      cartItems,
      (item) =>
        _.isInteger(item.entity.remaining) &&
        item.entity.remaining < quantity + 1 // eslint-disable-line no-undef
    );

    let canDecrement = multiplicator > 1;

    const baseClassName = this.constructor.baseClassName;

    if (mode === 'hidden') return null;

    return (
      (mode === 'globalQuantity' && (
        <div className={this.getMainCSSClass()}>
          <div className={baseClassName + '-mult'}>
            {!_.isEmpty(cartItems) && (
              <>
                <span className={baseClassName + '-mult-label'}>
                  Nombre de places souhaitées :
                </span>

                <span className={baseClassName + '-mult-nw'}>
                  <span
                    className={
                      baseClassName +
                      '-mult-button ' +
                      (canDecrement ? '' : ' disabled')
                    }
                    onClick={() =>
                      canDecrement &&
                      CartStoreActions.addItemMultiplicator(multiplicator - 1)
                    }
                  >
                    -
                  </span>

                  <input
                    className={baseClassName + '-mult-number'}
                    type="number"
                    min={1}
                    onChange={(e) =>
                      CartStoreActions.addItemMultiplicator(~~e.target.value)
                    }
                    value={multiplicator}
                  ></input>

                  <span
                    className={
                      baseClassName +
                      '-mult-button ' +
                      (canIncrement ? '' : ' disabled')
                    }
                    onClick={() =>
                      canIncrement &&
                      CartStoreActions.addItemMultiplicator(multiplicator + 1)
                    }
                  >
                    +
                  </span>
                </span>
              </>
            )}
          </div>
          <div className={baseClassName + '-total'}>
            <span>Total :</span>
            <span>{prettyPrice(cartTotal, { forceDigits: true })}</span>
          </div>

          <div className={baseClassName + '-errors'}>
            {_.map(
              cartErrors,
              (error, i) =>
                (error.entity._cls === 'Conference' && (
                  <div className="error">
                    {error.reason} pour la conférence du{' '}
                    <Date ts={error.entity.eventTs} format="L" />
                  </div>
                )) || (
                  <div className="error">
                    {(error.code === 3 &&
                      "Il ne reste pas assez de places disponibles pour '" +
                        error.entity.label +
                        "'") ||
                      error.reason}
                  </div>
                )
            )}
          </div>

          {!_.isEmpty(cartItems) && (
            <div className={baseClassName + '-validate'}>
              <PageLink
                pageId={
                  pageId || 'buyConferences' // retro-compat
                }
              >
                <a className={'standard-button'}>Valider</a>
              </PageLink>
            </div>
          )}
        </div>
      )) ||
      (!_.isEmpty(cartItems) && (
        <div className={baseClassName + '-validate'}>
          <PageLink pageId={pageId}>
            <a className={'standard-button'}>Voir le panier</a>
          </PageLink>
        </div>
      ))
    );
  }
}
