// @flow

import * as React from 'react';

import { Modal } from '@/components/old';

type Props = {|
  children: React.Node,
  isViewerOpen: boolean,
  closeImageViewer: () => void,
|};

export default function FullScreenImageViewer({
  children,
  isViewerOpen,
  closeImageViewer,
}: Props): React.Node {
  return isViewerOpen && <Modal onClose={closeImageViewer}>{children}</Modal>;
}
