/**
 
 * Component used to display question thumbs
 * a question can have an id, a title, an eventTs, an author, a vote count
 *
 */

import moment from 'moment';
import React from 'react';

import API from '@/utils/API';
import { InstanceStore, UserStore, UserActions } from '@/reflux';
import { SiteContext, ContentContext } from '@/globals/contexts';
import { withContext } from '@/utils/HOC';

import FrontComponentBase from '../../../FrontComponentBase';
import SingleEntity from '../../../SingleEntity';
import { Modal } from '@/components/old';
import Login from '../../../base/login/Login';
import { ReportButton } from '@/PUFComponents/components';

class QuestionsThumb extends FrontComponentBase {
  static baseClassName = 'QuestionsThumb';

  constructor(props) {
    super(props);
    this.state = {
      isLiked: false,
      cantLikeModal: false,
    };
  }

  // set user state
  stores = [UserStore, InstanceStore];

  didMount() {
    let bindLikes = this.getRebindToObjectFunc('likes');
    this.observeSingleEntity((question) => {
      bindLikes('likes_' + question._cls, question._id);
    });
  }

  logout = () => {
    UserActions.logout();
  };

  handleLike = () => {
    if (this.state.user) {
      let question = this.getSingleEntity();
      let { instanceName } = this.state;

      if (this.state.likes && this.state.likes.userLikes) {
        API.post(
          instanceName +
            '/like/' +
            question._cls +
            '/' +
            encodeURIComponent(question._id),
          { value: 0 }
        ).then((likes) => this.setState({ likes }));
      } else {
        API.post(
          instanceName +
            '/like/' +
            question._cls +
            '/' +
            encodeURIComponent(question._id),
          { value: 1 }
        ).then((likes) => this.setState({ likes }));
      }
    } else {
      this.setState({ cantLikeModal: true });
    }
  };

  closeLikeModal = () => {
    this.setState({ cantLikeModal: false });
  };

  render() {
    //let content = this.props.data && this.props.data.mainObject || this.props.content;
    let question = this.getSingleEntity();
    let { likes } = this.state;

    if (!question) return null;

    let { eventTs, publishTs, label, author } = question;
    let baseClassName = this.constructor.baseClassName;
    let componentMainClass = baseClassName;
    if (likes && likes.userLikes) {
      componentMainClass += ' ' + baseClassName + '--voted';
    }

    let likeCount = (likes && likes.likeScore) || 0; // better than likeCount if we introduce vote-down

    if (likeCount > 1) {
      likeCount += ' votes';
    } else {
      likeCount += ' vote';
    }

    moment.locale('fr');
    let displayedDate =
      publishTs &&
      ' le ' + moment.unix(eventTs || publishTs).format('Do MMMM YYYY');

    const { reportableContents } = this.props.siteContext.site;
    const isReportable = reportableContents?.includes('Question');

    return (
      <>
        <div className={componentMainClass}>
          <div className={baseClassName + '-content'}>
            {author && (
              <div className={baseClassName + '-content-header'}>
                <ContentContext.Provider value={author}>
                  <SingleEntity mode="name" options={{ createLink: false }} />
                </ContentContext.Provider>
              </div>
            )}

            <div className={baseClassName + '-content-body'}>
              <div className={baseClassName + '-content-body-desc'}>
                {label}
              </div>

              <div className={baseClassName + '-content-body-date'}>
                {displayedDate && <span>{displayedDate}</span>}
              </div>
            </div>
          </div>

          <div className={baseClassName + '-vote'}>
            {likes && (
              <div>
                <div className={baseClassName + '-vote-count'}>{likeCount}</div>
                <button
                  className={baseClassName + '-vote-button'}
                  onClick={() => this.handleLike()}
                >
                  {(likes.userLikes && <span>Votée &#10004;</span>) || (
                    <span>Voter</span>
                  )}
                </button>
              </div>
            )}

            {isReportable && (
              <ReportButton
                contentId={question._id}
                contentType={question._cls}
                contentText={question.label}
              />
            )}
          </div>
        </div>

        {this.state.cantLikeModal && (
          <Modal
            title="Se connecter"
            size="half"
            onClose={this.closeLikeModal}
            contentClassName={baseClassName + '-modal'}
          >
            <p>Vous devez vous connecter pour pouvoir participer :</p>
            <Login onSuccess={this.closeLikeModal} />
          </Modal>
        )}
      </>
    );
  }
}

export default withContext(SiteContext, 'siteContext', QuestionsThumb);
