import { withRouter } from 'next/router';
import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';

import { SiteContext } from '@/globals/contexts';
import { withLinks } from '@/utils/HOC';
import { UserStore, InstanceStore, UserActions } from '@/reflux';
import ApiClient from '@/utils/ApiClient';
import { isValidEmail } from '@/utils/misc';

import PageLink from '../navigation/PageLinkV1';
import ComponentBase from '../../ComponentBase';
import {
  InputTextBase,
  InputCheckboxBase,
  Button,
  EyeIconOn,
  EyeIconOff,
} from '@/components/old';

class Login extends ComponentBase {
  constructor(props) {
    super(props);

    this.state = {
      login: '',
      password: '',
      viewPass: false,

      shouldStayConnected: false,
    };

    this.stores = [InstanceStore, UserStore];
  }

  componentDidMount() {
    const { router } = this.props;
    const { query } = router;

    if (query.login && query.password) {
      UserActions.login(
        {
          login: query.login,
          password: query.password,
        },
        (err) =>
          !err ? router.push('/my-account/profile') : this.onLoadUserError(err)
      );
    }
  }

  onStartLoading = () => {
    this.setState({ loading: true, error: null });
  };

  onLoadUser = () => {
    this.setState({ loading: false });
    this.props.onSuccess && this.props.onSuccess();
  };

  onLoadUserError = (err) => {
    this.setState({ loading: false, error: err.message }); // TEMP
  };

  login = () => {
    let { login, password, shouldStayConnected } = this.state;
    this.onStartLoading();
    UserActions.login(
      {
        login: login,
        password: password,
        rememberMe: shouldStayConnected,
      },
      (err) => (!err ? this.onLoadUser() : this.onLoadUserError(err))
    );
  };

  toggleViewPassword = () => {
    this.setState({ viewPass: !this.state.viewPass }, this.onValidate);
  };

  stayConnected = (value) => {
    this.setState({ shouldStayConnected: value });
  };

  onChangeLogin = (login) => {
    this.setState({ login, resetPassSent: false, loginStatus: null });

    if (isValidEmail(login)) {
      UserActions.checkLoginConnectable(login, (loginStatus) =>
        this.setState({ loginStatus })
      );
    }
  };

  sendResetPasswordRequest = () => {
    let { login } = this.state;

    this.onStartLoading();
    ApiClient.post(
      'users',
      'forgotPass',
      { login },
      () => this.setState({ loading: false, resetPassSent: true }),
      (err) => this.setState({ loading: false, error: err.message })
    );
  };

  render() {
    const { links } = this.props;
    let { login, password, error, loading, viewPass, shouldStayConnected } =
      this.state;
    let { resetPassSent, loginStatus } = this.state;
    const displayRegister = !loginStatus || loginStatus === 'not_found';

    // TODO check autocomplete https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#inappropriate-for-the-control

    return (
      <SiteContext.Consumer>
        {(siteContext) => {
          return (
            <div
              className={classnames('LoginBase', {
                'with-register': displayRegister,
              })}
            >
              <form
                className="RegularForm RegularForm--login"
                onSubmit={this.login}
              >
                <div className="RegularForm-field">
                  <label className="RegularForm-field-label">
                    <span className="RegularForm-field-label-inner">Email</span>
                  </label>

                  <InputTextBase
                    name="login"
                    autoComplete="email"
                    placeholder="E-mail"
                    type="email"
                    value={login || ''}
                    onChange={this.onChangeLogin}
                  />
                </div>
                {(resetPassSent && (
                  <div>
                    <h2>Merci !</h2>
                    <p>
                      Il ne vous reste plus qu'à suivre les instructions
                      contenues dans le-mail qui vous a été envoyé.
                    </p>
                    <i>
                      Si vous n'avez pas reçu le mail, pensez éventuellement à
                      vérifier vos SPAM.
                    </i>
                  </div>
                )) ||
                  (loginStatus === 'paswword_to_set' && (
                    <div>
                      <h2>Vous devez activer votre compte</h2>
                      <p>
                        Cliquez sur le bouton ci-dessous afin de recevoir un
                        e-mail permettant de créer votre mot de passe
                      </p>
                      <Button
                        isSubmit={false}
                        onClick={this.sendResetPasswordRequest}
                      >
                        Recevoir l'e-mail pour activer mon compte
                      </Button>
                    </div>
                  )) || (
                    <>
                      <div className="RegularForm-field">
                        <label className="RegularForm-field-label">
                          <span className="RegularForm-field-label-inner">
                            Mot de passe
                          </span>
                        </label>

                        <div className="RegularForm-field--row">
                          <InputTextBase
                            name="password"
                            autoComplete="current-password"
                            placeholder="Mot de passe"
                            value={password || ''}
                            type={viewPass ? 'text' : 'password'}
                            onChange={(v) => this.setState({ password: v })}
                          />

                          <span
                            onClick={this.toggleViewPassword}
                            className={
                              viewPass
                                ? 'display-password active'
                                : 'display-password'
                            }
                            title="Afficher le mot de passe"
                          >
                            {viewPass ? <EyeIconOn /> : <EyeIconOff />}
                          </span>
                        </div>
                      </div>

                      <div className="LoginBase-forgot-pass">
                        <PageLink link={links.login.forgotPass}>
                          <a className="LoginBase-forgot-pass-link">
                            Mot de passe oublié
                          </a>
                        </PageLink>
                      </div>

                      <div className="stay-connected">
                        <div className="RegularForm-field RegularForm-field--checkbox ">
                          <InputCheckboxBase
                            label="Rester connecté"
                            value={shouldStayConnected}
                            onChange={(value) => this.stayConnected(value)}
                            clickableLabel={true}
                          />
                        </div>
                        <p className="RegularForm-description">
                          <small>
                            Ne pas cocher cette case sur un ordinateur public.
                          </small>
                        </p>
                      </div>

                      <div className="RegularForm-field">
                        <Button isSubmit={true} onClick={this.login}>
                          {_.get(
                            siteContext,
                            'siteConfig.options.loginLabel'
                          ) || 'Se connecter'}
                        </Button>
                      </div>
                    </>
                  )}

                {loading && <span>Connexion en cours... </span>}

                {error && <span className="error">{error}</span>}
              </form>

              {displayRegister && (
                <>
                  <hr className="seperator" />

                  <div className="LoginBase-subscribe RegularForm">
                    <h3 className="LoginBase-subscribe-title">
                      Pas encore inscrit ?
                    </h3>
                    <PageLink
                      link={links.login.register}
                      query={{
                        login: this.state.login,
                        from: this.props.from || this.props.router.asPath,
                      }}
                    >
                      <a className="LoginBase-subscribe-link">
                        {_.get(
                          siteContext,
                          'siteConfig.options.registerLabel'
                        ) || "S'abonner"}
                      </a>
                    </PageLink>
                  </div>
                </>
              )}
            </div>
          );
        }}
      </SiteContext.Consumer>
    );
  }
}

export default withRouter(withLinks(Login));
