import React from 'react';
import { useInterval } from 'usehooks-ts';
import { useRouter } from 'next/router';

import { useGlobal } from '@/hooks';
import API from '@/utils/API';
import { PaywallContext } from '@/globals/contexts';
import { readFromLocalStorage } from '@/helpers/storage';

import FutureGames from './FutureGames';
import PastGames from './PastGames';
import RankingTable from './RankingTable';
import { Flex, Tabs, Icon, Image, Notification } from '@/components/new';
import TournamentIntro from './TournamentIntro';
import CommentListV2 from '../../commentsV2';
import { RichTextContent } from '@/components/old';

const REFRESH_INTERVAL = 1000 * 60 * 5; // In milliseconds (5 minutes);
// const REFRESH_INTERVAL = 1000 * 30 * 1; // In milliseconds (30 seconds);
const LOGIN_FIELDS = [
  'login',
  'pass',
  'confirmPass',
  'nickname',
  'receiveNewsletter',
  'receiveSpam',
];

export default function FullTournament({ entity }) {
  const { siteId, user, site } = useGlobal();
  const { openModal } = React.useContext(PaywallContext);

  const [tournament, setTournament] = React.useState(entity);
  const { asPath } = useRouter();
  const hash = (asPath || '').split('#')[1];

  const {
    futureGames,
    pastGames,
    globalRanking,
    teams,
    _id: tournamentId,
    rules,
    highlightedUserRanking,
    linkToParticipant,
    participantCount,
    image,
    label,
    commentsCount,
    notification,
  } = tournament;
  const mustSeeIntro =
    !tournament.userRanking &&
    !readFromLocalStorage(`${tournamentId}_hasSeenIntro`);

  const [userBets, setUserBets] = React.useState(tournament.userBets);
  const [userRanking, setUserRanking] = React.useState(tournament.userRanking);

  const initialTab = [
    'future-games',
    'past-games',
    'ranking',
    'comments',
  ].includes(hash)
    ? hash
    : futureGames.length > 0
    ? 'future-games'
    : 'past-games';
  const [currentTab, setCurrentTab] = React.useState(initialTab);
  const [refresher, setRefresher] = React.useState(0);
  const unauthorized = 'authorized' in tournament && !tournament.authorized;

  const fetchUserInfo = React.useCallback(async () => {
    if (!user) {
      setUserBets({});
      setUserRanking(null);
      return;
    }

    const result = await API.get(
      `${siteId}/tournaments/${tournamentId}/user-results`
    );

    const { bets: fetchedUserBets, ranking: fetchedUserRanking } = result;

    setUserBets(fetchedUserBets);
    setUserRanking(fetchedUserRanking);
  }, [siteId, user, tournamentId, setUserBets, setUserRanking]);

  const refetchTournament = React.useCallback(async () => {
    const fetchedTournament = await API.get(
      `${siteId}/tournaments/${tournamentId}`
    );

    setTournament(fetchedTournament);
  }, [siteId, tournamentId]);

  React.useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  useInterval(() => {
    refetchTournament();
  }, REFRESH_INTERVAL);

  React.useEffect(() => {
    setTournament(entity);
    setUserBets(entity.userBets);
    setUserRanking(entity.userRanking);
  }, [entity, setTournament]);

  const makeBet = React.useCallback(
    async (gameId, { winnerId, score }) => {
      if (unauthorized) {
        openModal({
          content: tournament,
          options: {
            fields: LOGIN_FIELDS,
          },
        });
        return;
      }

      const bet = await API.post(`${siteId}/tournaments/${tournamentId}/bet`, {
        gameId,
        winnerId,
        score,
      });

      setUserBets((prevBets) => ({ ...prevBets, [gameId]: bet }));
    },
    [setUserBets, siteId, tournament, tournamentId, unauthorized, openModal]
  );

  const tabsItems = [
    {
      id: 'future-games',
      label: <span>A venir</span>,
      onClick: () => setCurrentTab('future-games'),
      active: currentTab === 'future-games',
      disabled: futureGames.length === 0,
    },
    {
      id: 'past-games',
      label: <span>Passés</span>,
      onClick: () => setCurrentTab('past-games'),
      active: currentTab === 'past-games',
      disabled: pastGames.length === 0,
    },
    {
      id: 'ranking',
      label: <span>Classement</span>,
      onClick: () => setCurrentTab('ranking'),
      active: currentTab === 'ranking',
      // disabled: globalRanking.length === 0, // Should be disabled but the rules are displayed so it has to stay active
    },
    {
      id: 'comments',
      label: (
        <div>
          <Icon name="comments" />
          <span className="comments-count tag">{commentsCount}</span>
        </div>
      ),
      onClick: () => setCurrentTab('comments'),
      active: currentTab === 'comments',
    },
  ];

  if (mustSeeIntro) {
    return (
      <div className="tournament tournament-full">
        <Flex
          direction="column"
          additionalClassName="tournament-header"
          horizontalAlign
        >
          <Image
            src={image}
            width={300}
            height={176}
            alt={`Logo ${label}`}
            defaultNextImage
          />
        </Flex>

        <TournamentIntro
          tournament={tournament}
          refresh={() => setRefresher(refresher + 1)}
        />
      </div>
    );
  }

  return (
    <div className="tournament tournament-full">
      <Flex
        direction="column"
        additionalClassName="tournament-header"
        horizontalAlign
      >
        <Image
          src={image}
          width={200}
          height={117.34}
          alt={`Logo ${label}`}
          defaultNextImage
          additionalClassName="tournament-logo"
        />

        {notification && (
          <Notification additionalClassName="tournament-notification">
            <RichTextContent html={notification} />
          </Notification>
        )}

        <Tabs items={tabsItems} size="normal" />
      </Flex>

      {currentTab === 'future-games' && (
        <FutureGames
          teams={teams}
          futureGames={futureGames}
          userBets={userBets}
          makeBet={makeBet}
          linkToParticipant={linkToParticipant}
        />
      )}

      {currentTab === 'past-games' && (
        <PastGames
          pastGames={pastGames}
          userBets={userBets}
          teams={teams}
          linkToParticipant={linkToParticipant}
        />
      )}

      {currentTab === 'ranking' && (
        <RankingTable
          globalRanking={globalRanking}
          userRanking={userRanking}
          rules={rules}
          highlightedUserRanking={highlightedUserRanking}
          participantCount={participantCount}
        />
      )}

      {currentTab === 'comments' && (
        <div className="comments-tab-content">
          <CommentListV2
            user={user}
            entity={tournament}
            site={site}
            siteId={siteId}
          />
        </div>
      )}
    </div>
  );
}
